import React, { useEffect, useState } from "react";
import { Container, Row, Col, Input } from "reactstrap";
import styled from "styled-components";
import { Colors } from "../../utils/constants";
import { Copy } from "./module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { 
    ColoredH2, 
    StyledHR, 
    ColoredOL, 
    StyledP, 
    CenterTitleFlexCol, 
    WhiteH2, 
    StyledBoldP, 
    ColoredUL, 
    CenterFlexCol, 
    StyledButton, 
    CompletedP, 
    CompletedDiv, 
    FlexCol,
} from "../../styles/styledComponents";
import { Event } from "../../utils/analytics";
import HomeworkImage from "../../images/homework.png";
import StarsImage from '../../images/stars.png';
import MeditationImage from '../../images/meditation.png';
import EmotionsImage from '../../images/emotions.png';

export const Module4 = ( props ) => {

    const [user, setUser] = useState();

    const [input1, setInput1] = useState((props.user.moduleData !== undefined && props.user.moduleData.module4 !== undefined && props.user.moduleData.module4.input1 != undefined) ? props.user.moduleData.module4.input1.input1 : "");
    const [input2, setInput2] = useState((props.user.moduleData !== undefined && props.user.moduleData.module4 !== undefined && props.user.moduleData.module4.input2 != undefined) ? props.user.moduleData.module4.input2.input2 : "");
    const [input3, setInput3] = useState((props.user.moduleData !== undefined && props.user.moduleData.module4 !== undefined && props.user.moduleData.module4.input3 != undefined) ? props.user.moduleData.module4.input3.input3 : "");
    const [input4, setInput4] = useState((props.user.moduleData !== undefined && props.user.moduleData.module4 !== undefined && props.user.moduleData.module4.input4 != undefined) ? props.user.moduleData.module4.input4.input4 : "");
    const [input5, setInput5] = useState((props.user.moduleData !== undefined && props.user.moduleData.module4 !== undefined && props.user.moduleData.module4.input5 != undefined) ? props.user.moduleData.module4.input5.input5 : "");
    const [input6, setInput6] = useState((props.user.moduleData !== undefined && props.user.moduleData.module4 !== undefined && props.user.moduleData.module4.input6 != undefined) ? props.user.moduleData.module4.input6.input6 : "");

    //These are taken from Module 1
    const [input13, setInput13] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input13 != undefined) ? props.user.moduleData.module1.input13.input13 : "");
    const [input14, setInput14] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input14 != undefined) ? props.user.moduleData.module1.input14.input14 : "");
    const [input15, setInput15] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input15 != undefined) ? props.user.moduleData.module1.input15.input15 : "");
    const [input16, setInput16] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input16 != undefined) ? props.user.moduleData.module1.input16.input16 : "");
    const [input17, setInput17] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input17 != undefined) ? props.user.moduleData.module1.input17.input17 : "");
    const [input18, setInput18] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input18 != undefined) ? props.user.moduleData.module1.input18.input18 : "");

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const UpdateInput = (inputKey, inputValue) => {
        var data = user;
        data.moduleData.module4[inputKey] = inputValue;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    const completeModule = () => {
        Event("Module Completed", "Mental Module", "Module 4");
        var data = user;
        data.modules.module4 = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module 4"].SubTitle1[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle2[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph1[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <WhiteCol>
                        <Container>
                            <Row>
                                <Col md="4" xs="12"><StyledP>{Copy["Module 4"].Table1R1C1[props.user.language]}</StyledP></Col>
                                <Col md="2" xs="12"><StyledBoldP>{Copy["Module 4"].Table1R1C2[props.user.language]}</StyledBoldP></Col>
                                <Col md="2"  xs="12"><StyledP>{Copy["Module 4"].Table1R2C1[props.user.language]}</StyledP></Col>
                                <Col md="4" xs="12"><StyledBoldP>{Copy["Module 4"].Table1R2C2[props.user.language]}</StyledBoldP></Col>
                            </Row>
                        </Container>
                    </WhiteCol>
                </Row>
                <Row>
                    <WhiteCol>
                        <Container>
                            <Row>
                                <Col md="4" xs="12"><StyledP>{Copy["Module 4"].Table1R1C1[props.user.language]}</StyledP></Col>
                                <Col md="2" xs="12"><StyledBoldP>{Copy["Module 4"].Table1R1C3[props.user.language]}</StyledBoldP></Col>
                                <Col md="2"  xs="12"><StyledP>{Copy["Module 4"].Table1R2C1[props.user.language]}</StyledP></Col>
                                <Col md="4" xs="12"><StyledBoldP>{Copy["Module 4"].Table1R2C3[props.user.language]}</StyledBoldP></Col>
                            </Row>
                        </Container>
                    </WhiteCol>
                </Row>
                <Row>
                    <WhiteCol>
                        <Container>
                            <Row>
                                <Col md="4" xs="12"><StyledP>{Copy["Module 4"].Table1R1C1[props.user.language]}</StyledP></Col>
                                <Col md="2" xs="12"><StyledBoldP>{Copy["Module 4"].Table1R1C4[props.user.language]}</StyledBoldP></Col>
                                <Col md="2"  xs="12"><StyledP>{Copy["Module 4"].Table1R2C1[props.user.language]}</StyledP></Col>
                                <Col md="4" xs="12"><StyledBoldP>{Copy["Module 4"].Table1R2C4[props.user.language]}</StyledBoldP></Col>
                            </Row>
                        </Container>
                    </WhiteCol>
                </Row>
            </Container>
            <Container>
                <Row>
                    <CenterFlexVerticalCol xs="12" md="3">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module 4"].SubTitleTalk[props.user.language]}</ColoredH2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledHR></StyledHR>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 4"].Paragraph3[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 4"].Paragraph4[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 4"].Paragraph5[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 4"].Paragraph6[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
                <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].List1Title[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy["Module 4"].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List1Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List1Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List1Item4[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
            </Container>
            <BorderContainer>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph7[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 4"].Paragraph8[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].Paragraph9[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </BorderContainer>
            <br />
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module 4"].SubTitle4[props.user.language]}</ColoredH2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledHR></StyledHR>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 4"].Paragraph10[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 4"].Paragraph11[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module 4"].SubTitle5[props.user.language]}</ColoredH2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledHR></StyledHR>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module 4"].List2Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module 4"].List2Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module 4"].List2Item3[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module 4"].List2Item4[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module 4"].SubTitle6[props.user.language]}</ColoredH2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledHR></StyledHR>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 4"].Paragraph12[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 4"].Paragraph13[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <br />
                        </Container>
                    </Col>
                    <Col xs="12" md="6">
                        <PhysicalActivityIMG src={MeditationImage}></PhysicalActivityIMG>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle7[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 4"].List3Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List3Item2[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module 4"].SubTitle8[props.user.language]}</ColoredH2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledHR></StyledHR>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 4"].Paragraph14[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 4"].Paragraph15[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 4"].Paragraph16[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                        </Container>
                        <br />
                    </Col>
                    <CenterFlexVerticalCol xs="12" md="3">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle9[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 4"].List4Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List4Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List4Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List4Item4[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module 4"].SubTitle10[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle11[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph17[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 4"].List5Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List5Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List5Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List5Item4[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle12[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph18[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 4"].List6Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List6Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List6Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List6Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List6Item5[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle13[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].ParagraphExtra1[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 4"].List6aItem1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List6aItem2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List6aItem3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List6aItem4[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle13a[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph18a[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 4"].List6bItem1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List6bItem2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].List6bItem3[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                        <EmotionsIMG src={EmotionsImage}></EmotionsIMG>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle14[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph19[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph20[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle15[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph21[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph22[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph23[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
            </Container>
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle16[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 4"].Paragraph24[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 4"].Paragraph25[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                    <Col xs="12" md="5">
                        <HomeworkIMG src={HomeworkImage}></HomeworkIMG>
                    </Col>
                </Row>
            </Container>
            <ColoredContainer>
                <Row>
                    <CenterFlexCol>
                        <StyledWhiteP>{Copy["Module 1"].SubTitle4[props.user.language]}</StyledWhiteP>
                    </CenterFlexCol>
                </Row>
                <Row>
                    <CenterFlexCol>
                        <StyledWhiteP>{Copy["Module 1"].SubTitle5[props.user.language]}</StyledWhiteP>
                    </CenterFlexCol>
                </Row>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <FlexCol md="2" xs="12">
                                    <StyledWhiteP>{Copy["Module 1"].Input11Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol md="2" xs="8">
                                    1<SliderInput type="range" min="1" max="10" value={input1} onChange={e => setInput1(e.target.value)} onBlur={() => {UpdateInput("input1", {input1})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol md="2"  xs="4">
                                    <ResultP>{input1}</ResultP>
                                    <OldResultP>{input13}</OldResultP>
                                </FlexCol>
                                <FlexCol md="2" xs="12">
                                    <StyledWhiteP>{Copy["Module 1"].Input12Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol md="2" xs="8">
                                    1<SliderInput type="range" min="1" max="10" value={input2} onChange={e => setInput2(e.target.value)} onBlur={() => {UpdateInput("input2", {input2})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol md="2"  xs="4">
                                    <ResultP>{input2}</ResultP>
                                    <OldResultP>{input14}</OldResultP>
                                </FlexCol>
                            </Row>
                            <Row>
                                <FlexCol md="2" xs="12">
                                    <StyledWhiteP>{Copy["Module 1"].Input13Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol md="2" xs="8">
                                    1<SliderInput type="range" min="1" max="10" value={input3} onChange={e => setInput3(e.target.value)} onBlur={() => {UpdateInput("input3", {input3})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol md="2"  xs="4">
                                    <ResultP>{input3}</ResultP>
                                    <OldResultP>{input15}</OldResultP>
                                </FlexCol>
                                <FlexCol md="2" xs="12">
                                    <StyledWhiteP>{Copy["Module 1"].Input14Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol md="2" xs="8">
                                    1<SliderInput type="range" min="1" max="10" value={input4} onChange={e => setInput4(e.target.value)} onBlur={() => {UpdateInput("input4", {input4})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol md="2"  xs="4">
                                    <ResultP>{input4}</ResultP>
                                    <OldResultP>{input16}</OldResultP>
                                </FlexCol>
                            </Row>
                            <Row>
                                <FlexCol md="2" xs="12">
                                    <StyledWhiteP>{Copy["Module 1"].Input15Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol md="2" xs="8">
                                    1<SliderInput type="range" min="1" max="10" value={input5} onChange={e => setInput5(e.target.value)} onBlur={() => {UpdateInput("input5", {input5})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol md="2"  xs="4">
                                    <ResultP>{input5}</ResultP>
                                    <OldResultP>{input17}</OldResultP>
                                </FlexCol>
                                <FlexCol md="2" xs="12">
                                    <StyledWhiteP>{Copy["Module 1"].Input16Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol md="2" xs="8">
                                    1<SliderInput type="range" min="1" max="10" value={input6} onChange={e => setInput6(e.target.value)} onBlur={() => {UpdateInput("input6", {input6})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol md="2"  xs="4">
                                    <ResultP>{input6}</ResultP>
                                    <OldResultP>{input18}</OldResultP>
                                </FlexCol>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </ColoredContainer>
            <Container>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph27[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph28[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
            </Container>
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 4"].SubTitle17[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph29[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph30[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph31[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph32[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph33[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph34[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 4"].Paragraph35[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </Container>
            {user !== undefined && <Container>
                <Row>
                    <CenterFlexCol>
                        {user.modules.module4 ? <CompletedDiv><CompletedP>Module Completed</CompletedP></CompletedDiv> : <StyledButton onClick={() => completeModule()} color="primary">Complete Module</StyledButton>}
                    </CenterFlexCol>
                </Row>
            </Container>}
        </React.Fragment>
    );
}

export default Module4;

const StarsIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;

const BorderContainer = styled(Container)`
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
    padding: 10px;
`;

const PhysicalActivityIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const EmotionsIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const HomeworkIMG = styled.img`
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
`;

const WhiteCol = styled(Col)`
    background-color: white;
    color: black;
    border-color: ${Colors.primaryblue};
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 6px;
`;

const ColoredContainer = styled(Container)`
    background-color: ${Colors.primaryblue};
    color: white;
    border-radius: 10px;
    padding-bottom: 10px;
    padding-top: 5px;
`;

const StyledWhiteP = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
    font-weight: normal;
    display: inline;
`;

const SliderInput = styled(Input)`
    color: ${Colors.primaryblue};
    max-width: 85%;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
`;

const ResultP = styled.p`
    font-weight: bold;
    background-color: white;
    color: black;
    border-style: solid;
    border-color: ${Colors.primaryblue};
    border-width: 1px;
    border-radius: 5px;
    margin: 0px;
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 2px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
`;

const OldResultP = styled.p`
    font-weight: bold;
    background-color: white;
    color: black;
    border-style: solid;
    border-color: ${Colors.primaryblue};
    border-width: 1px;
    border-radius: 5px;
    margin: 0px;
    margin-left: 5px;
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 2px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    opacity: 0.6;
`;