import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Button } from 'reactstrap';
import SubHeader from "../../common/sub-header";
import { listenToDocumentProperty } from "../../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../../utils/firestore";
import CommunitySection from "./community-section";
import InHouseArticle from "./in-house-article";
import { useParams } from "react-router-dom";
import { Colors } from "../../../utils/constants";

export const Community = () => {
    
    const [pictureURL, setPictureURL] = useState("https://team-unbreakable-space.nyc3.cdn.digitaloceanspaces.com/pictures/background-events.jpg");
    const [articles, setArticles] = useState({})
    const [events, setEvents] = useState({});
    const [pressReleases, setPressReleases] = useState({});
    const [backgrounders, setBackgrounders] = useState({});
    const [currentlyViewedArticle, setCurrentlyViewedArticle] = useState();
    const [viewingArticle, setViewingArticle] = useState(false);

    let { subheader } = useParams();
    if (subheader === undefined) {
        subheader = "Community Events";
        window.history.replaceState(null, "", "/Community/Community Events");
    }
    const [content, setContent] = useState(subheader);

    useEffect(() => {
        window.history.replaceState(null, "", "/Community/" + content);
    }, [content])

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "media", "articles", setArticles);
        return unsubscribe;
    }, [])

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "media", "events", setEvents);
        return unsubscribe;
    }, [])

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "media", "pressreleases", setPressReleases);
        return unsubscribe;
    }, [])

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "media", "backgrounders", setBackgrounders);
        return unsubscribe;
    }, [])

    const showArticle = (info) =>{
        setCurrentlyViewedArticle(info);
        setViewingArticle(true);
    }

    const stopViewingArticle = () => {
        setViewingArticle(false);
    }

    return(
        <React.Fragment>
            <MainDiv>
                <SubHeader setBackground={setPictureURL} setContent={setContent} selected={content} options={[
                    {_id: "Community Events", _background: "https://team-unbreakable-space.nyc3.cdn.digitaloceanspaces.com/pictures/background-events.jpg"},
                    {_id: "Articles", _background: "https://team-unbreakable-space.nyc3.cdn.digitaloceanspaces.com/pictures/background-media.jpg"},
                    {_id: "Press Releases"},
                    {_id: "Backgrounders"}
                ]}></SubHeader>
                <OffsetDiv></OffsetDiv> 
                <br/>
                {(content === "Articles" && !viewingArticle) && <CommunitySection contentName="Articles" currentContent={content} articles={articles} openArticleFunction={showArticle}/>}
                {(content === "Community Events" && !viewingArticle) && <CommunitySection contentName="Community Events" currentContent={content} articles={events} openArticleFunction={showArticle}/>}
                {(content === "Press Releases" && !viewingArticle) && <CommunitySection contentName="Press Releases" currentContent={content} articles={pressReleases} openArticleFunction={showArticle}/>}
                {(content === "Backgrounders" && !viewingArticle) && <CommunitySection contentName="Backgrounders" currentContent={content} articles={backgrounders} openArticleFunction={showArticle}/>}
                
                {viewingArticle && <InHouseArticle info={currentlyViewedArticle}/>}
                {viewingArticle && <BackButton onClick={stopViewingArticle}>Go Back</BackButton>}
            </MainDiv>
        </React.Fragment>
    );
}

export default Community;

const BackButton = styled(Button)`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;

    font-size: large;
    font-family: Roboto;
    text-align: center;
    color: black;
    border-radius: 10px;
    background: ${Colors.primaryblue};
    color: white;
    border-width: 1px;
    border-style: solid;
    &:hover {
        background: white;
        cursor: pointer;
        border-radius: 10px;
        border-color: ${Colors.primaryblue};
        border-width: 1px;
        border-style: solid;
        color: black;
    }
`

const MainDiv = styled.div`
background-color: ${Colors.primarybackground};
min-height: ${window.innerHeight - 149}px;
`;

const OffsetDiv = styled.div`
    height: 180px;
`;
