import React, { useState, useEffect } from "react";
import { Container, Row, Col, Collapse, Button, InputGroup, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { teamUnbreakableDB, generateGUID } from "../../utils/firestore";
import { listenToDocumentProperty, updateDocumentProperty } from "../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import AdminMediaArticle from "./admin-community-article";
import firebase from "firebase/app";

export const AdminCommunity = () => {
 
    const [articles, setArticles] = useState({});
    const [events, setEvents] = useState({});
    const [backgrounders, setBackgrounders] = useState({});
    const [pressReleases, setPressReleases] = useState({});

    const [tabShowing, setTabShowing] = useState(-1);

    const [order, setOrder] = useState(0);
    const [title, setTitle] = useState("");
    const [urlDescription, setURLDescription] = useState("");
    const [url, setUrl] = useState("");
    const [date, setDate] = useState("");
    const [inHouseArticle] = useState(false);
    const [contentSections, setContentSections] = useState([{title: "test", content: "test"}]);

    const [elementDropdownOpen, setElementDropdownOpen] = useState(false);

    const [articleType, setArticleType] = useState("article");

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "media", "articles", setArticles);
        return unsubscribe;
    }, [])
    //TODO if the DB areas are empty this will crash
    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "media", "events", setEvents);
        return unsubscribe;
    }, [])

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "media", "backgrounders", setBackgrounders);
        return unsubscribe;
    }, [])

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "media", "pressreleases", setPressReleases);
        return unsubscribe;
    }, [])

    const addItem = () => {
        let id = generateGUID();
        let data = {
            order: order,
            id: id,
            url: url,
            urlDescription: urlDescription,
            title: title,
            inHouseArticle: inHouseArticle,
            contentSections: contentSections,
            timeStamp: firebase.firestore.Timestamp.fromDate(new Date()),
            imageUrl: "N/A",
            date: date,
        }
        if(articleType === "article"){
            updateDocumentProperty(teamUnbreakableDB, "content", "media", "articles." + id, data);   
        }
        else if(articleType === "event") {
            updateDocumentProperty(teamUnbreakableDB, "content", "media", "events." + id, data);   
        }
        else if(articleType === "backgrounder"){
            updateDocumentProperty(teamUnbreakableDB, "content", "media", "backgrounders." + id, data);  
        }
        else{
            updateDocumentProperty(teamUnbreakableDB, "content", "media", "pressreleases." + id, data);  
        }
        clearInput();
    }

    const clearInput = () => {
        setOrder(0);
        setTitle("");
        setUrl("");
        setURLDescription("");
        setDate("");
        setContentSections({title: "", content: ""})
    }

    return(
        <Container>
             <Row>
                <CenterFlexCol>
                    <h1>Community</h1>
                </CenterFlexCol>
            </Row>
            <h2 onClick={() => setTabShowing(tabShowing === 0 ? -1 : 0)}>
                Articles
                {tabShowing !== 0 && <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                {tabShowing === 0 && <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton>}
            </h2>
            <Collapse isOpen={tabShowing === 0}>
                {(Object.entries(articles).length > 0) && Object.entries(articles).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                    return <ColoredDiv key={t[0]}>
                        <AdminMediaArticle 
                            articleType = "article" 
                            id={t[0]} 
                            imageUrl={t[1].imageUrl} 
                            url={t[1].url} 
                            order={t[1].order} 
                            urlDescription={t[1].urlDescription}  
                            title={t[1].title}
                            date={t[1].date}
                            timestamp={t[1].timeStamp}
                            contentSections={t[1].contentSections}
                            inHouseArticle = {t[1].inHouseArticle}
                        /> 
                    </ColoredDiv>
                })}
            </Collapse>
            <h2 onClick={() => setTabShowing(tabShowing === 1 ? -1 : 1)}>
                Community Events
                {tabShowing !== 1 && <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                {tabShowing === 1 && <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton>}
            </h2>
            <Collapse isOpen={tabShowing === 1}>
                {(Object.entries(events).length > 0) && Object.entries(events).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                    return <ColoredDiv key={t[0]}>
                        <AdminMediaArticle 
                            id={t[0]} 
                            articleType = "event"
                            imageUrl={t[1].imageUrl} 
                            url={t[1].url} 
                            order={t[1].order} 
                            urlDescription={t[1].urlDescription} 
                            title={t[1].title}
                            date={t[1].date}
                            timestamp={t[1].timeStamp}
                            contentSections={t[1].contentSections}
                            inHouseArticle = {t[1].inHouseArticle}
                            />
                    </ColoredDiv>
                })}
            </Collapse>
            <h2 onClick={() => setTabShowing(tabShowing === 2 ? -1 : 2)}>
                Backgrounders
                {tabShowing !== 2 && <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                {tabShowing === 2 && <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton>}
            </h2>
            <Collapse isOpen={tabShowing === 2}>
                {(Object.entries(backgrounders).length > 0) && Object.entries(backgrounders).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                    return <ColoredDiv key={t[0]}>
                        <AdminMediaArticle 
                            id={t[0]} 
                            articleType = "backgrounder"
                            imageUrl={t[1].imageUrl} 
                            url={t[1].url} 
                            order={t[1].order} 
                            urlDescription={t[1].urlDescription} 
                            title={t[1].title}
                            date={t[1].date}
                            timestamp={t[1].timeStamp}
                            contentSections={t[1].contentSections}
                            inHouseArticle = {t[1].inHouseArticle}
                            />
                    </ColoredDiv>
                })}
            </Collapse>
            <h2 onClick={() => setTabShowing(tabShowing === 3 ? -1 : 3)}>
                Press Releases
                {tabShowing !== 3 && <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                {tabShowing === 3 && <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton>}
            </h2>
            <Collapse isOpen={tabShowing === 3}>
                {(Object.entries(pressReleases).length > 0) && Object.entries(pressReleases).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                    return <ColoredDiv key={t[0]}>
                        <AdminMediaArticle 
                            id={t[0]} 
                            articleType = "pressrelease"
                            imageUrl={t[1].imageUrl} 
                            url={t[1].url} 
                            order={t[1].order} 
                            urlDescription={t[1].urlDescription} 
                            title={t[1].title}
                            date={t[1].date}
                            timestamp={t[1].timeStamp}
                            contentSections={t[1].contentSections}
                            inHouseArticle = {t[1].inHouseArticle}
                            />
                    </ColoredDiv>
                })}
            </Collapse>
            <Row>
                <h2>Add New Item</h2>
                <Col>
                <InputGroup>
                        <div style={{width:"200px"}}>
                            {articleType}
                        </div>
                        <Dropdown isOpen={elementDropdownOpen} toggle={() => {setElementDropdownOpen(!elementDropdownOpen)}}>
                            <DropdownToggle caret></DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => setArticleType("article")}>Article</DropdownItem>
                                <DropdownItem onClick={() => setArticleType("event")}>Community Event</DropdownItem>
                                <DropdownItem onClick={() => setArticleType("backgrounder")}>Backgrounder</DropdownItem>
                                <DropdownItem onClick={() => setArticleType("pressrelease")}>Press Release</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </InputGroup>

                    <InputGroup>
                        <div addonType="prepend" style={{width:"130px"}}>
                            Title of Article
                        </div>
                        <Input type="text" value={title} onChange={e => setTitle(e.target.value)}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"130px"}}>
                            Order
                        </div>
                        <Input type="text" value={order} onChange={e => setOrder(e.target.value)}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"130px"}}>
                            Short Description
                        </div>
                        <Input type="text" value={urlDescription} onChange={e => setURLDescription(e.target.value)}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"130px"}}>
                            Article URL
                        </div>
                        <Input type="text" value={url} onChange={e => setUrl(e.target.value)}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"130px"}}>
                            Date
                        </div>
                        <Input type="text" value={date} onChange={e => setDate(e.target.value)}></Input>
                    </InputGroup>
                </Col>
            </Row>
            <br />
            <Row>
                <FlexCol xs="1">
                    <Button onClick={() => {addItem()}} color="primary">
                        <FAIconButton icon={["fas", "plus-circle"]}></FAIconButton>
                    </Button>
                </FlexCol>
            </Row>
        </Container>
    ) 
}

export default AdminCommunity;


const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const ColoredDiv = styled.div`
    margin-bottom: 5px;
    padding: 5px;
    padding-top: 15px;
    background-color: rgba(0,0,0,0.05);
`;

const FlexCol = styled(Col)`
    display: flex;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;