import React from "react";
import { Container, Row, Col } from "reactstrap";
import styled, { keyframes } from 'styled-components';
import BlankImage from "../../../images/BlankUser.jpg"
import { Colors } from "../../../utils/constants";

export const Person = (props) => {   
    return(
        <React.Fragment>
            <StyledContainer delay={props.delay}>
                <Row>
                    <ImageCol xl="3">
                        <PersonImage src={(props.imageurl && props.imageurl !== "N/A") ? props.imageurl : BlankImage} />
                    </ImageCol>
                    <CenterCol>
                        <Title>{props.name} - {props.title}</Title>
                        <Description>{props.description}</Description>
                    </CenterCol>
                </Row>
                <Row>
                    <Col>
                        
                    </Col>
                </Row>
            </StyledContainer>
        </React.Fragment>
    );
}

export default Person;

const Description = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
`

const ImageCol = styled(Col)`
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    align-items: center;
`

const PersonImage = styled.img`
    width: 100%;
`

const Title = styled.h4`
    color: ${Colors.primaryblue};
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: xx-large;
    padding-top: 15px;
`


const StyledContainer = styled(Container)`
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: ${Colors.secondarybackground};
    border-radius: 3px;
    margin-top: 20px;
`;

const CenterCol = styled(Col)`

`;