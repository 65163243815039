import React, { useState} from "react";
import { Row, Col, Button, InputGroup, Input, InputGroupText } from "reactstrap";
import { teamUnbreakableDB } from "../../../utils/firestore";
import { updateDocumentProperty, deleteNestedDocumentProperty } from "../../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ModalWindow from "../../common/modalWindow";

export const AdminTestimonial = (props) => {
    
    const [testimonial, setTestimonial] = useState(props.testimonial);
    const [source, setSource] = useState(props.source);
    const [order, setOrder] = useState(props.order);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn, setModalConfirmOn] = useState(true);
    const [modalConfimFunction, setModalConfirmFunction] = useState();
    const [modalCancelText, setModalCancelText] = useState("Cancel");

    const toggle = () => setModal(!modal);

    const updateTestimonial = () => {
        var t = {
            testimonial: testimonial,
            source: source,
            order: order,
            id: props.id
        }
        updateDocumentProperty(teamUnbreakableDB, "content", "testimonials", "testimonials." + props.id, t, UpdateSuccess, UpdateFailed);
    }

    const UpdateSuccess = () => {
        setModalTitle("Successfully Updated");
        setModalMessage("Your testimonial has been updated successfully");
        setModalCancelText("Okay");
        setModalConfirmOn(false);
        setModal(true);
    }

    const UpdateFailed = (error) => {
        setModalTitle("Update Failed");
        setModalMessage("Something went wrong when updating: " + error);
        setModalCancelText("Okay");
        setModalConfirmOn(false);
        toggle();
    }

    const DeletePrompt = () => {
        setModalTitle("Delete Element?");
        setModalMessage("Are you sure you want to delete this Testimonial?");
        setModalCancelText("Cancel");
        setModalConfirmOn(true);
        setModalConfirmFunction(() => deleteTestimonial);
        toggle();
    }

    const deleteTestimonial = () => {
        deleteNestedDocumentProperty(teamUnbreakableDB, "content", "testimonials", "testimonials", props.id)
    }

    return(
        <React.Fragment>
            <ModalWindow 
                cancelText={modalCancelText} 
                isOpen={modal} 
                title={modalTitle} 
                message={modalMessage} 
                cancelFunction={toggle} 
                confirmFunction={modalConfimFunction} 
                showConfirmButton={modalConfirmOn} 
            />
            <Row>
                <Col>
                    <InputGroup>
                        <div addonType="prepend">
                            <InputGroupText>Source</InputGroupText>
                        </div>
                        <Input type="text" value={source} onChange={e => setSource(e.target.value)}/>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <div addonType="prepend">
                            <InputGroupText>Order</InputGroupText>
                        </div>
                        <Input type="text" value={order} onChange={e => setOrder(e.target.value)}/>
                    </InputGroup>
                </Col>
                <FlexCol xs="2">
                    <StyledButton onClick={()=> {updateTestimonial()}} color="primary">
                        <FAIconButton  icon={["fas", "edit"]}></FAIconButton>
                    </StyledButton>
                    <StyledButton onClick={()=> {DeletePrompt()}} color="danger">
                        <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                    </StyledButton>
                </FlexCol>
            </Row>
            <Row>
                <Col>
                    <InputGroup>
                        <Input type="textarea" value={testimonial} onChange={e => setTestimonial(e.target.value)}/>
                    </InputGroup>
                </Col>
            </Row>
        </React.Fragment>
    ) 
}

export default AdminTestimonial;

const StyledButton = styled(Button)`
    margin-left: 5px;
`;

const FlexCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;