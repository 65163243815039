import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { PageView } from "../../utils/analytics.js";

const RouteWrapper = ({ path, title, user, children, component: Component, render, ...rest }) => {

  useEffect(() => {
    document.title = title;
    PageView(title, path);
  }, []);

  console.log("USER in RouteWrapper")
  console.log(user);

  return (
    <Route user={user}
      {...rest}
      render={(props, user) => { 
        if (children) {
          return children;
        }
        else{
          return Component ? <Component user={user} {...props}/> : render(props);
        }
      }}
    />
  );
};

export default RouteWrapper;