import React from "react";
import { Container, Row, Col } from "reactstrap";
import styled from 'styled-components';
import DarkHomePageBackground from "../../images/DarkHomePageBackground.png";
import PictureBackground from "./picture-background";
import ReactGA from 'react-ga';
import { Colors } from "../../utils/constants";

export const NotFound = () => {
    ReactGA.pageview('/not-found');
    
    return(
        <React.Fragment>
            <MinHeightDiv>
                <PictureBackground url={DarkHomePageBackground}>
                </PictureBackground>
                <Shader></Shader>
                <OffsetDiv></OffsetDiv>
                <ContentContainer>
                    <Row>
                        <CenterCol>
                            <StyledP>That page doesn't exist or you don't have the required permission to view it.</StyledP>
                        </CenterCol>
                    </Row>
                </ContentContainer>
            </MinHeightDiv>
        </React.Fragment>
    );
}

export default NotFound;

const MinHeightDiv = styled.div`
    min-height: ${window.innerHeight - 149}px;
`;

const CenterCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-content: center;
`;

const StyledP = styled.p`
    margin: 0px;
    margin-bottom: 5px;
    font-size: 20px;
    color: white;
`;

const ContentContainer = styled(Container)`
    margin-top: 0px;
    background-color: ${Colors.primaryblue};
    max-width: 400px;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
`;

const OffsetDiv = styled.div`
    height: 180px;
`;

const Shader = styled.div`
    height: 100vh;
    position: fixed;
    z-index: -1;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
`;

