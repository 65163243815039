import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, InputGroup, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { teamUnbreakableDB, generateGUID } from "../../utils/firestore";
import { listenToDocumentProperty, updateDocumentProperty } from "../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import firebase from "firebase/app";
import AdminResearchRow from "./admin-research-row";

export const AdminResearch = () => {
    const [articleResearch, setarticleResearch] = useState({});
    const [order, setOrder] = useState(0);
    
    const [title, setTitle] = useState("");
    const [category, setCategory] = useState("");
    const [youtubeId, setYoutubeId] = useState("");
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState("");

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "research", "articles", setarticleResearch);
        return unsubscribe;
    }, [])

    const addResearch = () => {
        let id = generateGUID();
        let data = {
            order: order,
            id: id,
            url: url,
            description: description,
            title: title,
            category: category,
            youtubeId: youtubeId,
            fileName: "No File Uploaded",
            timeStamp: firebase.firestore.Timestamp.fromDate(new Date()),
        }
        updateDocumentProperty(teamUnbreakableDB, "content", "research", "articles." + id, data);  
        clearInput();
    }
    
    const clearInput = () => {
        setOrder(0);
        setTitle("");
        setUrl("");
        setDescription("");
        setCategory("");
    }

    return(
        <Container>
            <Row>
                <CenterFlexCol>
                    <h1>Research</h1>
                </CenterFlexCol>
            </Row>  
            {/*
            <h3>Articles</h3>
            {(Object.entries(articleResearch).length > 0) && Object.entries(articleResearch).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                return <ColoredDiv key={t[0]}>
                    <AdminResearchRow 
                        type="articles" 
                        timeStamp={t[1].timeStamp} 
                        fileName={t[1].fileName}
                        youtubeId={t[1].youtubeId}
                        id={t[0]} url={t[1].url} 
                        order={t[1].order} 
                        description={t[1].description}  
                        title={t[1].title}
                        category={t[1].category}
                        isArticle={false}
                        imgUrl={t[1].imgUrl}
                    />
                </ColoredDiv>
            })}
            */}
            <Row>
                <Col>
                <hr />
                    <h2>Add research</h2>
                    
                    <InputGroup>
                        <div addonType="prepend" style={{width:"180px"}}>
                            Title of Research
                        </div>
                        <Input type="text" value={title} onChange={e => setTitle(e.target.value)}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"180px"}}>
                            Order
                        </div>
                        <Input type="text" value={order} onChange={e => setOrder(e.target.value)}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"180px"}}>
                            Category
                        </div>
                        <Input type="text" value={category} onChange={e => setCategory(e.target.value)}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"180px"}}>
                            Research Description
                        </div>
                        <Input type="text" value={description} onChange={e => setDescription(e.target.value)}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"180px"}}>
                            Video: Youtube ID
                        </div>
                        <Input type="text" value={youtubeId} onChange={e => setYoutubeId(e.target.value)}></Input>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <FlexCol xs="1">
                    <Button onClick={() => {addResearch()}} color="primary">
                        <FAIconButton icon={["fas", "plus-circle"]}></FAIconButton>
                    </Button>
                </FlexCol>
            </Row>
        </Container>
    ) 
}

export default AdminResearch;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const ColoredDiv = styled.div`
    margin-bottom: 5px;
    padding: 5px;
    padding-top: 15px;
    background-color: rgba(0,0,0,0.05);
`;

const FlexCol = styled(Col)`
    display: flex;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;