import React, { useState, useEffect } from "react";
import { Container, Row, Col, Input } from "reactstrap";
import { Copy } from "./module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { 
    ColoredH2, 
    StyledHR, 
    ColoredOL, 
    StyledP, 
    CenterTitleFlexCol, 
    WhiteH2, 
    ColoredUL, 
    StyledButton, 
    CompletedP, 
    CompletedDiv,
    LargeInput,
    FlexCol,
} from "../../styles/styledComponents";
import styled from "styled-components";
import { Event } from "../../utils/analytics";
import HomeworkImage from "../../images/homework.png";
import PlanningImage from "../../images/planning.png";
import { Colors } from "../../utils/constants";
import StarsImage from '../../images/stars.png';
import MotivationImage from '../../images/motivation.png';

export const Module3 = ( props ) => {

    const [user, setUser] = useState();

    const [input1, setInput1] = useState((props.user.moduleData !== undefined && props.user.moduleData.module3 !== undefined && props.user.moduleData.module3.input1 != undefined) ? props.user.moduleData.module3.input1.input1 : "");
    const [input2, setInput2] = useState((props.user.moduleData !== undefined && props.user.moduleData.module3 !== undefined && props.user.moduleData.module3.input2 != undefined) ? props.user.moduleData.module3.input2.input2 : "");
    const [input3, setInput3] = useState((props.user.moduleData !== undefined && props.user.moduleData.module3 !== undefined && props.user.moduleData.module3.input3 != undefined) ? props.user.moduleData.module3.input3.input3 : "");
    const [input4, setInput4] = useState((props.user.moduleData !== undefined && props.user.moduleData.module3 !== undefined && props.user.moduleData.module3.input4 != undefined) ? props.user.moduleData.module3.input4.input4 : "");

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const UpdateInput = (inputKey, inputValue) => {
        var data = user;
        data.moduleData.module3[inputKey] = inputValue;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    const completeModule = () => {
        Event("Module Completed", "Mental Module", "Module 3");
        var data = user;
        data.modules.module3 = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                {/*
                <br />
                <Row>
                    <CenterFlexCol>
                        <ColoredH2>{Copy["Module 3"].Title[props.user.language]}</ColoredH2>
                    </CenterFlexCol>
                </Row>
                <br />
                */}
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module 3"].SubTitle1[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 3"].SubTitle2[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 3"].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 3"].List1Item2[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 3"].SubTitle3[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col xs="9">
                        <StyledP>{Copy["Module 3"].List2Title[props.user.language]}</StyledP>
                        <ColoredOL>
                            <li><StyledP>{Copy["Module 3"].List2Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 3"].List2Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 3"].List2Item3[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                    <CenterFlexVerticalCol>
                        <HomeworkIMG src={StarsImage}></HomeworkIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 3"].SubTitle4[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module 3"].Paragraph1[props.user.language]}</StyledP>
                        <StyledP>{Copy["Module 3"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row> 
            </Container>
            <br />
            <Container>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                        <PhysicalActivityIMG src={MotivationImage}></PhysicalActivityIMG>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 3"].SubTitle5[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module 3"].Paragraph3[props.user.language]}</StyledP>
                        <StyledP>{Copy["Module 3"].Paragraph4[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 3"].SubTitle6[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module 3"].Input1Header[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <ColoredRow>
                    <CenterFlexCol>
                        <WhiteP>1</WhiteP>
                        <SliderInput type="range" min="1" max="100" value={input1} onChange={e => setInput1(e.target.value)} onBlur={() => {UpdateInput("input1", {input1})}}></SliderInput>
                        <WhiteP>100</WhiteP>
                    </CenterFlexCol>
                    <CenterFlexCol>
                        <LargeInput value={input1} onChange={e => setInput1(e.target.value)} onBlur={() => {UpdateInput("input1", {input1})}}></LargeInput>
                    </CenterFlexCol>
                </ColoredRow>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col xs="12" md="5">
                        <HomeworkIMG src={PlanningImage}></HomeworkIMG>
                    </Col>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module 3"].SubTitle7[props.user.language]}</ColoredH2>
                                    <StyledHR></StyledHR>
                                    <StyledP>{Copy["Module 3"].Input2Header[props.user.language]}</StyledP>
                                    <LargeInput value={input2} onChange={e => setInput2(e.target.value)} onBlur={() => {UpdateInput("input2", {input2})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 3"].Input3Header[props.user.language]}</StyledP>
                                    <LargeInput value={input3} onChange={e => setInput3(e.target.value)} onBlur={() => {UpdateInput("input3", {input3})}}></LargeInput>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 3"].SubTitle8[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module 3"].Input4Header[props.user.language]}</StyledP>
                        <StyledP>{Copy["Module 3"].Input5Header[props.user.language]}</StyledP>
                        <LargeInput value={input4} onChange={e => setInput4(e.target.value)} onBlur={() => {UpdateInput("input4", {input4})}}></LargeInput>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <CenterFlexVerticalCol xs="2">
                        <HomeworkIMG src={StarsImage}></HomeworkIMG>
                    </CenterFlexVerticalCol>
                    <Col>
                        <ColoredH2>{Copy["Module 3"].SubTitle9[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module 3"].List3Title[props.user.language]}</StyledP>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 3"].List3Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 3"].List3Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 3"].List3Item3[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 3"].SubTitle10[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module 3"].Paragraph5[props.user.language]}</StyledP>
                        <StyledP>{Copy["Module 3"].Paragraph6[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 3"].SubTitle11[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 3"].Paragraph7[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 3"].Paragraph8[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                    <Col xs="12" md="5">
                        <HomeworkIMG src={HomeworkImage}></HomeworkIMG>
                    </Col>
                </Row>
            </Container>
            {user !== undefined && <Container>
                <Row>
                    <CenterFlexCol>
                        {user.modules.module3 ? <CompletedDiv><CompletedP>Module Completed</CompletedP></CompletedDiv> : <StyledButton onClick={() => completeModule()} color="primary">Complete Module</StyledButton>}
                    </CenterFlexCol>
                </Row>
            </Container>}
        </React.Fragment>
    );
}

export default Module3;

const PhysicalActivityIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const WhiteP = styled.p`
    color: white;
    margin: 0px;
    align-items: center;
    font-weight: bold;
`;

const ColoredRow = styled(Row)`
    background-color: ${Colors.primaryblue};
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    max-width: 400px;
    justify-content: center;
    margin: auto;
`;

const HomeworkIMG = styled.img`
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
`;

const SliderInput = styled(Input)`
    color: ${Colors.primaryblue};
    max-width: 80%;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
`;