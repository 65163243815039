import React, { useState, useEffect } from "react";
import styled, { keyframes } from 'styled-components';
import { Container, Row, Col, NavLink } from "reactstrap";
import PictureBackground from "../../common/picture-background";
import { MinHeightWrapper } from "../../common/min-height-wrapper";
import { teamUnbreakableDB } from "../../../utils/firestore";
import { listenToDocumentProperty } from "../../../utils/firestore-functions";
import { HomeElement } from "./home-element";
import { Link } from "react-scroll";
import '../../../styles/carouselStyle.css';
import HomeCarousel from "./home-carousel";
import ReactGA from 'react-ga';
import { getViewport } from "../../../utils/helper";
import CookiePermissionPopup from "../../common/cookiePermissions";
import { Colors } from "../../../utils/constants";
import {isMobile} from 'react-device-detect';

export const Home = (props) => {
    ReactGA.pageview('/home');

    const [title, setTitle] = useState();
    const [topParagraph, setTopParagraph] = useState();
    const [elements, setElements] = useState();
    const [screenSize, setScreenSize] = useState(4);
    const [donors, setDonors] = useState({});

    const [cookiesOpen, setCookiesOpen] = useState(true);

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "donors", "donors", SetupDonors);
        return unsubscribe;
    }, []);


    useEffect(() => {
        let timer = setTimeout(() => { setCookiesOpen(false)}, 6000);
        return () => {clearTimeout(timer)};
    }, []);

    const SetupDonors = (donors) => {
        var newDonorArray = [];
        (Object.entries(donors).length > 0) && Object.entries(donors).sort((a,b)=>(parseInt(a[1].order))-(parseInt(b[1].order))).map(donor => {
            newDonorArray.push(donor[1]);
        })
        setDonors(newDonorArray);
    }

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "home", "topParagraph", setTopParagraph);
        return unsubscribe;
    }, [])

    useEffect(() => {
        setScreenSize(getViewport());
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "home", "title", setTitle);
        return unsubscribe;
    }, [])

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "home", "elements", setElements);
        return unsubscribe;
    }, [])

    return(
        <React.Fragment>
            <CookiePermissionPopup cookiesOpen={cookiesOpen} setCookiesOpen={() => (setCookiesOpen())}/>
            <HeightDiv id="height">
                ({!isMobile} && <PictureBackground id="picture background" url={(title !== undefined && title.background !== undefined) ? title.background : ""} />
                : <PictureBackground id="picture background" url={(title !== undefined && title.mobileBackground !== undefined) ? title.mobileBackground : ""} />)
                <MinHeightWrapper>
                    <Spacer>
                    </Spacer>
                    <MainContentContainer size={screenSize}>
                        <Row>
                            <Col>
                                <Title fontsize="50px" id="title" delay={"1s"}>
                                    {title !== undefined && title.first}&nbsp;
                                </Title>
                                {screenSize <= 0 && <br />}
                                <Title id="title" delay={"2s"}>
                                    {title !== undefined && title.second}&nbsp;
                                </Title>
                                <Title id="title" delay={"3s"}>
                                    {title !== undefined && title.third}
                                </Title>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "20px"}}>
                            <Col>
                                <MainTitle id="title" delay={"4s"}>
                                    Team Unbreakable
                                </MainTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Paragraph color={"white"} delay={"5s"}>{topParagraph} <br/> </Paragraph>
                                <BulletinBoard delay={"5.5s"}>
                                    {/*<Bulletin>The Bold and Cold registration is now OPEN. </Bulletin>
                                    <Bulletin delay={"5.5s"}> Please save the following dates: <br/> </Bulletin>
                                    
                                    <Row>
                                        <Col xs="1" style={{textAlign: "right"}}>
                                            <Bulletin>
                                                •
                                            </Bulletin>
                                        </Col>
                                        <Col style={{paddingLeft: "0px"}}>
                                            <Bulletin>
                                                The Bold and Cold Run on November 19, 2022 
                                            </Bulletin>
                                        </Col>
                                    </Row>
                                    <Bulletin>
                                    <StyledNavLink href={("/About/Contact Us")}>Contact us</StyledNavLink> for more information.
                                    </Bulletin>*/}
                                    <Bulletin>
                                        {title !== undefined && title.paragraph1 !== undefined && title.paragraph1}
                                    </Bulletin> 
                                    <br/>
                                    <Bulletin>
                                        {title !== undefined && title.paragraph2 !== undefined && title.paragraph2}
                                    </Bulletin>
                                </BulletinBoard>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                {title !== undefined && title.button1url !== undefined && <RegisterNowButton 
                                href={title.button1url} 
                                target="_blank"
                                offset={-70} 
                                duration={100} 
                                spy={true} 
                                smooth={true} 
                                delay={"6s"}>{title.button1text}
                                </RegisterNowButton>}
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                {title !== undefined && title.button2url !== undefined && <RegisterNowButton 
                                href={title.button2url} 
                                target="_blank"
                                offset={-70} 
                                duration={100} 
                                spy={true} 
                                smooth={true} 
                                delay={"6s"}>{title.button2text}
                                </RegisterNowButton>}
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                {title !== undefined && title.button3url !== undefined && <RegisterNowButton 
                                href={title.button3url} 
                                target="_blank"
                                offset={-70} 
                                duration={100} 
                                spy={true} 
                                smooth={true} 
                                delay={"6s"}>{title.button3text}
                                </RegisterNowButton>}
                            </Col>
                        </Row>
                        {/*props.user !== "Guest" &&
                        <Row>
                            <Col>
                                <LearnMoreButton 
                                to="Content" 
                                offset={-70} 
                                duration={100} 
                                spy={true} 
                                smooth={true} 
                                delay={"6s"}>Learn More
                                </LearnMoreButton>
                            </Col>
                        </Row>*/}
                    </MainContentContainer>
                </MinHeightWrapper>
            </HeightDiv>
            
            <ContentContainer id="Content" fluid={true}>
                <Row>
                {elements !== undefined && (Object.entries(elements).length > 0) && Object.entries(elements).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                    return <HomeElementCol key={t[0]} xl="6"><HomeElement delay={"-0.5s"} type={t[1].type} key={t[0]} imgURL={t[1].imgURL} title={t[1].title} description={t[1].description} embedCode={t[1].embedCode}></HomeElement></HomeElementCol>
                })}
                </Row>
                
            </ContentContainer>
            <WhiteDiv id="white-divider"> 
            </WhiteDiv>
            <ContentContainer fluid={true}>
                    <Title style={{color: Colors.primaryblue}}>DONORS</Title>
                    <br/>
                    <br/>
                    <br/>
                    <Container fluid = {true} id="carousel-container">
                        <Row>
                            <Col>
                                {donors.length > 0 && <HomeCarousel items={donors} />}
                            </Col>
                        </Row>
                    </Container>
            </ContentContainer>
        </React.Fragment>
    );
}

export default Home;

const WhiteDiv = styled.div`
  height: 30px;
  background-color: white;
  width: 100%;
  padding-top: 14px;
`

const MainContentContainer = styled(Container)` 
    margin-left: ${props => props.size > 1 ? "7%" : "0%"};
    max-width: 700px;
    padding-bottom: ${props => props.size > 1 ? "" : "20px"};
`

const HomeElementCol = styled(Col)`
    margin-bottom: 50px;
`

const ContentContainer = styled(Container)`
    background-color: ${Colors.primarybackground};
    padding-top: 20px;
    padding-left: 5%;
    padding-right: 5%;
`;

const Spacer = styled.div`
    height: 250px;
`;

const FadeAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const MainTitle = styled.h1`
    display: inline;
    color: ${Colors.primaryblue};
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: xxx-large;
    animation: ${FadeAnimation} 2s ${props => props.delay} both;
`

const Title = styled.h1`
    display: inline;
    /*color: ${Colors.secondarygray}; */
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: xx-large;
    animation: ${FadeAnimation} 2s ${props => props.delay} both;
`;

const Paragraph = styled.p`
    color: ${props => props.color ? props.color : Colors.secondarygray};
    font-family: Roboto;
    font-style: normal;
    font-size: x-large;
    width: 95%;
    animation: ${FadeAnimation} 2s ${props => props.delay} both;
`
const BulletinBoard = styled.div`
    padding: 20px;
    background-color: ${Colors.primaryblue};
    width: 90%;
    margin: 10px 0px;
    border-radius: 10px;
    box-shadow: -5px 5px white;
    animation: ${FadeAnimation} 2s ${props => props.delay} both;
`

const Bulletin = styled.p`
    width: 95%;
    animation: ${FadeAnimation} 2s ${props => props.delay} both;
    margin-bottom: 0px;
    color: white;
    font-size: medium;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
`

const HeightDiv = styled.div`
    min-height: 100vh;
`;

const StyledNavLink = styled(NavLink)`
    color: white;
    textDecoration: none;
    font-style: italic;
    font-weight: bold;
    padding: 0px;
    display: inline;
`

const RegisterNowButton = styled(NavLink)`
display: inline;
font-size: large;
font-family: Roboto;
text-align: center;
color: black;
border-radius: 10px;
background: ${Colors.primaryblue};
color: white;
padding: 10px 15px;
text-decoration: none;
&:hover {
    background: white;
    border-radius: 10px;
    border-color: ${Colors.primaryblue};
    border-width: 1px;
    border-style: solid;
    color: black;
    cursor: pointer;
}
    animation: ${FadeAnimation} 2s ${props => props.delay} both;
`
const LearnMoreButton = styled(Link)`
    display: inline;
    font-size: large;
    font-family: Roboto;
    text-align: center;
    color: black;
    border-radius: 10px;
    background: ${Colors.primaryblue};
    color: white;
    padding: 10px;
    text-decoration: none;
    &:hover {
        background: white;
        border-radius: 10px;
        border-color: ${Colors.primaryblue};
        border-width: 1px;
        border-style: solid;
        color: black;
        cursor: pointer;
    }
        animation: ${FadeAnimation} 2s ${props => props.delay} both;
`