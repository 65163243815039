export const Copy = {
    "Module Introduction": {
        "Title": {
            "English": "INTRODUCTION",
            "French": "INTRODUCTION",
        },
        "Paragraph1": {
            "English": "The Team Unbreakable Running for Youth Mental Health Initiative uses running as a vehicle to improve, promote, and affirm wellness. The program is divided into two components:",
            "French": " L’initiative de course de l’équipe imbattable utilise la course comme véhicule ou moyen pour améliorer, promouvoir et affirmer le bien-être. Le programme est divisé en deux composantes:", 
        },
        "Paragraph2": {
            "English": "The Physical Component, which focuses on the mechanics and factors associated with running, and the Mental Component, which focuses on exercises and activities that promote overall wellbeing and sustainable behaviours.",
            "French": " La composante physique qui vise les techniques et les facteurs associés avec la course et la composante mentale, qui vise les exercices et les activités qui font la promotion du bien-être et des comportements durables.",
        },
        "List1Title": {
            "English": "The Physical Component includes a suggested running schedule and six distinct content categories:",
            "French": " La composante physique inclue une proposition d’horaire de course et six catégories de contenus:",
        },
        "List1Item1": {
            "English": "Safety",
            "French": "La sécurité",
        },
        "List1Item2": {
            "English": "How to Run (Running Mechanics)",
            "French": "Comment courir (techniques)",
        },
        "List1Item3": {
            "English": "How to Train (Running Drills)",
            "French": "Comment s’entraîner (exercices)",
        },
        "List1Item4": {
            "English": "How to Fuel (Nutrition & Hydration)",
            "French": "Comment s’alimenter (carburant et hydratation)",
        },
        "List1Item5": {
            "English": "How to Handle Training Adversity (Managing Physical Setbacks)",
            "French": "Comment surmonter les obstacles (gérer les défis physiques)",
        },
        "List1Item6": {
            "English": "Hills",
            "French": "Les côtes",
        },
        "Paragraph3": {
            "English": "The Team Unbreakable 5km training program is designed for beginner to intermediate runners. The program builds in intensity and duration, gradually working towards completion of a 5km distance. The program can be modified to fit your individual needs and goals",
            "French": "Le programme d’entraînement de 5km de l’équipe imbattable est désigné pour les coureurs débutants et intermédiaires. Le programme améliore l’intensité et la durée, en travaillant graduellement vers la réalisation d’une course de 5km. Le programme peut être modifié pour répondre à vos besoins et vos objectifs individuels. ",
        },
        "Paragraph4": {
            "English": "Note: Running is our Team Unbreakable target activity because it builds on a progression from walking, which is the most foundational movement across the lifespan. Much of the research evidence on the effects of physical activity on mental health is drawn from walking and running programs. However, we know that walking/running is not accessible to all. Please feel free to adapt the activity to any movement or physical activity that is right for you. Try to find activities that allow you to change up the intensity and progress in frequency and duration",
            "French": "Note: La course est l’activité ciblée pour l’équipe imbattable parce que c’est une activité qui progresse de la marche, qui est un mouvement naturel la vie durant. Plusieurs des données de recherches qui étudient les effets de l’activité physique sur la santé mentale sont tirées des programmes de marche et de course. Toutefois, nous savons que la marche et la course ne sont pas accessibles à tous. Sentez-vous libre d’adapter l’activité à n’importe quel mouvement ou activité physique qui vous convient. Essayez de trouver des activités qui vous permettent de varier l’intensité, la fréquence et la durée afin de progresser.",
        },
        "SubTitle1": {
            "English": "Training Schedule",
            "French": "Horaire d’entraînement",
        },
        "Paragraph5": {
            "English": "Suggested training schedule to complete a 5km run at the end of the program.",
            "French": "Horaire d’entraînement suggéré pour compléter une course de 5km.",
        },
        "Paragraph6": {
            "English": "Runs 1 and 2 are considered essential. Run 3 is optional and can be completed during personal time. The key principles when undertaking the 5km training program are completion and consistency.",
            "French": " Les courses 1 et 2 sont considérées essentielles. La course 3 est optionnelle et peut être complétée pendant ton temps personnel. Les principes clés lorsque tu entreprends un programme d’entraînement pour une course de 5 km sont de terminer et d’être constant.",
        },
        "Table1R1D1":{
            "English": "WEEK",
            "French": "SEMAINE",
        },
        "Table1R1D2":{
            "English": "WORKOUT STRUCTURE",
            "French": "STRUCTURE DE L’ENTRAÎNEMENT",
        },
        "Table1R1D3":{
            "English": "SESSION 1",
            "French": "SESSION 1",
        },
        "Table1R1D4":{
            "English": "SESSION 2",
            "French": "SESSION 2",
        },
        "Table1R1D5":{
            "English": "SESSION 3",
            "French": "SESSION 3",
        },
        "Table1R2D1":{
            "English": "1",
            "French": "1",
        },
        "Table1R2D2":{
            "English": "1 min running, 1 min walking",
            "French": "1 min de course, 1 min de marche",
        },
        "Table1R2D3":{
            "English": "20 min",
            "French": "20 min",
        },
        "Table1R2D4":{
            "English": "30 min",
            "French": "30 min",
        },
        "Table1R2D5":{
            "English": "20 min",
            "French": "20 min",
        },
        "Table1R3D1":{
            "English": "2",
            "French": "2",
        },
        "Table1R3D2":{
            "English": "2 min running, 1 min walking",
            "French": "2 min de course, 1 min de marche",
        },
        "Table1R3D3":{
            "English": "20 min",
            "French": "20 min",
        },
        "Table1R3D4":{
            "English": "30 min",
            "French": "30 min",
        },
        "Table1R3D5":{
            "English": "20 min",
            "French": "20 min",
        },
        "Table1R4D1":{
            "English": "3",
            "French": "3",
        },
        "Table1R4D2":{
            "English": "3 min running, 1 min walking",
            "French": "3 min de course, 1 min de marche",
        },
        "Table1R4D3":{
            "English": "20 min",
            "French": "20 min",
        },
        "Table1R4D4":{
            "English": "30 min",
            "French": "30 min",
        },
        "Table1R4D5":{
            "English": "20 min",
            "French": "20 min",
        },
        "Table1R5D1":{
            "English": "4",
            "French": "4",
        },
        "Table1R5D2":{
            "English": "4 min running, 1 min walking",
            "French": "4 min de course, 1 min de marche",
        },
        "Table1R5D3":{
            "English": "2.5 km",
            "French": "2,5 km",
        },
        "Table1R5D4":{
            "English": "3.0 km",
            "French": "3 km",
        },
        "Table1R5D5":{
            "English": "2.5 km",
            "French": "2,5 km",
        },
        "Table1R6D1":{
            "English": "5",
            "French": "5",
        },
        "Table1R6D2":{
            "English": "5 min running, 1 min walking",
            "French": "5 min de course, 1 min de marche",
        },
        "Table1R6D3":{
            "English": "2.5 km",
            "French": "2,5 km",
        },
        "Table1R6D4":{
            "English": "3.5 km",
            "French": "3,5 km",
        },
        "Table1R6D5":{
            "English": "3.0 km",
            "French": "3 km",
        },
        "Table1R7D1":{
            "English": "6",
            "French": "6",
        },
        "Table1R7D2":{
            "English": "6 min running, 1 min walking",
            "French": "6 min de course, 1 min de marche",
        },
        "Table1R7D3":{
            "English": "2.5 km",
            "French": "2,5 km",
        },
        "Table1R7D4":{
            "English": "4.0 km",
            "French": "4 km",
        },
        "Table1R7D5":{
            "English": "3.5 km",
            "French": "3,5 km",
        },
        "Table1R8D1":{
            "English": "7",
            "French": "7",
        },
        "Table1R8D2":{
            "English": "7 min running, 1 min walking",
            "French": "7 min de course, 1 min de marche",
        },
        "Table1R8D3":{
            "English": "2.5 km",
            "French": "2,5 km",
        },
        "Table1R8D4":{
            "English": "4.5 km",
            "French": "4,5 km",
        },
        "Table1R8D5":{
            "English": "3.5 km",
            "French": "3,5 km",
        },
        "Table1R9D1":{
            "English": "8",
            "French": "8",
        },
        "Table1R9D2":{
            "English": "8 min running, 1 min walking",
            "French": "8 min de course, 1 min de marche",
        },
        "Table1R9D3":{
            "English": "2.5 km",
            "French": "2,5 km",
        },
        "Table1R9D4":{
            "English": "4.5 km w hills",
            "French": "4,5 km + côtes",
        },
        "Table1R9D5":{
            "English": "3.5 km",
            "French": "3,5 km",
        },
        "Table1R10D1":{
            "English": "9",
            "French": "9",
        },
        "Table1R10D2":{
            "English": "9 min running, 1 min walking",
            "French": "9 min de course, 1 min de marche",
        },
        "Table1R10D3":{
            "English": "2.5 km",
            "French": "2,5 km",
        },
        "Table1R10D4":{
            "English": "4.5 km w hills",
            "French": "4,5 km + côtes",
        },
        "Table1R10D5":{
            "English": "3.0 km",
            "French": "3 km",
        },
        "Table1R11D1":{
            "English": "10",
            "French": "10",
        },
        "Table1R11D2":{
            "English": "10 min running, 1 min walking",
            "French": "10 min de course, 1 min de marche",
        },
        "Table1R11D3":{
            "English": "2.5 km",
            "French": "2,5 km",
        },
        "Table1R11D4":{
            "English": "5.0 km",
            "French": "5 km",
        },
        "Table1R11D5":{
            "English": "3.5 km",
            "French": "3,5 km",
        },
        "Table1R12D1":{
            "English": "11",
            "French": "11",
        },
        "Table1R12D2":{
            "English": "10 min running, 1 min walking",
            "French": "10 min de course, 1 min de marche",
        },
        "Table1R12D3":{
            "English": "2.5 km",
            "French": "2,5 km",
        },
        "Table1R12D4":{
            "English": "5.0 km w hills",
            "French": "5 km + côtes",
        },
        "Table1R12D5":{
            "English": "4.0 km",
            "French": "4 km",
        },
        "Table1R13D1":{
            "English": "12",
            "French": "12",
        },
        "Table1R13D2":{
            "English": "10 min running, 1 min walking",
            "French": "10 min de course, 1 min de marche",
        },
        "Table1R13D3":{
            "English": "2.5 km",
            "French": "2,5 km",
        },
        "Table1R13D4":{
            "English": "5.5 km or race",
            "French": "5,5 km ou course",
        },
        "Table1R13D5":{
            "English": "2.5 km (easy)",
            "French": "2,5 km (facile)",
        },
        "Table1R14D1":{
            "English": "13",
            "French": "13",
        },
        "Table1R14D2":{
            "English": "10 min running, 1 min walking",
            "French": "10 min de course, 1 min de marche",
        },
        "Table1R14D3":{
            "English": "2 km",
            "French": "2 km",
        },
        "Table1R14D4":{
            "English": "GOAL EVENT",
            "French": "ÉVÉNEMENT",
        },
        "Table1R14D5":{
            "English": "*note is missing ", 
            "French": "Session 3 se fait souvent seul(e)",},
    },
    "Module Safety": {
        "Title": {
            "English": "Let's Start With Safety",
            "French": "Commençons avec la sécurité",
        },
        "SubTitle1": {
            "English": "Why Is Safety Important",
            "French": "Pourquoi la sécurité est-elle importante?",
        },
/*TODO: Where is the sentence ‘Running should be safe’? (Courir devrait être sécuritaire)*/
        "Paragraph1": {
            "English": "Before you start, it is critical to understand the importance of safety. Understanding and being aware of your surroundings, wearing proper clothing, taking care of your body and mind, and knowing when to stop and/or leave an unsafe situation. Above all, always trust your intuition when it comes to your health and safety.",
            "French": " Avant de commencer, il est important de comprendre l’importance de la sécurité. Tu dois être conscient(e) et comprendre ton environnement, porter les vêtements appropriés, prendre soin de ton corps et ton cerveau et savoir quand arrêter ou quitter une situation risquée ou dangereuse.  Par-dessus tout, fais toujours confiance à ton intuition lorsqu’il s’agit de ta santé et ta sécurité.",
        },
        "SubTitle2": {
            "English": "Safety Best Practices",
            "French": "Meilleures pratiques en matière de sécurité",
        },
        "List1Title": {
            "English": "DO",
            "French": "À faire",
        },
        "List1Item1": {
            "English": "Run during daylights hours when possible. If running during nighttime, avoid dim light and less populated areas",
            "French": " Courir le jour lorsque c’est possible. Si tu dois courir le soir, évite les endroits sombres et moins peuplés ",
        },
        "List1Item2": {
            "English": "Let a friend or family member know when and where you are running",
            "French": "Dis à quelqu’un [famille, ami(e)] où et quand tu as l’intention de courir.",
        },
        "List1Item3": {
            "English": "Plan ahead! Map out multiple routes in case of bad weather or route closure",
            "French": "Planifie à l’avance! Trace une variété de routes en cas de mauvaise température ou de fermeture de route.",
        },
        "List1Item4": {
            "English": "Carry a piece of identification, cellphone, and whistle in case of emergencies.",
            "French": "Apporte avec toi une pièce d’identité, un cellulaire et un sifflet en cas d’urgence.",
	},
        "List2Title": {
            "English": "DO NOT",
            "French": "À ne pas faire",},
        "List2Item1": {
            "English": "Run in the road! Stay on paths and sidewalks. If you must run on the road, run against traffic. This way you are aware of oncoming traffic.",
            "French": " Courir dans la rue! Reste sur un sentier ou un trottoir. Si tu dois courir dans la rue, cours contre la circulation, de cette façon tu es conscient(e) des voitures qui circulent dans vers toi. ",},
        "List2Item2": {
            "English": "Assume that when crossing the street, the vehicle can see you or will stop and yield the way. Wait until the vehicle has passed or make eye contact and wait for an acknowledgment before crossing paths.",
            "French": " Penser que la voiture va s’arrêter ou te laisser passer. Avant de traverser une rue, regarde bien pour t’assurer que les voitures soient bien arrêtées; établie un contact visuel avec les conducteurs(trices) et attend un signal avant de procéder.",
	},
        "List2Item3": {
            "English": "Run during a thunder/lightning storm or other dangerous weather conditions.",
            "French": "Courir pendant un orage ou d’autres conditions météorologiques dangereuses.",},
        "SubTitle3": {
            "English": "How To Dress For Running",
            "French": "Comment se vêtir pour courir",
        },
        "List3Item1": {
            "English": "In cold weather, wear warm layers such as a sweat-wicking long-sleeve base layer, running tights or pants, windproof running jacket, hat or headband, gloves, earmuffs, and warm socks",
            "French": " Dans les temps froids, porte des épaisseurs chaudes incluant une couche de base à manches longues qui permet d’évacuer la sueur, des collants ou des pantalons de course, un coupe-vent, un chapeau ou bandeau, des gants, des cache-oreilles et des chaussettes chaudes.",
        },
        "List3Item2": {
            "English": "Consider not using earphones when running outdoors. Loud music can decrease your awareness",
            "French": " Considère ne pas porter des écouteurs lorsque tu cours à l’extérieur puisque la musique forte peut diminuer ta concentration.",
        },
        "List3Item3": {
            "English": "At night, wear reflective and bright clothing and/or flashing lights to improve visibility",
            "French": " Le soir, porte des vêtements lumineux ou équipés de bandes réfléchissantes ou avec des lumières clignotantes pour améliorer la visibilité.",
        },
        "List3Item4": {
            "English": "In warm weather, consider wearing sweat-wicking clothes such as athletic shirts, shorts, and socks. UV protection sunglasses and sunscreen are helpful for extra sunny days. A water bottle or sports drink is essential to fight off dehydration.",
            "French": " Dans les temps chauds, porte des vêtements athlétiques qui repoussent la transpiration. Des verres fumés avec protection UV et la crème solaire sont utiles pour les journées ensoleillées. Une bouteille d’eau ou un breuvage sportif est essentiel pour lutter la déshydratation.",
        },
        "List3Item5": {
            "English": "Wear proper running shoes that fit correctly and provide good support. As a rule of thumb, your running shoes should be half a size bigger than your normal shoes.",
            "French": " Porte des espadrilles confortables avec un bon support. Habituellement, tes espadrilles sont un demi-point plus grand que tes souliers de tous les jours.",
        },
        "SubTitle4": {
            "English": "A Note On Dressing For The Weather",
            "French": "Une note sur l’habillement pour la météo",
        },
        "Paragraph2": {
            "English": "Remember: bodies heat up when we exercise. To avoid overheating, wear clothing in layers that can be taken on or off. Correct layering will regulate the body’s temperature. In colder temperature, wear clothing that protects your vital organs and limbs. Consider bringing a change of dry clothes to change into after you have finished running. Learning to dress correctly for the weather and time of day is the best way to keep your body safe!",
            "French": " Rappelle-toi que le corps se réchauffe lorsqu’on fait de l’exercice. Pour éviter la surchauffe, porte des épaisseurs qui peuvent être enlevées ou remis. Le port de vêtements appropriés aidera à régulariser la température du corps. Au froid, porte des vêtements qui protègent tes organes vitaux et tes membres. Tu pourrais apporter des vêtements de rechange pour te changer après ta course. Apprendre à bien se vêtir selon la température et l’heure de la journée est la meilleure façon de garder ton corps en sécurité!",
        },
        "SubTitle5": {
            "English": "Homework",
            "French": "Devoir",
        },
        "Paragraph3": {
            "English": "Discuss the importance of safety to physical and mental health in relation to your neighbourhood setting.",
            "French": "",/*TODO: not sure where this comes from , I don’t have it*/
        },
        "Paragraph4": {
            "English": "Complete the Safety module questions.",
            "French": " Complète les questions du module sur la sécurité liée à la santé physique et mentale en pensant particulièrement à ton quartier.", /*TODO:*My sentence seems to combine par 3 and 4… hmm */
        },
    },
    "Module Running Mechanics": {
        "Title": {
            "English": "RUNNING MECHANICS",
            "French": "TECHNIQUE DE COURSE",
        },
        "Paragraph1": {
            "English": "The exercises on this page have been assembled to help Team Unbreakable runners learn the most effective running technique. The focus is on form and posture.",
            "French": " Les exercices sur cette page ont été assemblées pour aider les coureurs de l’équipe imbattable à apprendre les techniques les plus efficaces. On se concentre sur la forme et la posture.",
        },
        "Paragraph2": {
            "English": "Think of running effectively like sitting on a unicycle: Our legs come up-and-down like a piston, our feet should land beneath our hips, ideally hitting the ground 90 times in a minute (per foot). This is called cadence.",
            "French": "Lorsque tu cours, imagine que tu es assis sur un monocycle : tes jambes montent et descendent comme des pistons, tes pieds devraient atterrir sous tes hanches et idéalement toucher le sol 90 fois par minute (par pied). Ceci s’appelle la cadence.",
        },
        "Paragraph3": {
            "English": "The drills included here help us develop the most effective running “economy”, which is a fancy way of saying developing good form. Try these Team Unbreakable approved beginner movements and fun warmups to help you get the feel of good running form.",
            "French": " Les exercices suivants nous aident à développer une bonne forme. Essaye les mouvements pour débutants et les échauffements suivants qui sont approuvés par L’équipe imbattable dans le but de t’aider à découvrir la bonne forme d’un coureur.",
        },
        "SubTitle1": {
            "English": "Marches",
            "French": "Marches",
        },
        "Paragraph4": {
            "English": "Stand up straight with your hands on your head. Raise one knee fast to hip level, then lower it forcefully. Alternate legs and maintain a tall, strong posture. Try not to bounce around too much. The focus should be on smooth, controlled movements. You’re marching in place with your hands on your head and bringing your knees up a bit higher than usual! Try this exercise 2-3 times for 20 seconds each before a run!",
            "French": " Debout bien droit avec les mains sur ta tête, soulève un genou rapidement devant toi à la hauteur de ta hanche, ensuite baisse le vigoureusement. Alterne les jambes et maintient une posture bien droite. Essaye de ne pas trop bondir. Concentre sur des mouvements doux et contrôlés. Tu marches sur place avec tes mains sur ta tête and tu montes tes genoux un peu plus haut que d’habitude! Essaye cet exercice 2-3 fois pendant 20 secondes avant chaque course!",
        },
        "SubTitle2": {
            "English": "Arm Pumps",
            "French": "Bras devant-derrière",
        },
        "Paragraph5": {
            "English": "Stand up straight with your fists shoulder-width apart. Imagine you’re in a “on your mark, get set, go!” position, with one arm back and one lifted in front of your torso. Keeping your body stable, pump your arms back and forth like you’re running on the spot without using your legs. Try this exercise 1-2 minutes before a run!",
            "French": " Debout bien droit, tes poings sont écartés à la largeur des épaules. Imagine que tu es en position de départ, avec un bras vers l’arrière et l’autre devant ton torse. Garde ton corps stable et bouge tes bras vers l’avant et l’arrière comme si tu courais sans utiliser tes jambes. Essaye cet exercice 1-2 minutes avant ta course!",
        },
        "SubTitle3": {
            "English": "Butt Kicks",
            "French": "Les talons au fessier",
        },
        "Paragraph6": {
            "English": "You can do this exercise either standing on-the-spot or moving forward. Have fun with it! Keep your shoulders back and your core tight. Now, kick your feet back until your heel hits your butt (formal name: gluteus maximus) and repeat with the other leg. Pump your arms as you go. Try this exercise 2-3 times for 30 seconds before a run!",
            "French": " Tu peux effectuer cet exercice sur place ou en bougeant. Amuse-toi! Maintient tes épaules vers l’arrière et tes abdominaux serrés. Maintenant, donne un coup de pied vers l’arrière jusqu’à ce que tes talons frappent ton fessier (nom officiel : gluteus maximus) et répète avec l’autre jambe. Pompe tes bras en même temps. Essaye cet exercice 2-3 fois pendant 30 secondes avant de courir.",
        },
        "SubTitle4": {
            "English": "High Knees",
            "French": "Monter les genoux",
        },
        "Paragraph7": {
            "English": "This “high knees” exercise is guaranteed to make you work hard, get your heart rate up, get you breathing harder, and you might even sweat. Stand tall with your head up, feet shoulder-width apart. Bring your knees up and pump and your arms at the same time. Allow your momentum to bring you forward, leaning slightly into the movement. Work your way towards a designated distance (e.g., across a room in your house, down your street, across a park in your environment), and then jog or walk back to the starting spot and do it again. Try this exercise 2-3 times for 30 seconds before a run, or after a run as a cool-down exercise.",
            "French": " Cet exercice te fera travailler fort, augmentera ta fréquence cardiaque et respiratoire et te fera peut-être même transpirer. Debout bien droit avec la tête élevée, tes pieds sont écartés à la distance de tes épaules. Monte tes genoux et pompe tes bras en même temps. Donne-toi un élan en te penchant légèrement vers l’avant. Cible une distance (p. ex., traverse une pièce dans la maison, descend la rue, traverse un parc dans ton quartier) ensuite marche ou cour pour revenir au point de départ et fais-le encore. Essaye cet exercice 2-3 fois pendant 30 secondes avant de courir, ou après une course pour te refroidir.",
        },
        "SubTitle5": {
            "English": "Running Posture",
            "French": "La posture du coureur",
        },
        "Paragraph8": {
            "English": "Try to keep your eyes off the ground and your head up. Pick a spot in the distance and focus on it.",
            "French": "Essaye de regarder devant toi et garder ta tête élevée. Choisis un point dans la distance et concentre-toi sur ce point.",
        },
        "Paragraph9": {
            "English": "You want to be tall and strong (think unbreakable!) but not too tight and rigid. Pretend there is string attached to the top of your head, pulling you upwards.",
            "French": " Tu veux être grand(e) et fort(e) (pense imbattable!) sans être tendu(e) ou rigide. Fais à semblant qu’il y a une ficelle attachée au-dessus de ta tête qui la tire vers le haut.",
        },
        "Paragraph10": {
            "English": "Keeping your head up will help prevent you from slouching, which makes it more difficult to breathe while running.",
            "French": " Garder ta tête élevée t’aidera à éviter de courber le dos ce qui rend la respiration plus difficile en courant.",
        },
        "Paragraph11": {
            "English": "Once you are comfortable in this position, lock it in and you are ready to go.",
            "French": " Une fois que tu es confortable dans cette position, enregistre-la et tu es prêt(e) à partir.",
        },
        "Paragraph12": {
            "English": "The most important thing is this: just try your best! Very few people have the “perfect form” in technical terms. This is ok because we are all different. Find what works best for you, stick with it, and have fun.",
            "French": " Il est surtout important de faire du mieux que tu peux! Très peu de personnes ont la ‘forme parfaite’ au niveau de la technique. C’est correct car nous sommes tous différents. Trouve ce qui fonctionne le mieux pour toi, ne lâche pas et amuse-toi.",
        },
    },
    "Module Running Drills": {
        "Title": {
            "English": "RUNNING DRILLS",
            "French": "EXERCICES",
        },
        "Paragraph1": {
            "English": "Use these drills before or after runs, or even in your free time when you don’t have a run planned. Make them work for your schedule.",
            "French": " Utilise ces exercices avant et après tes courses ou même pendant les moments où tu n’as pas prévu courir. Tu peux t’en servir selon ton horaire.",
        },
        "Paragraph2": {
            "English": "Remember, relax your body and breathe as you work through these drills, using mindfulness tools to check in with your body.",
            "French": " Souviens-toi de détendre ton corps et respirer pendant que tu pratiques ces exercices, utilise tes outils de la pleine conscience pour monitorer ton corps.",
        },
        "Paragraph3": {
            "English": "These drills can accompany any of your Team Unbreakable training. You don’t have to do all of the drills at once, and you can mix and match as you get comfortable with them. We have provided a sample of how the activities could be matched, but please get creative too.",
            "French": "Tu peux intégrer ces exercices à tout moment de ton entraînement. Tu n’es pas obligé(e) de faire tous les exercices en même temps, tu peux en combiner comme bon te semble. Voici un échantillon à titre d’exemple, mais tu peux créer ton propre horaire.",
        },
        "SubTitle1": {
            "English": "Body Weight Awareness Drill",
            "French": "Exercice de prise de conscience du poids corporel",
        },
        "Paragraph4": {
            "English": "This is a balance drill. It will help us stay steady as our weight shifts when running.",
            "French": "Ceci est un exercice pour l’équilibre. Il te permettra de demeurer stable lors du transfert de poids d’une jambe à l’autre en courant.",
        },
        "BoldTitle1": {
            "English": "The Basics:",
            "French": "La base:",
        },
        "Paragraph5": {
            "English": "Start by standing in good posture, like someone is pulling the top of your head to stand straight up. Next, try to sway your body weight front to back, and back to front, and feel the weight transfer from the heels of the feet to your toes. The minute you have to take a step, or you fall over, you have gone too far.",
            "French": " Commence en te tenant debout droit, comme si quelqu’un tirait le dessus de ta tête pour te redresser. Ensuite, balance ton poids vers l’avant et l’arrière en tenant compte du poids qui est transféré des talons aux orteils. Du moment où tu dois prendre un pas pour éviter de tomber, tu es allé(e) trop loin.",
        },
        "BoldTitle2": {
            "English": "The Progression:",
            "French": "La progression",
        },
        "Paragraph6": {
            "English": "Try shifting your weight side to side, or in a circular motion as well!",
            "French": "Essaye de transférer ton poids d’un côté à l’autre ou dans un movement circulaire.",
        },
        "BoldTitle3": {
            "English": "Video Demonstration:",
            "French": "Vidéo de démontration",
        },
        "URL1": {
            "English": "https://www.youtube.com/watch?v=psEyuLcQwyI",
            "French": "https://www.youtube.com/watch?v=psEyuLcQwyI",
        },
        "SubTitle2": {
            "English": "Ball of Foot Hops with Forward Lean",
            "French": "Saut plante du pied en se penchant vers l’avant",
        },
        "Paragraph7": {
            "English": "This drill teaches us that simply changing the point of support that is striking the ground can help us move forward.",
            "French": "Cet exercice nous montre qu’en modifiant le point de support qui touche le sol, cela nous aide à bouger vers l’avant.",
        },
        "BoldTitle4": {
            "English": "The Basics:",
            "French": "La base:",
        },
        "Paragraph8": {
            "English": "Start by standing in a good, tall, and proud posture. Make sure your head is in a neutral position, core is tight, and that your shoulders are stacked on top of your hips. With a slight bend in your knees, try two-foot bounces on the balls of your feet while staying in one spot. Let the balls of your feet hit, while your heels will just slightly touch the ground on each bounce.",
            "French": " Commence par te tenir debout bien droit et fier. Assure-toi que ta tête est en position neutre, les abdominaux sont serrés et que tes épaules sont au-dessus de tes hanches. Avec les genoux légèrement fléchis, essaye de sauter avec tes deux pieds sur la plante de tes pieds en demeurant dans un endroit. Laisse la plante de tes pieds toucher le sol alors que les talons touchent à peine.",
        },
        "BoldTitle5": {
            "English": "The Progression:",
            "French": "La progression",
        },
        "Paragraph9": {
            "English": "Now that you have gotten the hang of the bounce, slightly increase the bend in your knees so that each two-foot bounce will force you to move slightly forward. Try it on one foot too!",
            "French": " Maintenant que tu maîtrises ce saut, augmente un peu le plie des genoux pour que chaque saut te force à bouger légèrement vers l’avant. Essaye-le aussi avec seulement un pied!",
        },
        "BoldTitle6": {
            "English": "Video Demonstration:",
            "French": "Vidéo de démonstration",
        },
        "URL2": {
            "English": "https://www.youtube.com/watch?v=TtW9od2Iy9w",
            "French": "https://www.youtube.com/watch?v=TtW9od2Iy9w",
        },
        "SubTitle3": {
            "English": "Falling Start Drill",
            "French": "Exercice faux départ",
        },
        "Paragraph10": {
            "English": "This drill helps to improve our running posture.",
            "French": "Cet exercice te permet d’améliorer ta posture pendant ta course.",
        },
        "BoldTitle7": {
            "English": "The Basics:",
            "French": "La base",
        },
        "Paragraph11": {
            "English": "Start by standing in front of a wall. Make sure you are standing in a good, tall, and proud posture. Then lean forward slowly, only bending at your ankles, and catch yourself on the wall before you fall forward.",
            "French": " Tu commences debout bien droit devant un mur. Assure-toi d’avoir une bonne posture, grande et fière. Penche-toi légèrement vers l’avant, fléchissant seulement tes chevilles et arrête-toi de tomber en touchant le mur.",
        },
        "BoldTitle8": {
            "English": "The Progression:",
            "French": "La progression",
        },
        "Paragraph12": {
            "English": "Once you have gotten the hang of it, try this without a wall in front of you! Again, start by standing straight in a tall posture. Lean with your entire body forward, bending only at your ankles, and catch yourself with a step forward into a slight jog before you fall.",
            "French": " Une fois que tu maîtrises l’exercice, essaye sans mur devant toi. Encore, commence avec le dos bien droit. Penche ton corps vers l’avant, en fléchissant seulement les chevilles et arrête-toi de tomber en prenant un pas vers l’avant et te lançant dans un jogging.",
        },
        "BoldTitle9": {
            "English": "Video Demonstration:",
            "French": "Vidéo de démontration",
        },
        "URL3": {
            "English": "https://www.youtube.com/watch?v=k-g_AbbQ438",
            "French": "https://www.youtube.com/watch?v=k-g_AbbQ438",
        },
        "SubTitle4": {
            "English": "Charlie's Angels Drill",
            "French": "Exercice ‘Les anges de Charlie’",
        },
        "Paragraph13": {
            "English": "This drill helps us keep our core tight when we run.",
            "French": "Cet exercice nous aide à garder les abdominaux serrés lorsqu’on court.",
        },
        "BoldTitle10": {
            "English": "The Basics:",
            "French": "La base",
        },
        "Paragraph14": {
            "English": "Start by standing in a good, tall, and proud posture. Then bring your arms straight in front of you, clasping your hands together without bending your elbows. With your hands clasped and out in front of you, you will now jog forward without a wag or bounce in your hands. You will know you are keeping your core tight if your hands do not bounce!",
            "French": " Tu commences debout bien droit. Allonge tes bras devant toi en joignant les mains sans plier les coudes. Avec les mains devant toi, tu vas courir sans bouger ni bondir tes mains. Tu sais que tes abdominaux sont serrés si tes mains ne bondissent pas!",
        },
        "SubTitle5": {
            "English": "Wall Drill",
            "French": "Exercice au mur",
        },
        "Paragraph15": {
            "English": "This drill is intended to help with our cadence and the rise and fall of our feet while running.",
            "French": "Cet exercice vise à t’aider avec ta cadence et le movement de tes pieds en courant.",
        },
        "BoldTitle11": {
            "English": "The Basics:",
            "French": "La base",
        },
        "Paragraph16": {
            "English": "Starting in a good posture, bring your arms out in front of you. With your hands in fists, walk towards a wall until your fists are flush with the wall. Without moving your feet, open your hands and place your palms on the wall. Your body should now be slightly leaning forward toward the wall. Once your body is used to this new angle, begin running on the spot. Think about pulling your feet with each step. Try freezing your body in motion to check and make sure that your feet are not going past your hips.",
            "French": " Tu commences debout bien droit. Lève tes bras devant toi et avec les mains en poings, marche vers un mur jusqu’à ce que tes poings touchent le mur. Sans bouger tes pieds, ouvre tes mains et place tes paumes sur le mur. Ton corps devrait être légèrement penché vers l’avant. Une fois que ton corps est confortable dans cette position, commence à courir sur place. Pense au fait de tirer ton pied vers le haut avec chaque pas. Essaye d’arrêter ton corps pendant le mouvement pour t’assurer que tes pieds sont alignés avec tes hanches.",
        },
        "BoldTitle12": {
            "English": "The Progression:",
            "French": "La progression",
        },
        "Paragraph17": {
            "English": "Once you have gotten the hang of it, try running off the wall while maintaining the same body mechanics.",
            "French": " Une fois que tu es confortable avec ce mouvement, essaye de courir sans toucher le mur tout en maintenant la mécanique corporelle.",
        },
        "BoldTitle13": {
            "English": "Video Demonstration:",
            "French": "Vidéo de démonstration",
        },
        "URL4": {
            "English": "https://www.youtube.com/watch?v=AUCBynFNCl4",
            "French": "https://www.youtube.com/watch?v=AUCBynFNCl4",
        },
        "SubTitle6": {
            "English": "Sample Schedule For Drills",
            "French": "Exemple d’un horaire d’exercice",
        },
        "BoldTitle14": {
            "English": "Week 1:",
            "French": "Semaine 1",
        },
        "List1Item1": {
            "English": "Ball of Foot Hops with Forward Lean 3x10",
            "French": "Saut sur la plante des pieds 3 x 10",
        },
        "List1Item2": {
            "English": "Wall Drill 3x20 on each leg",
            "French": "Exercice au mur 3 x 20 sur chaque jambe",
        },
        "BoldTitle15": {
            "English": "Week 2:",
            "French": "Semaine 2",
        },
        "List2Item1": {
            "English": "Forward Lunge 3x10 each leg",
            "French": "Fente avant 3 x 10 chaque jambe",
        },
        "List2Item2": {
            "English": "Charlie’s Angels Drill 3x1 minute to a marker or wall, jog/walk back, repeat",
            "French": "Exercice ‘Les anges de Charlie’ 3 x 1 minute vers un point au mur, marche/jog pour revenir, répéter.",
        },
        "BoldTitle16": {
            "English": "Week 3:",
            "French": "Semaine 3",
        },
        "List3Item1": {
            "English": "Wall Drill 3x20 on each leg",
            "French": "Exercice au mur 3 x 20 sur chaque jambe",
        },
        "List3Item2": {
            "English": "Falling Start Drill 4x “fall forward” and jog to a marker or wall, jog/walk back, repeat",
            "French": "Exercice faux depart 4 x ‘tomber-avant’ et courir à un point au mur, marche/jog pour revenir, répéter.",
        },
        "List3Item3": {
            "English": "Ball of Foot Hops with Forward Lean 3x10",
            "French": "Saut sur la plante des pieds, penché vers l’avant 3 x 10",
        },
        "BoldTitle17": {
            "English": "Week 4:",
            "French": "Semaine 4",
        },
        "List4Item1": {
            "English": "Charlie’s Angels Drill 3x1 minute to a marker or wall, jog/walk back, repeat",
            "French": "Exercice ‘Les anges de Charlie’ 3 x 1 minute vers un point au mur, marche/jog pour revenir, répéter.",
        },
        "List4Item2": {
            "English": "Wall Drill 4x20 on each leg",
            "French": "Exercice au mur 4 x 20 sur chaque jambe",
        },
        "List4Item3": {
            "English": "Falling Start Drill 4x “fall forward” and jog to a marker or wall, jog/walk back, repeat",
            "French": "4 x ‘tomber vers l’avant’ et jogger à un point, marche/jog pour revenir, répéter",
        },
        "Paragraph18": {
            "English": "Repeat your positive self-statements as you move through these activities. Focus on how repeating these statements alters your experience and state of mind while exercising",
            "French": " Répète tes affirmations positives en faisant ces activités. Concentre-toi sur comment l’affirmation modifie ton expérience et ton état d’esprit pendant les exercices.",
        },
    },
    "Module Nutrition": {
        "Title": {
            "English": "NUTRITION & HYDRATION",
            "French": "ALIMENTATION et HYDRATATION",
        },
        "Paragraph1": {
            "English": "Proper nutrition and hydration are fundamental to happy and effective running. Proper nutrition and hydration means drinking enough water (hydration) and making sure we nourish ourselves with nutrient rich snacks before, after, and sometimes even during exercise. In the running world, we refer to this as “fueling”: making sure our bodies have what they need to function at their best.",
            "French": " Une alimentation et hydratation adéquates sont fondamentales pour une course agréable et efficace. Il faut boire assez d’eau (hydratation) et s’alimenter avec des collations nutritives avant, après et parfois même pendant l’exercice. Dans le monde de la course, nous parlons de ‘carburant’ : c’est-à-dire s’assurer que notre corps a ce dont il a besoin pour fonctionner à son meilleur.",
        },
        "BoldTitle1": {
            "English": "Carbohydrates: What are they?",
            "French": "Glucides: c’est quoi?",
        },
        "Paragraph2": {
            "English": "A carbohydrate is a major nutrient needed in the body, and is composed of fiber, starch, and sugar. Thinking scientifically, carbohydrates (or “carbs”) are macromolecules made up of water and carbon. Our bodies break these down into smaller molecules which give us the energy that we need to get through the day.",
            "French": " Un glucide, un aliment nutritif important pour le corps, est composé de fibres, d’amidon et de sucre. En pensant scientifiquement, les glucides sont des macromolécules composées d’eau et de carbone. Notre corps les décompose en molécules plus petites qui nous fournissent l’énergie dont on a besoin pendant la journée.",
        },
        "BoldTitle2": {
            "English": "Carbohydrates are broken down into two major categories:",
            "French": "Il existe deux types majeurs de glucides:",
        },
        "Paragraph3": {
            "English": "Simple carbohydrates are broken down quickly in the body. They exist naturally in foods like fruits, vegetables, yogurt, and milk. However, simple carbohydrates are also found artificially in refined sugars (candy). They can have a negative impact on the body.",
            "French": "Les glucides simples sont digérés rapidement. Ils existent naturellement dans des aliments tels que des fruits, des légumes, du yaourt et du lait. Toutefois, les glucides simples sont aussi retrouvés de façon artificielle dans le sucre raffiné (bonbons). Ils peuvent avoir un impact négatif sur le corps.",
        },
        "Paragraph4": {
            "English": "Complex carbohydrates are broken down slowly in the body. They’re good to eat before exercise. Complex carbohydrates are great for runners because we need long lasting fuel for our training. Examples of complex carbohydrates are vegetables, some fruits, whole grains and oats, legumes, and beans.",
            "French": " Les glucides complexes sont digérés lentement. Ils sont bons à manger avant de faire de l’exercice. Les glucides complexes sont particulièrement bons pour les coureurs car nous avons besoin de carburant qui dure longtemps pour nos entraînements. Des exemples de glucides complexes sont des légumes, certains fruits, des grains entiers et l’avoine, les légumineuses et les haricots.",
        },
        "BoldTitle3": {
            "English": "Why Do Runners Need Carbs?",
            "French": "Pourquoi les coureurs ont-ils besoin des glucides?",
        },
        "Paragraph5": {
            "English": "Studies show that eating carbohydrates before a run improves alertness, endurance, and even reduces risk of injury. A simple snack like a banana or apple before a Team Unbreakable training run can make a difference in how you feel when you’re out there on the road. The body uses carbohydrates to produce glucose, the main type of sugar in the blood and the primary energy source for our body’s cells. Carbohydrates give runners an energy supply of glucose to help fuel us through our training runs.",
            "French": " Les recherches démontrent qu’en mangeant des glucides avant de courir on améliore l’éveil, l’endurance et on réduit même le risque de blessure. Une collation simple comme une banane ou une pomme avant un entraînement peut faire une différence sur comment tu te sens sur la route. Le corps transforme les glucides en glucose, le sucre retrouvé dans notre sang est la principale source d’énergie de nos cellules. Les glucides permettent une réserve d’énergie qui aide à fournir au coureur le carburant dont il a besoin pendant sa course d’entraînement.",
        },
        "BoldTitle4": {
            "English": "Best Snacks: Pre and Post Run!",
            "French": "Meilleures collations: avant et après la course!",
        },
        "Paragraph6": {
            "English": "Enjoy these healthy snacks before a run to help give you that needed energy boost. Remember, these aren’t intended as full meals, just quick pick-me-up treats right before and after your run.",
            "French": " Mange une collation santé avant la course pour rehausser ton énergie.  Souviens-toi qu’il ne s’agit pas d’un repas, seulement un petit remontant avant ou après ta course.",
        },
        "Table1R1D1": {
            "English": "Pre-Run Snacks",
            "French": "Avant la course",
        },
        "Table1R1D2": {
            "English": "Banana, Multigrain Bagel, Dry Cereal, Blueberries",
            "French": "banana, bagel multigrain, céréales sèches, bleuets",
        },
        "Table1R2D1": {
            "English": "Post-Run Snacks",
            "French": "Après la course",
        },
        "Table1R2D2": {
            "English": "Trail Mix, Chocolate Milk, Fruit Smoothie, Greek Yogurt",
            "French": "mélange de noix et de fruits séchés, lait au chocolat, smoothie aux fruits, yaourt grecque",
        },
        "Table1R3D1": {
            "English": "Paleo Options",
            "French": "Options paléo",
        },
        "Table1R3D2": {
            "English": "(Post or Pre Run): Plantain Chips, Carrots, Sweet Potato Fries",
            "French": "(avant ou après): chips plantain, carottes, patates douces frittes",
        },
        "Table1R4D1": {
            "English": "Gluten Free Options ",
            "French": "Options sans gluten",
        },
        "Table1R4D2": {
            "English": "(Post or Pre Run): Brown Rice Cakes, Greek Yogurt, Fruits and Veggies",
            "French": "(avant ou après): galette de riz brun, yaourt grecque, fruits et légumes",
        },
        "Table1R5D1": {
            "English": "Nut Free Options",
            "French": "Options sans noix",
        },
        "Table1R5D2": {
            "English": "(Post or Pre Run): Pretzels, Rice Cakes, Multigrain Toast with Wow Butter",
            "French": "(avant ou après): bretzels, galettes de riz, toasts multigrain avec ‘Wow butter’",
        },
        "SubTitle1": {
            "English": "Hydration",
            "French": "Hydratation",
        },
        "Paragraph7": {
            "English": "Sometimes, we get so busy we forget to do the basics. Things like drinking enough water through the day can have a big impact on the way we think and feel. It’s even more important to stay hydrated when we exercise.",
            "French": " Parfois, nous sommes tellement occupés qu’on oublie de faire les choses les plus simples. Par exemple, ne pas boire assez d’eau pendant la journée peut avoir un gros impact sur comment on pense et comment on se sent. Il est d’autant plus important d’être hydrater lorsqu’on fait de l’exercice.",
        },
        "Paragraph8": {
            "English": "It’s suggested that children aged 9 to 13 drink 7-8 cups of water a day and youth aged 14 to 18 drink 8-11 cups.",
            "French": " Il est recommandé que les enfants âgés entre 9 et 13 ans boivent 7-8 verres d’eau par jour et les jeunes âgés de 14-18 ans en boivent 8-11 verres.",
        },
        "Paragraph9": {
            "English": "There are a lot of sports drinks available to runners of all levels. But most times, these drinks are no better than a good glass of water. Drinking water helps to avoid muscle cramps during running (and while we’re keeping our muscles primed and ready to move). But for periods of exercise that last an hour or more, it’s important to consume a drink that contains sodium. This is where those sugary sports drinks come in handy.",
            "French": " Il existe plusieurs boissons pour sportifs. La plupart du temps, ces boissons ne peuvent pas remplacer un bon verre d’eau. Boire de l’eau aide à prévenir les crampes musculaires pendant que tu cours (ainsi que pendant les échauffements). Pour un exercice qui dure une heure ou plus, il est important de consommer une boisson qui contient du sodium. Voilà où les boissons pour sportifs peuvent être utiles.",
        },
        "Paragraph10": {
            "English": "And, if you are following the trends, there are also gels and energy bars that offer in-run nutrition. Just remember that these products don’t also offer hydration, so drinking water is still necessary.",
            "French": " Si tu suis les tendances, il existe aussi des gels énergétiques et des barres d’énergie qui offrent de l’énergie pendant la course. Mais souviens-toi que ces produits ne servent pas à t’hydrater, tu dois tout de même boire de l’eau.",
        },
        "BoldTitle5": {
            "English": "References",
            "French": "Références",
        },
        "Paragraph11": {
            "English": "Liecht, L. (2019, August 14) What to eat before, during, and after running. Health Magazine.",
            "French": " Liecht, L. (2019, August 14) What to eat before, during, and after running. Health Magazine.",
        },
        "Paragraph12": {
            "English": "Melina, R. (2010, October 21). Why do carbs improve marathon runners’ performance? Live Science.",
            "French": " Melina, R. (2010, October 21). Why do carbs improve marathon runners’ performance? Live Science.",
        },
        "Paragraph13": {
            "English": "Nesevich Bede, P. (2019, October 11). Wondering what to eat before running? Here are 16 healthy options. Runner’s World Magazine",
            "French": " Nesevich Bede, P. (2019, October 11). Wondering what to eat before running? Here are 16 healthy options. Runner’s World Magazine",
        },
        "Paragraph14": {
            "English": "Shirreffs, S. M. (2009). Hydration in sport and exercise: Water, sports drinks, and other drinks. Nutrition Bulletin, 34(4), 374-379.",
            "French": " Shirreffs, S. M. (2009). Hydration in sport and exercise: Water, sports drinks, and other drinks. Nutrition Bulletin, 34(4), 374-379.",
        },
    },
    "Module Setbacks": {
        "Title": {
            "English": "Managing Physical Setbacks",
            "French": "GÉRER LES OBSTACLES PHYSIQUES",
        },
        "Paragraph1": {
            "English": "Cramps and body pains might get in the way of your Team Unbreakable training. This section includes strategies to help you manage running-related cramps and minimize your risk of injury.",
            "French": " Les crampes et les douleurs corporelles peuvent empiéter sur ton entraînement. Cette section offre des stratégies qui t’aideront à contrôler les crampes liées à la course et à minimiser le risque de blessure.",
        },
        "BoldTitle1": {
            "English": "Breathing Cramps",
            "French": "Crampes liées à la respiration",
        },
        "Paragraph2": {
            "English": "Have you ever been running along when you suddenly feel a cramping feeling in the side of your abdomen? Some people call it a “stitch” and many experts agree that it is related to shallow breathing. Proper breathing while exercising should come from the belly. Inhaling by raising your shoulders and puffing out your chest will cause you to take shorter breaths, preventing you from getting the maximum amount of oxygen out of the air. For distance running and most other sports, absorbing enough oxygen is critical for your body to keep on going.",
            "French": " T’est-il déjà arrivé de ressentir une douleur intense d’un côté de ton abdomen pendant que tu cours? Certaines personnes appellent ça un ‘point’ et plusieurs experts disent que c’est lié à la respiration rapide et peu profonde. Pendant l’exercice, on devrait respirer profondément avec le ventre. Respirez en haussant les épaules et en ressortant la poitrine t’oblige à prendre de courtes respirations, ce qui t’évite d’obtenir le maximum d’oxygène possible. Pour les courses à distance et plusieurs autres sports, absorber assez d’oxygène est critique pour que ton corps continue à bouger.",
        },
        "Paragraph3": {
            "English": "Practice deep breathing: As you inhale, push out your belly and try to keep your rib cage from moving too much. It will probably feel weird at first but running is a great time to practice this technique and it will certainly improve your performance.",
            "French": " Pratique des respirations profondes: en inspirant, ressort ton ventre et essaye d’éviter que ta cage thoracique bouge trop. Le sentiment sera étrange pour commencer mais la course est un bon moment de pratiquer cette technique. Cela améliorera sûrement ta performance.",
        },
        "Paragraph4": {
            "English": "Remember the mindful breathing practice you’ve done. Focusing on taking deep breaths from the belly will help to make sure your body is getting enough oxygen. The more you practice deep breathing throughout your day, the more comfortable you will be using this type of breathing when you are running.",
            "French": " Souviens-toi de la pleine conscience respiratoire que tu as pratiquée. Se concentrer sur les grandes inspirations du ventre aidera à t’assurer que ton corps obtienne assez d’oxygène. En pratiquant souvent les respirations profondes, tu deviendras à l’aise de t’en servir en courant.",
        },
        "BoldTitle2": {
            "English": "Eating Cramps",
            "French": "Crampes liées à l’alimentation",
        },
        "Paragraph5": {
            "English": "Foods rich in protein and fat can take longer to digest than other foods, so eating these foods right before a run can contribute to side and stomach cramps. Drinking too much water can also weigh down the stomach, put additional strain on the muscles, and lead to cramping. If this is a persistent problem, try switching up your pre-run snack and find what works well for you. For example, some people swear by eating bananas before runs while others find that they cause gas, increasing the risk of stomach pain.",
            "French": " Les aliments riches en protéines et en gras prennent plus longtemps à digérer, que d’autres aliments, donc si on mange ces aliments avant une course, cela peut contribuer aux crampes au ventre ou au côté. Boire trop d’eau peut aussi alourdir l’estomac, ajouter une pression sur les muscles et mener aux crampes. Si ce problème persiste, essaye de manger une différente collation avant ta course pour voir si cela fonctionne mieux pour toi. Par exemple, certaines personnes jurent qu’une banane est la meilleure collation avant une course alors que d’autres disent que cela provoque des gaz, augmentant alors le risque de douleur au ventre.",
        },
        "List1Item1": {
            "English": "Give your body time to digest big meals or rich foods before a run",
            "French": "Donne le temps à ton corps de digérer un gros repas ou des aliments riches avant de faire ta course.",
        },
        "List1Item2": {
            "English": "Eat light snacks before a run and figure out what works best for you",
            "French": "Mange une collation légère avant ta course et découvre laquelle fonctionne mieux pour toi.",
        },
        "BoldTitle3": {
            "English": "Muscle Cramps",
            "French": "Crampes musculaires",
        },
        "Paragraph6": {
            "English": "Leg cramps are also relatively common with runners. However, there are likely multiple causes of these painful muscle spasms that occur during running and other forms of physical activity. To reduce the severity and frequency of cramps:",
            "French": "Les crampes aux jambes sont assez communes chez les coureurs. Toutefois, il y a probablement plusieurs causes pour les spasmes qui se produisent pendant une course et d’autres activités physiques. Pour réduire la sévérité et la fréquence des crampes:",
        },
        "List2Item1": {
            "English": "Make sure to complete a sufficient warm-up before running",
            "French": "Il est essential de commencer par un bon échauffement avant de courir",
        },
        "List2Item2": {
            "English": "Drink plenty of water throughout the day",
            "French": "Buvez amplement d’eau pendant la journée",
        },
        "List2Item3": {
            "English": "Make sure you are getting enough electrolytes",
            "French": "Assurez-vous d’avoir assez d’électrolytes",
        },
        "BoldTitle4": {
            "English": "What is an Electrolyte?",
            "French": "Alors, c’est quoi un electrolyte?",
        },
        "Paragraph7": {
            "English": "Electrolytes are minerals dissolved in our body’s fluids. They’re important for the body’s regular functions and even more important when we’re running.",
            "French": "Les électrolytes sont des minéraux dessous dans nos fluids corporels. Ils sont importants pour les fonctions régulières de ton corps et encore plus important lorsqu’on cour.",
        },
        "Paragraph8": {
            "English": "The 3 main electrolytes are…",
            "French": "Les 3 électrolytes principaux sont…",
        },
        "List3Item1": {
            "English": "Sodium",
            "French": "le sodium",
        },
        "List3Item2": {
            "English": "Chloride",
            "French": "le chlore",
        },
        "List3Item3": {
            "English": "Potassium",
            "French": "le potassium",
        },
        "Paragraph9": {
            "English": "We lose electrolytes when we run and sweat. Some healthy and tasty options for replenishing electrolytes following exercise include: coconut water, watermelon, avocado, fruit smoothies, bananas, and green veggies.",
            "French": " On perd des électrolytes lorsqu’on cour et transpire. Voici des exemples d’aliments, santé et délicieux, pour rehausser les électrolytes après ton exercice : eau de noix de coco, melon d’eau, avocat, smoothie aux fruits, bananes et légumes verts.",
        },
        "Paragraph10": {
            "English": "If these annoying cramping symptoms happen to you, remember that most cramps are linked with overworking your muscles and shallow breathing. So, practicing correct breathing techniques and conditioning your muscles through your Team Unbreakable training can help prevent cramps. Engaging in pre-run and post-run stretches can also help.",
            "French": " Si tu as la fâcheuse habitude d’avoir des crampes, souviens-toi que la plupart des crampes sont liées aux muscles et aux courtes inspirations. Mettre en pratique les bonnes techniques de respirations et conditionner tes muscles grâce aux exercices décrits dans ton guide d’entraînement de ‘l’équipe imbattable’ peut prévenir des crampes. Faire des étirements avant et après ta course peut aussi aider.",
        },
        "SubTitle1": {
            "English": "Injuries",
            "French": "Blessures",
        },
        "Paragraph11": {
            "English": "Unfortunately, injuries can be a part of our training experience. The most important thing we can do as runners – whether just starting out or long into training – is listen to our body when it’s too sore or hurt to run and take the time we need to recover fully. Injuries range from mild to severe. What’s essential is that we never “push through pain” when completing a Team Unbreakable training run. “Pushing through pain” can turn a relatively minor ache or strain into a serious injury requiring a lot of time off.",
            "French": "Malheureusement, des blessures peuvent faire partie de l’expérience d’entraînement. La chose la plus importante à faire comme coureur, que tu sois débutant ou non, est d’écouter ton corps. Lorsqu’il a trop mal pour courir, prends le temps nécessaire pour récupérer complètement. C’est essentiel de ne pas poursuivre malgré la douleur lorsque tu participes à une course d’entraînement de l’équipe imbattable. Poursuivre malgré la douleur peut transformer un mal ou une douleur mineure en blessure sérieuse qui nécessite un congé plus long.",
        },
        "Paragraph12": {
            "English": "The best ways to minimize injury are:",
            "French": "Les meilleures façons de réduire le risqué de blessures sont:",
        },
        "List4Item1": {
            "English": "Strength train: Practice strength training exercises like the ones below to keep your body strong.",
            "French": "Entraînement de la force musculaire: pratique des exercices comme ceux décrits ci-dessous par garder ton corps fort.",
        },
        "List4Item2": {
            "English": "Warm up: Take the time to do warm-up exercises before you run.",
            "French": "Échauffement: prend le temps d’effectuer des exercices d’échauffement avant de courir.",
        },
        "List4Item3": {
            "English": "Stretch: Try different dynamic stretches like the ones on the next page to keep your muscles loose and limber.",
            "French": "Étirement: essaye différents étirements comme ceux sur la prochaine page pour garder des muscles détendus et souples.",
        },
        "List4Item4": {
            "English": "Listen to your body: Don’t run if you’re hurt, feeling sick, or overly tired. Remember that time off is important time to help your body recover and get ready for more training.",
            "French": "Écoute ton corps: ne court pas si tu es blessé(e), tu te sens malade ou très fatigué(e). Souviens-toi que le temps de repos est important pour aider à ton corps à récupérer et se préparer pour d’autres sessions d’entraînement.",
        },
        "SubTitle2": {
            "English": "Team Unbreakable Beginner Strength Training Exercises",
            "French": "Équipe imbattable – exercices d’entraînement pour la force musculaire (débutant(e))",
        },
        "BoldTitle5": {
            "English": "Walking Lunges",
            "French": "Fentes avant",
        },
        "List5Item1": {
            "English": "Maintain straight upper body throughout the movement",
            "French": "Garde le dos bien droit pendant ce mouvement",
        },
        "List5Item2": {
            "English": "Maintain your balance",
            "French": "Maintien ton équilibre",
        },
        "List5Item3": {
            "English": "Knees are tracking properly; centered over foot",
            "French": "Le genou reste dans l’axe du pied",
        },
        "List5Item4": {
            "English": "Movement should not be painful",
            "French": "Le movement ne devrait pas être douloureux",
        },
        "List5Item5": {
            "English": "Alternate steps",
            "French": "Alterner les pas!",
        },
        "List5Item6": {
            "English": "Try 3 sets of 12 steps",
            "French": "Essaye 3 séries de 12 pas",
        },
        "BoldTitle6": {
            "English": "Calf Raises", 
            "French": "Mollets debout sur une jambe",
        },
        "List6Item1": {
            "English": "Perform at least 2 sets of 10 calf raises on each leg",
            "French": "Fais au moins 2 séries de 10 sur chaque jambe",
        },
        "List6Item2": {
            "English": "Balance yourself on an object if needed (a chair works!)",
            "French": "Tenir une chaise ou le mur au besoin pour garder ton équilibre",
        },
        "List6Item3": {
            "English": "Movement should not be painful",
            "French": "Le movement ne devrait pas être douloureux",
        },
        "List6Item4": {
            "English": "Try doing 3 sets of 12 reps",
            "French": "Essaye 3 séries de 12 répétitions",
        },
        "BoldTitle7": {
            "English": "Side Plank",
            "French": "Planche sur le côté",
        },
        "List7Item1": {
            "English": "3 sets of holding 45 seconds per side",
            "French": "Essaye 3 séries de 45 secondes par côté",
        },
        "List7Item2": {
            "English": "Elbow is directly under shoulder",
            "French": "S’assurer que le coude est directement sous l’épaule",
        },
        "List7Item3": {
            "English": "Keep spine 'neutral' and untwisted",
            "French": "Garder le corps aligné",
        },
        "List7Item4": {
            "English": "Try to breathe while holding plank",
            "French": "Essaye de respire en tenant la position",
        },
        "List7Item5": {
            "English": "Top foot is in front (put on arm in the air to make it tougher!)",
            "French": "Bouger le pied du dessus vers l’avant (étirer le bras vers le plafond pour un plus grand défi)",
        },
        "BoldTitle8": {
            "English": "Single Leg Hop",
            "French": "Saut sur une jambe",
        },
        "List8Item1": {
            "English": "Hop on one leg - try 2 sets of 10 hops per foot",
            "French": "Saute sur une jambe – essaye 2 séries de 10 sauts par pied",
        },
        "List8Item2": {
            "English": "Maintain balance",
            "French": "Maintenir l’équilibre",
        },
        "List8Item3": {
            "English": "Movement should not be painful",
            "French": "Le mouvement ne devrait pas être douloureux",
        },
        "BoldTitle9": {
            "English": "Squat",
            "French": "Squat",
        },
        "List9Item1": {
            "English": "Stay fairly upright during squat",
            "French": "Commence en position debout et garde le dos droit, les pieds légèrement écartés",
        },
        "List9Item2": {
            "English": "Keep knees centered over feet",
            "French": "Plie les genoux sans dépasser la pointe des pieds tout en abaissant tes hanches (comme si tu allais t’asseoir)",
        },
        "List9Item3": {
            "English": "Try 3 sets of 12 reps",
            "French": "Essaye 3 séries de 12 répétitions",
        },
        "List9Item4": {
            "English": "Start the movement with the hips",
            "French": "Commence le mouvement avec les hanches",
        },
        "List9Item5": {
            "English": "Keep feet flat on the ground",
            "French": "Garde tes pieds plats parterre",
        },
        "List9Item6": {
            "English": "Movement should not be painful",
            "French": "Le mouvement ne devrait pas être douloureux",
        },
        "BoldTitle10": {
            "English": "Step Up",
            "French": "Monte une marche",
        },
        "List10Item1": {
            "English": "Maintain an upright position throughout the movement (not pitching forward)",
            "French": "Maintenir une position droite pendant le mouvement",
        },
        "List10Item2": {
            "English": "Push down through the step, try not to push off back foot",
            "French": "Faire travailler les muscles de la jambe qui monte la marche, essayer de ne pas pousser avec l’autre pied",
        },
        "List10Item3": {
            "English": "Repeat same leg",
            "French": "Répéter avec la même jambe",
        },
        "SubTitle3": {
            "English": "Dynamic Stretches",
            "French": "Étirements dynamiques",
        },
        "BoldTitle11": {
            "English": "Dynamic Piriformis/Hip Stretch",
            "French": "Étirement dynamique du muscle pyramidal (piriforme)",
        },
        "List11Item1": {
            "English": "Cross one ankle over knee",
            "French": "Sur le dos, poser le pied droit sur la cuisse gauche",
        },
        "List11Item2": {
            "English": "Reach for the legs and pull to chest gently",
            "French": "S’allonger pour rejoinder les jambes et les tirer doucement vers le tronc",
        },
        "List11Item3": {
            "English": "Hold for 5-10 seconds and repeat 5x per leg",
            "French": "Tenir pendant 5 à 10 secondes et répéter 5 x par jambe",
        },
        "BoldTitle12": {
            "English": "Dynamic Hamstring Stretch",
            "French": "Étirement dynamique de l’arrière de la cuisse (muscle ischio-jambiers)",
        },
        "List12Item1": {
            "English": "Maintain level hips",
            "French": "Sur le dos, allonger une jambe vers le plafond, puis tirer la jambe vers vous. ",
        },
        "List12Item2": {
            "English": "Knee being stretched is unlocked",
            "French": "Le genou est débarré. ",
        },
        "List12Item3": {
            "English": "Leg does not cross middle of the body",
            "French": "La jambe de dépasse pas le milieu du corps",
        },
        "List12Item4": {
            "English": "Other leg is bent",
            "French": "L’autre jambe est pliée.",
        },
        "List12Item5": {
            "English": "Use hands or a towel if available",
            "French": "Utiliser vos mains ou une corde au besoin.",
        },
        "List12Item6": {
            "English": "Try 1 set of 10 reps on each leg, only hold stretch for 2 seconds",
            "French": "Essaye 1 série de 10 répétitions avec chaque jambe, tenir pendant seulement 2 secondes.",
        },
        "BoldTitle13": {
            "English": "Dynamic Quad Stretch",
            "French": "Étirement du devant de la jambe (muscle quadriceps)",
        },
        "List13Item1": {
            "English": "Reach back for ankle",
            "French": "S’allonger pour rejoinder sa cheville",
        },
        "List13Item2": {
            "English": "Pull knee backwards gently as you push the same hip forwards",
            "French": "Amener le talon vers la fesse pour allonger les muscles du devant de la jambe (quadriceps)",
        },
        "List13Item3": {
            "English": "Keep knee in line with hip (do not elevate the knee)",
            "French": "Garder le genou aligné à la hanche (ne pas élever le genou)",
        },
        "List13Item4": {
            "English": "Hold for 2 seconds x 10 reps on each leg",
            "French": "Tenir pendant 2 secondes x 10 répétitions pour chaque jambe",
        },
        "BoldTitle14": {
            "English": "Dynamic Calf Stretch",
            "French": "Étirement dynamique du mollet",
        },
        "List14Item1": {
            "English": "Take step towards the wall",
            "French": "En inclinant légèrement le tronc vers l’avant, fléchir une jambe en allongeant l’autre vers l’arrière",
        },
        "List14Item2": {
            "English": "Keep the back heel down and back knee straight",
            "French": "Garder le talon au sol et le genou droit de la jambe arrière",
        },
        "List14Item3": {
            "English": "Move forward through the front knee to feel stretch in the back leg",
            "French": "Bouger vers l’avant afin d’être en mesure de ressentir l’étirement dans le mollet et le jambe placée derrière.",
        },
        "List14Item4": {
            "English": "Hold for 5 seconds and repeat 5x per leg",
            "French": "Tenir pendant 5 secondes et répéter 5 x par jambe",
        },
        "Paragraph13": {
            "English": "When you are stretching, be sure to engage your mindfulness skills. Pay attention to the feelings in your body as you work through the motions. Inhale and exhale slowly, focusing on the breath. Focus on the sensation of your body on the mat, your limbs moving, the gradual loosening and relaxation of your muscles.",
            "French": " Lorsque tu fais des étirements, assure-toi de faire appel à tes habiletés de la pleine conscience. Sois attentif(e) à ce que tu ressens dans ton corps en faisant les mouvements. Inspire et expire lentement, en portant une attention particulière à ta respiration. Concentre-toi sur la sensation de ton corps sur le matelas, le mouvement de tes membres, tes muscles qui se détendent et relaxent graduellement.",
        },
        "Paragraph14": {
            "English": "As you stretch, close your eyes and practice visualizing your goals.",
            "French": "Lorsque tu t’étires, ferme les yeux et pratique la visualisation de tes objectifs.",
        },
    },
    "Module Hills": {
        "Title": {
            "English": "HILLS",
            "French": "LES CÖTES",
        },
        "SubTitle1": {
            "English": "Hill Training: Why Do Runners Do it?",
            "French": "Entraînement en côte: pourquoi les coureurs le font-ils?",
        },
        "Paragraph1": {
            "English": "Hill training is an integral piece of any running program. Running hills helps build muscle strength, endurance, and improve running form.",
            "French": "L’entraînement en côte fait partie intégrante de tout programme de course à pied. Faire des côtes aide à construire la force musculaire, l’endurance et améliore la forme.",
        },
        "SubTitle2": {
            "English": "Hill Training: Tips and Pointers",
            "French": "L’entraînement en côte: conseils pratiques",
        },
        "List1Item1": {
            "English": "Keep your posture strong! Remember what we learned in the running diagram. Keeping your head up and posture strong will keep your airways open as you make your way up the hill. Our body wants to slouch when we get tired, making it more difficult to breathe. Gravity is really working against us when we run uphill, so our bodies are even more prone to slouch. When you feel yourself slouching, imagine there is a string at the top of your head gently tugging you back upright. The next tip will help you maintain a strong posture as you conquer those hills!",
            "French": " Maintenir une bonne posture! Rappelle-toi ce qu’on a appris dans le diagramme de course. Garder ta tête haute et maintenir une bonne posture permettra à tes voies respiratoires de rester ouvertes pendant tu montes la côte. Notre corps veut s’affaler (tomber vers l’avant) lorsqu’on est fatigué, ce qui rend la respiration plus difficile. La gravité fonctionne vraiment contre nous quand on cour en montée, de sorte que notre corps est encore plus enclin à s’affaler. Lorsque tu ressens que ton corps s’affale, imagine qu’il y a une corde sur le dessus de ta tête qui tire doucement vers le haut. Le prochain conseil t’aidera à maintenir une bonne posture pour conquérir les côtes!",
        },
        "List1Item2": {
            "English": "Choose targets and run towards them! Choose physical landmarks up the hill. Run towards them one-by-one until you reach the top! These could be a road sign, mailbox, a tree, or a fire hydrant. Anything you can use to break the hill down until smaller sections. Keeping your eye on a target up the hill will help your body maintain strong posture. Once you reach one target, pick another, and continue this process until you make it to the top of that hill!",
            "French": " Choisis des cibles vers lesquelles courir!  Choisis un repère physique au haut de la côte, soit un panneau routier, une boîte aux lettres, un arbre ou une borne-fontaine. Tu peux choisir ce que tu veux car l’idée est de réduire la côte en plus petites sections. Garder l’œil sur une cible en haut de la côte t’aidera à maintenir une bonne posture. Une fois que tu atteins une cible, choisis-en une autre et continue le processus jusqu’à ce que tu atteignes le sommet de cette colline.",
        },
        "List1Item3": {
            "English": "Pump those arms! Remember the “arm pumps” drill from the “ running mechanics” module? Now’s the time to put into practice! Pumping those arms as you run uphill will help you gain momentum and push you forward. Think of your arms as pumping pistons moving back and forth and driving you to the top. Don’t worry, you’ve got this!",
            "French": " Pompe tes bras! Te souviens-tu de l’exercice ‘Bras devant-derrière’ dans le module ‘Technique’? C’est maintenant le moment de l’appliquer. En pompant tes bras, tu prendras de l’élan et tu seras poussé vers l’avant. Pense à tes bras comme des pistons qui bougent de l’avant vers l’arrière pour te conduire vers le haut. Ne t’inquiète pas, tu vas y arriver!",
        },
        "SubTitle3": {
            "English": "Hill Training and Psychological Skills: Positive Self-Talk",
            "French": "L’entraînement en côte et les habiletés psychologiques: dialogue interne positif",
        },
        "Paragraph2": {
            "English": "Hill training can be tough! They offer us a perfect chance to work on positive self- talk and resiliency building mantras.",
            "French": "L’entraînement en côte peut être difficile! C’est une occasion parfait pour pratiquer notre dialogue interne positif et nos mantras. ",
        },
        "SubTitle4": {
            "English": "Homework",
            "French": "Devoir",
        },
        "Paragraph3": {
            "English": "Identify a challenge or challenges you have encountered in the last year and were able to overcome. Think of these challenges as your personal “hills” - changes in the landscape of your life that made things difficult. What were the nature of these challenges or “hills”? What tools did you use to get up and over to smoother territory?",
            "French": " Identifie un défi ou des défis rencontrés au cours de la dernière année que tu as pu relever. Pense à ces défis comme tes ‘côtes personnelles’- les changements au paysage de vie qui ont rendu les choses difficiles pour toi. Quelle était la nature de ces défis ou ‘côtes’? Quels outils as-tu utilisé pour les surmonter?",
        },
    },
    "Alternative Cardiovascular Workouts": {
        "Title": {
            "English": "ALTERNATIVE CARDIOVASCULAR WORKOUTS",
            "French": "",
        },
        "SubTitle1": {
            "English": "Why are different workouts important?",
            "French": "",
        },
        "Paragraph1": {
            "English": "Incorporating different types of cardiovascular workouts in your fitness routine can add the variety your mind needs to prevent boredom and keep workouts feeling motivating! Additionally, alternative cardiovascular exercises are essential because they provide flexibility and continuity to your fitness routine when outdoor running becomes impractical due to unfavourable weather conditions.",
            "French": "",
        },
        "SubTitle2": {
            "English": "What are cardiovascular exercises?",
            "French": "",
        },
        "Paragraph2": {
            "English": "Cardiovascular exercise can also be called Cardio, aerobic, or endurance exercise, which are a type of physical activity that focuses on increasing your heart rate and breathing rate for an extended period of time. It is called 'cardiovascular' because it specifically targets your heart. When you engage in cardio exercise, your body uses lots of oxygen!",
            "French": "",
        },
        "SubTitle3": {
            "English": "Brainstorm! How many alternative cardio exercises can you think of?",
            "French": "",
        },
        "SubTitle4": {
            "English": "Alternative Cardio Workout Options",
            "French": "",
        },
        "BoldTitle1": {
            "English": "Stair/Chair Workout:",
            "French": "",
        },
        "Paragraph3": {
            "English": "The stair/chair workout is a cardio workout that can be with just a chair or the stairs. This simple workout can progress or regress by increasing weight or decreasing reps/sets. This workout is designed for all skill levels and can be easily manipulated to cater to individual needs.",
            "French": "",
        },
        "BoldTitle2": {
            "English": "Skipping:",
            "French": "",
        },
        "Paragraph4": {
            "English": "Skipping is a unique way for individuals of all levels to experience the benefits of cardio. The skipping workout below is a quick way to increase heart rate and can be done as a warm-up for other workouts. Skipping is a skill that can be done in various locations and can be altered to cater to individual needs.",
            "French": "",
        },
        "SubTitle5": {
            "English": "Stair/Chair Workout",
            "French": "",
        },
        "Paragraph5": {
            "English": "The exercises selected target major upper and lower body muscle groups to make this a full-body workout.",
            "French": "",
        },
        "BoldTitle3": {
            "English": "Key Concepts:",
            "French": "",
        },
        "List1Item1": {
            "English": "The movements are all basic movements that can be done with or without weights. If you want the exercise to be more challenging, use weights.",
            "French": "",
        },
        "List1Item2": {
            "English": "Adjust the reps and sets of each exercise to match the needs of the participants. If you need more rest, add it in. If you want to make it more challenging, decrease the rest time.",
            "French": "",
        },
        "List1Item3": {
            "English": "Play some music if you need an extra boost!",
            "French": "",
        },
        "BoldTitle4": {
            "English": "Location:",
            "French": "",
        },
        "List2Item1": {
            "English": "Staircase indoors or outdoors",
            "French": "",
        },
        "List2Item2": {
            "English": "Chair/bench/step indoors or outdoors.",
            "French": "",
        },
        "BoldTitle5": {
            "English": "Materials",
            "French": "",
        },
        "List3Item1": {
            "English": "Staircase or chair",
            "French": "",
        },
        "List3Item2": {
            "English": "Dumbbells or weighted objects (optional)",
            "French": "",
        },
        "BoldTitle6": {
            "English": "Description",
            "French": "",
        },
        "List4Item1": {
            "English": "Warm-up - 10 reps x 3-4 sets",
            "French": "",
        },
        "List4Item1Item1": {
            "English": "Burpees",
            "French": "",
        },
        "List4Item1Item2": {
            "English": "Mountain Climbers",
            "French": "",
        },
        "List4Item1Item3": {
            "English": "Jump Squats",
            "French": "",
        },
        "List5Item1": {
            "English": "Workout - 10 reps x 3-4 sets",
            "French": "",
        },
        "List5Item1Item1": {
            "English": "Push-ups",
            "French": "",
        },
        "List5Item1Item2": {
            "English": "Tricep Dips",
            "French": "",
        },
        "List5Item1Item3": {
            "English": "Step-ups",
            "French": "",
        },
        "List5Item1Item4": {
            "English": "Bulgarian Split Squats",
            "French": "",
        },
        "List6Item1": {
            "English": "Cooldown - hold for 10 seconds",
            "French": "",
        },
        "List6Item1Item1": {
            "English": "Reach up to the sky and then touch your toes (10 seconds each)",
            "French": "",
        },
        "List6Item1Item2": {
            "English": "Lunge and hold",
            "French": "",
        },
        "List6Item1Item3": {
            "English": "Arm across chest",
            "French": "",
        },
        "List6Item1Item4": {
            "English": "Triceps stretch",
            "French": "",
        },
        "BoldTitle7": {
            "English": "Video Demonstration",
            "French": "",
        },
        "URL1": {
            "English": "https://www.youtube.com/watch?v=h0BgJN14GPo",
            "French": "",
        },
        "BoldTitle8": {
            "English": "Reps (Repetitions)",
            "French": "",
        },
        "Paragraph6": {
            "English": "Refers to the number of times you perform a specific exercise in one set. For example, if you are doing push-ups, each time you lower your body and push back up, that is one repetition. If you are doing 10 push-ups, you are doing 10 reps.",
            "French": "",
        },
        "BoldTitle9": {
            "English": "Sets",
            "French": "",
        },
        "Paragraph7": {
            "English": "A set is a group of reps. For example, if you do 10 push-ups and then take a short break, and then do another 10 push-ups, you have done 2 sets of 10 reps.",
            "French": "",
        },
        "SubTitle6": {
            "English": "Skipping",
            "French": "",
        },
        "Paragraph8": {
            "English": "The timing of this skipping workout can be changed so that it can be suitable for anyone (i.e. increasing rest time between sets or decreasing the amount of time skipping).",
            "French": "",
        },
        "BoldTitle10": {
            "English": "Key Concepts:",
            "French": "",
        },
        "List7Item1": {
            "English": "Skipping takes some practice. Throughout the workout, make sure that you keep a positive mindset and focus your attention on positive self-talk.",
            "French": "",
        },
        "List7Item2": {
            "English": "The goal is to increase heart rate in a fun way!",
            "French": "",
        },
        "List7Item3": {
            "English": "Play some music if you need an extra boost!",
            "French": "",
        },
        "BoldTitle11": {
            "English": "Location:",
            "French": "",
        },
        "List8Item1": {
            "English": "Open space with a flat surface is needed. The ceiling (if indoors), should be high enough so the rope does not hit it. If outdoors, ensure that the surface is not icy or slippery before use.",
            "French": "",
        },
        "BoldTitle12": {
            "English": "Materials",
            "French": "",
        },
        "List9Item1": {
            "English": "Skipping rope",
            "French": "",
        },
        "BoldTitle13": {
            "English": "Description",
            "French": "",
        },
        "Paragraph9": {
            "English": "30 seconds for each of the following:",
            "French": "",
        },
        "List10Item1": {
            "English": "Normal/comfortable skipping",
            "French": "",
        },
        "List10Item2": {
            "English": "Speed skipping",
            "French": "",
        },
        "List10Item3": {
            "English": "Normal/comfortable skipping",
            "French": "",
        },
        "List10Item4": {
            "English": "Speed skipping",
            "French": "",
        },
        "List10Item5": {
            "English": "REST (30 seconds - 1 minute)",
            "French": "",
        },
        "List10Item6": {
            "English": "Normal/comfortable skipping",
            "French": "",
        },
        "List10Item7": {
            "English": "Left/Right alternate skipping",
            "French": "",
        },
        "List10Item8": {
            "English": "Normal/comfortable skipping",
            "French": "",
        },
        "List10Item9": {
            "English": "Left/Right alternate skipping",
            "French": "",
        },
        "BoldTitle14": {
            "English": "Challenge Yourself",
            "French": "",
        },
        "Paragraph10": {
            "English": "Only do this option if the above exercises were easy and effortless or if you are already an experienced skipper.",
            "French": "",
        },
        "Paragraph11": {
            "English": "After the first REST period, instead of doing left/right alternating skipping, add in the following:",
            "French": "",
        },
        "List11Item1": {
            "English": "Normal/comfortable skipping",
            "French": "",
        },
        "List11Item2": {
            "English": "High knees skipping",
            "French": "",
        },
        "List11Item3": {
            "English": "Normal/comfortable skipping",
            "French": "",
        },
        "List11Item4": {
            "English": "High knees skipping",
            "French": "",
        },
        "BoldTitle15": {
            "English": "Video Demonstration",
            "French": "",
        },
        "URL2": {
            "English": "https://www.youtube.com/watch?v=HjA__1jpMuI",
            "French": "",
        },
        "BoldTitle16": {
            "English": "Reflection: Which alternative cardio workout did you enjoy the most and why?",
            "French": "",
        },
    },
    "Finding the Right Equipment": {
        "Title": {
            "English": "FINDING THE RIGHT EQUIPMENT",
            "French": "",
        },
        "Paragraph1": {
            "English": "In this physical health module, we are going to discuss the benefits and importance of wearing proper running shoes. Wearing proper running shoes is essential for several reasons that all help ensure a safe and enjoyable running experience. Additionally, we are going to discuss indoor vs outdoor running and how different locations can affect our physical and mental health!",
            "French": "",
        },
        "SubTitle1": {
            "English": "Benefits of Wearing Proper Running Shoes",
            "French": "",
        },
        "List1Item1Title": {
            "English": "Injury Prevention:",
            "French": "",
        },
        "List1Item1Content": {
            "English": "Proper running shoes provide the necessary support and cushioning to help absorb impact and reducen stress on your joints, muscles, and tendons.",
            "French": "",
        },
        "List2Item1Title": {
            "English": "Comfort and Performance:",
            "French": "",
        },
        "List2Item1Content": {
            "English": "Running in shoes designed specifically for this activity ensures a higher level of comfort. When you feel more comfortable, you are more likely to focus better, leading to better performance!",
            "French": "",
        },
        "List3Item1Title": {
            "English": "Support and Stability:",
            "French": "",
        },
        "List3Item1Content": {
            "English": "Whether you have high arches or flat feet, proper running shoes can correct or accommodat these conditions and reduce the risk of injury.",
            "French": "",
        },
        "List4Item1Title": {
            "English": "Shock Absorption:",
            "French": "",
        },
        "List4Item1Content": {
            "English": "Running is a lot of stress on your joints and feet due to the repetitive impact. Good running shoes are designed with advanced cushioning technology that absorbs shock and prevents long-term damage.",
            "French": "",
        },
        "List5Item1Title": {
            "English": "Longevity:",
            "French": "",
        },
        "List5Item1Content": {
            "English": "Good running shoes may cost more initially, but they are designed to withstand the demands of running, making them more durable than regular sneakers.",
            "French": "",
        },
        "List6Item1Title": {
            "English": "Proper Fit:",
            "French": "",
        },
        "List6Item1Content": {
            "English": "Running shoes are available in different sizes and widths to accommodate the unique shape of your feet. A proper fit ensures that your toes have enough room to move and that your feet are held securely in place, reducing the chances of blisters, hot spots, and discomfort during runs.",
            "French": "",
        },
        "SubTitle2": {
            "English": "How to find the right running shoe for you?",
            "French": "",
        },
        "List7Item1": {
            "English": "Consider where you are planning to run (road running, trail running, etc.)",
            "French": "",
        },
        "List7Item2": {
            "English": "Decide if you want more or less cushioning (do you want to feel like you're running on a cloud? Or do you want a light and fast shoe?)",
            "French": "",
        },
        "List7Item3": {
            "English": "Understand if you need specific support (do you have high arches? Flat feet? Do you need extra support for your ankles?)",
            "French": "",
        },
        "List7Item4": {
            "English": "Make sure the shoe fits! (Watch the video below)",
            "French": "",
        },
        "List7Item5": {
            "English": "Consult an expert! (Kinesiologist, physical therapist, physiotherapist, etc.)",
            "French": "",
        },
        "SubTitle3": {
            "English": "Understanding If You Need Specific Support",
            "French": "",
        },
        "Paragraph2": {
            "English": "What is your pronation type? Pronation is a fancy word for how your foot hits the ground when you are running. You want to pay attention to if your foots stays centered or if it rolls to the inside or the outside because this will indicate what type of shoe you need.",
            "French": "",
        },
        "List8Item1Title": {
            "English": "Overpronation:",
            "French": "",
        },
        "List8Item1Content": {
            "English": "Your foots rolls inward when running/walking. If you pick your running shoes right now, you will see that the inside of the shoe is more worn out than the outside.",
            "French": "",
        },
        "List8Item1Content2": {
            "English": "Pretend your right hand is your right foot! Place the bottom of your palm on the table so that most of your hand is pointing up to the sky. Now, roll your hand forward but only allow your thumb and index to roll off the table.",
            "French": "",
        },
        "List9Item1Title": {
            "English": "Neutral:",
            "French": "",
        },
        "List9Item1Content": {
            "English": "Your foot hits the ground evenly when running/walking. If you pick up your running shoe right now, you will see that there is balanced wear down on the soles of your shoes.",
            "French": "",
        },
        "List9Item1Content2": {
            "English": "Pretend your right hand is your foot! Place the bottom of your palm on the table so that most of your hand is pointing up to the sky. Now, roll your hand forward and allow all hour fingers to equally leave the table at the same time.",
            "French": "",
        },
        "List10Item1Title": {
            "English": "Underpronation:",
            "French": "",
        },
        "List10Item1Content": {
            "English": "Your foot will roll outwards when you run or walk. If you pick your running shoe right now, you will see that the outside of the shoe is more worn out than the inside.",
            "French": "",
        },
        "List10Item1Content2": {
            "English": "Pretend your right hand is your right foot! Place the bottom of your palm on the table so that most of your hand is pointing up to the sky. Now, roll your hand forward but only allow your pinky to roll off the table.",
            "French": "",
        },
        "SubTitle4": {
            "English": "How long should my running shoes last before I need new ones?",
            "French": "",
        },
        "Paragraph3": {
            "English": "Once you find the right pair of running shoes that support your foot type and are comfortable to run in, the average running shoe should last you between 400 to 500 miles of running. Depending on how much you run, that could be anywhere from 3 - 6 months! You will know that you need new shoes because the bottoms of your runing shoes will be compressed and worn down.",
            "French": "",
        },
        "SubTitle5": {
            "English": "Making Sure the Shoe You Pick is Comfortable",
            "French": "",
        },
        "Paragraph4": {
            "English": "Take the following quiz to find the right running shoe for you! The quiz is just 6 questions! Write down what type of shoe was recommended for you!",
            "French": "",
        },
        "URL1": {
            "English": "https://www.asics.com/us/en-us/shoe-finder/",
            "French": "https://www.asics.com/us/en-us/shoe-finder/",
        },
    },
};
