import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { Colors } from "../../../utils/constants";

const ResearchHome= () => {
    const colors = [ "white" , Colors.primaryblue, "white", Colors.secondarygray, ];
    const textColors = [Colors.secondarygray, "white", Colors.secondarygray, "white", ];
    const titleColors = [Colors.primaryblue, "white", Colors.primaryblue, "white"];

   return (
   <React.Fragment>
       <div>
       <Container>
            <br />
            <Row>
                <Col>
                    <StyledHeaderTitle>Research</StyledHeaderTitle><br />
                </Col>
            </Row>
       <ContentContainer>
       <BackgroundContainer>
           <br/>
           <Row>
               <Col>
                    <StyledParagraph>
                        Team Unbreakable is both an evidence-based and evidence-generating program linking physical activity and mental health. 
                        At the core of our program are the following successful ingredients:
                    </StyledParagraph>
               </Col>
           </Row>
           <Row>
                <Col xl="6">
                    <NoMarginContainer>
                        <Row>
                            <Col xl="6">
                                <StyledColumnContainer backcolor={colors[0]}>
                                    <IngredientTitle titleColor={titleColors[0]}>Running</IngredientTitle>
                                    <BoxParagraph textcolor={textColors[0]}>
                                        Can be any physical activity that has varying levels, 
                                        intensities, duration, flexible timing that supports the person’s individual circumstances;
                                    </BoxParagraph>
                                </StyledColumnContainer>
                            </Col>
                            <Col xl="6">
                                <StyledColumnContainer backcolor={colors[1]}>
                                    <IngredientTitle titleColor={titleColors[1]}>Social</IngredientTitle>
                                    <BoxParagraph textcolor={textColors[1]}>
                                        Program is provided in a group context (e.g., school, recreational group) for 
                                        discussion of the material, and potential for physical activity partners or groups;
                                    </BoxParagraph>
                                </StyledColumnContainer>
                            </Col>
                        </Row>
                    </NoMarginContainer>
                </Col>
                <Col xl="6">
                    <NoMarginContainer>
                        <Row>
                            <Col>
                                <StyledRowContainer backcolor={colors[2]}>
                                    <IngredientTitle titleColor={titleColors[2]}>Self-regulation strategies</IngredientTitle>
                                    <BoxParagraph textcolor={textColors[2]}>
                                        Mental health materials focus on strategies for ultimate personal success and 
                                        empowerment, adaptability, and resilience;
                                    </BoxParagraph>
                                </StyledRowContainer>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <StyledRowContainer backcolor={colors[3]}>
                                    <IngredientTitle titleColor={titleColors[3]}>Tracking & Feedback</IngredientTitle>
                                    <BoxParagraph textcolor={textColors[3]}>Rewards, self-monitoring, and sometimes a goal event!</BoxParagraph>
                                </StyledRowContainer>
                            </Col>
                        </Row>
                    </NoMarginContainer>
                </Col>
            </Row>
            <br />
            <br />
            <Row>
                <Col>
                    <SecondaryTitle>Research Shows...</SecondaryTitle>
                    <StyledParagraph>
                        If you run as a teenager, you are more likely to be physically activity at least 5-10 years later.  Researchers in this study conclude that 
                       <i>“efforts to establish the habit of participation in sports and running in adolescence may promote higher PA levels in adulthood”. </i>
                       <StyledLink href="https://ijbnpa.biomedcentral.com/articles/10.1186/s12966-015-0237-x">Click here to read the article.</StyledLink>
                    </StyledParagraph>
                    <StyledParagraph>
                        These findings are important because people who are more active generally report being physically, mentally, and socially more healthy across their lifetime!
                    </StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SecondaryTitle>And the importance of physical activity on mental health is uncontested…</SecondaryTitle>
                    <StyledParagraph>
                        As researchers have claimed: 
                    </StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ColorBox backcolor={colors[1]}>
                        <BoxParagraph textcolor={textColors[1]}>
                            <i>“There is robust evidence that structured and supervised physical activity, including aerobic and resistance  
                            training, can improve multiple outcomes in major depression, pre/postnatal depression, anxiety/stress 
                            disorders and schizophrenia. Emerging evidence suggests a potential role for physical activity in bipolar 
                            disorder and alcohol use disorders.” </i>
                            <StyledBoxLink style={{display: "inline-block"}}href="https://pubmed.ncbi.nlm.nih.gov/31145143/">Click here to read the article.</StyledBoxLink>
                        </BoxParagraph>
                    </ColorBox>
                </Col>
            </Row>
            <br />
            <Row>
                <StyledParagraph>
                    Specifically, physical activity at moderate-to-vigorous intensity (like running!) in adolescence 
                    has been associated with better mental health in adulthood. For example, researchers have found:
                </StyledParagraph>
            </Row>
            <Row>
                <Col lg="4" sm="12">
                    <ColorBox height="300px;" backcolor={colors[1]}>
                        <IngredientTitle titleColor={titleColors[1]}>1</IngredientTitle>
                        <BoxParagraph textcolor={textColors[1]}>
                            Participating in sports <b>at school</b> are protective of depression symptoms, anxiety symptoms, stress, and well-being.<br /> <br />
                            <StyledBoxLink href="https://pubmed.ncbi.nlm.nih.gov/24928804/">Click here to read the article.</StyledBoxLink>
                            <StyledBoxLink href="https://www.sciencedirect.com/science/article/pii/S1755296616300497?via%3Dihub">More information</StyledBoxLink>
                        </BoxParagraph>
                    </ColorBox>
                </Col>
                <Col lg="4" sm="12">
                    <ColorBox height="300px;" backcolor={colors[1]}>
                        <IngredientTitle titleColor={titleColors[1]}>2</IngredientTitle>
                        <BoxParagraph textcolor={textColors[1]}>
                            Team sports (and group activity) are important for mental health outcomes. <br/> <br />
                            <StyledBoxLink href="https://pubmed.ncbi.nlm.nih.gov/27018562/">Click here to read the article.</StyledBoxLink>
                            <StyledBoxLink href="https://pubmed.ncbi.nlm.nih.gov/23176784/">More information</StyledBoxLink>
                        </BoxParagraph>
                    </ColorBox>
                </Col>
                <Col lg="4" sm="12">
                    <ColorBox height="300px;" backcolor={colors[1]}>
                        <IngredientTitle titleColor={titleColors[1]}>3</IngredientTitle>
                        <BoxParagraph textcolor={textColors[1]}>
                            Adolescents who maintain a level of physical activity after high school report lower stress and better coping, and fewer symptoms of anxiety.<br /> <br />
                            <StyledBoxLink href="https://onlinelibrary.wiley.com/doi/full/10.1111/sms.13957">Click here to read the article.</StyledBoxLink>
                        </BoxParagraph>
                    </ColorBox>
                </Col>
            </Row>
            <br />
            <Row>
                <StyledParagraph>
                    Researchers conclude that physical activity, and in particular within team sport or group contexts, “should be encouraged” and supported among adolescents.
                </StyledParagraph>
            </Row>
            <Row>
                <Col>
                    <SecondaryTitle>Tracking your runs is motivational. </SecondaryTitle>
                    <StyledParagraph>
                        A recent research study compared physical activity of students who tracked their runs with students who did not track their runs. 
                        The students who tracked their runs were more motivated to continue running, and found the support from their teacher extra helpful: <StyledLink href="https://link.springer.com/chapter/10.1007/978-3-030-21562-0_25">Click here to read the article.</StyledLink>
                    </StyledParagraph>
                    <StyledParagraph>
                        Much like what we do in the Team Unbreakable program, building challenges and achievements into physical activity programs using 
                        goal-setting principles also helps motivate you to be more physically active. <StyledLink href="https://link.springer.com/chapter/10.1007/978-3-030-21905-5_10">Click here to read the article.</StyledLink>
                    </StyledParagraph>
                    <StyledParagraph>
                        When combined with physical activity, other self-regulation strategies, like positive self-talk, imagery, and mindfulness, also lead 
                        to better mental health. In a sample of help-seeking students, the combined use of physical activity and mental health strategies (in fact, 
                        the program that recent improvements to Team Unbreakable have been modeled from) significantly improved depression and anxiety symptoms and 
                        psychological distress. <StyledLink href="https://www.sciencedirect.com/science/article/pii/S1755296621000119">Click here to read the article.</StyledLink>
                    </StyledParagraph>
                    <SecondaryTitle>Also,</SecondaryTitle>
                    <StyledParagraph>
                        The original Team Unbreakable group program started as an outpatient clinic running program. The original program continues today, and the 
                        findings for people with complex mood disorders suggest significant decreases in depression, anxiety, and stress: <StyledLink href="https://bmjopensem.bmj.com/content/4/1/e000314">Click here to read the article.</StyledLink>
                    </StyledParagraph>
                    <StyledParagraph>
                        And our own research shows that Team Unbreakable participants report…
                    </StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col lg="3" xs="6">
                    <ColorBox height="200px;" backcolor={colors[0]}>
                        <IngredientTitle titleColor={titleColors[0]}>100%</IngredientTitle>
                        <NoMarginP textcolor="black">
                            say they have improved mental health
                        </NoMarginP>
                    </ColorBox>
                </Col>
                <Col lg="3" xs="6">
                    <ColorBox height="200px;" backcolor={colors[0]}>
                        <IngredientTitle titleColor={titleColors[0]}>78%</IngredientTitle>
                        <NoMarginP textcolor="black">
                            report lower levels of stress
                        </NoMarginP>
                    </ColorBox>
                </Col>
                <Col lg="3" xs="6">
                    <ColorBox height="200px;" backcolor={colors[0]}>
                        <IngredientTitle titleColor={titleColors[0]}>95%</IngredientTitle>
                        <NoMarginP textcolor="black">
                            say they feel more socially supported
                        </NoMarginP>
                    </ColorBox>
                </Col>
                <Col lg="3" xs="6">
                    <ColorBox height="200px;" backcolor={colors[0]}>
                        <IngredientTitle titleColor={titleColors[0]}>80%</IngredientTitle>
                        <NoMarginP textcolor="black">
                            report being more physically active
                        </NoMarginP>
                    </ColorBox>
                </Col>
            </Row>
            <br />
            <Row>
                <Col md="6" xs="12">
                    <ColorBox height="270px;" backcolor={colors[0]}>
                        <IngredientTitle titleColor={titleColors[0]}>95%</IngredientTitle>
                        <NoMarginP textcolor="black">
                            report a sense of accomplishment “you really feel 
                            like you have accomplished something…  for me that
                            was doing something that was out of my comfort 
                            zone, and I feel it was time  for growth” (15 year old female participant).
                        </NoMarginP>
                    </ColorBox>
                </Col>
                <Col md="6" xs="12">
                    <ColorBox height="270px;" backcolor={colors[0]}>
                        <IngredientTitle titleColor={titleColors[0]}>50%</IngredientTitle>
                        <NoMarginP textcolor="black">
                            say they feel the program improved their academics 
                            “I noticed I was participating more in class and 
                            engaging in different group discussions we had in 
                            class” (14 year old male participant).
                        </NoMarginP>
                    </ColorBox>
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs="12">
                    <ColorBox height="200px;" backcolor={colors[0]}>
                        <IngredientTitle titleColor={titleColors[0]}>60% / 90%</IngredientTitle>
                        <NoMarginP textcolor="black">
                            Over 60% of the parents/guardians and 90% of the program facilitators also report seeing mental health, stress, social support, 
                            and physical health improvements in their children/students who are involved in the Team Unbreakable program.
                        </NoMarginP>
                    </ColorBox>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <StyledParagraph>
                        Also, students in schools with the Team Unbreakable program generally reported higher resilience, lower psychological distress, better mental health 
                        compared to students in schools without the Team Unbreakable program.
                    </StyledParagraph>
                    
                    <StyledParagraph>
                        Finally, participants report:
                    </StyledParagraph>
                    <ul>
                        <ListItem>
                            92% reported being very satisfied with the Team Unbreakable program
                        </ListItem>
                        <ListItem>
                            83% reported that participating in the Team Unbreakable program was fun
                        </ListItem>
                        <ListItem>
                            92% would recommend the Team Unbreakable program to a friend or family member
                        </ListItem>
                        <ListItem>
                            100% reported that Team Unbreakable was valuable and important for mental health
                        </ListItem>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SecondaryTitle>
                        Why does physical activity help with mental health?
                    </SecondaryTitle>
                    <StyledParagraph>
                        Running (or any moderate-intensity physical activity that is possible based on personal circumstances) can:
                    </StyledParagraph>
                    <ol>
                        <ListItem>
                            Prevent symptoms of mental illness and diagnosis of some mental illnesses
                        </ListItem>
                        <ListItem>
                            Help to treat and manage symptoms of mental illness
                        </ListItem>
                        <ListItem>
                            Improve quality of life and physical and mental health
                        </ListItem>
                    </ol>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SecondaryTitle>
                        How does it work?
                    </SecondaryTitle>
                    <StyledParagraph>
                        <b>Physical activity increases your body temperature.</b><br />
                        When you are warmer, you gain the sense that you are comfortable and cared for, and this makes you feel better. 
                    </StyledParagraph>
                    <StyledParagraph>
                        <b>Physical activity helps our bodies adapt to history.</b><br /> From a historical perspective, humans were naturally much more active than we are now. 
                        By being physically active, your activity level is closer to that core level of movement that human bodies are meant to be… and that makes your body 
                        and you mind feel better.
                    </StyledParagraph>
                    <StyledParagraph>  
                        <b>Physical activity influences your physiology.</b><br /> When you participant in physical activity, you may sweat or feel your heart racing. 
                        These physiological reactions mimic the feeling of anxiety, and these feelings can make you more accustomed to those symptoms which lessens the 
                        impact of them on your mental health. 
                    </StyledParagraph>
                    <StyledParagraph>
                        <b>Physical activity effects are tied to your brain activity and brain chemistry.</b><br />
                        When you are physically active, many of your body’s systems work to protect from symptoms of mental illness. For more details on these effects, 
                        see this research report: <StyledLink href="https://www.sciencedirect.com/science/article/pii/S0149763419305640">Click here to read the article.</StyledLink>
                    </StyledParagraph>
                    <StyledParagraph>
                        <b>Physical activity improves confidence and self-esteem.</b><br />
                        Being physically active gives you a sense of purpose or accomplishment. Specifically, the complexities of physical activity and setting small goals 
                        offer plenty of opportunities for positive feedback, feeling successful and achieving, which helps stave off symptoms of mental illness.
                    </StyledParagraph>
                </Col>
            </Row>
       </BackgroundContainer>
       </ContentContainer>
       </Container>
       </div>
   </React.Fragment>);
}
export default ResearchHome;


const ContentContainer = styled(Container)`
    max-width: 1200px;
    margin-bottom: 2%;

`;

const StyledHeaderTitle = styled.h1`
    color: ${Colors.primaryblue};
    font-family: Roboto;
`

const BackgroundContainer = styled(Container)`
    background-color: white;
`;

const ListItem = styled.li`
    color: black;
    font-size: medium;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
`

const ColorBox = styled.div`
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.secondarybackground};
    background-color: ${props => props.backcolor};
    margin-left: 0px;
    padding: 20px;
    min-height: ${props => props.height ? props.height : ""}
`

const SecondaryTitle = styled.h3`
    font-weight: normal;
    color: ${Colors.primaryblue};
    font-family: Roboto;
    font-style: normal;
    justify-content: left;
    
`

const StyledLink = styled.a`
    color: ${Colors.primaryblue};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

const StyledBoxLink = styled.a`
    color: white;
    font-size: 16px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    display: block;
`

const StyledParagraph = styled.p`
    color: black;
    font-size: medium;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
`

const NoMarginContainer = styled(Container)`
    padding: 0px;
    
`

const IngredientTitle = styled.h5`
    font-size: xx-large;
    margin-bottom: 20px;
    color: ${props => props.titleColor}
`

const StyledColumnContainer = styled(Container)`
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.secondarybackground};
    background-color: ${props => props.backcolor};
    margin-left: 0px;
    padding: 20px;
`;

const StyledRowContainer = styled(Container)`
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.secondarybackground};
    background-color: ${props => props.backcolor};
    margin-left: 0px;
    width: 100%;
    height: 100%;
    padding: 20px;
`;

const NoMarginP = styled.p`
    margin-top: -20px;
    font-family: Roboto;
    font-style: normal;
    font-size: x-large;
    color: ${props => props.textcolor};
`


const BoxParagraph = styled.p`
    font-family: Inter;
    font-style: normal;
    font-size: x-large;
    color: ${props => props.textcolor};
    margin-bottom: 10px;
`
