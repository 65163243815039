import React, { useState } from 'react';
import styled from 'styled-components'
import { Container, Col, Row, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Colors } from "../../utils/constants";

const CookiePermissionPopup = (props) => {

    const [cookiesOpen, setCookiesOpen] = useState(true);

    return (
        <Collapse isOpen={cookiesOpen}>
            <CookiePermissionsContainer>
                <FlexCol jc="center">
                        <StyledP> 
                            Please note that on our website, we use cookies to enchance your experience and for analytics. By continuing to use our website, you agree to our use of cookies and similar technologies.
                        </StyledP>
                </FlexCol>
                <StyledCol xs="auto" jc="flex-end" style onClick={() => {setCookiesOpen(false)}}>
                    <StyledFAIcon style={{cursor: 'pointer'}} size="2x" icon={["fas", "angle-double-down"]}></StyledFAIcon>
                </StyledCol>
            </CookiePermissionsContainer>
        </Collapse>
    )
};

export default CookiePermissionPopup;

const CookiePermissionsContainer = styled(Container)`
    display: flex;
    align-items: center;
    position: fixed;
    top: 92vh;
    height: 8vh;
    max-width: 100%;
    width: 100%;
    background-color: ${Colors.primarybackground};
    z-index: 10;
    box-shadow: 0px -1px 10px ${Colors.primaryblue};
`;

const FlexCol = styled(Col)`
    display: flex;
    justify-content: ${props => props.jc};
`;

const StyledCol = styled(Col)`
    display: flex;
    justify-content: ${props => props.jc};
    align-items: top;
`;


const StyledFAIcon = styled(FontAwesomeIcon)`
    color: ${Colors.primaryblue};
    justify-content: center;
    margin-top: -10px;
`;
const StyledP = styled.p`
    display: flex;
    margin-top: 10px;
`;