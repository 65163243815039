import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Container, Row, Col, Button } from 'reactstrap';
import HighFive from "../../images/HighFive.png";
import { Colors } from "../../utils/constants";

export const Donate = () => {
    const [screenSize, setScreenSize] = useState("lg");

    useEffect(() => {
        setScreenSize(getViewport());
      }, [document.documentElement.clientWidth]);


    function getViewport () {
        const width = Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        )
        if (width <= 576) return 'xs'
        if (width <= 768) return 'sm'
        if (width <= 992) return 'md'
        if (width <= 1200) return 'lg'
        return 'xl'
    }
    
    return(
        <React.Fragment>
            <OffsetDiv />
            <StyledContainer>
                <Row>
                    <Col>
                        <StyledHeaderTitle>Donate</StyledHeaderTitle>
                    </Col>
                </Row>
                <br />
                <Row>
                  {(screenSize === "xl" || screenSize === "lg") && <ImageCol lg="5" xs="2">
                        <StyledImg src={HighFive} />
                    </ImageCol>}
                    <Col>
                        <StyledTitle>Every donation is a step ahead</StyledTitle>
                        <StyledParagraph>
                            Team Unbreakable is a non-profit organization that, through donations, 
                            is able to deliver evidence-based programming to help improve youth 
                            mental health. We value all contributions to the program.
                        </StyledParagraph>
                        <StyledParagraph>Some specific ways that donations are working for us:</StyledParagraph>
                        <ul>
                            <StyledItem>Expanding the program target audiences</StyledItem>
                            <StyledItem>Building all resources to address equity, diversity and inclusion</StyledItem>
                            <StyledItem>Constant evolution based on research and practice</StyledItem>
                            <StyledItem>Increasing the information and curriculum in the program</StyledItem>
                            <StyledItem>Developing new delivery models</StyledItem>
                            <StyledItem>Supporting youth activism around mental health initiatives</StyledItem>
                            <StyledItem>Monthly donations are also available</StyledItem>
                        </ul>
                        <br />
                        <CenteredDiv>
                            <StyledAnchor href="https://www.canadahelps.org/en/dn/4475"><StyledButton>Donate Now</StyledButton></StyledAnchor>
                        </CenteredDiv>
                    </Col>
                </Row>
            </StyledContainer>
        </React.Fragment>
    );
}

export default Donate;

const CenteredDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
`

const StyledAnchor = styled.a`

`

const StyledItem = styled.li`
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: large;
`

const OffsetDiv = styled.div`
    height: 120px;
`;

const StyledButton = styled(Button)`
    text-align: center;
    font-family: Roboto;        
    width: 150px;
    background: ${Colors.primaryblue};
    border-radius: 5px;
    color: white;
    border: none;
    padding: 15px;
    &:hover {
        color: white;
        background-color: ${Colors.secondarygray};
        cursor: pointer;
    }
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 50px;
`

const StyledHeaderTitle = styled.h1`
    color: ${Colors.primaryblue};
    color: ${Colors.primaryblue};
    font-family: Roboto;
`

const ImageCol = styled(Col)`
    padding-left: 0px;
`

const StyledImg = styled.img`
    width: 100%;
`

const StyledContainer = styled(Container)`
    margin-bottom: 40px;
    background-color: white;
    max-width: 1200px;
`;

const StyledTitle = styled.h1`
    color: ${Colors.primaryblue};
    padding-top: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: xxx-large;
`

const StyledParagraph = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: large;
`
