import styled from "styled-components";
import { Colors } from "../utils/constants";
import { Container, Row, Col, Table, Button, Input } from "reactstrap";

export const ColoredH2 = styled.h2`
color: ${Colors.primaryblue};
margin-bottom: 0px;
`;

export const StyledHR = styled.hr`
    margin-top: 0px;
    margin-bottom: 15px;
    background-color: ${Colors.primaryblue};
`;

export const ColoredOL = styled.ol`
color: ${Colors.primaryblue};
font-weight: bold;
`;

export const StyledP = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
    color: black;
    font-weight: normal;
    display: inline;
`;

export const CenterTitleFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
    background-color: ${Colors.primaryblue};
    color: white;
`;

export const WhiteH2 = styled.h2`
    color: white;
    margin-bottom: 0px;
`;

export const StyledBoldP = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
    color: black;
    font-weight: bold;
    display: inline;
`;

export const ColoredUL = styled.ul`
color: ${Colors.primaryblue};
`;

export const BoldP = styled.p`
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: bold;
`;


export const CompletedDiv = styled.div`
    background-color: ${Colors.primaryblue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 20px;
`;

export const CompletedP = styled.p`
    color: white;
    margin: 0px;
    font-weight: bold;
`;

export const StyledButton = styled(Button)`
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: ${Colors.primaryblue};
    border-color: ${Colors.secondarygray};
`;

export const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;


export const LargeInput = styled(Input)`
    max-width: 350px;
    padding: 5px;
    border-radius: 15px;
    border-color: ${Colors.primaryblue};
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
`;

export const FlexCol = styled(Col)`
    display: flex;
    align-items: center;
`;