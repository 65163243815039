import React, { useState } from 'react';
import { Button, Col } from 'reactstrap';
import styled from 'styled-components';
import { convertUTCDateToLocalDate } from '../../../utils/helper';
import VideoModal from '../../common/video-modal';
import { Colors } from "../../../utils/constants";

const ResourceCard = (props) => {
    var time = new Date(1970, 0, 1);
    time.setSeconds(props.date.seconds);
    time = convertUTCDateToLocalDate(time).toLocaleDateString();
    const [isOpen, setIsOpen] = useState(false);

    //TODO needs styling updates
    return ( <StyeldCol xs="12" md="6" lg="4">
        <VideoModal isOpen={isOpen} title={props.title} videoId={props.youtubeId} cancelFunction={() => setIsOpen(false)} />
        <MarginDiv>
            {(!props.imgUrl || props.imgUrl !== "N/A") && <div>
                <ArticleImg src={props.imgUrl} alt={props.title} />
            </div>}
            {props.title && <StyledTitle>{props.title}</StyledTitle>}
            {props.date && <StyledTimeAndCategory>{time} | {props.category}</StyledTimeAndCategory>}
            {props.description && <StyledDescription>{props.description}</StyledDescription>}
            {props.downloadlink && <div><br /><StyledAnchor href={props.downloadlink}><b>DOWNLOAD</b><br /></StyledAnchor></div>}
            {props.youtubeId && <div><br /><StyledButton onClick={() => setIsOpen(true)}>Watch Video</StyledButton></div>}
        </MarginDiv>
    </StyeldCol> );
}

const ArticleImg = styled.img`
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    border-radius: 5px;
`

const StyledButton = styled(Button)`
    right: 0%;
`

const StyledTitle = styled.h4`
color: ${Colors.primaryblue};
text-align: center;
font-family: Source Sans Pro;
font-style: normal;
font-weight: bold;
font-size: x-large;
margin-top: 10px;
`

const StyledDescription = styled.p`
margin-bottom: 0px;
text-align: center;
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 16px;
`

const StyledTimeAndCategory = styled.p`
text-align: center;
font-family: Source Sans Pro;
font-style: normal;
font-weight: normal;
font-size: 18px;
`

const MarginDiv = styled.div`
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.secondarybackground};
    background-color: white;
    margin-bottom: 30px;
    border-radius: 5px;
    min-height: 180px;
`

const StyledAnchor = styled.a`
    color: ${Colors.primaryblue};
    text-decoration: none;
`

const StyeldCol = styled(Col)`
    
`
 
export default ResourceCard;