import { NewUser } from "./constants";

const version = 1.0;

export function getViewport () {
    const width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    if (width <= 576) return 0
    if (width <= 768) return 1
    if (width <= 992) return 2
    if (width <= 1600) return 3
    if (width <= 1900) return 4
    return 5
}

export const GetVersion = () => {
    return version;
}

export function updateUsers(db) {
    db.collection("admin").doc("users").get().then(function(doc) {
        if (doc.exists) {
            let users = doc.data().users;
            Object.entries(users).forEach(element => {
                updateUser(db, element);
            });
        } else {
            console.log("No such document!");
        }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    //Update user information
    //Write user information
};

function updateUser(db, user) {
    let tempUser = NewUser;
    //Basic Data
    tempUser.admin = user[1].admin;
    tempUser.awaitingInstructorConfirmation = user[1].awaitingInstructorConfirmation;
    tempUser.email = user[1].email;
    tempUser.firstName = user[1].firstName;
    tempUser.lastName = user[1].lastName;
    tempUser.id = user[1].id;
    tempUser.instructor = user[1].instructor;
    tempUser.language = user[1].language;
    tempUser.location = user[1].location;
    tempUser.loggedInToday = user[1].loggedInToday;
    tempUser.teacher = user[1].teacher;
    tempUser.verified = user[1].verified;
    //Which modules have been 'completed'
    user[1].modules.introduction != undefined ? tempUser.modules.introduction = user[1].modules.introduction : tempUser.modules.introduction = false;
    user[1].modules.module1 != undefined ? tempUser.modules.module1 = user[1].modules.module1 : tempUser.modules.module1 = false;
    user[1].modules.module2 != undefined ? tempUser.modules.module2 = user[1].modules.module2 : tempUser.modules.module2 = false;
    user[1].modules.module3 != undefined ? tempUser.modules.module3 = user[1].modules.module3 : tempUser.modules.module3 = false;
    user[1].modules.module4 != undefined ? tempUser.modules.module4 = user[1].modules.module4 : tempUser.modules.module4 = false;
    user[1].modules.module5 != undefined ? tempUser.modules.module5 = user[1].modules.module5 : tempUser.modules.module5 = false;
    user[1].modules.module6 != undefined ? tempUser.modules.module6 = user[1].modules.module6 : tempUser.modules.module6 = false;
    user[1].modules.module7 != undefined ? tempUser.modules.module7 = user[1].modules.module7 : tempUser.modules.module7 = false;
    user[1].modules.module8 != undefined ? tempUser.modules.module8 = user[1].modules.module8 : tempUser.modules.module8 = false;
    //Activities tracked
    tempUser.data = user[1].data;
    //Module Data tracked
    user[1].moduleData != undefined ? tempUser.moduleData = user[1].moduleData : tempUser.moduleData = NewUser.moduleData;

    db.collection("admin").doc("users").update({
        [user[1].id]: tempUser
    })
    .then(() => {
        console.log("Successfully updated: " + user[1].id);
    })
    .catch((error) => {
        console.log(error);
    })
};

export function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime());

    var currentDate = Date.now();
    currentDate = new Date(currentDate);
    var offset = currentDate.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
}

export function validateEmail(value) {    
    
    if(value === ""){
        return false;
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(String(value).toLowerCase())){
        return true;
    }
    else{
        return false;
    }
    
}

export function validPassword(value) {
    var regularExpression  = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    var minCharacters = 6;

    if(!regularExpression.test(value)){
        return false;
    }
    if(value < 6){
        return false;
    }
    return true;
}