import React, { useState, useEffect } from "react";
import { Container, Row, Col, Input, InputGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import styled from "styled-components";
import { listenToDocument } from '../../utils/firestore-functions';
import { teamUnbreakableDB } from "../../utils/firestore";
import { Colors } from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newActivityTracked } from "../../utils/challenges";
import ActivityBackground from "../../images/activity-combined-background.png";
import { Event } from '../../utils/analytics';
import { set } from "react-ga";

export const StudentActivity = ( props ) => {
    
    const [date, setDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000 )).toISOString().split("T")[0]);
    const [distance, setDistance] = useState(0);
    const [time, setTime] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)
    const [preRunMood, setPreRunMood] = useState(6);
    const [postRunMood, setPostRunMood] = useState(6);
    const [preRunEmotions, setPreRunEmotions] = useState("");
    const [postRunEmotions, setPostRunEmotions] = useState("");
    //const [food, setFood] = useState("");
    //const [water, setWater] = useState(0);
    //const [sleep, setSleep] = useState(0);
    //const [route, setRoute] = useState("");
    //const [comments, setComments] = useState("No Comments");
    const [ranWithOthers, setRanWithOthers] = useState(false);

    const [successModal, setSuccessModal] = useState(false);

    const [modal, setModal] = useState(false);
    const [modalWarnings, setModalWarnings] = useState([]);
    
    const [user, setUser] = useState();

    const regexFloat = /[^0-9.]/;
    const regexText = /[^a-zA-Z0-9.'-_ !?"]/;
    
    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const updateMinutes = (min) => {
        setMinutes(min);
        setTime(parseFloat(min) + parseFloat(seconds/60));
    };

    const updateSeconds = (sec) => {
        setSeconds(sec);
        setTime(parseFloat(minutes) + parseFloat(sec/60));
    };

    const closeModal = () => {
        setModalWarnings([]);
        setModal(false);
    };

    const closeSuccessModal = () => {
        setDistance(0);
        setSeconds(0);
        setMinutes(0);
        setTime(0);
        setPreRunMood(6);
        setPostRunMood(6);
        setPreRunEmotions("");
        setPostRunEmotions("");
        setRanWithOthers(false);
        //setFood("");
        //setWater(0);
        //setSleep(0);
        //setRoute("");
        //setComments("No Comments");
        setSuccessModal(false);
    }

    const successToggle = () => {
        Event("Activity Tracked", { user: "Put UserID here"});
        setSuccessModal(true);
    }

    const toggle = () => {
        let ar = [];
        let dateCheck = false;
        if (date.length > 0){
            dateCheck = true;
        }
        else{
            ar.push("Date is not set correctly. Please select the date again.");
        }
        //Check Distance
        let distanceCheck = regexFloat.test(distance);
        if (distanceCheck) ar.push("Distance contains an illegal character. It must be between 0-9, and can include a .");
        if (distance === 0) ar.push("Distance cannot be 0.");
        //Check Time
        let timeCheck = regexFloat.test(time);
        if (timeCheck) ar.push("Time contains an illegal character. It must be between 0-9, and can include a .");
        if (time === 0) ar.push("Time cannot be 0.");
        //Check Pre-Run Mood
        let preRunMoodCheck = regexFloat.test(preRunMood);
        if (preRunMoodCheck) ar.push("Pre-run Mood contains an illegal character. It must be between 0-9, or include a .");
        if (preRunMood === 0) ar.push("Pre-run Mood cannot be 0.");
        //Check Pre-Run Emotion
        /*
        let preRunEmotionsCheck = regexText.test(preRunEmotions);
        if (preRunEmotionsCheck) ar.push("Pre-run Emotions contains an illegal character.");
        */
        //Check Post-Run Mood
        let postRunMoodCheck = regexFloat.test(postRunMood);
        if (postRunMoodCheck) ar.push("Post-run Mood contains an illegal character. It must be between 0-9, and can include a .");
        if (postRunMood === 0) ar.push("Post-run Mood cannot be 0.");
        //Check Post-Run Emotion
        /*
        let postRunMoodEmotionsCheck = regexText.test(postRunEmotions);
        if (postRunMoodEmotionsCheck) ar.push("Post-run Emotions contains an illegal character.");
        */
        //Check Food
        //let foodCheck = regexText.test(food);
        //if (foodCheck) ar.push("What you ate today contains an illegal character.");
        //Check Hydration
        //let waterCheck = regexFloat.test(water);
        //if (waterCheck) ar.push("Hydration contains an illegal character. It must be between 0-9, and can include a .");
        //Check Sleep
        //let sleepCheck = regexFloat.test(sleep);
        //if (sleepCheck) ar.push("Sleep contains an illegal character. It must be between 0-9, and can include a .");
        //Check Route Conditions
        //let routeCheck = regexText.test(route);
        //if (routeCheck) ar.push("Route contains an illegal character.");
        //Check Comments
        //let commentsCheck = regexText.test(comments);
        //if (commentsCheck) ar.push("Comments contains an illegal character.");
        setModalWarnings(ar);
        if (ar.length === 0){
            submitActivity();
            successToggle();
        }
        else{
            setModal(!modal);
        }
    }

    const submitActivity = () => {
        let activity = {
            date: date,
            distance: distance,
            time: time,
            preRunMood: preRunMood,
            postRunMood: postRunMood,
            preRunEmotions: preRunEmotions,
            postRunEmotions: postRunEmotions,
            ranWithOthers: ranWithOthers,
            //food: food,
            //water: water,
            //sleep: sleep,
            //route: route,
            //comments: comments
        };
        newActivityTracked(activity, user);
    }

    return (
        <React.Fragment>
            <Title>Runner's Log</Title>
            <LogContainer id="latest-run-container">
                <FlexRow>
                    <TitleFlexCol xs="12" md="4">
                        <WhiteTitle>{props.user.firstName}'s Latest Run</WhiteTitle>
                    </TitleFlexCol>
                    <Col></Col>
                    <Col style={{ backgroundColor:"rgba(0,0,0,0.3)", borderRadius:"15px", marginRight:"10px"}}>
                        <Container id="details-input-container" style={{paddingRight: "0px"}}>
                            <Row>
                                <FlexCol>
                                    <NoMarginBoldP>Date</NoMarginBoldP>
                                    <CalendarInput defaultValue={date} value={date} onChange={e => {setDate(e.target.value)}} type="date"></CalendarInput>
                                </FlexCol>
                            </Row>
                            <Row>
                                <FlexCol>
                                    <NoMarginBoldP>Distance (km):</NoMarginBoldP>
                                    <SmallInput value={distance} onChange={e => setDistance(e.target.value)} ></SmallInput>
                                </FlexCol>
                            </Row>
                            <Row>
                                <FlexCol>
                                    <NoMarginBoldP>Time: (min : sec)</NoMarginBoldP>
                                    <SmallInput value={minutes} onChange={e => updateMinutes(e.target.value)} ></SmallInput>
                                    <NoMarginBoldP>:</NoMarginBoldP>
                                    <SmallInput value={seconds} onChange={e => updateSeconds(e.target.value)} ></SmallInput>
                                </FlexCol>
                            </Row>
                            <Row style={{height:"46px"}}>
                                <FlexCol>
                                    <NoMarginBoldP style={{ marginRight:"33px"}}>Did you run with anyone?</NoMarginBoldP>
                                    <FormGroup switch>
                                        <StyledToggle role="switch" type="switch" checked={ranWithOthers} onChange={e => setRanWithOthers(!ranWithOthers)} ></StyledToggle>
                                    </FormGroup>
                                </FlexCol>
                            </Row>
                            {/* <Row>
                                <FlexCol>
                                    <NoMarginBoldP>Sleep (in hrs):</NoMarginBoldP>
                                    <SmallInput value={sleep} onChange={e => setSleep(e.target.value)} ></SmallInput>
                                </FlexCol>
                            </Row> */}
                        </Container>
                    </Col>
                </FlexRow>
            </LogContainer>
            <Container fluid={true}>
                <Row>
                    <LeftCol xs="12" md="">
                        <SolidContainer>
                            <Row>
                                <CenterFlexCol> 
                                    <WhiteH2>Pre-Run Mood</WhiteH2>
                                </CenterFlexCol>
                            </Row>
                            <Row>
                                <CenterFlexCol>
                                    <NoMarginBoldP>{preRunMood}</NoMarginBoldP>
                                </CenterFlexCol>
                            </Row>
                            <BottomRow>
                                <CenterFlexCol xs="3">
                                    <NoMarginP>1 (Very Bad)</NoMarginP>
                                </CenterFlexCol>
                                <CenterFlexCol>
                                    <WideSlide onInput={(e) => {setPreRunMood(e.target.value)}} type="range" min="1" max="10" />
                                </CenterFlexCol>
                                <CenterFlexCol xs="3">
                                    <NoMarginP>10 (Very Good)</NoMarginP>
                                </CenterFlexCol>
                            </BottomRow>
                        </SolidContainer>
                    </LeftCol>
                    <Col md="1" xs="0" />
                    <RightCol xs="12" md="">
                        <SolidContainer>
                            <Row>
                                <CenterFlexCol> 
                                    <WhiteH2>Post-Run Mood</WhiteH2>
                                </CenterFlexCol>
                            </Row>
                            <Row>
                                <CenterFlexCol>
                                    <NoMarginBoldP>{postRunMood}</NoMarginBoldP>
                                </CenterFlexCol>
                            </Row>
                            <BottomRow>
                                <CenterFlexCol xs="3">
                                    <NoMarginP>1 (Very Bad)</NoMarginP>
                                </CenterFlexCol>
                                <CenterFlexCol>
                                    <WideSlide onInput={(e) => {setPostRunMood(e.target.value)}} type="range" min="1" max="10" />
                                </CenterFlexCol>
                                <CenterFlexCol xs="3">
                                    <NoMarginP>10 (Very Good)</NoMarginP>
                                </CenterFlexCol>
                            </BottomRow>
                        </SolidContainer>
                    </RightCol>
                </Row>
            </Container>
            {/*
            <SolidContainer>
                <TopSpacerRow>
                    <Col>
                    </Col>
                </TopSpacerRow>
                <BottomRow>
                    <Col md="4" xs="12">
                        <NoMarginBoldP style={{margin: "0px 15px"}}>How much did you drink?</NoMarginBoldP>
                        <InputGroup>
                            <LargeInput value={food} onChange={e => setFood(e.target.value)} ></LargeInput>
                        </InputGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <NoMarginBoldP style={{margin: "0px 15px"}}>Route Conditions</NoMarginBoldP>
                        <InputGroup>
                            <LargeInput value={route} onChange={e => setRoute(e.target.value)} ></LargeInput>
                        </InputGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <NoMarginBoldP style={{margin: "0px 15px"}}>Comments About Run</NoMarginBoldP>
                        <InputGroup>
                            <LargeInput value={comments} onChange={e => setComments(e.target.value)} ></LargeInput>
                        </InputGroup>
                    </Col>
                </BottomRow>
            </SolidContainer>
            */}
            <StyledModal isOpen={modal} toggle={toggle}>
                <ModalHeader>
                    <h4>You cannot submit your activity until you fix the following:</h4>
                </ModalHeader>
                <ModalBody>
                    {modalWarnings.length > 0 && modalWarnings.map(entry => {
                        return( 
                            <p>
                                {entry}
                            </p>
                        )
                    })}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {closeModal()}} color="danger">
                        <FAIconButton icon={["fas", "times-circle"]}></FAIconButton>
                    </Button>
                </ModalFooter>
            </StyledModal>
            <StyledModal isOpen={successModal} toggle={successToggle}>
                <ModalHeader>
                    <h4>Activity Successfully Recorded</h4>
                </ModalHeader>
                <ModalFooter>
                    <Button onClick={() => {closeSuccessModal()}} color="success">
                        <FAIconButton icon={["fas", "check"]}></FAIconButton>
                    </Button>
                </ModalFooter>
            </StyledModal>
            <CenterFlexCol>
                <StyledButton onClick={() => {toggle()}} color="primary">Submit</StyledButton>
            </CenterFlexCol>
        </React.Fragment>
    );
}

const StyledToggle = styled(Input)`
    height: 20px;
    width: 100px;
    padding: 5px;
    margin-left: 20px;
    margin-right: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -o-center;
    text-align: -ms-center;
    text-align: center;
`;

const CenteredContent = styled(Col)`
align-items: center;
`

export default StudentActivity;

const TopSpacerRow = styled(Row)`
    height: 10px;
`;

const WideSlide = styled(Input)`
    width: 100%;
    margin-right: 10px;
`;

const BottomRow = styled(Row)`
    padding-bottom: 10px;
`;

const LeftCol = styled(Col)`
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
`;

const RightCol = styled(Col)`
    margin: 0px;
    padding-right: 0px;
    padding-left: 0px;
`;

const WhiteH2 = styled.h2`
    color: white;
`;

const FlexRow = styled(Row)`
    
`;

const BorderRow = styled(Row)`
    border-radius: 15px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
`;

const StyledButton = styled(Button)`
    width: 160px;
    margin-top: 5px;
    font-size: 20px;
    font-family: Roboto;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -o-center; 
    text-align: -ms-center;
    text-align: center;
    background: ${Colors.primaryblue};
    color: white;
    border-style: none;
    margin-bottom: 2px;
    &:hover {
        background: white;
        color: black;
        border-style: solid;
        border-color: black;
        border-size: 1px;
        margin-bottom: 0px;
    }
`;

const StyledHR = styled.hr`
    background-color: ${Colors.primaryblue};
    margin-bottom: 2px;
    margin-top: 2px;
`;

const MoodContainer = styled(Container)`
    width: 100%;
    height: auto;
    background-size: cover;
`;

const FlexCol = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const TitleFlexCol = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 50px;
`;

const LogContainer = styled(Container)`
    background-color: ${Colors.primaryblue};
    background-image: url(${ActivityBackground});
    background-size: auto 100%;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 15px 15px 15px 15px;
`;

const SolidContainer = styled(Container)`
    background-color: ${Colors.primaryblue};
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 15px 15px 15px 15px;
`;

const WhiteTitle = styled.h1`
    color: white;
`

const Title = styled.h2`
    color: ${Colors.primaryblue};
    margin-bottom: 20px;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledModal = styled(Modal)`
    border-color: ${Colors.primaryblue};
    z-index: 5;
`;

const LargeInput = styled(Input)`
    max-width: 100%;
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -o-center; 
    text-align: -ms-center;
    text-align: center;
`

const CalendarInput = styled(Input)`
    max-width: 180px;
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -o-center; 
    text-align: -ms-center;
    text-align: center;
`

const SmallInput = styled(Input)`
    max-width: 50px;
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -o-center; 
    text-align: -ms-center;
    text-align: center;
`;

const NoMarginBoldP = styled.p`
    margin: 0px;
    font-weight: bold;
    color: white;
    font-size: large;
`;

const NoMarginP = styled.p`
    margin: 0px;
    color: white;
`;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
`;
