import React, { useEffect } from 'react';
import { Route, Redirect } from "react-router-dom";

const ActionUrl = () => {

    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    var action = getParameterByName("mode");
    var actionCode = getParameterByName("oobCode");

    return ( <Route
        render={(props) => {
          if (action === "verifyEmail"){
              return(<Redirect
                to={{ pathname: "/EmailVerification", state: { from: props.location, actionCode: actionCode } }}  />)
          }
          else if(action === "resetPassword"){
            return (<Redirect
                to={{ pathname: "/ResetPassword", state: { from: props.location, actionCode: actionCode } }} />
            )}      
        }} /> );
}
 
export default ActionUrl;