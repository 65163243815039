import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Copy } from "./module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { 
    ColoredH2, 
    StyledHR, 
    StyledP, 
    CenterTitleFlexCol, 
    WhiteH2, 
    ColoredUL, 
    CenterFlexCol, 
    StyledButton, 
    CompletedP, 
    CompletedDiv, 
} from "../../styles/styledComponents";
import { Colors } from '../../utils/constants';
import { Event } from "../../utils/analytics";
import styled from "styled-components";
import StarsImage from '../../images/stars.png';
import BalanceImage from '../../images/balance.png';
import ToolsImage from '../../images/tools.png';

export const Module8 = ( props ) => {

    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const completeModule = () => {
        Event("Module Completed", "Mental Module", "Module 8");
        var data = user;
        data.modules.module8 = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module 8"].SubTitle1[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 8"].SubTitle2[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 8"].Paragraph1[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 8"].SubTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 8"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                    <CenterFlexVerticalCol xs="12" md="3">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 8"].SubTitle4[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 8"].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List1Item2[props.user.language]}</StyledP></li>
                            <ul>
                                <li><StyledP>{Copy["Module 8"].List1Item2a[props.user.language]}</StyledP></li>
                                <li><StyledP>{Copy["Module 8"].List1Item2b[props.user.language]}</StyledP></li>
                                <li><StyledP>{Copy["Module 8"].List1Item2c[props.user.language]}</StyledP></li>
                                <li><StyledP>{Copy["Module 8"].List1Item2d[props.user.language]}</StyledP></li>
                                <li><StyledP>{Copy["Module 8"].List1Item2e[props.user.language]}</StyledP></li>
                                <li><StyledP>{Copy["Module 8"].List1Item2f[props.user.language]}</StyledP></li>
                            </ul>
                            <li><StyledP>{Copy["Module 8"].List1Item3[props.user.language]}</StyledP></li>
                            <ul>
                                <li><StyledP>{Copy["Module 8"].List1Item3a[props.user.language]}</StyledP></li>
                            </ul>
                            <li><StyledP>{Copy["Module 8"].List1Item4[props.user.language]}</StyledP></li>
                            <ul>
                                <li><StyledP>{Copy["Module 8"].List1Item4a[props.user.language]}</StyledP></li>
                                <li><StyledP>{Copy["Module 8"].List1Item4b[props.user.language]}</StyledP></li>
                            </ul>
                            <li><StyledP>{Copy["Module 8"].List1Item5[props.user.language]}</StyledP></li>
                            <ul>
                                <li><StyledP>{Copy["Module 8"].List1Item5a[props.user.language]}</StyledP></li>
                            </ul>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                        <EmotionsIMG src={BalanceImage}></EmotionsIMG>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 8"].SubTitle5[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 8"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 8"].Paragraph3[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 8"].Paragraph3a[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 8"].SubTitle6[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 8"].Paragraph4[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 8"].List2Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List2Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List2Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List2Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List2Item5[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List2Item6[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List2Item7[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List2Item8[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List2Item9[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List2Item10[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List2Item11[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module 8"].SubTitle7[props.user.language]}</ColoredH2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledHR></StyledHR>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 8"].Paragraph5[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module 8"].List3Item1[props.user.language]}</StyledP></li>
                                        <ul>
                                            <li><StyledP>{Copy["Module 8"].List3Item1a[props.user.language]}</StyledP></li>
                                        </ul>
                                        <li><StyledP>{Copy["Module 8"].List3Item2[props.user.language]}</StyledP></li>
                                        <ul>
                                            <li><StyledP>{Copy["Module 8"].List3Item2a[props.user.language]}</StyledP></li>
                                        </ul>
                                        <li><StyledP>{Copy["Module 8"].List3Item3[props.user.language]}</StyledP></li>
                                        <ul>
                                            <li><StyledP>{Copy["Module 8"].List3Item3a[props.user.language]}</StyledP></li>
                                        </ul>
                                    </ColoredUL>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <CenterFlexVerticalCol xs="12" md="6">
                        <SocialMediaIMG src={ToolsImage}></SocialMediaIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <br />
            </Container>
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 8"].SubTitle8[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 8"].List4Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List4Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List4Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List4Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List4Item5[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List4Item6[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List4Item7[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List4Item8[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 8"].List4Item9[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            {user !== undefined && <Container>
                <Row>
                    <CenterFlexCol>
                        {user.modules.module8 ? <CompletedDiv><CompletedP>Module Completed</CompletedP></CompletedDiv> : <StyledButton onClick={() => completeModule()} color="primary">Complete Module</StyledButton>}
                    </CenterFlexCol>
                </Row>
            </Container>}
        </React.Fragment>
    );
}

export default Module8;

const SocialMediaIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
`;

const EmotionsIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StarsIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;