import React, { useState, useEffect } from 'react';
import { Container, Row, Col} from 'reactstrap';
import styled from 'styled-components';
import { teamUnbreakableDB } from '../../utils/firestore';
import { listenToDocumentProperty } from '../../utils/firestore-functions';
import { Colors } from "../../utils/constants";

const Links = () => {
    const [links, setLinks] = useState({});

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "links", "links", setLinks);
        return unsubscribe;
    }, []);

    return (<React.Fragment>
        <OffsetDiv />
        <MainDiv>
            <ContentContainer fluid={true}>
                <br />
                <Row>
                    <Col>
                        <StyledHeaderTitle>Links</StyledHeaderTitle><br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ReachOutParagraph>
                            This is not a site for personal disclosure of mental health distress, suicidal thoughts or behaviors. If you are in crisis, please call 911
                            or go to your nearest emergency department for assistance.
                        </ReachOutParagraph>
                    </Col>
                </Row>
                {(Object.entries(links).length > 0) && Object.entries(links).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                    return <Row key={t[0]}>
                        <Col>
                            <StyledTitle>{t[1].title}</StyledTitle>
                            <StyledParagraph>{t[1].description}</StyledParagraph>
                            <a href={t[1].linkURL}>{t[1].linkURL}</a>
                        </Col>
                    </Row>
                })}
                <br />
            </ContentContainer>
        </MainDiv>
    </React.Fragment> );
}
 
export default Links;

const StyledParagraph = styled.p`
    color: black;
    font-size: medium;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
`

const StyledTitle = styled.h4`
    padding-top: 15px;
    padding-bottom: 20px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: xx-large;
    margin-bottom: 0px;
    color: ${Colors.primaryblue};
`;

const ReachOutParagraph = styled.p`
color: black;
font-size: medium;
font-family: Inter;
font-style: normal;
font-weight: bold;
`

const OffsetDiv = styled.div`
    height: 90px;
`;

const ContentContainer = styled(Container)`
    padding-left: 15%;
    padding-right: 15%;

`;

const MainDiv = styled.div`
    background-color: ${Colors.primarybackground};
`

const StyledHeaderTitle = styled.h1`
    color: ${Colors.primaryblue};
    font-family: Roboto;
`