import React, { useState, useEffect } from 'react';
import { teamUnbreakableDB, generateGUID } from '../../utils/firestore';
import { listenToDocumentProperty, updateDocumentProperty } from "../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Button, InputGroup, Input } from "reactstrap";
import styled from "styled-components";
import AdminDonorRow from './admin-donor-row';

const AdminDonor = () => {
    const [donors, setDonors] = useState({});
    const [name, setName] = useState("");
    const [order, setOrder] = useState(0);
    const [url, setUrl] = useState("");

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "donors", "donors", setDonors);
        return unsubscribe;
    }, [])

    const addDonor = () => {
        var guid = generateGUID();
        var donor = {};
        donor.name= name;
        donor.order= order;
        donor.url = url;
        donor.id= guid;
        updateDocumentProperty(teamUnbreakableDB, "content", "donors", "donors." + guid, donor);
        clearInput();
    }

    const clearInput = () => {
        setName("");
        setOrder(0);
    }

    return ( 
        <Container>
            <Row>
                <CenterFlexCol>
                    <h1>Donors</h1>
                </CenterFlexCol>
            </Row>
            {(Object.entries(donors).length > 0) && Object.entries(donors).sort((a,b)=>(parseInt(a[1].order))-(parseInt(b[1].order))).map(donor => {
                return <ColoredDiv key={donor[0]}>
                    <AdminDonorRow url={donor[1].url} name={donor[1].name} id={donor[0]} imageurl={donor[1].imageUrl} order={donor[1].order} />
                </ColoredDiv>
            })}
            <Row>
                <Col>
                    <h2>Add New Donor</h2>
                </Col>
            </Row>
            <Row>
                <FlexCol xs="1">
                    <Button onClick={() => {addDonor()}} color="primary">
                        <FAIconButton icon={["fas", "user-plus"]}></FAIconButton>
                    </Button>
                </FlexCol>
                <FlexCol>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"100px"}}>
                            Name
                        </div>
                        <Input value={name} onChange={e => setName(e.target.value)} />
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend">
                            Order
                        </div>
                        <Input value={order} onChange={e => setOrder(e.target.value)} />
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend">
                            Website URL
                        </div>
                        <Input value={url} onChange={e => setUrl(e.target.value)} />
                    </InputGroup>
                </FlexCol>
            </Row>
            <Row>
                <FlexCol xs="1">
                </FlexCol>
            </Row>
        </Container>
     );
}
 
export default AdminDonor;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const ColoredDiv = styled.div`
    margin-bottom: 5px;
    padding: 5px;
    padding-top: 15px;
    background-color: rgba(0,0,0,0.05);
`;

const FlexCol = styled(Col)`
    display: flex;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;