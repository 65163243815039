import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Copy } from "./module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { 
    ColoredH2, 
    StyledHR, 
    StyledP, 
    CenterTitleFlexCol, 
    WhiteH2, 
    StyledBoldP, 
    ColoredUL, 
    CenterFlexCol, 
    StyledButton, 
    CompletedP, 
    CompletedDiv, 
    LargeInput
} from "../../styles/styledComponents";
import { Colors } from "../../utils/constants";
import { Event } from "../../utils/analytics";
import styled from "styled-components";
import StarsImage from '../../images/stars.png';
import SocialMediaImage from '../../images/social-media.png';
import GratitudeImage from '../../images/gratitude.png';

export const Module7 = ( props ) => {

    const [user, setUser] = useState();

    const [input1, setInput1] = useState((props.user.moduleData !== undefined && props.user.moduleData.module7 !== undefined && props.user.moduleData.module7.input1 != undefined) ? props.user.moduleData.module7.input1.input1 : "");

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const UpdateInput = (inputKey, inputValue) => {
        var data = user;
        data.moduleData.module7[inputKey] = inputValue;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    const completeModule = () => {
        Event("Module Completed", "Mental Module", "Module 7");
        var data = user;
        data.modules.module7 = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module 7"].SubTitle1[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <CenterFlexCol>
                        <StyledBoldP>{Copy["Module 7"].Paragraph1[props.user.language]}</StyledBoldP>
                    </CenterFlexCol>
                </Row>
            </Container>
            <Container>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 7"].SubTitle2[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 7"].SubTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledBoldP>{Copy["Module 7"].Paragraph3Title[props.user.language]}</StyledBoldP>
                        <StyledP>{Copy["Module 7"].Paragraph3[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledBoldP>{Copy["Module 7"].Paragraph4Title[props.user.language]}</StyledBoldP>
                        <StyledP>{Copy["Module 7"].Paragraph4[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledBoldP>{Copy["Module 7"].Paragraph5Title[props.user.language]}</StyledBoldP>
                        <StyledP>{Copy["Module 7"].Paragraph5[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledBoldP>{Copy["Module 7"].Paragraph6Title[props.user.language]}</StyledBoldP>
                        <StyledP>{Copy["Module 7"].Paragraph6[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 7"].SubTitle4[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].List1Title[props.user.language]}</StyledP>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 7"].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 7"].List1Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 7"].List1Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 7"].List1Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 7"].List1Item5[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 7"].List1Item6[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 7"].List1Item7[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                    <CenterFlexVerticalCol xs="12" md="6">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 7"].SubTitle5[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph7[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 7"].List2Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 7"].List2Item2[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            <WhiteContainer>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph8[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph9[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </WhiteContainer>
            <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 7"].SubTitle6[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph10[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph11[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                        <EmotionsIMG src={GratitudeImage}></EmotionsIMG>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 7"].SubTitle7[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph12[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph13[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph14[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 7"].SubTitle8[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph15[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph16[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 7"].SubTitle9[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 7"].List3Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 7"].List3Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 7"].List3Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 7"].List3Item4[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 7"].SubTitle10[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph17[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 7"].SubTitle11[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 7"].Paragraph18[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledBoldP>{Copy["Module 7"].Input1Header[props.user.language]}</StyledBoldP>
                        <LargeInput value={input1} onChange={e => setInput1(e.target.value)} onBlur={() => {UpdateInput("input1", {input1})}}></LargeInput>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <br />
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module 7"].SubTitle12[props.user.language]}</ColoredH2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledHR></StyledHR>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 7"].Paragraph19[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 7"].Paragraph20[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 7"].Paragraph21[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <CenterFlexVerticalCol xs="12" md="6">
                        <SocialMediaIMG src={SocialMediaImage}></SocialMediaIMG>
                    </CenterFlexVerticalCol>
                </Row>
            </Container>
            {user !== undefined && <Container>
                <Row>
                    <CenterFlexCol>
                        {user.modules.module7 ? <CompletedDiv><CompletedP>Module Completed</CompletedP></CompletedDiv> : <StyledButton onClick={() => completeModule()} color="primary">Complete Module</StyledButton>}
                    </CenterFlexCol>
                </Row>
            </Container>}
        </React.Fragment>
    );
}

export default Module7;

const EmotionsIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const WhiteContainer = styled(Container)`
    border-radius: 10px;
    background-color: white;
    border-color: ${Colors.primaryblue};
    border-width: 1px;
    border-style: solid;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const SocialMediaIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
`;

const StarsIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;
