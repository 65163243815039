import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Copy } from "./physical-module-copy";
import { listenToDocument } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { ColoredH2, StyledHR, StyledP, CenterTitleFlexCol, WhiteH2, BoldP, ColoredOL } from "../../styles/styledComponents";
import styled from "styled-components";
import { Colors } from "../../utils/constants";
import StarsImage from '../../images/stars.png';
import HealthyImage from '../../images/healthy.png';

export const PhysicalModuleNutrition = ( props ) => {

    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    return(
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module Nutrition"].Title[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Nutrition"].Paragraph1[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Nutrition"].BoldTitle1[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Nutrition"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Nutrition"].BoldTitle2[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy["Module Nutrition"].Paragraph3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Nutrition"].Paragraph4[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <StyledHR />
                <Row>
                    <CenterFlexVerticalCol>
                        <PhysicalActivityIMG src={HealthyImage}></PhysicalActivityIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <StyledHR />
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Nutrition"].BoldTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Nutrition"].Paragraph5[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Nutrition"].BoldTitle4[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Nutrition"].Paragraph6[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Container>
                            <StyledWhiteRow>
                                <Col md="3" xs="12">
                                    <h6><b>{Copy["Module Nutrition"].Table1R1D1[props.user.language]}</b></h6>
                                </Col>
                                <Col md="8" xs="12">
                                    <StyledP>{Copy["Module Nutrition"].Table1R1D2[props.user.language]}</StyledP>
                                </Col>
                            </StyledWhiteRow>
                            <StyledWhiteRow>
                                <Col md="3" xs="12">
                                    <h6><b>{Copy["Module Nutrition"].Table1R2D1[props.user.language]}</b></h6>
                                </Col>
                                <Col md="8" xs="12">
                                    <StyledP>{Copy["Module Nutrition"].Table1R2D2[props.user.language]}</StyledP>
                                </Col>
                            </StyledWhiteRow>
                            <StyledWhiteRow>
                                <Col md="3" xs="12">
                                    <h6><b>{Copy["Module Nutrition"].Table1R3D1[props.user.language]}</b></h6>
                                </Col>
                                <Col md="8" xs="12">
                                    <StyledP>{Copy["Module Nutrition"].Table1R3D2[props.user.language]}</StyledP>
                                </Col>
                            </StyledWhiteRow>
                            <StyledWhiteRow>
                                <Col md="3" xs="12">
                                    <h6><b>{Copy["Module Nutrition"].Table1R4D1[props.user.language]}</b></h6>
                                </Col>
                                <Col md="8" xs="12">
                                    <StyledP>{Copy["Module Nutrition"].Table1R4D2[props.user.language]}</StyledP>
                                </Col>
                            </StyledWhiteRow>
                            <StyledWhiteRow>
                                <Col md="3" xs="12">
                                    <h6><b>{Copy["Module Nutrition"].Table1R5D1[props.user.language]}</b></h6>
                                </Col>
                                <Col md="8" xs="12">
                                    <StyledP>{Copy["Module Nutrition"].Table1R5D2[props.user.language]}</StyledP>
                                </Col>
                            </StyledWhiteRow>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Nutrition"].SubTitle1[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Nutrition"].Paragraph7[props.user.language] + " "}</StyledP>
                        <StyledP>{Copy["Module Nutrition"].Paragraph8[props.user.language] + " "}</StyledP>
                        <StyledP>{Copy["Module Nutrition"].Paragraph9[props.user.language] + " "}</StyledP>
                        <StyledP>{Copy["Module Nutrition"].Paragraph10[props.user.language]+ " "}</StyledP>
                    </Col>
                    <CenterFlexVerticalCol lg="4">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Nutrition"].BoldTitle5[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Nutrition"].Paragraph11[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Nutrition"].Paragraph12[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Nutrition"].Paragraph13[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Nutrition"].Paragraph14[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default PhysicalModuleNutrition;

const PhysicalActivityIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const StarsIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledWhiteRow = styled(Row)`
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
    background-color: white;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
`;