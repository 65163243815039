import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import { Copy } from "./physical-module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { ColoredH2, StyledHR, StyledP, CenterTitleFlexCol, WhiteH2, ColoredUL, BoldP } from "../../styles/styledComponents";
import { Colors } from "../../utils/constants";

export const PhysicalModuleRunningDrills = ( props ) => {

    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const completeModule = () => {
        var data = user;
        data.modules.introduction = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module Running Drills"].Title[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph1[props.user.language] + " "}</StyledP>
                        <StyledP>{Copy["Module Running Drills"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph3[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Running Drills"].SubTitle1[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <StyledWhiteRow>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph4[props.user.language]}</StyledP>
                    </Col>
                </StyledWhiteRow>
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle1[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph5[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle2[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph6[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle3[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledURL href={Copy["Module Running Drills"].URL1[props.user.language]}>Video Example</StyledURL>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Running Drills"].SubTitle2[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <StyledWhiteRow>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph7[props.user.language]}</StyledP>
                    </Col>
                </StyledWhiteRow>
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle4[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph8[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle5[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph9[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle6[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledURL href={Copy["Module Running Drills"].URL2[props.user.language]}>Video Example</StyledURL>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Running Drills"].SubTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <StyledWhiteRow>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph10[props.user.language]}</StyledP>
                    </Col>
                </StyledWhiteRow>
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle7[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph11[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle8[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph12[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle9[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledURL href={Copy["Module Running Drills"].URL3[props.user.language]}>Video Example</StyledURL>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Running Drills"].SubTitle4[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <StyledWhiteRow>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph13[props.user.language]}</StyledP>
                    </Col>
                </StyledWhiteRow>
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle10[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph14[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Running Drills"].SubTitle5[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <StyledWhiteRow>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph15[props.user.language]}</StyledP>
                    </Col>
                </StyledWhiteRow>
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle11[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph16[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle12[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph17[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle13[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledURL href={Copy["Module Running Drills"].URL4[props.user.language]}>Video Example</StyledURL>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Running Drills"].SubTitle6[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle14[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Running Drills"].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Running Drills"].List1Item2[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle15[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Running Drills"].List2Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Running Drills"].List2Item2[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle16[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Running Drills"].List3Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Running Drills"].List3Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Running Drills"].List3Item3[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Running Drills"].BoldTitle17[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Running Drills"].List4Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Running Drills"].List4Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Running Drills"].List4Item3[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <StyledWhiteRow>
                    <Col>
                        <StyledP>{Copy["Module Running Drills"].Paragraph18[props.user.language]}</StyledP>
                    </Col>
                </StyledWhiteRow>
            </Container>
        </React.Fragment>
    );
}

export default PhysicalModuleRunningDrills;

const StyledWhiteRow = styled(Row)`
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
    background-color: white;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const StyledURL = styled.a`
`;