import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import styled from "styled-components";
import { Colors } from "../../utils/constants";
import { Copy } from "./physical-module-copy";
import { listenToDocument } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import Carousel from "react-multi-carousel";
import CarouselWeek from "./carousel-week";
import { ColoredH2, StyledHR, ColoredOL, StyledP, CenterTitleFlexCol, WhiteH2, StyledBoldP, BoldP } from "../../styles/styledComponents";
import StarsImage from '../../images/stars.png';

export const PhysicalModuleIntroduction = ( props ) => {
    const responsive = {
        mobile: {
          breakpoint: { max: 1500, min: 0 },
          items: 1
        }
      };

    const [screenSize, setScreenSize] = useState("lg");
    const [carouselDetails, setCarouselDetails] = useState([]);
    const [tableDetails, setTableDetails] = useState([]);

    useEffect(() => {
        setScreenSize(getViewport());
      }, [document.documentElement.clientWidth]);


    function getViewport () {
        const width = Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        )
        if (width <= 576) return 'xs'
        if (width <= 768) return 'sm'
        if (width <= 992) return 'md'
        if (width <= 1200) return 'lg'
        return 'xl'
    }

    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    useEffect(() => {
        GetCarouselDetails();
        GetTableDetails();
    }, []);

    const GetCarouselDetails = () => {
        var carouselItems = [];

        for(let r = 2; r < 15; r++){   
            var property = "Table1R" + r + "D";
            carouselItems.push( <CarouselWeek 
            weekNumber={Copy["Module Introduction"][property + "1"][props.user.language]} 
            workoutStructure={Copy["Module Introduction"][property + "2"][props.user.language]}
            session1={Copy["Module Introduction"][property + "3"][props.user.language]}
            session2={Copy["Module Introduction"][property + "4"][props.user.language]}
            session3={Copy["Module Introduction"][property + "5"][props.user.language]} />)
        }

        setCarouselDetails(carouselItems);
    }

    const GetTableDetails = () => {
        var tableItems = [];

        for(let i = 2; i < 15; i++){
            var property = "Table1R" + i + "D";
            tableItems.push(<tr>
                <td><StyledP>{Copy["Module Introduction"][property + "1"][props.user.language]}</StyledP></td>
                <td><StyledP>{Copy["Module Introduction"][property + "2"][props.user.language]}</StyledP></td>
                <td><StyledP>{Copy["Module Introduction"][property + "3"][props.user.language]}</StyledP></td>
                <td><StyledP>{Copy["Module Introduction"][property + "4"][props.user.language]}</StyledP></td>
                <td><StyledP>{Copy["Module Introduction"][property + "5"][props.user.language]}</StyledP></td>
            </tr>)
        }

        setTableDetails(tableItems);
    }

    return(
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module Introduction"].Title[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
            </Container>
            <Container>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Introduction"].Paragraph1[props.user.language] + " "}</StyledP>
                        <StyledP>{Copy["Module Introduction"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Introduction"].List1Title[props.user.language]}</StyledP>
                        <ColoredOL>
                            <li><StyledP>{Copy["Module Introduction"].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Introduction"].List1Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Introduction"].List1Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Introduction"].List1Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Introduction"].List1Item5[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Introduction"].List1Item6[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                    <CenterFlexVerticalCol lg="4">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Introduction"].Paragraph3[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <BoldP>{Copy["Module Introduction"].Paragraph4[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Introduction"].SubTitle1[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Introduction"].Paragraph5[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Introduction"].Paragraph6[props.user.language]}</StyledP>
                    </Col>
                </Row>
                {(screenSize === "xl" || screenSize === "lg" || screenSize === "md") && <Row>
                    <WhiteCol>
                        <Table>
                            <tr>
                                <td><StyledBoldP>{Copy["Module Introduction"].Table1R1D1[props.user.language]}</StyledBoldP></td>
                                <td><StyledBoldP>{Copy["Module Introduction"].Table1R1D2[props.user.language]}</StyledBoldP></td>
                                <td><StyledBoldP>{Copy["Module Introduction"].Table1R1D3[props.user.language]}</StyledBoldP></td>
                                <td><StyledBoldP>{Copy["Module Introduction"].Table1R1D4[props.user.language]}</StyledBoldP></td>
                                <td><StyledBoldP>{Copy["Module Introduction"].Table1R1D5[props.user.language]}</StyledBoldP></td>
                            </tr>
                            {tableDetails.map((tableRow, i) => {
                                return tableRow;
                            })}
                        </Table>
                    </WhiteCol>
                </Row>}
                <br />
                {(screenSize === "sm" || screenSize === "xs") && <Row>
                    <Col>
                        <Carousel             
                            responsive={responsive} 
                            infinite={true}
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            autoPlay={false}
                            arrows={true} 
                            >
                              {carouselDetails}
                        </Carousel>
                    </Col>
                </Row>} 
                
            </Container>
        </React.Fragment>
    );
}

export default PhysicalModuleIntroduction;

const StarsIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const WhiteCol = styled(Col)`
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
    border-radius: 10px;
    padding: 10px;
    margin: 15px 12px;
`;