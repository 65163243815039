import React from 'react';
import { Container, Row, Col } from "reactstrap";
import styled from 'styled-components';
import StudentPlaybook from "../../../booklets/Team-Unbreakable-Playbook-English.pdf";
import { Colors } from "../../../utils/constants";

const StudentResources = () => {

   return (   
    <HeightDiv>
    <InteractingContainer>
        <Row>
            <Col>
                <Title>Student Resources</Title>
            </Col>
        </Row>
        <MarginRow>
            <Col>
                <StyledP><StyledA href="https://team-unbreakable-space.nyc3.cdn.digitaloceanspaces.com/resources/documents/Team-Unbreakable-Playbook-English.pdf">Download the English pdf here</StyledA></StyledP>
                <br></br>
                <StyledP><StyledA href="https://team-unbreakable-space.nyc3.cdn.digitaloceanspaces.com/resources/documents/Team-Unbreakable-Playbook-French.pdf">Download the French pdf here</StyledA></StyledP>
            </Col>
        </MarginRow>
    </InteractingContainer>
</HeightDiv>);
}
export default StudentResources;

const HeightDiv = styled.div`
min-height: ${window.innerHeight - 149}px;
`

const StyledP = styled.p`
    margin: 0px;
    
    font-size: large;
    text-align: center;
`;

const StyledA = styled.a`
    color: white;
`


const MarginRow = styled(Row)`
    margin-top: 5px;
    
`;

const Title = styled.h3`
    color: white;
    text-align: center;
`;

const InteractingContainer = styled(Container)`
    margin-top: 0px;
    background-color: ${Colors.primaryblue};
    max-width: 500px;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
`;