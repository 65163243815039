import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import styled from 'styled-components';
import { auth } from "../../utils/firestore";
import PictureBackground from "../common/picture-background";
import DarkHomePageBackground from "../../images/DarkHomePageBackground.png";
import { Colors } from "../../utils/constants";

export const EmailVerification = (props) => {
    useEffect(() => {          
        checkIfValidEmail();
   }, []);
 
    const checkIfValidEmail = () => {
        if(props.location.state !== undefined){
            var actionCode = props.location.state.actionCode;
            auth.applyActionCode(actionCode).then((resp) => {
                console.log("Successfully Verified Email")
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    const GoToStudentPanel = () => {
        window.location = "/Playbook";
    }

    return(
        <React.Fragment>
            <HeightDiv>
                <PictureBackground id="picture background" url={DarkHomePageBackground} />
                <OffsetDiv />
                <InteractingContainer>
                <Row>
                    <Col>
                        <RegisterTitle>Registration Complete</RegisterTitle>
                    </Col>
                </Row>
                <MarginRow>
                    <Col>
                        <StyledP>Your email has been verified you are now able to access the playbook and additional resources.</StyledP>
                    </Col>
                </MarginRow>
                <MarginRow>
                    <CenterFlexCol>
                        <StudentPanelButton onClick={() => {GoToStudentPanel()}} color="primary">Digital Playbook Panel</StudentPanelButton>
                    </CenterFlexCol>
                </MarginRow>
            </InteractingContainer>
        </HeightDiv>
    </React.Fragment>
    );
}

export default EmailVerification;

const StudentPanelButton = styled(Button)`
    background-color: white;
    padding-left: 40px;
    padding-right: 40px;
    color: ${Colors.primaryblue};
    border: none;
    &:hover {
        color: white;
        background-color: ${Colors.secondarygray};
    }
`

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const StyledP = styled.p`
    margin: 0px;
    color: white;
    font-size: 18px;
`;

const OffsetDiv = styled.div`
    height: 180px;
`;

const MarginRow = styled(Row)`
    margin-top: 5px;
`;


const InteractingContainer = styled(Container)`
    margin-top: 0px;
    background-color: ${Colors.primaryblue};
    max-width: 400px;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
`;

const HeightDiv = styled.div`
    min-height: ${window.innerHeight - 149}px;
`;

const RegisterTitle = styled.h3`
    color: white;
    text-align: center;
`;