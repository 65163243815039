import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import styled from 'styled-components';
import Logo from "../../images/DiamondLogo.png";
//import { TwitterTimelineEmbed } from 'react-twitter-embed';
import InstagramFeedContainer from './instagram-feed-container';
import { getViewport } from '../../utils/helper';
import { ReturnInstagramToken, listenToDocumentProperty } from '../../utils/firestore-functions';
import { teamUnbreakableDB } from '../../utils/firestore';
import { Colors } from "../../utils/constants";
import { Timeline } from 'react-twitter-widgets'

const Footer = () => {
    /* TWITTER EMBED WHEN UPDATED - TODO: MAY
    
        <TwitterTimelineEmbed  
            onLoad={function noRefCheck(){}}
            noFooter noHeader 
            sourceType="profile" 
            screenName="teamunbreakable" 
            options={{
                height: 350}}
                />
    */
    const [instagramLimit, setInstagramLimit] = useState();
    const [token, setInstagramToken] = useState();
    const [supportItems, setSupportItems] = useState([]);

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "footer", "listItems", setSupportItems);
        return unsubscribe;
    }, [])

    useEffect(() => {
        SetInstagramPictureNumbers(getViewport());
    }, []);

    const SetInstagramPictureNumbers = (screenSize) => {
        if(screenSize === 5){
            setInstagramLimit(8);
        }
        else if(screenSize === 4){
            setInstagramLimit(6);
        }
        else if(screenSize === 3 || screenSize === 2){
            setInstagramLimit(3);
        }
        else{
            setInstagramLimit(2);
        }
    }

    useEffect(() => {
        // this is to avoid memory leaks (MN: Just copied it from Eric's example)
        const abortController = new AbortController();

        RefreshInstagramToken();

        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort(); 
        };
    }, []);

    const RefreshInstagramToken = () => {
        ReturnInstagramToken(teamUnbreakableDB, setInstagramToken);
    }

    return (
    <React.Fragment>
        <StyledContainer fluid={true}>
        <BlueDivider/>
            <Row>
                <Col lg="4">
                    <Title>ABOUT US</Title>
                    <AboutContainer>
                        <Row>
                            <Col xs="3">
                                <LogoIcon src={Logo} alt="Unbreakable" />
                            </Col>
                            <Col xs="9">
                                <Paragraph>
                                    Team Unbreakable is a free mental health program for youth that 
                                    helps build life skills through physical activity and mindful self managing strategies.
                                </Paragraph>
                                <Paragraph>
                                    Ways to support Team Unbreakable:
                                </Paragraph>
                                <ul>
                                    {supportItems.length > 0 && supportItems.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                {item.url !== "" ? <StyledA href={item.url}><StyledP>{item.text}</StyledP></StyledA> : <StyledP>{item.text}</StyledP>}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Col>
                        </Row>
                    </AboutContainer>
                </Col>
                <Col lg="4">
                    <Title>INSTAGRAM</Title>
                    <InstagramFeedContainer token={token} limit={instagramLimit} />
                </Col>
                <Col lg="4">
                    <FullHeightDiv>
                        {/*
                        <Title>TWITTER</Title>
                        <MarginRow> 
                        <a class="twitter-timeline" href="https://twitter.com/teamunbreakable?ref_src=twsrc%5Etfw">Tweets by teamunbreakable</a> 
                        <Timeline
                            dataSource={{
                                sourceType: 'profile',
                                screenName: 'teamunbreakable'
                            }}
                            options={{
                                height: '400'
                            }}
                        />
                        */}
                        {/*
                        <TwitterTimelineEmbed  
                            onLoad={function noRefCheck(){}}
                            noFooter noHeader 
                            sourceType="profile" 
                            screenName="teamunbreakable" 
                            options={{
                                height: 300}}
                        />
                        </MarginRow>
                        */}
                    </FullHeightDiv>
                </Col>
                <hr/>
                <Col>
                    <Row>
                        <Col xs="auto">
                        <Title style={{marginBottom: "0px"}}>LINKS</Title>
                        </Col>
                        <Col xs="auto">
                        <LinkP href="/Contact">Contact Us</LinkP>
                        </Col>
                        <Col xs="auto">
                        <LinkP href="/Donate">Donate</LinkP>
                        </Col>
                        <Col xs="auto">
                        <LinkP href="/Links">Links</LinkP>
                        </Col>
                        <Col xs="auto">
                        <LinkP href="/register">Sign Up</LinkP>
                        </Col>
                        <Col xs="auto">
                        <LinkP href="/privacy">Privacy Policy</LinkP>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col><StyledCopyright>&#169; Copyright 2024. All Rights Reserved</StyledCopyright><br /></Col>
                <Col md="2"><StyledCopyright>Made by <LinkP href="https://deleptual.ca/">Deleptual</LinkP></StyledCopyright></Col>
            </Row>
        </StyledContainer>
        </React.Fragment>
    );
}

export default Footer;

const StyledA = styled.a`
    color: black;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    &:hover {
        text-decoration: underline;
    }
`

const StyledP = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    text-align: left;
`

const MarginRow = styled(Row)`
    margin-bottom: 8px;
`

const BlueDivider = styled.hr`
    color: ${Colors.primaryblue};
    height: 3px;
    opacity: 1;
    margin-bottom: 25px;
`

const LinkP = styled.a`
    color: ${Colors.primaryblue};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

const FullHeightDiv = styled.div`
    height: 100%;
`

const AboutContainer = styled(Container)`
    padding-left: 0px;
`

const Title = styled.h5`
    text-align: left;
    font-weight: bold;
`

const Paragraph = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    text-align: left;
`

const StyledCopyright = styled.p`
    text-align: left;
    color: #999999;
`

const LogoIcon = styled.img`
    width: 50px;
    display: block;
    margin-right: auto;
    vertical-align: middle;
`

const StyledContainer = styled(Container)`
    background-color: white;
    text-align: center;
    bottom: 0px;
    z-index: 10000;
    width: 100%;
    margin-bottom: -17px;
    padding-top: 25px;
    padding-left: 7%;
    padding-right: 7%;
`