import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import { Colors } from "../../utils/constants";
import { Copy } from "./physical-module-copy";
import { listenToDocument } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { ColoredH2, StyledHR, StyledP, CenterTitleFlexCol, WhiteH2, ColoredUL, BoldP } from "../../styles/styledComponents";
import StarsImage from '../../images/stars.png';
import CrampsImage from '../../images/cramps.png';
import InjuryImage from '../../images/injury.png';

export const PhysicalModuleSetbacks = ( props ) => {

    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    return(
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module Setbacks"].Title[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <ColoredH2>{Copy["Module Setbacks"].BoldTitle1[props.user.language]}</ColoredH2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <StyledHR></StyledHR>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <StyledP>{Copy["Module Setbacks"].Paragraph2[props.user.language]}</StyledP>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <StyledP>{Copy["Module Setbacks"].Paragraph3[props.user.language]}</StyledP>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <StyledP>{Copy["Module Setbacks"].Paragraph4[props.user.language]}</StyledP>
                            </Col>
                        </Row>
                    </Col>
                    <CenterFlexVerticalCol lg="4">
                            <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Setbacks"].BoldTitle2[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Setbacks"].Paragraph5[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Setbacks"].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Setbacks"].List1Item2[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <StyledHR />
                <Row>
                    <CenterFlexVerticalCol>
                        <PhysicalActivityIMG src={CrampsImage}></PhysicalActivityIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <StyledHR />
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Setbacks"].BoldTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Setbacks"].Paragraph6[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Setbacks"].List2Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Setbacks"].List2Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Setbacks"].List2Item3[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Setbacks"].BoldTitle4[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Setbacks"].Paragraph7[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Setbacks"].Paragraph8[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Setbacks"].List3Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Setbacks"].List3Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Setbacks"].List3Item3[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Setbacks"].Paragraph9[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Setbacks"].Paragraph10[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </Container>
                <br />
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module Setbacks"].SubTitle1[props.user.language]}</ColoredH2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledHR></StyledHR>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module Setbacks"].Paragraph11[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module Setbacks"].Paragraph12[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module Setbacks"].List4Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List4Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List4Item3[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List4Item4[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <CenterFlexVerticalCol lg="4">
                            <InjuryIMG src={InjuryImage}></InjuryIMG>
                    </CenterFlexVerticalCol>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module Setbacks"].SubTitle2[props.user.language]}</ColoredH2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledHR></StyledHR>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BoldP>{Copy["Module Setbacks"].BoldTitle5[props.user.language]}</BoldP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module Setbacks"].List5Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List5Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List5Item3[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List5Item4[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List5Item5[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List5Item6[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BoldP>{Copy["Module Setbacks"].BoldTitle6[props.user.language]}</BoldP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module Setbacks"].List6Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List6Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List6Item3[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List6Item4[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BoldP>{Copy["Module Setbacks"].BoldTitle7[props.user.language]}</BoldP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module Setbacks"].List7Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List7Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List7Item3[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List7Item4[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List7Item5[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BoldP>{Copy["Module Setbacks"].BoldTitle8[props.user.language]}</BoldP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module Setbacks"].List8Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List8Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List8Item3[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BoldP>{Copy["Module Setbacks"].BoldTitle9[props.user.language]}</BoldP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module Setbacks"].List9Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List9Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List9Item3[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List9Item4[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List9Item5[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List9Item6[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BoldP>{Copy["Module Setbacks"].BoldTitle10[props.user.language]}</BoldP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module Setbacks"].List10Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List10Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List10Item3[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                            </Container>
                            </Col>
                            <Col>
                            <Container>
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module Setbacks"].SubTitle3[props.user.language]}</ColoredH2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledHR></StyledHR>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BoldP>{Copy["Module Setbacks"].BoldTitle11[props.user.language]}</BoldP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module Setbacks"].List11Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List11Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List11Item3[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BoldP>{Copy["Module Setbacks"].BoldTitle12[props.user.language]}</BoldP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module Setbacks"].List12Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List12Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List12Item3[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List12Item4[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List12Item5[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List12Item6[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BoldP>{Copy["Module Setbacks"].BoldTitle13[props.user.language]}</BoldP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module Setbacks"].List13Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List13Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List13Item3[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List13Item4[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BoldP>{Copy["Module Setbacks"].BoldTitle14[props.user.language]}</BoldP>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module Setbacks"].List14Item1[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List14Item2[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List14Item3[props.user.language]}</StyledP></li>
                                        <li><StyledP>{Copy["Module Setbacks"].List14Item4[props.user.language]}</StyledP></li>
                                    </ColoredUL>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Container> 
                <StyledWhiteRow>
                    <Col>
                        <StyledP>{Copy["Module Setbacks"].Paragraph13[props.user.language] + " "}</StyledP>
                        <StyledP>{Copy["Module Setbacks"].Paragraph14[props.user.language]}</StyledP>
                    </Col>
                </StyledWhiteRow>
            </Container>
        </React.Fragment>
    );
}

export default PhysicalModuleSetbacks;

const PhysicalActivityIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const InjuryIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    border-style: solid;
    border-color: ${Colors.primaryblue};
    border-width: 1px;
`;

const StarsIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledWhiteRow = styled(Row)`
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
    background-color: white;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
`;