import React, { useState, useEffect } from "react";
import { Container, Row, Col, Input, Button } from "reactstrap";
import styled from "styled-components";
import { Colors } from "../../utils/constants";
import { Copy } from "./module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import PhysicalActivityImage from '../../images/physical-activity.png';
import FlowerImage from '../../images/Flower.png';
import StarsImage from '../../images/stars.png';
import SelfReflectionImage from '../../images/self-reflection.png';
import HomeworkImage from "../../images/homework.png";
import { ColoredH2, StyledHR, ColoredOL, StyledP, CenterTitleFlexCol, WhiteH2, StyledBoldP, ColoredUL, LargeInput, FlexCol } from "../../styles/styledComponents";
import { Event } from "../../utils/analytics";

export const Module1 = (props) => {

    const [user, setUser] = useState();
    const [input1, setInput1] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input1 != undefined) ? props.user.moduleData.module1.input1.input1 : "");
    const [input5, setInput5] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input5 != undefined) ? props.user.moduleData.module1.input5.input5 : "");
    const [input6, setInput6] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input6 != undefined) ? props.user.moduleData.module1.input6.input6 : "");
    const [input7, setInput7] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input7 != undefined) ? props.user.moduleData.module1.input7.input7 : "");
    const [input8, setInput8] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input8 != undefined) ? props.user.moduleData.module1.input8.input8 : "");
    const [input9, setInput9] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input9 != undefined) ? props.user.moduleData.module1.input9.input9 : "");
    const [input10, setInput10] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input10 != undefined) ? props.user.moduleData.module1.input10.input10 : "");
    const [input11, setInput11] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input11 != undefined) ? props.user.moduleData.module1.input11.input11 : "");
    const [input12, setInput12] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input12 != undefined) ? props.user.moduleData.module1.input12.input12 : "");
    const [input13, setInput13] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input13 != undefined) ? props.user.moduleData.module1.input13.input13 : "");
    const [input14, setInput14] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input14 != undefined) ? props.user.moduleData.module1.input14.input14 : "");
    const [input15, setInput15] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input15 != undefined) ? props.user.moduleData.module1.input15.input15 : "");
    const [input16, setInput16] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input16 != undefined) ? props.user.moduleData.module1.input16.input16 : "");
    const [input17, setInput17] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input17 != undefined) ? props.user.moduleData.module1.input17.input17 : "");
    const [input18, setInput18] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.input18 != undefined) ? props.user.moduleData.module1.input18.input18 : "");

    const [radioGroup1, setRadioGroup1] = useState((props.user.moduleData !== undefined && props.user.moduleData.module1 !== undefined && props.user.moduleData.module1.radioGroup1 != undefined) ? props.user.moduleData.module1.radioGroup1 : [false, false, false]);

    const toggleRadioGroup = (array, item) => {
        let temp = array[item];
        for (let ctr = 0; ctr < array.length; ctr++){
            array[ctr] = false;
        }
        array[item] = !temp;
        setRadioGroup1([...array]);
        var data = user;
        data.moduleData.module1.radioGroup1 = array;
        updateUser(teamUnbreakableDB, user.id, data);
    }

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const UpdateInput = (inputKey, inputValue) => {
        var data = user;
        data.moduleData.module1[inputKey] = inputValue;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    const completeModule = () => {
        Event("Module Completed", "Mental Module", "Module 1");
        var data = user;
        data.modules.module1 = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container fluid={true}>
                {/*
                <br />
                <Row>
                    <CenterFlexCol>
                        <ColoredH2>{Copy["Module 1"].Title[props.user.language]}</ColoredH2>
                    </CenterFlexCol>
                </Row>
                <br />
                */}
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module 1"].SubTitle1[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 1"].SubTitle2[props.user.language]}</ColoredH2>
                        <ColoredHR />
                        <StyledP>{Copy["Module 1"].List1Title[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <ColoredOL>
                            <DetailedListItem><StyledBoldP>{Copy["Module 1"].List1Item1Title[props.user.language]}</StyledBoldP><StyledP>{Copy["Module 1"].List1Item1[props.user.language]}</StyledP></DetailedListItem>
                            <DetailedListItem><StyledBoldP>{Copy["Module 1"].List1Item2Title[props.user.language]}</StyledBoldP><StyledP>{Copy["Module 1"].List1Item2[props.user.language]}</StyledP></DetailedListItem>
                            <DetailedListItem><StyledBoldP>{Copy["Module 1"].List1Item3Title[props.user.language]}</StyledBoldP><StyledP>{Copy["Module 1"].List1Item3[props.user.language]}</StyledP></DetailedListItem>
                            <DetailedListItem><StyledBoldP>{Copy["Module 1"].List1Item4Title[props.user.language]}</StyledBoldP><StyledP>{Copy["Module 1"].List1Item4[props.user.language]}</StyledP></DetailedListItem>
                            <DetailedListItem><StyledBoldP>{Copy["Module 1"].List1Item5Title[props.user.language]}</StyledBoldP><StyledP>{Copy["Module 1"].List1Item5[props.user.language]}</StyledP></DetailedListItem>
                            <DetailedListItem><StyledBoldP>{Copy["Module 1"].List1Item6Title[props.user.language]}</StyledBoldP><StyledP>{Copy["Module 1"].List1Item6[props.user.language]}</StyledP></DetailedListItem>
                        </ColoredOL>
                    </Col>
                    <Col lg="6">
                        <StarsIMG src={FlowerImage}></StarsIMG>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <CenterFlexVerticalCol>
                        <PhysicalActivityIMG src={PhysicalActivityImage}></PhysicalActivityIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
            </Container>
            <Container>
                <br />
                <Row>
                    <CenterFlexVerticalCol lg="6">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                    <CenterFlexVerticalCol lg="6">
                        <StyledP>{Copy["Module 1"].Input1Header[props.user.language]}</StyledP>
                        <LargeInput value={input1} onChange={e => setInput1(e.target.value)} onBlur={() => {UpdateInput("input1", {input1})}}></LargeInput>
                        <StyledP>{Copy["Module 1"].List2Title[props.user.language]}</StyledP>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 1"].List2Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].List2Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].List2Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].List2Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].List2Item5[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].List2Item6[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </CenterFlexVerticalCol>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 1"].SubTitle3[props.user.language]}</ColoredH2>
                        <ColoredHR />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg="6">
                        <Container>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 1"].Input2Header[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                            <Row>
                                <FlexCol>
                                    <SmallInput key={5} type="checkbox" checked={radioGroup1[0]} onChange={e => toggleRadioGroup(radioGroup1, 0)}></SmallInput>
                                    <StyledP>{Copy["Module 1"].Input2Tooltip1[props.user.language]}</StyledP>
                                </FlexCol>
                            </Row>
                            <Row>
                                <FlexCol>
                                    <SmallInput key={6} type="checkbox" checked={radioGroup1[1]} onChange={e => toggleRadioGroup(radioGroup1, 1)}></SmallInput>
                                    <StyledP>{Copy["Module 1"].Input2Tooltip2[props.user.language]}</StyledP>
                                </FlexCol>
                            </Row>
                            <Row>
                                <FlexCol>
                                    <SmallInput key={7} type="checkbox" checked={radioGroup1[2]} onChange={e => toggleRadioGroup(radioGroup1, 2)}></SmallInput>
                                    <StyledP>{Copy["Module 1"].Input2Tooltip3[props.user.language]}</StyledP>
                                </FlexCol>
                            </Row>
                            <Row>
                                <CenterFlexCol>
                                    <SelfReflectionIMG src={SelfReflectionImage}></SelfReflectionIMG>
                                </CenterFlexCol>
                            </Row>
                        </Container>
                    </Col>
                    <Col lg="6">
                        <BorderContainer>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 1"].Input3Header[props.user.language]}</StyledP>
                                    <LargeInput value={input5} onChange={e => setInput5(e.target.value)} onBlur={() => {UpdateInput("input5", {input5})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 1"].Input4Header[props.user.language]}</StyledP>
                                    <LargeInput value={input6} onChange={e => setInput6(e.target.value)} onBlur={() => {UpdateInput("input6", {input6})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 1"].Input5Header[props.user.language]}</StyledP>
                                    <LargeInput value={input7} onChange={e => setInput7(e.target.value)} onBlur={() => {UpdateInput("input7", {input7})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 1"].Input6Header[props.user.language]}</StyledP>
                                    <LargeInput value={input8} onChange={e => setInput8(e.target.value)} onBlur={() => {UpdateInput("input8", {input8})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 1"].Input7Header[props.user.language]}</StyledP>
                                    <LargeInput value={input9} onChange={e => setInput9(e.target.value)} onBlur={() => {UpdateInput("input9", {input9})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 1"].Input8Header[props.user.language]}</StyledP>
                                    <LargeInput value={input10} onChange={e => setInput10(e.target.value)} onBlur={() => {UpdateInput("input10", {input10})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 1"].Input9Header[props.user.language]}</StyledP>
                                    <LargeInput value={input11} onChange={e => setInput11(e.target.value)} onBlur={() => {UpdateInput("input11", {input11})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledP>{Copy["Module 1"].Input10Header[props.user.language]}</StyledP>
                                    <LargeInput value={input12} onChange={e => setInput12(e.target.value)} onBlur={() => {UpdateInput("input12", {input12})}}></LargeInput>
                                </Col>
                            </Row>
                        </BorderContainer>
                    </Col>
                </Row>              
                <br></br>
            </Container>     
            <ColoredContainer>
                <Row>
                    <CenterFlexCol>
                        <p>{Copy["Module 1"].SubTitle4[props.user.language]}</p>
                    </CenterFlexCol>
                </Row>
                <Row>
                    <CenterFlexCol>
                        <p>{Copy["Module 1"].SubTitle5[props.user.language]}</p>
                    </CenterFlexCol>
                </Row>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <FlexCol lg="2" xs="5">
                                    <StyledWhiteP>{Copy["Module 1"].Input11Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol lg="2" xs="5">
                                    1<SliderInput type="range" min="1" max="10" value={input13} onChange={e => setInput13(e.target.value)} onBlur={() => {UpdateInput("input13", {input13})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol xs="2">
                                    <ResultP>{input13}</ResultP>
                                </FlexCol>
                                <FlexCol lg="2" xs="5">
                                    <StyledWhiteP>{Copy["Module 1"].Input12Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol lg="2" xs="5">
                                    1<SliderInput type="range" min="1" max="10" value={input14} onChange={e => setInput14(e.target.value)} onBlur={() => {UpdateInput("input14", {input14})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol xs="2">
                                    <ResultP>{input14}</ResultP>
                                </FlexCol>
                            </Row>
                            <Row>
                                <FlexCol lg="2" xs="5">
                                    <StyledWhiteP>{Copy["Module 1"].Input13Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol lg="2" xs="5">
                                    1<SliderInput type="range" min="1" max="10" value={input15} onChange={e => setInput15(e.target.value)} onBlur={() => {UpdateInput("input15", {input15})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol xs="2">
                                    <ResultP>{input15 ? input15 : " "}</ResultP>
                                </FlexCol>
                                <FlexCol lg="2" xs="5">
                                    <StyledWhiteP>{Copy["Module 1"].Input14Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol lg="2" xs="5">
                                    1<SliderInput type="range" min="1" max="10" value={input16} onChange={e => setInput16(e.target.value)} onBlur={() => {UpdateInput("input16", {input16})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol xs="2">
                                    <ResultP>{input16}</ResultP>
                                </FlexCol>
                            </Row>
                            <Row>
                                <FlexCol lg="2" xs="5">
                                    <StyledWhiteP>{Copy["Module 1"].Input15Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol lg="2" xs="5">
                                    1<SliderInput type="range" min="1" max="10" value={input17} onChange={e => setInput17(e.target.value)} onBlur={() => {UpdateInput("input17", {input17})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol xs="2">
                                    <ResultP>{input17}</ResultP>
                                </FlexCol>
                                <FlexCol lg="2" xs="5">
                                    <StyledWhiteP>{Copy["Module 1"].Input16Header[props.user.language]}</StyledWhiteP>
                                </FlexCol>
                                <FlexCol lg="2" xs="5">
                                    1<SliderInput type="range" min="1" max="10" value={input18} onChange={e => setInput18(e.target.value)} onBlur={() => {UpdateInput("input18", {input18})}}></SliderInput>10
                                </FlexCol>
                                <FlexCol xs="2">
                                    <ResultP>{input18}</ResultP>
                                </FlexCol>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </ColoredContainer>
            <Container>
                <Row>
                    <Col>
                        <br></br>
                        <ColoredH2>{Copy["Module 1"].SubTitle6[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <BorderCol>
                        <StyledP>{Copy["Module 1"].Paragraph1[props.user.language]}{", "}<StyledBoldP>{props.user.firstName}</StyledBoldP>{", "}
                        {Copy["Module 1"].Paragraph2[props.user.language]}{" "}
                        {Copy["Module 1"].Paragraph3[props.user.language]}{" "}</StyledP>
                        <br></br>
                        <br></br>
                        <StyledP>{Copy["Module 1"].List3Header[props.user.language]}</StyledP>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 1"].List3Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].List3Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].List3Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].List3Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].List3Item5[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].List3Item6[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].List3Item7[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </BorderCol>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <br></br>
                        <ColoredH2>{Copy["Module 1"].SubTitle7[props.user.language]}</ColoredH2>
                        <ColoredHR />
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 1"].Paragraph4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 1"].Paragraph5[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                    <Col md="6" xs="12">
                        <HomeworkIMG src={HomeworkImage}></HomeworkIMG>
                    </Col>
                </Row>
            </Container>
            {user !== undefined && <Container>
                <Row>
                    <CenterFlexCol>
                        {user.modules.module1 ? <CompletedDiv><CompletedP>Module Completed</CompletedP></CompletedDiv> : <StyledButton onClick={() => completeModule()} color="primary">Complete Module</StyledButton>}
                    </CenterFlexCol>
                </Row>
            </Container>}
        </React.Fragment>
    );
}

export default Module1;

const HomeworkIMG = styled.img`
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
`;

const BorderContainer = styled(Container)`
    background-color: F8F8F8;
    color: black;
    border-color: ${Colors.primaryblue};
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    padding-bottom: 10px;
    padding-top: 5px;
`;

const ColoredContainer = styled(Container)`
    background-color: ${Colors.primaryblue};
    color: white;
    border-radius: 10px;
    padding-bottom: 10px;
    padding-top: 5px;
`;

const DetailedListItem = styled.li`
    margin-bottom: 5px;
`;

const PhysicalActivityIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const SelfReflectionIMG = styled.img`
    max-width: 100%;
    max-height: 450px;
    object-fit: contain;
`;

const StarsIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;

const ResultP = styled.p`
    font-weight: bold;
    background-color: white;
    color: black;
    border-style: solid;
    border-color: ${Colors.primaryblue};
    border-width: 1px;
    border-radius: 5px;
    margin: 0px;
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 2px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
`;

const SliderInput = styled(Input)`
    color: ${Colors.primaryblue};
    max-width: 80%;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const BorderCol = styled(Col)`
    border-radius: 15px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
    background-color: white;
    padding: 15px;
`;

const SmallInput = styled(Input)`
    max-width: 50px;
    padding: 5px;
    border-radius: 15px;
    border-color: ${Colors.primaryblue};
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
`;

const CompletedDiv = styled.div`
    background-color: ${Colors.primaryblue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 20px;
`;

const StyledWhiteP = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
    font-weight: normal;
    display: inline;
`;

const CompletedP = styled.p`
    color: white;
    margin: 0px;
    font-weight: bold;
`;

const StyledButton = styled(Button)`
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: ${Colors.primaryblue};
    border-color: ${Colors.secondarygray};
`;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const ColoredHR = styled.hr`
    background-color: ${Colors.primaryblue};
    margin-top: 0px;
    margin-bottom: 6px;
`;
