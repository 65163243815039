import React, { useState, useEffect } from "react";
import { Container, Row, Col, Input, Table } from "reactstrap";
import styled from "styled-components";
import { Colors } from "../../utils/constants";
import { Copy } from "./module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import GoalsMountainImage from "../../images/goals-mountain.png";
import GoalsImage from "../../images/growing-ideas.png";
import HomeworkImage from "../../images/homework.png";
import { 
    ColoredH2, 
    StyledHR, 
    ColoredOL, 
    StyledP, 
    CenterTitleFlexCol, 
    WhiteH2, 
    StyledBoldP, 
    ColoredUL, 
    CenterFlexCol, 
    StyledButton, 
    CompletedP, 
    CompletedDiv, 
} from "../../styles/styledComponents";
import { Event } from "../../utils/analytics";

export const Module2 = ( props ) => {

    const [user, setUser] = useState();

    const [input1, setInput1] = useState((props.user.moduleData !== undefined && props.user.moduleData.module2 !== undefined && props.user.moduleData.module2.input1 != undefined) ? props.user.moduleData.module2.input1.input1 : "");
    const [input2, setInput2] = useState((props.user.moduleData !== undefined && props.user.moduleData.module2 !== undefined && props.user.moduleData.module2.input2 != undefined) ? props.user.moduleData.module2.input2.input2 : "");
    const [input3, setInput3] = useState((props.user.moduleData !== undefined && props.user.moduleData.module2 !== undefined && props.user.moduleData.module2.input3 != undefined) ? props.user.moduleData.module2.input3.input3 : "");

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const UpdateInput = (inputKey, inputValue) => {
        var data = user;
        data.moduleData.module2[inputKey] = inputValue;

        updateUser(teamUnbreakableDB, user.id, data);
    };

    const completeModule = () => {
        Event("Module Completed", "Mental Module", "Module 2");

        var data = user;
        data.modules.module2 = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                {/*}
                <br />
                <Row>
                    <CenterFlexCol>
                        <ColoredH2>{Copy["Module 2"].Title[props.user.language]}</ColoredH2>
                    </CenterFlexCol>
                </Row>
                <br />
                */}
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module 2"].SubTitle1[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 2"].SubTitle2[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module 2"].List1Title[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col lg="2" xs="12">
                        <GoalsMountainIMG src={GoalsMountainImage}></GoalsMountainIMG>
                    </Col>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 2"].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].List1Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].List1Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].List1Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].List1Item5[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].List1Item6[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 2"].SubTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col lg="2" xs="12">
                        <h5><b>{Copy["Module 2"].TableR1C1[props.user.language]}</b></h5>
                    </Col>
                    <Col>
                        <p>{Copy["Module 2"].TableR1C2[props.user.language]}</p>
                    </Col>
                    </Row>
                <Row>
                    <Col lg="2" xs="12">
                        <h5><b>{Copy["Module 2"].TableR2C1[props.user.language]}</b></h5>
                    </Col>
                    <Col>
                        <p>{Copy["Module 2"].TableR2C2[props.user.language]}</p>
                    </Col>
                    </Row>
                <Row>
                    <Col lg="2" xs="12">
                        <h5><b>{Copy["Module 2"].TableR3C1[props.user.language]}</b></h5>
                    </Col>
                    <Col>
                        <p>{Copy["Module 2"].TableR3C2[props.user.language]}</p>
                    </Col>
                </Row>
                <Row>
                    <Col lg="2" xs="12">
                        <h5><b>{Copy["Module 2"].TableR4C1[props.user.language]}</b></h5>
                    </Col>
                    <Col>
                        <p>{Copy["Module 2"].TableR4C2[props.user.language]}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 2"].SubTitle4[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module 2"].Paragraph1[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <TableCol>
                            <Container>
                                <Row>
                                    <Col lg="6" xs="12">
                                        <h5>{Copy["Module 2"].Table2R1C1[props.user.language]}</h5>
                                    </Col>
                                    <Col lg="6" xs="12">
                                        <p>{Copy["Module 2"].Table2R1C2[props.user.language]}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" xs="12">
                                        <h5>{Copy["Module 2"].Table2R2C1[props.user.language]}</h5>
                                    </Col>
                                    <Col lg="6" xs="12">
                                        <p>{Copy["Module 2"].Table2R2C2[props.user.language]}</p>
                                    </Col>
                                </Row>
                            </Container>
                    </TableCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 2"].List2Title[props.user.language]}</StyledP>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 2"].List2Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].List2Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].List2Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].List2Item4[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <CenterFlexVerticalCol>
                        <PhysicalActivityIMG src={GoalsImage}></PhysicalActivityIMG>
                    </CenterFlexVerticalCol>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 2"].SubTitle5[props.user.language]}</ColoredH2>
                    </Col>  
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy["Module 2"].Paragraph2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].Paragraph3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].Paragraph4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].Paragraph5[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].Paragraph6[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 2"].SubTitle6[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 2"].Input1[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <GoalInput value={input1} onChange={e => setInput1(e.target.value)} onBlur={() => {UpdateInput("input1", {input1})}}></GoalInput>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <GoalInput value={input2} onChange={e => setInput2(e.target.value)} onBlur={() => {UpdateInput("input2", {input2})}}></GoalInput>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <GoalInput value={input3} onChange={e => setInput3(e.target.value)} onBlur={() => {UpdateInput("input3", {input3})}}></GoalInput>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg="6" xs="12">
                        <StyledP>{Copy["Module 2"].List3Title[props.user.language]}</StyledP>
                        <WhiteTable>
                            <tr>
                                <BoldTD><StyledBoldP>{Copy["Module 2"].List3Item1[props.user.language]}</StyledBoldP></BoldTD>
                            </tr>
                            <tr>
                                <BoldTD><StyledBoldP>{Copy["Module 2"].List3Item2[props.user.language]}</StyledBoldP></BoldTD>
                            </tr>
                            <tr>
                                <BoldTD><StyledBoldP>{Copy["Module 2"].List3Item3[props.user.language]}</StyledBoldP></BoldTD>
                            </tr>
                            <tr>
                                <BoldTD><StyledBoldP>{Copy["Module 2"].List3Item4[props.user.language]}</StyledBoldP></BoldTD>
                            </tr>
                        </WhiteTable>
                    </Col>
                    <Col lg="6" xs="12">
                        <StyledP>{Copy["Module 2"].List4Title[props.user.language]}</StyledP>
                        <WhiteTable>
                            <tr>
                                <td><StyledP>{Copy["Module 2"].List4Item1[props.user.language]}</StyledP></td>
                            </tr>
                            <tr>
                                <td><StyledP>{Copy["Module 2"].List4Item2[props.user.language]}</StyledP></td>
                            </tr>
                            <tr>
                                <td><StyledP>{Copy["Module 2"].List4Item3[props.user.language]}</StyledP></td>
                            </tr>
                            <tr>
                                <td><StyledP>{Copy["Module 2"].List4Item4[props.user.language]}</StyledP></td>
                            </tr>
                        </WhiteTable>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 2"].Paragraph7[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col xs="12" md="6">
                        <HomeworkIMG src={HomeworkImage}></HomeworkIMG>
                    </Col>
                    <Col>
                        <ColoredH2>{Copy["Module 2"].SubTitle7[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 2"].Paragraph8[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 2"].Paragraph9[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            {user !== undefined && <Container>
                <Row>
                    <CenterFlexCol>
                        {user.modules.module2 ? <CompletedDiv><CompletedP>Module Completed</CompletedP></CompletedDiv> : <StyledButton onClick={() => completeModule()} color="primary">Complete Module</StyledButton>}
                    </CenterFlexCol>
                </Row>
            </Container>}
        </React.Fragment>
    );
}

export default Module2;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const HomeworkIMG = styled.img`
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
`;

const PhysicalActivityIMG = styled.img`
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
`;

const TableCol = styled(Col)`
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
    border-radius: 10px;
    padding: 15px;
    padding-bottom: 0px;
    background-color: white;
    width: 90%;
    margin-left: 30px;
    margin-right: 30px;
`;

const WhiteTable = styled(Table)`
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
`;

const GoalsMountainIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;

const GoalInput = styled(Input)`
    padding: 5px;
    border-radius: 15px;
    border-color: ${Colors.primaryblue};
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
`;

const BoldTD = styled.td`
    font-weight: bold;
`;