import React from "react";
import styled from 'styled-components';

export const PictureBackground = (props) => {

    return(
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <PictureBackgroundDiv url={props.url}>
                    </PictureBackgroundDiv>
                </ParentDiv>
            </HeightDiv>
        </React.Fragment>
    );
}

export default PictureBackground;

const HeightDiv = styled.div`
    height: 100vh;
    position: fixed;
    z-index: -1;
    width: 100%;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const PictureBackgroundDiv = styled.div`
    width: 100%;
    height: 100%;
    z-index: -1000;
    background-image: url(${props => props.url});
    background-repeat: no-repeat;
    background-size: cover;
`;