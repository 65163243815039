import { updateUser } from "./firestore-functions";
import { teamUnbreakableDB, generateGUID } from "./firestore";
import DistanceRunChallengeImage from '../images/challenge-distance-run.png';
import TimedRunChallengeImage from '../images/challenge-timed-run.png';
import TotalDistanceRunChallengeImage from '../images/challenge-total-distance.png';
import LoginChallengeImage from '../images/challenge-login.png';

export const points = {
    1: 5,
    2: 15,
    3: 35,
    4: 75,
    5: 155,
    6: 200,
    7: 300,
    8: 400,
    9: 500,
}

export const getCurrentChallenge = (user) => {
    if (user.data === undefined){
        return "User data was undefined.";
    };
    if (user.data.currentChallenge === undefined){
        return "User data current challenge was undefined.";
    };
    if (user.data.currentChallenge.challengeName === undefined){
        return "User data current challenge name was undefined.";
    };
    if (user.data.currentChallenge.challengeLevel === undefined){
        return "User data current challenge level  was undefined.";
    };
    return challenges[user.data.currentChallenge.challengeName];
}

export const getChallenge = (challenge) => {
    if (challenges.hasOwnProperty(challenge)){
        return challenges[challenge];
    }
    else{
        return challenge + " was not found in the list of challenges.";
    }
};

export const getAllChallenges = (user) => {
    return challenges;
}

export const loggedInToday = (user) => {
    user.data.loggedInToday = true;
    user.data.daysLoggedTotal += 1;
    user.data.daysLoggedConsecutively += 1;
    user.data.challenges.challengeDaysInARow.current += 1;
    
    updateUser(teamUnbreakableDB, user.id, user);
}

export const newActivityTracked = (activity, user) => {
    //Basically iterate through each challenge and see if we made progress.
    let u = user;
    let guid = generateGUID();
    u.data.activities[guid] = activity;
    if (u.data.challenges.challengeTotalDistanceRun === undefined || u.data.challenges.challengeTotalDistanceRun.current === undefined){
        u.data.challenges.challengeTotalDistanceRun = {
            current: 0,
            level: 1
        };
    }
    u.data.challenges.challengeTotalDistanceRun.current += parseFloat(activity.distance);
    //NOTHING for Daily Login
    //Check Distance for Daily Run
    if (u.data.challenges.challengeDailyDistanceRun === undefined || u.data.challenges.challengeDailyDistanceRun.current === undefined){
        u.data.challenges.challengeDailyDistanceRun = {
            current: 0,
            level: 1
        };
    }
    if (parseFloat(activity.distance) >= u.data.challenges.challengeDailyDistanceRun.current){
        u.data.challenges.challengeDailyDistanceRun.current = parseFloat(activity.distance);
    }
    //Check Time for Daily Run
    if (u.data.challenges.challengeDailyTimeRun === undefined || u.data.challenges.challengeDailyTimeRun.current === undefined){
        u.data.challenges.challengeDailyTimeRun = {
            current: 0,
            level: 1 
        }
    }
    if (parseFloat(activity.time) >= u.data.challenges.challengeDailyTimeRun.current){
        u.data.challenges.challengeDailyTimeRun.current = parseFloat(activity.time);
    }

    updateUser(teamUnbreakableDB, user.id, u);
}

/*
    CHALLENGE DAYS IN A ROW
*/

const challengeDaysInARowLevels = {
    1: 2,
    2: 3,
    3: 5,
    4: 7,
    5: 14,
    6: 20,
    7: 30,
    8: 45,
    9: 60,
}

const challengeDaysInARowToString = (user) => {
    return "Consecutive Logins";
}

const challengeDaysInARowGetLevel = (user) => {
    if (user.data.challenges.challengeDaysInARow === undefined || user.data.challenges.challengeDaysInARow.level === undefined){
        user.data.challenges.challengeDaysInARow = {
            current: 0,
            level: 1
        }
        updateUser(teamUnbreakableDB, user.id, user);
        return 1;
    }
    return user.data.challenges.challengeDaysInARow.level;
}

const challengeDaysInARowGetDescription = () => {
    return "Challenge yourself to run consistently by tracking runs in consecutive days!"
}

const challengeDaysInARowLevelUp = (user) => {
    let obj = {
        current: 0,
        level: user.data.challenges.challengeDaysInARow.level + 1
    }

    var data = user;
    data.challenges.challengeDaysInARow = obj;
    data.points = user.data.points + points[user.data.challenges.challengeDaysInARow.level];

    updateUser(teamUnbreakableDB, user.id, data);
}

const challengeDaysInARowGetCurrent = (user) => {
    if (user.data.challenges.challengeDaysInARow === undefined || user.data.challenges.challengeDaysInARow.current === undefined){
        user.data.challenges.challengeDaysInARow = {
            current: 0,
            level: 1
        }
        updateUser(teamUnbreakableDB, user.id, user);
        return 0;
    }
    return user.data.challenges.challengeDaysInARow.current;
}

/*
    CHALLENGE DISTANCE IN A SINGLE RUN
*/

const challengeDailyDistanceRunLevels = {
    1: 2,
    2: 3,
    3: 5,
    4: 7,
    5: 14,
    6: 20,
    7: 25,
    8: 30,
    9: 40
}

const challengeDailyDistanceRunToString = (user) => {
    return "Distance Run";  
}

const challengeDailyDistanceRunGetLevel = (user) => {
    if (user.data.challenges.challengeDailyDistanceRun === undefined || user.data.challenges.challengeDailyDistanceRun.level === undefined){
        user.data.challenges.challengeDailyDistanceRun = {
            current: 0,
            level: 1
        }
        updateUser(teamUnbreakableDB, user.id, user);
        return 1;
    }
    return user.data.challenges.challengeDailyDistanceRun.level;
}

const challengeDailyDistanceRunGetDescription = () => {
    return "Challenge yourself to run a little further than you did last time!"
}

const challengeDailyDistanceRunLevelUp = (user) => {
    let obj = {
        current: 0,
        level: user.data.challenges.challengeDailyDistanceRun.level + 1
    }

    var data = user;
    data.challenges.challengeDailyDistanceRun = obj;
    data.points = user.data.points + points[user.data.challenges.challengeDailyDistanceRun.level];

    updateUser(teamUnbreakableDB, user.id, data);
}

const challengeDailyDistanceRunGetCurrent = (user) => {
    if (user.data.challenges.challengeDailyDistanceRun === undefined || user.data.challenges.challengeDailyDistanceRun.current === undefined){
        user.data.challenges.challengeDailyDistanceRun = {
            current: 0,
            level: 1
        }

        updateUser(teamUnbreakableDB, user.id, user);
        return 0;
    }
    return user.data.challenges.challengeDailyDistanceRun.current;
}

/*
    CHALLENGE TIME IN A SINGLE RUN
*/

const challengeDailyTimeRunLevels = {
    1: 10,
    2: 15,
    3: 30,
    4: 45,
    5: 60,
    6: 75,
    8: 90,
    9: 120,
}

const challengeDailyTimeRunToString = (user) => {
    return "Timed Run";
}

const challengeDailyTimeRunGetLevel = (user) => {
    if (user.data.challenges.challengeDailyTimeRun === undefined || user.data.challenges.challengeDailyTimeRun.level === undefined){
        user.data.challenges.challengeDailyTimeRun = {
            current: 0,
            level: 1
        }

        updateUser(teamUnbreakableDB, user.id, user);
        return 1;
    }
    return user.data.challenges.challengeDailyTimeRun.level;
}

const challengeDailyTimeRunGetDescription = () => {
    return "Challenge yourself to run a little longer each day!"
}

const challengeDailyTimeRunLevelUp = (user) => {
    let obj = {
        current: 0,
        level: user.data.challenges.challengeDailyTimeRun.level + 1
    }

    var data = user;
    data.challenges.challengeDailyTimeRun = obj;
    data.points = user.data.points + points[user.data.challenges.challengeDailyTimeRun.level];

    updateUser(teamUnbreakableDB, user.id, data);
}

const challengeDailyTimeRunGetCurrent = (user) => {
    if (user.data.challenges.challengeDailyTimeRun === undefined || user.data.challenges.challengeDailyTimeRun.current === undefined){
        user.data.challenges.challengeDailyTimeRun = {
            current: 0,
            level: 1
        }

        updateUser(teamUnbreakableDB, user.id, user);
        return 0;
    }
    return user.data.challenges.challengeDailyTimeRun.current;
}

/*
    CHALLENGE TOTAL DISTANCE IN A SINGLE RUN
*/

const challengeTotalDistanceRunLevels = {
    1: 10,
    2: 30,
    3: 75,
    4: 150,
    5: 250,
    6: 400,
    7: 650,
    8: 800,
    9: 1000,
}

const challengeTotalDistanceRunToString = (user) => {
    return "Total Distance";
}

const challengeTotalDistanceRunGetLevel = (user) => {
    if (user.data.challenges.challengeTotalDistanceRun === undefined || user.data.challenges.challengeTotalDistanceRun.level === undefined){
        user.data.challenges.challengeTotalDistanceRun = {
            current: 0,
            level: 1
        }

        updateUser(teamUnbreakableDB, user.id, user);
        return 1;
    }
    return user.data.challenges.challengeTotalDistanceRun.level;
}

const challengeTotalDistanceRunGetDescription = () => {
    return "Challenge yourself to run further each day and increase your total distance."
}

const challengeTotalDistanceRunLevelUp = (user) => {
    let obj = {
        current: user.data.challenges.challengeTotalDistanceRun.current,
        level: user.data.challenges.challengeTotalDistanceRun.level + 1
    }

    var data = user;
    data.challenges.challengeTotalDistanceRun = obj;
    data.points = user.data.points + points[user.data.challenges.challengeTotalDistanceRun.level];

    updateUser(teamUnbreakableDB, user.id, data);
}

const challengeTotalDistanceRunGetCurrent = (user) => {
    if (user.data.challenges.challengeTotalDistanceRun === undefined || user.data.challenges.challengeTotalDistanceRun.current === undefined){
        user.data.challenges.challengeTotalDistanceRun = {
            current: 0,
            level: 1
        }

        updateUser(teamUnbreakableDB, user.id, user);
        return 0;
    }
    return user.data.challenges.challengeTotalDistanceRun.current;
}

const challenges = {
    "challengeDaysInARow": {
        "id": "challengeDaysInARow",
        "toString": challengeDaysInARowToString,
        "getLevel": challengeDaysInARowGetLevel,
        "description": challengeDaysInARowGetDescription,
        "levelUp": challengeDaysInARowLevelUp,
        "getCurrent": challengeDaysInARowGetCurrent,
        "goals": challengeDaysInARowLevels,
        "unit": "days",
        "image": LoginChallengeImage
    },
    "challengeDailyDistanceRun": {
        "id": "challengeDailyDistanceRun",
        "toString": challengeDailyDistanceRunToString,
        "getLevel": challengeDailyDistanceRunGetLevel,
        "description": challengeDailyDistanceRunGetDescription,
        "levelUp": challengeDailyDistanceRunLevelUp,
        "getCurrent": challengeDailyDistanceRunGetCurrent,
        "goals": challengeDailyDistanceRunLevels,
        "unit": "km",
        "image": DistanceRunChallengeImage
    },
    "challengeDailyTimeRun": {
        "id": "challengeDailyTimeRun",
        "toString": challengeDailyTimeRunToString,
        "getLevel": challengeDailyTimeRunGetLevel,
        "description": challengeDailyTimeRunGetDescription,
        "levelUp": challengeDailyTimeRunLevelUp,
        "getCurrent": challengeDailyTimeRunGetCurrent,
        "goals": challengeDailyTimeRunLevels,
        "unit": "minutes",
        "image": TimedRunChallengeImage
    },
    "challengeTotalDistanceRun": {
        "id": "challengeTotalDistanceRun",
        "toString": challengeTotalDistanceRunToString,
        "getLevel": challengeTotalDistanceRunGetLevel,
        "description": challengeTotalDistanceRunGetDescription,
        "levelUp": challengeTotalDistanceRunLevelUp,
        "getCurrent": challengeTotalDistanceRunGetCurrent,
        "goals": challengeTotalDistanceRunLevels,
        "image": TotalDistanceRunChallengeImage
    },
};