import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';
import React, { useState, useEffect } from 'react';
import { PageView } from './utils/analytics';
import Header from './components/common/header';
import About from './components/publicPages/about/about';
import Resources from './components/publicPages/resources/resources';
import Community from './components/publicPages/community/community';
//import Research from './components/publicPages/research/research';
import Home from './components/publicPages/home/home';
import Register from './components/registration/register';
import Admin from '../src/components/admin/admin';
import Donate from './components/publicPages/donate';
import Playbook from '../src/components/student/student';
import NotFound from './components/common/not-found';
import NotVerified from './components/common/not-verified';
import AdminRoute from './components/routeWrappers/adminRoute';
import VerifiedRoute from './components/routeWrappers/verifiedRoute';
import RouteWrapper from './components/routeWrappers/routeWrapper';
import Footer from './components/common/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee, faEdit, faRedo, faTrash, faChevronDown, faChevronUp, faUserPlus, faExclamationCircle, faCopy, faAnchor, faSpaceShuttle,
  faBiohazard, faSkull, faBalanceScale, faMinusSquare, faToggleOn, faToggleOff, faPlusCircle, faCheck, faTimesCircle, faStar, faHeart, faBed,
  faGrin, faRunning, faSmile, faEye, faEyeSlash, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { getUserInformation, updateUser } from '../src/utils/firestore-functions';
import { teamUnbreakableDB, auth } from '../src/utils/firestore';
import PrivacyPolicy from './components/publicPages/privacy-policy';
import { LostPassword } from './components/accountNavigation/lostPassword';
import ResetPassword from './components/accountNavigation/resetPassword';
import EmailVerification from './components/registration/EmailVerification';
import ActionUrl from './components/common/actionUrl';
import ContactUs from './components/publicPages/contact-us';
import { loggedInToday } from './utils/challenges';
//import { Modules } from '../src/components/modules/modules';
import RegisterComplete from './components/registration/registerComplete';
import LoginWindow from './components/accountNavigation/login-window';
import { NewUser } from './utils/constants';
import Links from './components/publicPages/links';
import { GetVersion } from './utils/helper';
import { useCookies } from 'react-cookie';
import firebase from "firebase/app";
import { Event, UpdateUser } from "./utils/analytics";
import { v4 as uuidv4 } from 'uuid';
import CookiePermissionPopup from './components/common/cookiePermissions'

const history = createBrowserHistory();

library.add(fab, faCheckSquare, faCoffee, faEdit, faRedo, faTrash, faChevronDown, faChevronUp, faUserPlus, faExclamationCircle, faCopy, faAnchor, faSpaceShuttle,
  faBiohazard, faSkull, faBalanceScale, faMinusSquare, faToggleOn, faToggleOff, faTwitter, faInstagram, faFacebook, faLinkedin, faYoutube, faPlusCircle, faCheck, faTimesCircle, faStar,
  faHeart, faBed, faGrin, faRunning, faSmile, faEye, faEyeSlash, faAngleDoubleDown);

const App = () => {
  const [user, setUser] = useState();
  const [cookies, setCookie] = useCookies();

  const onAuthStateChange = () => {
    return auth.onAuthStateChanged(user => {
      if (user) {
        //getUserInformation(teamUnbreakableDB, user.uid, setUserProfile, failedToFindUserProfile)
        getUserInformation(teamUnbreakableDB, user.uid, setUserProfile, () => setUser("Guest"))
        UpdateUser(user.uid);
      } else {
        setUser("Guest");
        if (cookies['team-unbreakable-user-id'] === undefined) {
          const randomGUID = uuidv4(); //generate random cookie
          setCookie('team-unbreakable-user-id', randomGUID, { path: '/'});
          UpdateUser(cookies['team-unbreakable-user-id']); 
        } else {
          UpdateUser(cookies['team-unbreakable-user-id']);
        }
      }
    });
  }

  useEffect(() => {
    onAuthStateChange();
  }, []);

  useEffect(() => {
    if (user !== undefined && user.data !== undefined && user.data.loggedInToday === false){
      loggedInToday(user);
    }
  });

  const setUserProfile = ((user) => {
    setUser(user);
  });
/*Trying the HIMALAYAS style
 const setUserProfile = (user) => {
    if (!user.data.loggedInToday){
      loggedInToday(user);
    }
    if(!user.timeStamp){
      var data = {timestamp: firebase.firestore.Timestamp.now()}
      updateUser(teamUnbreakableDB, user.id, data);
    }

    if(!user.verified && auth.currentUser.emailVerified){
      var newInformation = {verified: true};
      updateUser(teamUnbreakableDB, user.id, newInformation);
      if (user.instructor === true) {
        Event("New Instructor Verified - test");
      } else {
        Event("New User Verified - test");
      }
    }
    
    setUser(user);
  }

  */
/*
TODO This needs some sort of work around, but it would be VERY unlikely that this happens?
  const failedToFindUserProfile = (error) => {
    let person = NewUser;
    NewUser.email = auth.currentUser.email;
    NewUser.firstName = auth.currentUser.displayName;
    NewUser.teacher = "";
    NewUser.location = "";
    NewUser.admin = false;
    NewUser.verified = false;
    NewUser.id = auth.currentUser.uid;
    console.log("New user");
    console.log(NewUser);
  updateDocumentProperty(teamUnbreakableDB, "admin", "users", auth.currentUser.uid, person, 
    function(){
        user.sendEmailVerification().then(() => {
            setUserProfile(NewUser);
        });
  },
    function(){
        console.log("Failed to create user in DB");
    })
  }
*/
  return (
    <Router history={history} >
      {console.log("User: " + user)}
      {console.log(user)}
      {user !== undefined && <Header user={user} />}
      {user !== undefined && <Switch>
        <RouteWrapper title="About | Team Unbreakable" path="/About/:subheader" component={About} />
        <RouteWrapper title="About | Team Unbreakable" path="/About" component={About} />

        {/*
        <RouteWrapper title="Resources | Team Unbreakable" path="/Resources/:subheader" user={user} render={(props) => <Resources user={user}/>}/>
        <RouteWrapper title="Resources | Team Unbreakable" path="/Resources" user={user} render={(props) => <Resources user={user} />}/>
        */}

        <RouteWrapper title="Resources | Team Unbreakable" path="/Resources/:subheader" user={user}>
          <Resources user={user} />
        </RouteWrapper>
        <RouteWrapper title="Resources | Team Unbreakable" path="/Resources" user={user}>
          <Resources user={user} />
        </RouteWrapper>
        
        <RouteWrapper title="Community | Team Unbreakable" path="/Community/:subheader" component={Community} />
        <RouteWrapper title="Community | Team Unbreakable" path="/Community" component={Community} />
        {/*<Route path="/Research" component={Research} />*/}
        <RouteWrapper title="Register| Team Unbreakable" path="/Register" component={Register} />
        <RouteWrapper title="Login | Team Unbreakable" path="/Login" component={LoginWindow}/>
        <RouteWrapper title="Lost Password | Team Unbreakable" path="/LostPassword" component={LostPassword} />
        <RouteWrapper path="/ActionUrl" component={ActionUrl} />
        <RouteWrapper title="Reset Password | Team Unbreakable" path="/ResetPassword" component={ResetPassword} />
        <RouteWrapper title="Email Verification | Team Unbreakable" path="/EmailVerification" component={EmailVerification}/>
        <RouteWrapper title="Donate | Team Unbreakable" path="/Donate" component={Donate}/>
        <RouteWrapper title="Contact Us | Team Unbreakable" path="/Contact" component={ContactUs}/>
        <RouteWrapper title="Register Complete | Team Unbreakable" path="/RegisterComplete" component={RegisterComplete}/>
        <RouteWrapper title="Privacy Policy | Team Unbreaakble" path="/Privacy" component={PrivacyPolicy} />
        <RouteWrapper title="Links | Team Unbreakable" path="/Links" component={Links} />    
        {/* EB: I think this was just for testing right?
        <Route path="/Modules">
          <Modules user={user}></Modules>
        </Route>
        */}

        {/* USER CONTROLLED*/}
        <VerifiedRoute title="Playbook | Team Unbreakable" path="/Playbook" user={user}>
          <Playbook user={user}></Playbook>
        </VerifiedRoute>

        {/* ADMIN */}
        <AdminRoute title=" Admin Home | Team Unbreakable"  path="/admin" user={user} >
          <Admin user={user}></Admin>
        </AdminRoute>

        <RouteWrapper title="Not Found | Team Unbreakable" path="/Not-Found" component={NotFound} />       
        <RouteWrapper title="Not Verified | Team Unbreakable" path="/Verify Account" user={user} component={NotVerified} /> 
        
        <RouteWrapper title="Home | Team Unbreakable" path="/" component={Home} user={user}/>         
      </Switch>}
      {user !== undefined && <Footer />}
    </Router>
  );
}

export default App;
