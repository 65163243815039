export const NewUser = {
    timeStamp: "",
    admin: false,
    verified: false,
    email: "",
    firstName: "",
    id: "",
    location: "",
    teacher: "",
    language: "English",
    instructor: false,
    awaitingInstructorConfirmation: false,
    modules: {
        introduction: false,
        module1: false,
        module2: false,
        module3: false,
        module4: false,
        module5: false,
        module6: false,
        module7: false,
        module8: false,
    },
    moduleData: {
        introduction: {

        },
        module1: {
            input1: {
                input1: ""
            },
            input2: {
                input2: "",
            },
            input3: {
                input3: ""
            },
            input4: {
                input4: "",
            },
            input5: {
                input5: ""
            },
            input6: {
                input6: "",
            },
            input7: {
                input7: ""
            },
            input8: {
                input8: "",
            },
            input9: {
                input9: ""
            },
            input10: {
                input10: "",
            },
            input11: {
                input11: ""
            },
            input12: {
                input12: "",
            },
            input13: {
                input13: ""
            },
            input14: {
                input14: "",
            },
            input15: {
                input15: ""
            },
            input16: {
                input16: "",
            },
            input17: {
                input17: ""
            },
            input18: {
                input18: "",
            },
            input19: {
                input19: ""
            },
            radioGroup1: [false,false,false],
        },
        module2: {
            input1: {
                input1: ""
            },
            input2: {
                input2: ""
            },
            input3: {
                input3: ""
            },
        },
        module3: {
            input1: {
                input1: ""
            },
            input2: {
                input2: "",
            },
            input3: {
                input3: ""
            },
            input4: {
                input4: "",
            },
        },
        module4: {
            
        },
        module5: {
            input1: {
                input1: ""
            },
            input2: {
                input2: "",
            },
            input3: {
                input3: ""
            },
            input4: {
                input4: "",
            },
            input5: {
                input5: ""
            },
        },
        module6: {
            input1: {
                input1: ""
            },
            input2: {
                input2: "",
            },
            input3: {
                input3: ""
            },
            input4: {
                input4: "",
            },
            input5: {
                input5: ""
            },
            input6: {
                input6: "",
            },
        },
        module7: {
            input1: {
                input1: ""
            },
        },
        module8: {
            input1: {
                input1: ""
            },
        },
    },
    data: {
        points: 0,
        daysLoggedTotal: 0,
        daysLoggedConsecutively: 0,
        daysStreakRecord: 0,
        loggedInToday: false,
        activities: {

        },
        totalDistanceRun: 0,
        currentChallenge: {
            challengeName: "challengeDaysInARow",
            challengeLevel: 1
        },
        challenges: {
            challengeDaysInARow: {
                level: 1,
                current: 0,
            },
            challengeDailyDistanceRun: {
                level: 1,
                current: 0,
            },
            challengeDailyTimeRun: {
                level: 1,
                current: 0,
            },
            challengeTotalDistanceRun: {
                level: 1,
                current: 0,
            }
        }
    }
};

export const Person = { 
    description: "",
    imageUrl: "N/A",
    name: "",
    order: 0,
    title: "",
}

export const Activity = {
    timestamp: "",
    distance: "",
    time: "",
    preMoodRating: 0,
    preMoodEmotions: "",
    postMoodRating: 0,
    postMoodEmotions: "",
    food: "",
    waterGlasses: 0,
    sleep: "",
    routeConditions: "",
    comments: ""
};

export const Colors = {
    primaryblue: "#22b7cc",
    secondarygray: "#333333",
    primarybackground: "#f8f8f8",
    secondarybackground: "#e5e5e5",
    secondarytext: "#b4b4b4",
}