import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import { Container, Col, Button, Alert, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import styled from 'styled-components'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Colors } from "../../utils/constants";

class ImageUploadAndCrop extends PureComponent {
    constructor(props){
        super(props);
        this.removeImage = this.removeImage.bind(this);
    }

    state = {
        submitFunction: this.props.submitFunction,
        passOverInfo: this.props.passOver,
        maxWidth: this.props.maxWidth,
        crop: this.props.crop,
        croppedFile: "",
        src: null,
        error: "",
        cropWindowOpen: false,
      };

      openCropWindow = () => {
        this.setState({cropWindowOpen: true, crop: this.props.crop});
      }

      closeCropWindow = () => {
        this.setState({cropWindowOpen: false, src: null});
      }
  
      onSelectFile = e => {
        if (e && e.length > 0) {
          const reader = new FileReader();
          reader.addEventListener('load', () =>
            this.setState({ src: reader.result, originalFile: e })
          );
          reader.readAsDataURL(e[0]);
        }
        this.openCropWindow();
      };
    
      onImageLoaded = image => {
        this.imageRef = image;
      };
    
      onCropComplete = crop => {
        this.makeClientCrop(crop);
      };
    
      onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
      };

      removeImage(){
        this.setState({src: "", croppedImageUrl: "", fileName: ""})
    }
    
      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );

          this.onImageEdit(croppedImageUrl);
        }
      }
  
    onImageEdit = async (imgUrl) => {
        var imgExt = this.getUrlExtension(imgUrl);
    
        const response = await fetch(imgUrl);
        const blob = await response.blob();
        const file = new File([blob], "profileImage." + imgExt, {
          type: blob.type,
        });

        this.setState({ croppedImageUrl: imgUrl, croppedFile: file });
    }

    getUrlExtension = (url) => {
        return url
          .split(/[#?]/)[0]
          .split(".")
          .pop()
          .trim();
      }
    
      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error('Canvas is empty');
              return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          }, 'image/jpeg');
        });
      }

      handleRejectedFile(error){
        console.log("Invalid file type chosen");
        console.log(error);
    }

      render() {
        const { crop, croppedImageUrl, src, submitFunction, croppedFile, maxWidth, passOverInfo, cropWindowOpen } = this.state;
        return (
          <React.Fragment>
              <StyledModal isOpen={cropWindowOpen}>
                  <ModalHeader>Crop Image</ModalHeader>
                  <StyledModalBody>
                  {src && (
                    <ReactCrop
                      src={src}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}
                    {this.state.error && <StyledAlert color="danger">{this.state.error}</StyledAlert>}
                  {src && <Col>
                          <StyledButton onClick={this.removeImage} color="danger">
                              <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                          </StyledButton>
                      </Col>}
                  </StyledModalBody>
                  <ModalFooter>
                      <Button color="secondary" onClick={() => this.closeCropWindow()}>Cancel</Button>
                      <Button color="primary" onClick={() => submitFunction(croppedFile, croppedImageUrl, passOverInfo)}>Upload Photo</Button>
                  </ModalFooter>
              </StyledModal>
          
              <StyledContainer maxwidth={(src && maxWidth) ? maxWidth : "300px"}>
                  {!src && <Dropzone
                      accept='image/*'
                      multiple={false} 
                      onDropAccepted={this.onSelectFile}
                      onDropRejected={rejectedFiles => this.handleRejectedFile(rejectedFiles)} 
                      >
                          {({dropZoneProps, getRootProps, getInputProps}) => (
                              <section>
                                  <StyledDropBox {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <p>{this.props.descriptionText}</p>
                                  </StyledDropBox>
                              </section>
                          )}
                          </Dropzone>}             
                </StyledContainer>
            </React.Fragment>
        );
      }
    }

export default ImageUploadAndCrop;

const StyledModalBody = styled(ModalBody)`
`

const StyledModal = styled(Modal)`
    position: absolute;
    left: 15%;
    top: 5%;
    width: fit-content;
    max-width: 60%;
`

const StyledAlert = styled(Alert)`
  width: 150px;
  justify-content: center;
  margin-left: 20px;
`

const StyledDropBox = styled.div`
  height: 150px;
  border: dashed;
  border-width: 1px;
  padding: 10px;
`

const StyledContainer = styled(Container)`
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  border: solid;
  border-color: ${Colors.primaryblue};
  margin: auto;
  margin-top: ${props => props.topmargin};
  max-width: ${props => props.maxwidth};
  box-shadow: 0 0.5rem 1rem ${Colors.secondarygray};
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledButton = styled(Button)`
    margin-left: 5px;
`;
