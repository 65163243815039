import React, { useState } from 'react';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import styled from 'styled-components';
import { generateGUID, teamUnbreakableDB } from '../../utils/firestore';
import { updateDocumentProperty } from '../../utils/firestore-functions';
import ModalWindow from '../common/modalWindow';
import firebase from "firebase/app";
import emailjs, { init } from 'emailjs-com';
import { Colors } from "../../utils/constants";

const ContactUs = () => {
    
    init("user_8Qt3ZJv9dlnGhiJefkhBh");
    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn, setModalConfirmOn] = useState(true);
    const [modalConfimFunction, setModalConfirmFunction] = useState();

    const toggle = () => setModal(!modal);
    const [copyAlertTim, setCopyAlertTim] = useState(false);
    const [copyAlertNicole, setCopyAlertNicole] = useState(false);

    const copyEmail = (person) => {
        if (person === "Tim") {
            navigator.clipboard.writeText("tmcfadden@teamunbreakable.ca");
            setCopyAlertTim(true);

            let timer = setTimeout(() => { 
                setCopyAlertTim(false);
            }, 3000);
            return () => {clearTimeout(timer)};
        } else if (person === "Nicole") {
            navigator.clipboard.writeText("nicolek@teamunbreakable.ca");
            setCopyAlertNicole(true);

            let timer = setTimeout(() => { 
                setCopyAlertNicole(false);
            }, 3000);
            return () => {clearTimeout(timer)};
        }
    }

    const SendMessage = () => {
        let id = generateGUID();
        let data = {
            name: name,
            email: email,
            phone: phone,
            message: message,
            resolved: false,
            timeStamp: firebase.firestore.Timestamp.fromDate(new Date()),
        }
        updateDocumentProperty(teamUnbreakableDB, "admin", "tickets", "contactus." + id, data, SuccessSend, FailedSend);
    }

    const SuccessSend = () => {
        emailjs.send("service_xfajc29", "template_aerd9av", {
            name: name,
            email: email,
            message: message,
        });
        setModalTitle("Message Sent");
        setModalMessage("Your message has been sent");
        setModalConfirmOn(false);
        setModal(true);
        ClearInputs();
    }

    const ClearInputs = () => {
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
    }

    const FailedSend = (error) => {
        setModalTitle("Message was not sent");
        setModalMessage("Something went wrong and your message failed to send");
        setModalConfirmOn(false);
        setModal(true);
    }

    return (<React.Fragment>
        <ModalWindow cancelText="OK" isOpen={modal} title={modalTitle} message={modalMessage} cancelFunction={toggle} confirmFunction={modalConfimFunction} showConfirmButton={modalConfirmOn} />
        <MainDiv>
            <Container>
                <br />
                <Row>
                    <Col>
                        <StyledHeaderTitle>Contact Us</StyledHeaderTitle><br />
                    </Col>
                </Row>
                <ContentContainer>
                <Row>
                    <NoPaddingCol>
                        <ReachOutParagraph>
                            This is not a site for personal disclosure of mental health distress, suicidal thoughts or behaviors. If you are in crisis, please call 911
                            or go to your nearest emergency department for assistance.
                        </ReachOutParagraph>
                    </NoPaddingCol>
                </Row>
                <Row>
                    <Col lg="6" xs="12" style={{paddingLeft: "5px"}}><StyledInput placeholder="Name *" value={name} onChange={e => setName(e.target.value)} /></Col>
                    <Col lg="6" xs="12"style={{paddingRight: "5px"}}><StyledInput placeholder="Email *" value={email} onChange={e => setEmail(e.target.value)} /></Col>
                </Row>
                <Row>
                    <NoPaddingCol xs="12">
                        <StyledInput placeholder="Phone Number" value={phone} onChange={e => setPhone(e.target.value)} />
                    </NoPaddingCol>
                </Row>
                <Row>
                    <NoPaddingCol xs="12">
                        <StyledInput placeholder="Message" type="textarea" value={message} onChange={e => setMessage(e.target.value)} />
                    </NoPaddingCol>
                </Row>
                <Row>
                    <NoPaddingCol>
                        <StyledButton onClick={() => SendMessage()}>Send Message</StyledButton>
                    </NoPaddingCol>
                </Row>
                <Row> 
                    <Col>
                        <ReachOutParagraph>
                            Send a message through the site or send us a direct email. 
                        </ReachOutParagraph>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ContactParagraph>
                            Tim McFadden, Executive Director <br/>
                        </ContactParagraph>
                        <Row>
                        <Col xs="auto">
                        <EmailButton onClick={() => copyEmail("Tim")}>tmcfadden@teamunbreakable.ca</EmailButton>
                        </Col>
                        <Col>
                        <StyledAlert>Copied!</StyledAlert>
                        </Col>
                        </Row> 
                    </Col>
                    <Col>
                        <ContactParagraph> 
                            Nicole Kuzmich, Outreach <br/>
                        </ContactParagraph>
                        <Row>
                        <Col xs="auto">
                        <EmailButton onClick={() => copyEmail("Nicole")}>nicolek@teamunbreakable.ca</EmailButton>
                        </Col>
                        <Col>
                        <StyledAlert>Copied!</StyledAlert>
                        </Col>
                        </Row> 
                    </Col>
                </Row>
            </ContentContainer>
            </Container>
        </MainDiv>
    </React.Fragment> );
}
 
export default ContactUs;

const NoPaddingCol = styled(Col)`
    padding-left: 5px;
    padding-right: 5px;
`
const StyledAlert = styled(Container)`
    color: ${Colors.secondarybackground};
    font-size:16px;
    font-family: Roboto;
    font-weight: normal;
    padding: 0px;
`


const EmailButton = styled.div`
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    font-family: Roboto;
    padding-left: 0px;
    padding-right: 0px;
    color: ${Colors.primaryblue};
    border: none;
    &:hover {
        color: black;
        padding-left: 0px;
        background: none;
        cursor: pointer;
    }
    &:focus {
        color: black;
        border: none;
        border-weight: 0px;
        padding-left: 0px;
        background: none;
        cursor: pointer;
    }
`

const ReachOutParagraph = styled.p`
    font-weight: bold;
    font-size: 20px;
`
const ContactParagraph = styled.p`
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0px;
`

const OffsetDiv = styled.div`
`;

const StyledInput = styled(Input)`
    width: 100%;
    margin-bottom: 25px;
    height: 50px;
    &:focus {
        box-shadow: 0 0 10px white;
}
`

const ContentContainer = styled(Container)`
    max-width: 1200px;
    margin-bottom: 2%;
`;


const StyledButton = styled(Button)`
    text-align: center;
    font-family: Roboto;
    width: 150px;
    background: ${Colors.primaryblue};
    border-radius: 5px;
    color: white;
    border: none;
    padding: 15px;
    &:hover {
        color: white;
        background-color: ${Colors.secondarygray};
        cursor: pointer;
    }
    margin-bottom: 50px;
`

const MainDiv = styled.div`
    background-color: ${Colors.primarybackground};
`

const StyledHeaderTitle = styled.h1`
    color: ${Colors.primaryblue};
    font-family: Roboto;
`