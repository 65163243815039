import { auth, storage, teamUnbreakableDB } from "./firestore";
import axios from 'axios'

function getDescendantProp(obj, desc) {
    var arr = desc.split('.');
    while (arr.length) {
      obj = obj[arr.shift()];
    }
    return obj;
  }


export const ReturnInstagramToken = (db, successCallback) => {

    const configRef = db.collection("admin").doc("config");
    configRef.get().then(function(doc) {
        async function refreshToken (token) {
            console.log("Refreshing Token")
            const url = `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${token}`; 
            try{
                axios
                    .get(url)
                    .then((resp) => {
                        console.log(resp);
                        const timeNow = Date.now();
                        const update = {"instagramInfo.lastRefresh": timeNow, "instagramInfo.token": resp.data.access_token};
                        configRef.update(update);
                    })
            } catch (err) {
                console.log("NO LUCK!")
                console.log('error', err)
            }
        }

        if(doc.exists) {
            let info = doc.data()["instagramInfo"];
            console.log(info)
            if(info) {
                const lastRefresh = info["lastRefresh"]
                const timeNow = Date.now();

                if (lastRefresh === undefined) {
                    //set the lastRefresh time
                    const update = {"instagramInfo.lastRefresh": timeNow};
                    configRef.update(update);
                } else if ((timeNow - lastRefresh) > 2592000000) {
                    // refresh the token 
                    refreshToken(info["token"]);
                } 
                let token = doc.data()["instagramInfo"]["token"];
                successCallback(token);
            } 
        }
    })
}

export const listenToCollection = (db, collection, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collection).where("id","!=","").onSnapshot((docs) => {
        if (!docs === undefined || docs === null){
            throw "Collection not found! Collection: " + collection;
        }
        else{
            var values = [];
            docs.forEach((doc) => {
                values.push(doc.data());
            })
            successCallback(values);
        }
    })
    return unsubscribe;
}; 

//Will use this to listen to users information
export const listenToDocument = (db, collection, document, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collection).doc(document).onSnapshot((doc) => {
        if (!doc.exists){
            throw "Document not found! Collection: " + collection + ", Document: " + doc;
        }
        else{
            const value = doc.data();
            successCallback(value);
        }
    })
    return unsubscribe;
}; 

export const listenToDocumentProperty = (db, collection, document, property, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collection).doc(document).onSnapshot((doc) => {
        if (!doc.exists){
            throw "Document not found! Collection: " + collection + ", Document: " + doc;
        }
        else{
            const value = doc.data()[property];
            successCallback(value);
        }
    })
    return unsubscribe;
};

export const listenToNestedDocumentProperty = (db, collection, document, property, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collection).doc(document).onSnapshot((doc) => {
        if (!doc.exists){
            throw "Document not found! Collection: " + collection + ", Document: " + doc;
        }
        else{
            const value = getDescendantProp(doc.data(),property);
            successCallback(value);
        }
    })
    return unsubscribe;
};

export const updateDocumentProperty = (db, collection, document, property, value, successCallback, failureCallback) => {
    db.collection(collection).doc(document).update({
        [property]: value
    })
    .then(() => {
        if (successCallback !== undefined) {
            successCallback();
        }
    })
    .catch((error) => {
        if(failureCallback){
            console.log(error);
            failureCallback(error);
        }
    })
};

export const updateUser = (db, userId, data, successCallback, failureCallback) => {
    db.collection("users").doc(userId).update(data) //TODO does this need squigly braces?
    .then(() => {
        if (successCallback !== undefined) {
            successCallback();
        }
    })
    .catch((error) => {
        if(failureCallback){
            console.log(error);
            failureCallback(error);
        }
    })
};

export const setDocument = (db, collection, document, data, successCallback, failureCallback) => {
    db.collection(collection).doc(document).set(
        data
    )
    .then(() => {
        if (successCallback !== undefined) {
            successCallback();
        }
    })
    .catch((error) => {
        if(failureCallback){
            console.log(error);
            failureCallback(error);
        }
    })
};

export const deleteDocumentProperty = (db, collection, document, property, successCallback, failureCallback) => {
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(collection).doc(document)).then((doc) => {
            var data = doc.data();
            delete data[property];
            transaction.update(db.collection(collection).doc(document), data);
        });
    });
};

export const deleteNestedDocumentProperty = (db, collection, document, propertyParent, propertyChild, successCallback, failureCallback) => {
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(collection).doc(document)).then((doc) => {
            var data = doc.data();
            delete data[propertyParent][propertyChild];
            transaction.update(db.collection(collection).doc(document), data);
        });
    });
};

export const registerUserWithEmailVerification = (email, password, auth, successCallback, failureCallback) => {
    let actionCodeSettings = {
        url: 'http://161.35.105.21/EmailLoginLink',
        handleCodeInApp: true,
    };
    auth.sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
        window.localStorage.setItem('emailForSignIn', email);
    })
    .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
        failureCallback(errorCode + ": " + errorMessage);
    });
}

export const signInWithEmailLink = (auth) => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt('Please provide your email for confirmation');
        }
        // The client SDK will parse the code from the link for you.
        auth.signInWithEmailLink(email, window.location.href)
        .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
}

export const registerUser = (email, password, auth, successCallback, failureCallback) => {
    auth.createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                var user = userCredential.user;
                successCallback(user.uid);
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                failureCallback(errorCode + ": " + errorMessage);
            });
}

export const loginUser = (email, password, auth, successCallback, failureCallback) => {
    
    auth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
            if (successCallback !== undefined){
                successCallback();
            }
        })
        .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode + " " + errorMessage);
            if (failureCallback !== undefined){
                failureCallback();
            }
        });
}

export function getUserInformation(db, userid, successcallback, failureCallback){
    db.collection("users").doc(userid).get().then(function(doc) {
    if (doc.exists) {
            successcallback(doc.data());
    } else {
        if(failureCallback){
            failureCallback();
        }
    }
    }).catch(function(error) {
        console.log("Error getting document:", error);
    });
}

export const UploadFileToStorage = (fileSource, path, child, successcallback, failcallback, id="Null") => { 
    const uploadTask = storage.ref(path + child).put(fileSource);
    uploadTask.on(
    "state_changed",
    snapshot => {
        const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
    },
    error => {
        console.log(error);
        if(failcallback){
            failcallback(error);
        }
    },
    () => 
    {
        storage
        .ref(path)
        .child(child)
        .getDownloadURL()
        .then(url => {
            successcallback(url, id);
        });
    });   
};

export const DeleteFromStorage = (storageRef, child, successcallback) => {
    var storageReference = storage.ref();
    var ref = storageReference.child(storageRef + child);
    // Delete the file
    ref.delete().then(() => {
        if(successcallback){
            successcallback();
        }
    }).catch((error) => {
    // Uh-oh, an error occurred!
    });
}
