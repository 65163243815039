import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Button } from 'reactstrap';
import { Colors } from "../../../utils/constants";

const ContactTicket = ({name, email, phone, timeStamp, confirmFunction, ticket}) => {

   return (
      <StyledButton onClick={() => confirmFunction(ticket)}>
         <Container>
         <Row>
               <Col>
                  <h1>{name}</h1>
               </Col>
               <Col>
                  <h3>{email}</h3>
               </Col>
               <Col>
                  <h3>{phone}</h3>
               </Col>
               <Col>
                  <h5>{timeStamp}</h5>
               </Col>
         </Row>
      </Container>
      </StyledButton>
   );
}
export default ContactTicket;

const StyledButton = styled(Button)`
   border-style: solid;
   border-width: 3px;
   border-color: ${Colors.primaryblue};
   border-radius: 5px;
`