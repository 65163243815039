import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, InputGroup, Input } from "reactstrap";
import { teamUnbreakableDB, generateGUID } from "../../../utils/firestore";
import { listenToDocumentProperty, updateDocumentProperty } from "../../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import AdminTestimonial from "./admin-testimonial";

export const AdminTestimonials = () => {
 
    const [testimonials, setTestimonials] = useState({});
    const [testimonial, setTestimonial] = useState("");
    const [testimonialSource, setTestimonialSource] = useState("");
    const [testimonialOrder, setTestimonialOrder] = useState(0);

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "testimonials", "testimonials", setTestimonials);
        return unsubscribe;
    }, [])

    const addTestimonial = () => {
        var id = generateGUID();
        var t = {
            testimonial: testimonial,
            source: testimonialSource,
            order: testimonialOrder,
            id: id
        }
        updateDocumentProperty(teamUnbreakableDB, "content", "testimonials", "testimonials." + id, t);
        clearInput();
    }

    const clearInput = () => {
        setTestimonial("");
        setTestimonialSource("");
        setTestimonialOrder(0);
    }

    return(
        <Container>
            <Row>
                <CenterFlexCol>
                    <h1>Testimonials</h1>
                </CenterFlexCol>
            </Row>
            {(Object.entries(testimonials).length > 0) && Object.entries(testimonials).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                return <ColoredDiv key={t[0]}>
                    <AdminTestimonial id={t[0]} order={t[1].order} source={t[1].source} testimonial={t[1].testimonial}></AdminTestimonial>
                </ColoredDiv>
            })}
            <Row>
                <Col>
                    <h2>New Testimonial</h2>
                </Col>
            </Row>
            <Row>
                <FlexCol xs="1">
                    <Button onClick={() => {addTestimonial()}} color="primary">
                        <FAIconButton icon={["fas", "user-plus"]}></FAIconButton>
                    </Button>
                </FlexCol>
                <FlexCol>
                    <InputGroup>
                        <div style={{width:"100px"}}>
                            Source
                        </div>
                        <Input value={testimonialSource} onChange={e => setTestimonialSource(e.target.value)} />
                    </InputGroup>
                    <InputGroup>
                        Order
                        <Input value={testimonialOrder} onChange={e => setTestimonialOrder(e.target.value)} />
                    </InputGroup>
                </FlexCol>
            </Row>
            <Row>
                <FlexCol xs="1">
                </FlexCol>
                <FlexCol>
                    <InputGroup>
                        <div style={{width:"100px"}}>
                            Testimonial
                        </div>
                    <Input type="textarea" value={testimonial} onChange={e => setTestimonial(e.target.value)}/>
                    </InputGroup>
                </FlexCol>
            </Row>
        </Container>
    ) 
}

export default AdminTestimonials;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const ColoredDiv = styled.div`
    margin-bottom: 5px;
    padding: 5px;
    padding-top: 15px;
    background-color: rgba(0,0,0,0.05);
`;

const FlexCol = styled(Col)`
    display: flex;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;