import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import styled from 'styled-components'
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { Colors } from "../../utils/constants";

const SubHeader = (props) => {
    var size = useWindowSize();

    const click = (option) => {
        props.setContent(option._id);
        if (option._background !== undefined){
            props.setBackground(option._background);
        }
    }

    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
          width: undefined,
          height: undefined,
        });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
          // Set window width/height to state
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
      }, []); // Empty array ensures that effect is only run on mount
      return windowSize;
    }

    return (
        <React.Fragment>
            <SubHeaderDiv>
                <HeightDiv margincheck={size.width > 800 ? "7.5%" : "0%"}> {/* TODO is this a valid way to check for where margin should be? */}
                    <Container fluid={true}>
                        <Row>
                            <ScrollMenu>
                                {props.options.map(option => {
                                    if (option._id === props.selected){
                                        return <SelectedSubHeaderMenuButton color="primary" onClick={() => click(option)} key={option._id}>
                                            {option._id}
                                        </SelectedSubHeaderMenuButton>
                                    }
                                    else{
                                        return <SubHeaderMenuButton color="primary" onClick={() => click(option)} key={option._id}>
                                            {option._id}
                                        </SubHeaderMenuButton>
                                    }
                                })}
                                </ScrollMenu>
                        </Row>
                    </Container>
                </HeightDiv>
            </SubHeaderDiv>
        </React.Fragment>
    )
}

const SubHeaderMenuButton = styled(Col)`
    white-space: nowrap;
    text-decoration: none;
    align-self: center;
    text-align: center;
    padding: 9px 0px 2px 0px;
    color: black;
    min-width: fit-content;
    height: 50px;
    font-family: Roboto;
    font-size: large;
    margin-left: 10px;
    margin-right: 10px;
    &:hover {   
        color: ${Colors.primaryblue};
        border-color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

const SelectedSubHeaderMenuButton = styled(Col)`
    white-space: nowrap;
    text-decoration: none;
    align-self: center;
    text-align: center;
    padding: 9px 0px 2px 0px;
    color: ${Colors.primaryblue};
    min-width: fit-content;
    height: 50px;
    border-style: solid;
    border-width: 0px 0px 5px 0px;
    border-color: ${Colors.primaryblue};
    font-family: Roboto;
    font-size: large;
    margin-left: 10px;
    margin-right: 10px;
`;

const SubHeaderDiv = styled.div`
    position: fixed;
    width: 100%;
    top: 100px;
    z-index: 2;
    background-color: ${Colors.primarybackground};
    border-style: solid;
    border-width: 2px;
    border-color: ${Colors.secondarybackground} ${Colors.primarybackground};
`;

const HeightDiv = styled.div`
    height: 50px;
    align-items: center;
    margin-left: ${props => props.margincheck};
    margin-top: 10px;
`;

export default SubHeader