import React, { useState, useEffect } from "react";
import { Container, Row, Col, Collapse, InputGroup, Input, Table, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { teamUnbreakableDB } from "../../utils/firestore";
import styled from "styled-components";
import { listenToCollection, updateUser } from "../../utils/firestore-functions";
import ModalWindow from "../common/modalWindow";
import { Colors } from "../../utils/constants";
import Papa from "papaparse";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";

const AdminData = () => {
    
    const [fullUsers, setFullUsers] = useState({});
    const [filteredUsers, setFilteredUsers] = useState({}); 
    const [users, setUsers] = useState({});
    const [keyword, setKeyword] = useState("");
    const [areUsersVisible, setAreUsersVisible] = useState(false);
    const [areInstructorsVisible, setAreInstructorsVisible] = useState(false);
    const [sortByParameter, setSortByParameter] = useState("");
    const [startFilter, setStartFilter] = useState(null);
    const [endFilter, setEndFilter] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn, setModalConfirmOn] = useState(true);
    const [modalConfimFunction] = useState();
    const [modalCancelText, setModalCancelText] = useState("Cancel");

    const toggle = () => setModal(!modal);

    
    useEffect(() => {
        var unsubscribe = listenToCollection(teamUnbreakableDB, "users", setFullUsers);
        return unsubscribe;
    }, [])

    const participantFilter = () => {
        var tempUsers = {};
        Object.entries(fullUsers).map((user) => {
            if (!user[1].instructor) {
                tempUsers[user[0]] = user[1];
            };
        });
        tempUsers = applyDateFilter(tempUsers);
        setFilteredUsers(tempUsers);
    };

    const instructorFilter = () => {
        var tempUsers = {};
        Object.entries(fullUsers).map((user) => {
            if (user[1].instructor) {
                tempUsers[user[0]] = user[1];
            };
        });
        tempUsers = applyDateFilter(tempUsers);
        setFilteredUsers(tempUsers);
    };

    const applyDateFilter = (tempUsers) => {
        if (startFilter !== null && endFilter !== null) {
            Object.entries(tempUsers).map((user) => {
                Object.entries(user[1].data.activities).map((activity) => {
                    let strings = activity[1].date.split("-");
                    let d = new Date(strings[0], strings[1] - 1, strings[2]);
                    let time = d.getTime();
                    if (time < startFilter.ts || time > endFilter.ts) {
                        delete tempUsers[user[0]].data.activities[activity[0]];
                    }
                })
            })
        } else if (startFilter !== null) {
            Object.entries(tempUsers).map((user) => {
                Object.entries(user[1].data.activities).map((activity) => {
                    let strings = activity[1].date.split("-");
                    let d = new Date(strings[0], strings[1] - 1, strings[2]);
                    let time = d.getTime();
                    if (time < startFilter.ts) {
                        delete tempUsers[user[0]].data.activities[activity[0]];
                    }
                })
            })
        } else if (endFilter !== null) {
            Object.entries(tempUsers).map((user) => {
                Object.entries(user[1].data.activities).map((activity) => {
                    let strings = activity[1].date.split("-");
                    let d = new Date(strings[0], strings[1] - 1, strings[2]);
                    let time = d.getTime();
                    if (time > endFilter.ts) {
                        delete tempUsers[user[0]].data.activities[activity[0]];
                    }
                })
            })
        } else {
            return tempUsers;
        }
        return tempUsers;
    };

    const exportCSV = () => {
        let x = [];
        Object.entries(filteredUsers).map((el, index) => {
            Object.entries(el[1].data.activities).map((el2) => {
                let d = {};
                d.admin = el[1].admin;
                d.awaitingInstructorConfirmation = el[1].awaitingInstructorConfirmation;
                d.id = el[1].id;
                d.language = el[1].language;
                d.timeStamp = el[1].timeStamp;
                d.verified = el[1].verified;
                d.date = el2[1].date;
                d.distance = el2[1].distance;
                d.preRunMood = el2[1].preRunMood;
                d.postRunMood = el2[1].postRunMood;
                d.time = el2[1].time;
                d.ranWithOthers = el2[1].ranWithOthers;
                d.birthday = el[1].birthday ? el[1].birthday : "no data";
                d.gender = el[1].gender ? el[1].gender : "no data";
                x.push(d);
            });
        })
        const csv = Papa.unparse(x);
        const blob = new Blob([csv]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "data.csv";
        link.click();
    };

    const formatTime = (minutes) => {
        let seconds = minutes * 60;
        seconds = Math.round(seconds/60);
        minutes = Math.floor(minutes);
        return minutes + "m " + seconds + "s";
    };

    return (
        <React.Fragment>
            <ModalWindow 
                cancelText={modalCancelText} 
                isOpen={modal} 
                title={modalTitle} 
                message={modalMessage} 
                cancelFunction={toggle} 
                confirmFunction={modalConfimFunction} 
                showConfirmButton={modalConfirmOn} 
            />
            <PrimaryContainer>
                <Row>
                    <Col>
                        <h2>Admin Data</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Date filters need to be set before clicking participants or instructors. You can use 0, 1, 2 date filters, and they should all work. Exporting won't include empty rows.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={() => participantFilter()}>Students</Button>
                        <Button onClick={() => instructorFilter()}>Instructors</Button>
                        <Button onClick={() => exportCSV()}>Export CSV</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Start Date</p>
                    </Col>
                    <Col>
                        <p>End Date</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker onChange={(newValue) => {setStartFilter(newValue)}}/>
                        </LocalizationProvider>
                    </Col>
                    <Col>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker onChange={(newValue) => {setEndFilter(newValue)}}/>
                        </LocalizationProvider>
                    </Col>
                </Row>
                {filteredUsers !== undefined && Object.entries(filteredUsers).length > 0 && Object.entries(filteredUsers).map((user) => {
                    return (
                        <React.Fragment>
                            <h4>{user[1].id}</h4>
                            {user[1].data.activities !== undefined && Object.entries(user[1].data.activities).length > 0 && <Row>
                                <Col>
                                    <p>Date</p>
                                </Col>
                                <Col>
                                    <p>Distance</p>
                                </Col>
                                <Col>
                                    <p>Time</p>
                                </Col>
                                <Col>
                                    <p>Pre Run Mood</p>
                                </Col>
                                <Col>
                                    <p>Post Run Mood</p>
                                </Col>
                                <Col>
                                    <p>Ran With Others</p>
                                </Col>
                            </Row>}
                            {user[1].data.activities !== undefined && Object.entries(user[1].data.activities).length > 0 && Object.entries(user[1].data.activities).map((activity) => {
                                return(
                                    <React.Fragment>
                                        <Row>
                                            <Col>
                                                <p>{activity[1].date}</p>
                                            </Col>
                                            <Col>
                                                <p>{activity[1].distance} km</p>
                                            </Col>
                                            <Col>
                                                <p>{formatTime(activity[1].time)}</p>
                                            </Col>
                                            <Col>
                                                <p>{activity[1].preRunMood}</p>
                                            </Col>
                                            <Col>
                                                <p>{activity[1].postRunMood}</p>
                                            </Col>
                                            <Col>
                                                <p>{activity[1].ranWithOthers ? activity[1].ranWithOthers.toString() : "no data"}</p>
                                            </Col>
                                            <Col>
                                                <p>{activity[1].birthday ? activity[1].birthday.toString() : "no data"}</p>
                                            </Col>
                                            <Col>
                                                <p>{activity[1].gender ? activity[1].gender.toString() : "no data"}</p>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )
                            })}
                        </React.Fragment>)
                })}
            </PrimaryContainer>
        </React.Fragment>
     );
}
 
export default AdminData;

const StyledAnchor = styled.a`
    text-decoration: underline;
    color: black;
    &:hover {
        color: ${Colors.primaryblue};
        cursor: pointer;
    }
`;

const PrimaryContainer = styled(Container)`
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;