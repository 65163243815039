import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { Colors } from "../../utils/constants";
import Roadmap from "../../images/Roadmap.png";
import { Copy } from "./module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { Event } from "../../utils/analytics";

export const ModuleIntroduction = ( props ) => {

    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const completeModule = () => {
        Event("Module Completed", "Mental Module", "Introduction");

        var data = user;
        data.modules.introduction = true;
        updateUser(teamUnbreakableDB, user.id, data)
    };

    return(
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <CenterFlexCol>
                        <ColoredH2>{Copy["Module Introduction"].Title[props.user.language]}</ColoredH2>
                    </CenterFlexCol>
                </Row>
            </Container>
            <Container>
                <br />
                <Row>
                    <Col>
                        <RoadmapIMG src={Roadmap}></RoadmapIMG>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <ColoredH4>{Copy["Module Introduction"].SubTitle2[props.user.language]}</ColoredH4>
                                    <ColoredHR />
                                    <StyledP>{Copy["Module Introduction"].Module1[props.user.language]}</StyledP>
                                    <StyledP>{Copy["Module Introduction"].Module2[props.user.language]}</StyledP>
                                    <StyledP>{Copy["Module Introduction"].Module3[props.user.language]}</StyledP>
                                    <StyledP>{Copy["Module Introduction"].Module4[props.user.language]}</StyledP>
                                    <br></br>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredH4>{Copy["Module Introduction"].SubTitle3[props.user.language]}</ColoredH4>
                                    <ColoredHR />
                                    <StyledP>{Copy["Module Introduction"].Module5[props.user.language]}</StyledP>
                                    <StyledP>{Copy["Module Introduction"].Module6[props.user.language]}</StyledP>
                                    <StyledP>{Copy["Module Introduction"].Module7[props.user.language]}</StyledP>
                                    <StyledP>{Copy["Module Introduction"].Module8[props.user.language]}</StyledP>
                                    <br></br>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ColoredH4>{Copy["Module Introduction"].SubTitle4[props.user.language]}</ColoredH4>
                                    <ColoredHR />
                                    <StyledP>{Copy["Module Introduction"].Resource1[props.user.language]}</StyledP>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            {user !== undefined && <Container>
                <Row>
                    <CenterFlexCol>
                        {user.modules.introduction ? <CompletedDiv><CompletedP>Module Completed</CompletedP></CompletedDiv> : <StyledButton onClick={() => completeModule()} color="primary">Complete Module</StyledButton>}
                    </CenterFlexCol>
                </Row>
            </Container>}
        </React.Fragment>
    );
}

export default ModuleIntroduction;

const CompletedDiv = styled.div`
    background-color: ${Colors.primaryblue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 20px;
`;

const CompletedP = styled.p`
    color: white;
    margin: 0px;
    font-weight: bold;
`;

const StyledButton = styled(Button)`
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: ${Colors.primaryblue};
    border-color: ${Colors.secondarygray};
`;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const RoadmapIMG = styled.img`
    max-width: 100%;
    height: auto;
    border-style: solid;
    border-color: ${Colors.primaryblue};
    border-width: 1px;
    margin-bottom: 10px;
    background-color: white;
`;

const StyledP = styled.p`
    margin-bottom: 0px;
`;

const ColoredH2 = styled.h2`
    color: ${Colors.primaryblue};
    margin-bottom: 0px;
`;

const ColoredH4 = styled.h4`
    color: ${Colors.primaryblue};
    margin-bottom: 0px;
`;

const ColoredHR = styled.hr`
    background-color: ${Colors.primaryblue};
    margin-top: 0px;
    margin-bottom: 6px;
`;