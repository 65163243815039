import React, { useState, useEffect } from "react";
import { Container, Row, Col, Collapse, InputGroup, Input, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { teamUnbreakableDB } from "../../utils/firestore";
import styled from "styled-components";
import { listenToCollection, updateUser } from "../../utils/firestore-functions";
import ModalWindow from "../common/modalWindow";
import { Colors } from "../../utils/constants";

const AdminUsers = () => {
    
    const [fullUsers, setFullUsers] = useState({});
    const [users, setUsers] = useState({});
    const [keyword, setKeyword] = useState("");
    const [areUsersVisible, setAreUsersVisible] = useState(false);
    const [areInstructorsVisible, setAreInstructorsVisible] = useState(false);
    const [sortByParameter, setSortByParameter] = useState("");
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn, setModalConfirmOn] = useState(true);
    const [modalConfimFunction] = useState();
    const [modalCancelText, setModalCancelText] = useState("Cancel");

    const toggle = () => setModal(!modal);

    
    useEffect(() => {
        var unsubscribe = listenToCollection(teamUnbreakableDB, "users", SetLists);
        return unsubscribe;
    }, [])

    const SetLists = (users) => {
        let x = users.reduce((accumulator, value) => { 
            return {...accumulator,  [value.id]: value}
        }, {});
        setFullUsers(x);
        SearchUsers(keyword, x, "FirstName");
    }

    const SearchUsers = (keyword, fullUsers, sortParameter = sortByParameter) => {
        setKeyword(keyword);

        if(keyword === ""){
            setUsers(fullUsers)
        }

        var filteredUsers = {};

        for (const [key, value] of Object.entries(fullUsers)) {
            if (fullUsers[key].firstName !== undefined) { 
                if(fullUsers[key].firstName.toLowerCase().includes(keyword.toLowerCase())){
                    filteredUsers[key] = value;
                }
            }
        }
        SortUsers(filteredUsers, sortParameter);
    }

    const AddSortParameter = (parameter) => {  
        SearchUsers(keyword, fullUsers, parameter);
    }

    const SortUsers = (filteredUsers, sortParameter) => {
        var sortedList = Object.entries(filteredUsers);

        if(sortParameter === "Archived"){
            sortedList = SortOnlyArchivedUsers(sortedList);
        }
        else{
            sortedList = RemoveArchivedUsers(sortedList);
        }

        if(sortParameter === "FirstName"){           
            sortedList = sortedList.sort((a,b)=>((a[1].firstName.toLowerCase() < b[1].firstName.toLowerCase()) ? -1 : 
            (a[1].firstName.toLowerCase() > b[1].firstName.toLowerCase()) ? 1 : 0));
        }
        else if(sortParameter === "Date"){
            var tempArray = [];
            var noTimeArray =[];

            for(let i = 0; i < sortedList.length; i++){
                if(sortedList[i][1].timeStamp){
                    tempArray.push(sortedList[i]);
                }
                else{
                    noTimeArray.push(sortedList[i]);
                }
            }

            sortedList = tempArray;

            sortedList.sort(function(a, b){
                return b[1].timeStamp.seconds - a[1].timeStamp.seconds;
            })

            for(let i = 0; i < noTimeArray.length; i++){
                sortedList.push(noTimeArray[i]);
            }
        }

        setSortByParameter(sortParameter);
        setUsers(sortedList);
    }

    const SortOnlyArchivedUsers = (userList) => {
        var tempArray = [];
        
        for(let i = 0; i < userList.length; i++){
            if(userList[i][1].archived){
                tempArray.push(userList[i]);
            }
        }

        return tempArray;
    }

    const RemoveArchivedUsers = (userList) => {
        var tempArray = [];
        
        for(let i = 0; i < userList.length; i++){
            if(!userList[i][1].archived){
                tempArray.push(userList[i]);
            }
        }

        return tempArray;
    }

    const SuccessUpdate = () => {
        setModalTitle("Users Updated");
        setModalMessage("List of users have been updated");
        setModalCancelText("Okay");
        setModalConfirmOn(false);
        setModal(true);
    }

    const FailedUpdate = () => {
        setModalTitle("Update Failed");
        setModalMessage("Something happened, failed to update user list");
        setModalCancelText("Okay");
        setModalConfirmOn(false);
        setModal(true);
    }

    const SetAdminStatus = (user, isAdmin) =>{
        var data = {admin: isAdmin}
        updateUser(teamUnbreakableDB, user.id, data, SuccessUpdate, FailedUpdate);
    }

    const MakeInstructor = (user) => {
        var data = {awaitingInstructorConfirmation: !user.instructor, instructor: !user.instructor}
        updateUser(teamUnbreakableDB, user.id, data, SuccessUpdate, FailedUpdate);
    }

    const ArchiveUser = (user) => {
        var data = user;
        if(!user.archived){
            data.archived = true;
        }
        else{
            data.archived = false;
        }

        updateUser(teamUnbreakableDB, user.id, data, SuccessUpdate, FailedUpdate);
    }

    const refreshUsers = () => {
        //updateUsers(teamUnbreakableDB); //TODO this is still using the "Admin", "Users" Collection/document
    };

    /* EB: Used this function to move all the users from a document to their own documents.
    const deleptual = () => {
        console.log(fullUsers);
        const u = Object.entries(fullUsers).map((user) =>  {
            console.log(user[0]);
            console.log(user[1]);
            
            if (user[0] !== undefined || user[0] !== null || user[0] !== ""){
                setDocument(teamUnbreakableDB, "users", user[0], user[1], () => {console.log("Success: " + user[0])}, () => {console.log("Failure: " + user[0])});
            }
            
        })
    };
    */

    return (
        <React.Fragment>
            <ModalWindow 
                cancelText={modalCancelText} 
                isOpen={modal} 
                title={modalTitle} 
                message={modalMessage} 
                cancelFunction={toggle} 
                confirmFunction={modalConfimFunction} 
                showConfirmButton={modalConfirmOn} 
            />
            {console.log(fullUsers)}
            <PrimaryContainer>
                {/* <Button onClick={() => {refreshUsers()}}>Refresh Users</Button> */}
                {/*EB: We had this button to populate the Users collection
                <Button onClick={() => {deleptual()}}>Deleptual</Button>
                */}
                <Input onChange={e => SearchUsers(e.target.value, fullUsers)} value={keyword} placeholder="Search Users..."/>
                <h1 onClick={() => setAreUsersVisible(!areUsersVisible)}>
                    Student
                    {!areUsersVisible && <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                    {areUsersVisible && <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton>}
                </h1>
                <Collapse isOpen={areUsersVisible}>
                    <Row>
                        <Col>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th><StyledAnchor onClick={() => AddSortParameter("FirstName")}>First Name</StyledAnchor></th>
                                        <th>Email</th>
                                        <th>Program Leader</th>
                                        <th>Location</th>
                                        <th><StyledAnchor onClick={() => AddSortParameter("Date")}>Registration Date</StyledAnchor></th>
                                        <th>Make Program Leader</th>
                                        {/*<th>Verified</th>*/}
                                        <th><StyledAnchor onClick={() => AddSortParameter("Archived")}>Archive User</StyledAnchor></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.length > 0 && users.map(user => {
                                         if(!user[1].awaitingInstructorConfirmation){ 
                                             return <tr key={user[0]}>
                                                <td>{user[1].firstName}</td>
                                                <td>{user[1].email}</td>
                                                <td>{user[1].teacher}</td>
                                                <td>{user[1].location}</td>
                                                <td>{(user[1].timeStamp !== undefined && user[1].timeStamp != "") ? 
                                                    user[1].timeStamp.toDate().toLocaleString("en-US") : 
                                                    ""}
                                                </td>
                                                <td><Input type="checkbox" onChange={() => MakeInstructor(user[1])} defaultChecked={user[1].instructor}></Input></td>
                                                {/*<td><Input type="checkbox" disabled defaultChecked={user[1].verified}/></td>*/}
                                                <td><Input type="checkbox" onChange={() => ArchiveUser(user[1])} defaultChecked={user[1].archived}></Input></td>
                                        </tr>
                                         }
                                    })}
                                </tbody>
                            </Table>
                            <InputGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                </Collapse>
            </PrimaryContainer>
            <PrimaryContainer>
                <h1 onClick={() => setAreInstructorsVisible(!areInstructorsVisible)}>
                    Program Leaders
                    {!areInstructorsVisible && <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                    {areInstructorsVisible && <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton>}
                </h1>
                <Collapse isOpen={areInstructorsVisible}>
                    <Row>
                        <Col>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th><StyledAnchor onClick={() => AddSortParameter("FirstName")}>First Name</StyledAnchor></th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Position</th>
                                        <th>Organization</th>
                                        <th><StyledAnchor onClick={() => AddSortParameter("Date")}>Registration Date</StyledAnchor></th>
                                        <th>Admin</th>
                                        {false && <th>Valid Program Leader</th>}
                                        {/*<th>Verified</th>*/}
                                        <th><StyledAnchor onClick={() => AddSortParameter("Archived")}>Archive</StyledAnchor></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.length > 0 && users.map(user => {
                                         if(user[1].awaitingInstructorConfirmation){ 
                                             return <tr key={user[0]}>
                                            <td>{user[1].firstName}</td>
                                            <td>{user[1].lastName}</td>
                                            <td>{user[1].email}</td>
                                            <td>{user[1].position ? user[1].position : ""}</td>
                                            <td>{user[1].organization ? user[1].organization : ""}</td>
                                            <td>{user[1].timeStamp !== undefined ? 
                                                user[1].timeStamp.toDate().toLocaleString("en-US") : 
                                                ""}
                                            </td>
                                            <td><Input type="checkbox" onChange={() => SetAdminStatus(user[1], !user[1].admin)} defaultChecked={user[1].admin}></Input></td>
                                            {false && <td><Input type="checkbox" onChange={() => MakeInstructor(user[1])} defaultChecked={user[1].instructor}></Input></td>}
                                            {/*<td><Input type="checkbox" disabled defaultChecked={user[1].verified}/></td>*/}
                                            <td><Input type="checkbox" onChange={() => ArchiveUser(user[1])} defaultChecked={user[1].archived}></Input></td>
                                        </tr>
                                        }
                                    })}
                                </tbody>
                            </Table>
                            <InputGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                </Collapse>
            </PrimaryContainer>
        </React.Fragment>
     );
}
 
export default AdminUsers;

const StyledAnchor = styled.a`
    text-decoration: underline;
    color: black;
    &:hover {
        color: ${Colors.primaryblue};
        cursor: pointer;
    }
`;

const PrimaryContainer = styled(Container)`
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;