const gtag = window.gtag;

export const PageView = (title, location) => {  
    gtag('event', 'page_view', {
        page_title: title,
        page_location: location,
    });
}

export const Event = (name, category = "", label ="", value = 0) => {
    gtag('event', name, {
        'event_category': category,
        'event_label': label,
        'value': value
    });
};

export const UpdateUser = (user_id) => {
    gtag('set', {
        'user_id': user_id,
    });
}

