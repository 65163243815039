import React from 'react'
import styled from 'styled-components';

export const InstagramFeed = (props) => {
    const { id, caption, media_type, media_url} = props.feed
    let post;

    const handlePostClick = (url) => {
        window.open("https://www.instagram.com/officialteamunbreakable/?hl=en", '_blank');
    };

    switch (media_type) {
        case "VIDEO":
            post = (
                <StyledVideo onClick={() => handlePostClick(media_url)}
                    width='100%'
                    height='auto' 
                    src={media_url} 
                    type="video/mp4" 
                    controls playsinline>
                </StyledVideo>
            )
            break;
        case "CAROUSEL_ALBUM":
            post = (
                <StyledIMG onClick={() => handlePostClick(media_url)}
                    width='100%'
                    height='auto'
                    id={id} 
                    src={media_url} 
                    alt={caption} 
                />
            );
            break;
        default:
            post = (
                <StyledIMG onClick={() => handlePostClick(media_url)}
                    width='100%'
                    height='auto'
                    id={id} 
                    src={media_url} 
                    alt={caption} 
                />
            );
    }       

    return (
        <React.Fragment>
            {post}
        </React.Fragment>
    );
}

export default InstagramFeed;

const StyledIMG = styled.img`
    border-radius: 15px;
    height: 120px;
    width: 120px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline;
    vertical-align: bottom;
    object-fit: cover;
`;

const StyledVideo = styled.video`
    display: inline;    
    height: 120px;
    width: 120px;
    border-radius: 15px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    vertical-align: bottom;
`;
