import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, InputGroup, Input } from "reactstrap";
import { teamUnbreakableDB, generateGUID } from "../../utils/firestore";
import { listenToDocumentProperty, updateDocumentProperty } from "../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import AdminLinkRow from './admin-link-row';

const AdminLinks = () => {
    const [links, setLinks] = useState({});

    const [title, setTitle] = useState("");
    const [linkDescription, setLinkDescription] = useState("");
    const [linkURL, setLinkURL] = useState("");
    const [order, setOrder] = useState(0);

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "links", "links", setLinks);
        return unsubscribe;
    }, []);

    const addLink = () => {
        let id = generateGUID();
        let e = {
            order: order,
            id: id,
            title: title,
            description: linkDescription,
            linkURL: linkURL,
        }
        updateDocumentProperty(teamUnbreakableDB, "content", "links", "links." + id, e); 
        clearInput();
    }

    const clearInput = () => {
        setOrder(0);
        setTitle("");
        setLinkDescription("");
        setLinkURL("");
    }

   return (<Container>
    <Row>
        <CenterFlexCol>
            <h1>Links</h1>
        </CenterFlexCol>
    </Row>
    {(Object.entries(links).length > 0) && Object.entries(links).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
        return <ColoredDiv key={t[0]}>
            <AdminLinkRow id={t[0]} linkURL={t[1].linkURL} order={t[1].order} description={t[1].description} title={t[1].title}></AdminLinkRow>
        </ColoredDiv>
    })}
    <Row>
        <Col>
            <h2>Add Link</h2>
        </Col>
    </Row>
        <Row>
        <FlexCol xs="1">
            <Button onClick={() => {addLink()}} color="primary">
                <FAIconButton icon={["fas", "plus-circle"]}></FAIconButton>
            </Button>
        </FlexCol>
        <FlexCol>
        </FlexCol>
    </Row>
    <Row>
        <Col>
            <InputGroup>
                <div addonType="prepend">
                    Order
                </div>
                <Input value={order} onChange={e => setOrder(e.target.value)} />
            </InputGroup>
            <InputGroup>
                <div addonType="prepend">
                    Title
                </div>
                <Input value={title} onChange={e => setTitle(e.target.value)} />
            </InputGroup>
            <InputGroup>
                <div addonType="prepend">
                    Description
                </div>
                <Input value={linkDescription} onChange={e => setLinkDescription(e.target.value)} />
            </InputGroup>
            <InputGroup>
                <div addonType="prepend">
                    Link URL
                </div>
                <Input value={linkURL} onChange={e => setLinkURL(e.target.value)} />
            </InputGroup>
        </Col>
    </Row>
</Container>);
}
export default AdminLinks;


const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const StyledP = styled.p`
    margin: 0px;
`;

const ColoredDiv = styled.div`
    margin-bottom: 5px;
    padding: 5px;
    padding-top: 15px;
    background-color: rgba(0,0,0,0.05);
`;

const FlexCol = styled(Col)`
    display: flex;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;