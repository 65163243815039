import React from 'react';
import {  Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";

const ModalWindow = ({isOpen, title, message, cancelFunction, cancelText = "Cancel", confirmFunction, showConfirmButton = true, confirmButtonText = "Confirm"}) => {
    return (
    <StyledModal isOpen={isOpen}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
            {message}
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={cancelFunction}>{cancelText}</Button>
            {showConfirmButton && <Button color="primary" onClick={confirmFunction}>{confirmButtonText}</Button>}
        </ModalFooter>
    </StyledModal> );
}
 
export default ModalWindow;


const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`