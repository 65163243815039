import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, InputGroup, Input } from "reactstrap";
import { teamUnbreakableDB, generateGUID } from "../../../utils/firestore";
import { listenToDocumentProperty, updateDocumentProperty } from "../../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import AdminAboutProgram from "./admin-about-program";

export const AdminAbout = () => {
 
    const [program, setProgram] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [order, setOrder] = useState(0);

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "about", "program", setProgram);
        return unsubscribe;
    }, [])

    const addProgramSection = () => {
        var id = generateGUID();
        var data = {
            description: description,
            title: title,
            order: order,
            id: id
        }
        updateDocumentProperty(teamUnbreakableDB, "content", "about", "program." + id, data); 
        clearInput();
    }

    const clearInput = () => {
        setTitle("");
        setDescription("");
        setOrder(0);
    }

    return(
        <Container>
            <Row>
                <CenterFlexCol>
                    <h1>About Program</h1>
                </CenterFlexCol>
            </Row>
            {(Object.entries(program).length > 0) && Object.entries(program).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                return <ColoredDiv key={t[0]}>
                    <AdminAboutProgram id={t[0]} order={t[1].order} title={t[1].title} url={t[1].imgURL} description={t[1].description}></AdminAboutProgram>
                </ColoredDiv>
            })}
            <Row>
                <FlexCol xs="1">
                    <Button onClick={() => {addProgramSection()}} color="primary">
                        <FAIconButton icon={["fas", "user-plus"]}></FAIconButton>
                    </Button>
                </FlexCol>
                <FlexCol>
                    <InputGroup>
                        <div style={{width:"100px"}}>
                            Title
                        </div>
                        <Input value={title} onChange={e => setTitle(e.target.value)} />
                    </InputGroup>
                    <InputGroup>
                        Order
                        <Input value={order} onChange={e => setOrder(e.target.value)} />
                    </InputGroup>
                </FlexCol>
            </Row>
            <Row>
                <Col>
                    <InputGroup>
                        Description
                        <Input type="textarea" value={description} onChange={e => setDescription(e.target.value)} />
                    </InputGroup>
                </Col>
            </Row>
        </Container>
    ) 
}

export default AdminAbout;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const ColoredDiv = styled.div`
    margin-bottom: 5px;
    padding: 5px;
    padding-top: 15px;
    background-color: rgba(0,0,0,0.05);
`;

const FlexCol = styled(Col)`
    display: flex;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;