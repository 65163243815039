import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Copy } from "./physical-module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { ColoredH2, StyledHR, ColoredOL, ColoredUL, StyledP, CenterTitleFlexCol, WhiteH2, LargeInput, BoldP } from "../../styles/styledComponents";
import StarsImage from '../../images/stars.png';
import styled from "styled-components";
import { Colors } from "../../utils/constants";

export const PhysicalModuleAlternativeCardio = ( props ) => {

    const [user, setUser] = useState();
    const [input1, setInput1] = useState((props.user.moduleData !== undefined && props.user.moduleData.alternativeCardio !== undefined && props.user.moduleData.alternativeCardio.input1 != undefined) ? props.user.moduleData.alternativeCardio.input1.input1 : "");
    const [input2, setInput2] = useState((props.user.moduleData !== undefined && props.user.moduleData.alternativeCardio !== undefined && props.user.moduleData.alternativeCardio.input2 != undefined) ? props.user.moduleData.alternativeCardio.input2.input2 : "");
    const module = "Alternative Cardiovascular Workouts";

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const completeModule = () => {
        var data = user;
        data.modules.introduction = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    const UpdateInput = (inputKey, inputValue) => {
        var data = user;
        data.moduleData.module1[inputKey] = inputValue;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy[module].Title[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy[module].SubTitle1[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <StyledP>{Copy[module].Paragraph1[props.user.language]}</StyledP>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ColoredH2>{Copy[module].SubTitle2[props.user.language]}</ColoredH2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <StyledHR></StyledHR>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <StyledP>{Copy[module].Paragraph2[props.user.language]}</StyledP>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BoldP>{Copy[module].SubTitle3[props.user.language]}</BoldP>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <LargeInput style={{maxWidth:"100%"}} value={input1} onChange={e => setInput1(e.target.value)} onBlur={() => {UpdateInput("input1", {input1})}}></LargeInput>
                            </Col>
                        </Row>
                    </Col>
                    <CenterFlexVerticalCol lg="4">
                            <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <CenterFlexVerticalCol>
                        <PhysicalActivityIMG src={"https://team-unbreakable-space.nyc3.cdn.digitaloceanspaces.com/pictures/modules/physical-modules/stairs.png"}></PhysicalActivityIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy[module].SubTitle4[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle1[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].Paragraph3[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle2[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].Paragraph4[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <StyledWhiteRow>
                    <Col>
                        <ColoredH2>{Copy[module].SubTitle5[props.user.language]}</ColoredH2>
                    </Col>
                </StyledWhiteRow>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].Paragraph5[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle3[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List1Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List1Item3[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle4[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List2Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List2Item2[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle5[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List3Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List3Item2[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle6[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].List4Item1[props.user.language]}</StyledP>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List4Item1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List4Item1Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List4Item1Item3[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].List5Item1[props.user.language]}</StyledP>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List5Item1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List5Item1Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List5Item1Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List5Item1Item4[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].List6Item1[props.user.language]}</StyledP>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List6Item1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List6Item1Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List6Item1Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List6Item1Item4[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle7[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledURL href={Copy[module].URL1[props.user.language]}>Video Example</StyledURL>
                    </Col>
                </Row>
                <StyledWhiteRow>
                    <Col>
                        <Row>
                            <Col>
                                <ColoredH2>{Copy[module].BoldTitle8[props.user.language]}</ColoredH2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <StyledP>{Copy[module].Paragraph6[props.user.language]}</StyledP>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs='1'>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <ColoredH2>{Copy[module].BoldTitle9[props.user.language]}</ColoredH2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <StyledP>{Copy[module].Paragraph7[props.user.language]}</StyledP>
                            </Col>
                        </Row>
                    </Col>
                </StyledWhiteRow>
                <StyledWhiteRow>
                    <Col>
                        <ColoredH2>{Copy[module].SubTitle6[props.user.language]}</ColoredH2>
                    </Col>
                </StyledWhiteRow>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].Paragraph8[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle10[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List7Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List7Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List7Item3[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle11[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List8Item1[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle12[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List9Item1[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle13[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].Paragraph9[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List10Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List10Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List10Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List10Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List10Item5[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List10Item6[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List10Item7[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List10Item8[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List10Item9[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle14[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].Paragraph10[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].Paragraph11[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List11Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List11Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List11Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List11Item4[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BoldP>{Copy[module].BoldTitle15[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledURL href={Copy[module].URL2[props.user.language]}>Video Example</StyledURL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy[module].BoldTitle16[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LargeInput style={{maxWidth:"100%"}} value={input1} onChange={e => setInput1(e.target.value)} onBlur={() => {UpdateInput("input1", {input1})}}></LargeInput>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default PhysicalModuleAlternativeCardio;

const PhysicalActivityIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StarsIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;

const StyledWhiteRow = styled(Row)`
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
    background-color: white;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const StyledURL = styled.a`
`;