export const Copy = {
    "Module Introduction": {
        "Title": {
            "English": "TEAM UNBREAKABLE ROADMAP",
            "French": "La feuille de route de L’équipe imbattable",
        },
        "SubTitle1": {
            "English": "Mental Health",
            "French": "Santé mentale",
        },
        "SubTitle2": {
            "English": "Core Program",
            "French": "Programme de base",
        },
        "Module1": {
            "English": "Module #1: Physical Health for Mental Health",
            "French": "Module 1: La santé physique pour la santé mentale",
        },
        "Module2": {
            "English": "Module #2: Goal Setting",
            "French": "Module 2: Établir des objectifs",
        },
        "Module3": {
            "English": "Module #3: Motivation",
            "French": "Module 3: La motivation",
        },
        "Module4": {
            "English": "Module #4: Psychological Skills",
            "French": "Module 4: Les habiletés psychologiques",
        },
        "SubTitle3": {
            "English": "Additional Modules",
            "French": "Modules supplémentaires",
        },
        "Module5": {
            "English": "Module #5: Social Benefits",
            "French": "Module 5: Bienfaits sociaux",
        },
        "Module6": {
            "English": "Module #6: Body Image",
            "French": "Module 6: Image corporelle",
        },
        "Module7": {
            "English": "Module #7: Self Compassion",
            "French": "Module 7: Auto-compassion",
        },
        "Module8": {
            "English": "Module #8: Creating A Balanced Life",
            "French": "Module 8: Créer une vie équilibrée",
        },
        "SubTitle4": {
            "English": "Physical Activity - Resources",
            "French": "Activité physique - Ressources",
        },
        "Resource1": {
            "English": "Running Schedule and Training Resources",
            "French": "Horaire de course et ressources d’entraînement",
        },
        "Resource2": {
            "English": "Worksheets: Runners Log, Physical Activity Self-Awareness Exercise",
            "French": "Feuilles de travail: Journal de bord du coureur, les exercices de prise de conscience face à l’activité physique",
        },
    },
    "Module 1": {
        "Title": {
            "English": "Module #1",
            "French": "Module 1",
        },
        "SubTitle1": {
            "English": "Physical Health for Mental Health",
            "French": "Santé physique pour la santé mentale",
        },
        "SubTitle2": {
            "English": "Benefits of Physical Health for Mental Health",
            "French": "Les bienfaits de l’activité physique sur la santé mentale",
        },
        "List1Title": {
            "English": "Making time for physical activity provides serious mental health benefits:",
            "French": "Lorsqu’on prend le temps de faire de l’activité physique, notre santé mentale en bénéficie grandement:",
        },
        "List1Item1Title": {
            "English": "Stress reduction: ",
            "French": "Réduction du stress",
        },
        "List1Item1": {
            "English": "Physical activity helps to moderate the brain's response to stress and boost the body's ability to deal with existing tension.",
            "French": "L’activité physique aide à modérer la réponse de notre cerveau face au stress et améliore notre habilete à gérer les tensions existantes.",
        },
        "List1Item2Title": {
            "English": "Better mood: ",
            "French": "Meilleure humeur:",
        },
        "List1Item2": {
            "English": "Exercise release endorphins, which create feelings of happiness and euphoria. Studies have shown that physical activity can even help to alleviate symptoms among those suffering from depression and anxiety.",
            "French": " L’exercice relâche des endorphines, qui créent un sentiment de bonheur et d’euphorie. Des études ont démontré que l’activité physique peut même atténuer des symptômes chez ceux et celles souffrant de dépression et d’anxiété.",
        },
        "List1Item3Title": {
            "English": "Cognitive (brain) benefits: ",
            "French": "Bienfaits cognitifs (cerveau):",
        },
        "List1Item3": {
            "English": "Regular physical activity boosts memory and can improve overall brain performance.",
            "French": " L’activité physique régulière améliore notre mémoire et notre performance cognitive en général.",
        },
        "List1Item4Title": {
            "English": "Improved body image and self-esteem: ",
            "French": "Amélioration de l’image de soi et estime de soi:",
        },
        "List1Item4": {
            "English": "On a very basic level, physical fitness can boost self-esteem and improve positive self-image. Physical activity makes you feel better about yourself.",
            "French": " À la base, la forme physique peut remonter ton estime de soi et améliorer ton image de soi. L’activité physique fait en sorte que tu te sens mieux avec toi-même.",
        },
        "List1Item5Title": {
            "English": "More confidence: ",
            "French": "Plus de confiance",
        },
        "List1Item5": {
            "English": "Setting physical activity goals and seeing your progress (e.g., through apps or wearable trackers) can be a major source of confidence and provide a sense of accomplishment and mastery.",
            "French": " Fixer des objectifs d’activité physique et voir tes progrès (p. ex., avec l’aide d’applis ou d’outils comme une montre intelligente) peut augmenter ta confiance et générer un sentiment d’accomplissement et de maîtrise.",
        },
        "List1Item6Title": {
            "English": "Connection to others: ",
            "French": "Connexion aux autres:",
        },
        "List1Item6": {
            "English": "Physical activity often creates connections (either real or perceived) with other people and this feeling of connection builds positive mood.",
            "French": " l’activité physique favorise les connexions (réelles ou perçues) avec d’autres personnes et ce sentiment de connexion est positif pour notre humeur.",
        },
        "Input1Header": {
            "English": "What are your main reasons for wanting to do more physical activity?",
            "French": "Quelles sont les raisons principales pour lesquelles tu veux faire plus d’activité physique?",
        },
        "List2Title": {
            "English": "Other benefits of physical activity:",
            "French": "D’autres bienfaits de l’activité physique",
        },
        "List2Item1": {
            "English": "Improving strength",
            "French": "Améliorer sa force",
        },
        "List2Item2": {
            "English": "Improving sleep",
            "French": "Améliorer son sommeil",
        },
        "List2Item3": {
            "English": "Improving energy levels",
            "French": "Améliorer son niveau d’énergie",
        },
        "List2Item4": {
            "English": "Feeling healthier overall",
            "French": "Se sentir mieux en général",
        },
        "List2Item5": {
            "English": "Improving focus / concentration",
            "French": "Améliorer son attention et sa concentration",
        },
        "List2Item6": {
            "English": "Improving endurance",
            "French": "Améliorer son endurance",
        },
        "SubTitle3": {
            "English": "Physical Activity Self Reflection",
            "French": "Introspection sur mon activité physique",
        },
        "Input2Header": {
            "English": "How active would you consider yourself right now?",
            "French": "Quel est ton niveau d’activité physique présentement?",
        },
        "Input2Tooltip1": {
            "English": "Inactive (< 2 hours/week)",
            "French": "Inactif(ve) (< 2 heures par semaine)",
        },
        "Input2Tooltip2": {
            "English": "Active (~ 2 hours/week)",
            "French": "Actif(ve) ( 2 heures par semaine)",
        },
        "Input2Tooltip3": {
            "English": "Very Active (> 2 hours/week)",
            "French": "Très actif(ve) (> 2 heures par semaine)",
        },
        "Input3Header": {
            "English": "Are you currently involved in any kind of physical activity?",
            "French": "Pratiques-tu présentement une activité physique?",
        },
        "Input4Header": {
            "English": "If so, what do you do?",
            "French": "Si oui, que fais-tu?",
        },
        "Input5Header": {
            "English": "What kind of physical activity do you like?",
            "French": "Quel genre d’activité aimes-tu?",
        },
        "Input6Header": {
            "English": "What do you dislike?",
            "French": "Qu’est-ce que tu n’aimes pas?",
        },
        "Input7Header": {
            "English": "If you have tried to change your physical activity levels in the past, what kind of things got in your way?",
            "French": " Si tu as tenté de modifier ton niveau d’activité physique dans le passé, quels obstacles as-tu rencontrés?",
        },
        "Input8Header": {
            "English": "What helped?",
            "French": "Qu’est-ce qui t’a aidé?",
        },
        "Input9Header": {
            "English": "Think of another habit or behaviour that you have made changes to before.",
            "French": "Pense à une autre habitude ou un comportement que tu as déjà changé.",
        },
        "Input10Header": {
            "English": "What helped you succeed in making that change?",
            "French": "Comment as-tu réussi à faire le changement?",
        },
        "SubTitle4": {
            "English": "Below are some PSYCHOLOGICAL SKILLS that relate to physical activity",
            "French": " Voici des habiletés psychologiques liées à l’activité physique",
        },
        "SubTitle5": {
            "English": "On a scale of 1 (not good at this skill at all) to 10 (excellent at this skill), how would you rate yourself on each of these skill areas?",
            "French": " Sur une échelle de 1 (pas du tout habile) à 10 (j’excelle dans ce domaine), quelle cote te donnerais-tu pour chaque habileté?",
        },
        "Input11Header": {
            "English": "Stress Management",
            "French": "Gestion du stress",
        },
        "Input12Header": {
            "English": "Self-Talk",
            "French": "Dialogue intérieur",
        },
        "Input13Header": {
            "English": "Imagery / Visualization",
            "French": "Imagerie mentale / visualisation",
        },
        "Input14Header": {
            "English": "Confidence",
            "French": "Confiance",
        },
        "Input15Header": {
            "English": "Motivation",
            "French": "Motivation",
        },
        "Input16Header": {
            "English": "Concentration",
            "French": "Concentration",
        },
        "SubTitle6": {
            "English": "Committing to yourself",
            "French": "MON ENGAGEMENT ENVERS MOI-MÊME",
        },
        "Paragraph1": {
            "English": "I",
            "French": "Je",
        },
        "Paragraph2": {
            "English": "am making a commitment to myself to work towards becoming more physically active.",
            "French": " m’engage à devenir plus actif/active physiquement.",
        },
        "Paragraph3": {
            "English": "I hereby commit to taking responsibility for my health and the actions required to achieve my running or physical activity goals. I realize that this process will be very rewarding, but that it will also be challenging. I promise to give it my continued effort.",
            "French": " Je m’engage à prendre en main ma santé et poser les gestes pour atteindre mes objectifs liés à la course ou l’activité physique. Je réalise que ce processus sera gratifiant, mais qu’il y aura aussi des défis. Je promets de fournir un effort continu.",
        },
        "List3Header": {
            "English": "During this challenge, I promise to:",
            "French": " Pendant ce défi, je promets de:",
        },
        "List3Item1": {
            "English": "Relinquish excuses",
       "French": "renoncer aux excuses",
        },
        "List3Item2": {
            "English": "Devote time to learning how to become more active and make use of what I learn",
            "French": "consacrer du temps pour apprendre comment devenir plus actif/active et appliquer ce que j’apprends",
        },
        "List3Item3": {
            "English": "Not blame anyone, including myself, for setbacks",
            "French": "ne pas blâmer quiconque, incluant moi-même, pour des obstacles",
        },
        "List3Item4": {
            "English": "Remain focused and get back on track when my running or physical activity does not go as planned",
            "French": "garder mon focus et me reprendre en main lorsque ma course ou mon activité physique ne va pas tel que planifié",
        },
        "List3Item5": {
            "English": "Remember why I am trying to become more active, especially when I am tempted to give in or give up",
            "French": "me souvenir pourquoi je désire devenir plus actif/active, surtout lorsque je pense abandonner",
        },
        "List3Item6": {
            "English": "Give myself positive feedback and not let negative thoughts stop me from achieving my goals",
            "French": "m’offrir une rétroaction positive et ne pas laisser les pensées négatives m’empêcher d’atteindre mes objectifs",
        },
        "List3Item7": {
            "English": "Ask for support from my friends and family to help keep me accountable and provide encouragement",
            "French": "demander l’appui de mes amis et ma famille afin que je sois redevable et pour qu’ils m’encouragent.",
        },
        "SubTitle7": {
            "English": "Homework",
            "French": "Devoir",
        },
        "Paragraph4": {
            "English": "Complete the Physical Activity Self-Awareness Exercise on a minimum of 2 days this week",
            "French": "Complète l’exercice Prise de conscience sur mon activité physique pendant au moins 2 jours cette semaine",
        },
        "Paragraph5": {
            "English": "Use your Runner's Log to start tracking your runs, including your pre- and post-run mood",
            "French": " Utilise ton Journal de bord du coureur pour commencer à documenter tes courses, incluant ton humeur avant et après l’activité.",
        },
    },
    "Module 2": {
        "Title": {
            "English": "Module #2",
            "French": "Module 2",
        },
        "SubTitle1": {
            "English": "Goal Setting",
            "French": "Établir des objectifs",
        },
        "SubTitle2": {
            "English": "Why Do We Need Goals",
            "French": "Pourquoi a-t-on besoin des objectifs?",
        },
        "List1Title": {
            "English": "Goals are like a mental 'finish line', they give us a reason to work hard and help us focus on a task. They also:",
            "French": " Les objectifs sont mentalement comme une ‘ligne de fin’, ils nous donnent une raison pour travailler fort et nous aide à se concentrer sur la tâche.  De plus, ils:",
        },
        "List1Item1": {
            "English": "Increase self-awareness & self-confidence",
            "French": "augmentent notre prise de conscience et notre confiance",
        },
        "List1Item2": {
            "English": "Decrease feelings of anxiety",
            "French": "diminuent les sentiments d’anxiété",
        },
        "List1Item3": {
            "English": "Help us manage our time",
            "French": "nous aident à gérer notre temps",
        },
        "List1Item4": {
            "English": "Help us commit to a process or plan (e.g., a training plan)",
            "French": "nous aident à s’engager au processus ou au plan (p. ex., un plan d’entraînement)",
        },
        "List1Item5": {
            "English": "Provide long-term vision and short-term motivation",
            "French": "fournissent une vision à long terme et une motivation à court terme",
        },
        "List1Item6": {
            "English": "Help us get more engaged in the learning process",
            "French": "nous aident à s’engager dans le processus d’apprentissage",
        },
        "SubTitle3": {
            "English": "Principles of Goal Setting: A.I.M.S.",
            "French": "Principes liés aux objectifs: A.I.M.S.",
        },
        "TableR1C1": {
            "English": "Achievable",
            "French": "Atteignable",
        },
        "TableR1C2": {
            "English": "Realistic and challenging; Hard enough to require effort, but not impossible",
            "French": " Réaliste et ambitieux; assez difficile pour nécessiter un effort mais pas impossible",
        },
        "TableR2C1": {
            "English": "Important",
            "French": "Important",
        },
        "TableR2C2": {
            "English": "Valuable to YOU; Aligned with what you want for yourself",
            "French": "Significatif pour TOI; s’aligne avec ce que tu désires pour toi-même",
        },
        "TableR3C1": {
            "English": "Measurable",
            "French": "Mesurable",
        },
        "TableR3C2": {
            "English": "Able to be evaluate, so that you can determine whether you're on track or whether you need to adjust your efforts or your goal",
            "French": " Te permet d’évaluer si tu es sur la bonne voie ou si tu dois ajuster tes efforts ou ton objectif",
        },
        "TableR4C1": {
            "English": "Specific",
            "French": "Spécifique",
        },
        "TableR4C2": {
            "English": "As detailed as possible, including a timeframe",
            "French": " Aussi détaillé que possible, incluant un échéancier",
        },
        "SubTitle4": {
            "English": "Types Of Goals",
            "French": "Types d’objectifs",
        },
        "Paragraph1": {
            "English": "Goals can be focused on process or outcome. Setting process goals can help plan what needs to be done to achieve your outcome goals. This can ultimately maximize the likelihood of achieving your longer-term outcome goals.",
            "French": " Les objectifs peuvent être centrés sur un processus ou un résultat souhaité. Fixer des objectifs liés au processus peut te permettre de planifier les étapes pour accomplir tes objectifs liés au résultat. Ceci peut augmenter les chances d’atteindre ton objectif final qui est à long terme.",
        },
        "Table2R1C1": {
            "English": "Outcome goals focus on the end result:",
            "French": "L’objectif final vise le résultat souhaité:",
        },
        "Table2R1C2": {
            "English": "\"I want to run the whole 5km without stopping\"",
            "French": " ‘Je veux courir la course de 5 km sans arrêter.’ ",
        },
        "Table2R2C1": {
            "English": "Process goals focus on the specific actions that help you achieve your outcome goal:",
            "French": "L’objectif de processus vise les actions spécifiques ou les étapes qui te permettent d’atteindre ton objectif final:",
        },
        "Table2R2C2": {
            "English": "\"I plan to attend ALL the training runs in Team Unbreakable\"",
            "French": " ‘Je planifie de participer à TOUTES les courses d’entraînement de L’équipe imbattable.’ ",
        },
        "List2Title": {
            "English": "Goals can also be focused on short-term or long-term achievements. If your goal is to achieve something by the end of the winter months, make smaller, progressive goals for the end of each month that, if achieved, will put you on track to achieving the larger goal. Short-term goals help you:",
            "French": " Les objectifs peuvent également être centrés sur des réalisations à court terme ou à long terme. Si ton objectif est de réussir quelque chose avant la fin de l’hiver, fixe des plus petits objectifs progressifs pour la fin de chaque mois. En atteignant chacun d’eux, tu seras sur la bonne voie pour atteindre ton plus grand objectif.  Les petits objectifs t’aident à:",
        },
        "List2Item1": {
            "English": "Track your progress",
            "French": "garder une trace de tes progrès",
        },
        "List2Item2": {
            "English": "Adjust your effort or your goal if you need to",
            "French": "adjuster tes efforts ou ton objectif au besoin",
        },
        "List2Item3": {
            "English": "Focus on more manageable \"chunks\" instead of being overwhelmed by long-term goals",
            "French": "concentrer sur des plus petites bouchées plutôt que de se sentir dépassé par l’objectif final",
        },
        "List2Item4": {
            "English": "Feel encouraged every time you achieve a short term goal!",
            "French": "te sentir encouragé(e) chaque fois que tu atteins un objectif à court terme!",
        },
        "SubTitle5": {
            "English": "Tips To Remember About Goals",
            "French": "Conseils à retenir au sujet des objectifs",
        },
        "Paragraph2": {
            "English": "Specific and realistic goals work best. The people who succeed in making long-lasting changes are those who set realistic, specific goals. \"I'm going to recycle all my plastic bottles and magazines\" is a much more doable and clear goal than, \"I'm going to do more for the environment.\" And that makes it easier to stick to it!",
            "French": " Les objectifs spécifiques et réalistes fonctionnent le mieux. Les gens qui réussissent à faire des changements durables sont ceux qui se fixent des objectifs spécifiques et réalistes. ‘Je vais recycler toutes mes bouteilles en plastique et mes revues’ est un objectif plus clair et faisable que ‘Je vais en faire plus pour l’environnement’. C’est donc plus facile à s’y tenir.",
        },
        "Paragraph3": {
            "English": "Changes take time to become habits. It will probably take at least a month before any changes become a routine part of your life. Be forgiving, patient, and persistent with yourself. Your brain needs time to get used to the idea that this new thing you're doing is part of your regular routine.",
            "French": " Le temps est nécessaire pour qu’une action devienne une habitude.  Ça prendra probablement au moins un mois avant qu’une action fasse partie de ta routine. Fais preuve de patience, d’indulgence et persévérance. Ton cerveau a besoin du temps pour s’habituer à cette nouveauté qui fait maintenant partie de ta routine.",
        },
        "Paragraph4": {
            "English": "Repeating a goal makes it stick. Say your goal out loud every morning to remind yourself what you want and what you're working for. Writing it down works too. You can write your goal somewhere you'll see it often, like the bathroom mirror or the bulletin board in your bedroom. Every time you remind yourself of your goal, you are helping to train your brain. When you are stating your goal, state it as though it is already happening. Then, take a moment to imagine how it would feel to achieve your goal. The more you can feel it, the stronger the connection will be in your brain.",
            "French": "Répéter son objectif le fera coller. Récite ton objectif à voix haute chaque matin pour te rappeler ce que tu veux et ce pour lequel tu travailles. L’écrire fonctionne aussi. Tu peux l’écrire et le placer quelque part où tu le verras souvent, comme le miroir dans la salle de bain ou le babillard dans ta chambre. Chaque fois que tu dis ou lis ton objectif, tu entraînes ton cerveau. Dis-le et prend un moment pour imaginer comment tu te sentiras lorsque tu l’atteindras. Plus tu le ressens, plus forte sera la connexion dans ton cerveau",
        },
        "Paragraph5": {
            "English": "Goals are personal. The key to making any change is to find the desire within yourself – you have to do it because you want to, not because a friend, coach, or parent wants you to. It will be harder to stay on track and motivated if you’re doing something out of obligation to another person. Comparing yourself to others doesn’t work well. Everyone is different, and that’s a good thing!",
            "French": "Les objectifs sont personnels.  La clé de la réussite pour apporter tout changement est de trouver le désir à l’intérieur de soi – tu le fais parce que tu veux le faire et non parce qu’un ami, un entraîneur ou un parent veut que tu le fasses. C’est plus difficile de rester sur la bonne voie et garder sa motivation lorsqu’on se sent obligé. Te comparer aux autres ne fonctionne pas très bien. 	Tout le monde est différent, et ça c’est une bonne chose!",
        },
        "Paragraph6": {
            "English": "Roadblocks don’t mean failure. Sometimes it takes many attempts to reach your goal, and that’s ok! No one expects you to be perfect and neither should you. Slip-ups are part of the learning process as you retrain your brain into a new way of thinking. It’s normal to mess up when trying to make a change. Focus on getting right back on track. If we didn’t make mistakes, we wouldn’t learn from them!",
            "French": "Un obstacle ne signifie pas un échec. Parfois on doit essayer plus d’une fois pour atteindre son objectif, et c’est correct! Personne s’attend que tu sois parfait et tu ne devrais pas non plus. Les erreurs font partie du processus d’apprentissage lorsque tu entraînes ton cerveau à penser différemment. C’est normal de rater un essai mais l’importance est de se remettre sur la bonne voie. On apprend par nos erreurs!",
        },
        "SubTitle6": {
            "English": "Set Your Exercise Goals",
            "French": "Établir ses objectifs d’exercice",
        },
        "Input1": {
            "English": "List some of your goals here:",
            "French": "Écris un objectif ici:",
        },
        "List3Title": {
            "English": "Do your goals work with the AIMS principle? If not re-write them so that they do:",
            "French": " Ton objectif respecte-t-il le principe AIMS?  Si non, récris-le pour qu’il soit ",
        },
        "List3Item1": {
            "English": "Achievable",
            "French": "Atteignable",
        },
        "List3Item2": {
            "English": "Important",
            "French": "Important",
        },
        "List3Item3": {
            "English": "Measurable",
            "French": "Mesurable",
        },
        "List3Item4": {
            "English": "Specific",
            "French": "Spécifique",
        },
        "List4Title": {
            "English": "Whenever you're setting physical activity goals, consider the FITT principle:",
            "French": " Lorsque tu établis un objectif lié à l’activité physique, pense au principe FITT.",
        },
        "List4Item1": {
            "English": "Frequency how often will you do it?",
            "French": " Fréquence (comment souvent le feras-tu?)",
        },
        "List4Item2": {
            "English": "Intensity how hard will you be working?",
            "French": " Intensité (comment fort travailleras-tu?)",
        },
        "List4Item3": {
            "English": "Time how long will you do it for?",
            "French": " Temps (quelle sera la durée?)",
        },
        "List4Item4": {
            "English": "Type what type of activity is it?",
            "French": " Type (de quel type d’activité s’agit-il?)",
        },
        "Paragraph7": {
            "English": "Using the AIMS and FITT principles, make an outline of short-term goals (e.g., weekly goals, daily goals) that set the path towards your long-term goal",
            "French": " En utilisant les principes AIMS et FITT, rédige une série d’objectifs à court terme (p. ex., hebdomadaire, journalier) qui te mettront sur la bonne voie vers l’atteinte de ton objectif à long terme.",
        },
        "SubTitle7": {
            "English": "Homework",
            "French": "Devoir",
        },
        "Paragraph8": {
            "English": "Fill out the goal pathway (with your long-term and short-term goals)",
            "French": " Complète le parcours d’objectifs sur la page précédente en identifiant ton objectif à long terme et tes objectifs à court terme.",
        },
        "Paragraph9": {
            "English": "Continue using your Runner's Log to track your runs",
            "French": " Continue à compléter ton Journal de bord du coureur pour documenter tes courses.",
        },
    },
    "Module 3": {
        "Title": {
            "English": "Module #3",
            "French": "Module 3",
        },
        "SubTitle1": {
            "English": "Motivation",
            "French": "Motivation",
        },
        "SubTitle2": {
            "English": "What Is Motivation?",
            "French": "Qu’est-ce que la motivation?",
        },
        "List1Item1": {
            "English": "Motivation is defined as the process that initiates, guides, and maintains goal-oriented behaviours. Motivation is what causes us to act, whether it is getting a glass of water to reduce thirst or reading a book to gain knowledge.",
            "French": "On définit la motivation comme étant le processus qui initie, guide et maintient un comportement ciblé.  La motivation est ce qui nous mène à agir, que ce soit d’aller chercher un verre d’eau pour se désaltérer ou lire un livre pour acquérir des connaissances.",
        },
        "List1Item2": {
            "English": "Motivation involves the biological, emotional, social, and cognitive forces that activate behaviour. In everyday language, the term motivation is frequently used to describe why a person does something. For example, you might say that a student is so motivated to get into a certain university that she spends every night spending.",
            "French": "La motivation implique des forces biologiques, émotionnelles, sociales et cognitives qui poussent quelqu’un dans son action. Le terme motivation est souvent utilisé pour décrire pourquoi une personne fait quelque chose. Par exemple, on pourrait dire qu’une étudiante est tellement motivée d’être admise à telle université qu’elle passe toutes ses soirées à étudier.",
        },
        "SubTitle3": {
            "English": "Components of Motivation",
            "French": "Composantes de la motivation",
        },
        "List2Title": {
            "English": "There are three major components of motivation:",
            "French": " La motivation a trois principales composantes:",
        },
        "List2Item1": {
            "English": "Activation involves the decision to initiate a behaviour (e.g., joining the Team Unbreakable Run Group)",
            "French": " Le déclenchement signifie la décision de commencer un comportement (p. ex., joindre le groupe de coureurs de L’équipe imbattable)",
        },
        "List2Item2": {
            "English": "Persistence is the continued effort toward a goal even though obstacles may exist (e.g., finding a safe ride home from a training session when your usual ride falls through)",
            "French": " La persévérance est l’effort continu fournit pour atteindre l’objectif malgré les obstacles (p. ex., trouver un moyen sécuritaire de rentrer chez soi après un entraînement).",
        },
        "List2Item3": {
            "English": "Intensity can be seen in the concentration and vigour that goes into pursuing a goal. For example, one student might run 5k without much effort, while another student will train regularly, participate in all the run sessions, and use the run log to track progress.",
            "French": " L’intensité se voit dans le niveau d’effort et de concentration nécessaire pour atteindre l’objectif. Par exemple, un(e) étudiant(e) cour le 5km sans effort alors qu’un autre s’entraîne régulièrement, participe à toutes les sessions et utilise le Journal de bord du coureur pour documenter ses progrès.",
        },
        "SubTitle4": {
            "English": "Types Of Motivation",
            "French": "Types de motivation",
        },
        "Paragraph1": {
            "English": "Extrinsic motivations are those that arise from outside of the individual and often involve rewards such as trophies, money, social recognition, or praise.",
            "French": " La motivation extrinsèque vient de l’extérieur de l’individu c’est-à-dire qu’on le fait pour recevoir quelque chose comme une récompense, un trophée, de l’argent ou des éloges.",
        },
        "Paragraph2": {
            "English": "Intrinsic motivations are those that arise from within the individual, such as running a 5k for the personal satisfaction of finishing the distance or for the pure joy of the activity itself. Intrinsic motivation is the strongest type of motivation and leads to long-term participation or engagement in a target behaviour.",
            "French": " La motivation intrinsèque vient de l’intérieur de l’individu c’est-à-dire que terminer la course de 5km 	sans arrêter m’apporte une satisfaction personnelle et de la joie. La motivation intrinsèque est puissante 	car il s’agit d’une force interne qui alimente le désir de participer ou de s’engager à long terme.",
        },
        "SubTitle5": {
            "English": "Maintaining Motivation",
            "French": "Maintenir sa motivation",
        },
        "Paragraph3": {
            "English": "One of the biggest issues around motivation for physical activity is that many of us tend to want to wait until we \"feel motivated\" before we exercise or start an activity. However, the feeling of being motivated often comes AFTER we start engaging in the activity.",
            "French": " Un des plus grands enjeux liés à la motivation et l’activité physique est que plusieurs d’entre nous avons tendance à attendre qu’on se ‘sente motivé’ avant de faire de l’exercice ou de débuter une activité. Toutefois, le sentiment de motivation vient souvent APRÈS s’être engagé dans une activité.",
        },
        "Paragraph4": {
            "English": "Motivation can be supported by reflecting on your progress towards your goals, anticipating barriers and coming up with solutions in advance, using cues to action to carry you through the times when your motivation is naturally low, and drawing on support from your teachers, family, or friends.",
            "French": " Des moyens de rester motivé peuvent inclure une réflexion sur tes progrès, anticiper les embuches possibles et prévoir des solutions à l’avance, utiliser les repères qui te poussent à l’action surtout lorsque ta motivation est naturellement basse et puiser de l’appui de ta famille, tes amis/amies ou tes enseignantes/enseignants.",
        },
        "SubTitle6": {
            "English": "Goal Check-In",
            "French": "Vérification d’objectif",
        },
        "Input1Header": {
            "English": "How would you rate your progress towards your current physical activity goal? (100% means you completely met your goal)",
            "French": " Comment évaluerais-tu ton progrès par rapport à ton objectif lié à l’activité physique? (100% veut dire que tu as complètement atteint ton objectif)",
        },
        "SubTitle7": {
            "English": "Planning for Barriers",
            "French": "Planifier pour les obstacles",
        },
        "Input2Header": {
            "English": "What are some of the obstacles that you expect to encounter while trying to reach your goals? (e.g., low energy, having to find ways to be active at home, changes to your schedule)",
            "French": "",
        },
        "Input3Header": {
            "English": "Some of the barriers are hard to change. What are some solutions and strategies you can use to overcome these obstacles? (e.g., running at a different time of day, preparing a workout station in your room in advance)",
            "French": " Peux-tu identifier quelques obstacles que tu peux prévoir en essayant d’atteindre tes objectifs? (p. ex., manque d’énergie, trouver des façons d’être actif(ve) à la maison, changement à ton horaire)",
        },
/*
*** Missing section *** ENGLISH: Some of the barriers are hard to change. What are some solutions and strategies you can use to overcome these obstacles?
(e.g., running at a different time of day, preparing a workout station in your room in advance) 1. to 8.
French: Certains obstacles sont difficiles à surmonter. Quelles solutions ou stratégies peux-tu prévoir pour surmonter ces obstacles? 
(p. ex., courir à un temps différent de la journée, préparer à l’avance une place dans ta chambre pour t’entraîner)
1.	____________________________________________
2.	____________________________________________
3.	____________________________________________
4.	____________________________________________
5.	____________________________________________
6.	____________________________________________
7.	____________________________________________
8.	____________________________________________
*/
        "SubTitle8": {
            "English": "If-Then Planning",
            "French": "Planification Si-Alors",
        },
        "Input4Header": {
            "English": "If-then planning is one strategy you can use to overcome barriers to physical activity. Anticipate the challenges in advance and come up with strategies to adapt your physical activity or adjust your behaviour in response. That way, when the challenge arises, you can proceed ‘automatically’ to the solution.",
            "French": " Tu peux utiliser la planification ‘si-alors’ pour surmonter des obstacles. Anticipe les défis ou les obstacles à l’avance et choisis des stratégies pour adapter ton activité physique ou ajuster ton comportement. De cette façon, lorsque l’obstacle survient, tu peux procéder ‘automatiquement’ à la solution prévue.",
        },
        "Input5Header": {
            "English": "Example: If I don’t have time for a full run, then I will stretch or go for a short walk.",
            "French": " Par exemple, si je n’ai pas le temps de faire une pleine course, alors je vais faire des étirements et sortir faire une courte marche.",
        },
        "SubTitle9": {
            "English": "Using Cues to Action",
            "French": "Utiliser les repères",
        },
        "List3Title": {
            "English": "Build cues into your environment to remind yourself to do the physical activity you planned for (and as a commitment to yourself so that when the time comes, you don’t have to debate whether or not to do it). Example:",
            "French": " Créer des repères autour de toi qui te serviront de rappels pour faire les activités physiques que tu as prévu (et pour démontrer ton engagement pour que quand vient le temps, tu n’auras pas à débattre si tu devrais le faire ou non). Par exemple :",
        },
        "List3Item1": {
            "English": "Set an alarm for a specific time",
            "French": " Régler une sonnerie pour un temps spécifique.",
        },
        "List3Item2": {
            "English": "Leave your running shoes out as a visual cue to run / workout",
            "French": " Laisser tes espadrilles en pleine vue comme un indice visuel te rappelant de courir ou de t’entraîner.",
        },
        "List3Item3": {
            "English": "Commit to a specific virtual workout class and set reminders or notifications leading up to the class time",
            "French": " T’engager à suivre une session d’entraînement virtuelle et régler des rappels menant à la session.",
        },
        "SubTitle10": {
            "English": "Drawing on Support for Physical Activity",
            "French": "Puiser sur l’appui",
        },
        "Paragraph5": {
            "English": "Including people from your network (friends, family, coaches, mentors) in your physical activity plans can make a huge difference. Their involvement and support can help keep you accountable, provide motivation, and help you build on your physical activity goals.",
            "French": " Inclure des gens de ton réseau (amis, famille, entraîneurs, mentors) dans ton plan peut faire une grande différence. Leur implication et leur appui peut t’aider à être redevable, te fournir une motivation et t’aider à bâtir tes objectifs en matière d’activité physique.",
        },
        "Paragraph6": {
            "English": "Who could you invite to become a part of your physical activity plan? Write down their initials and your ideas about the role(s) each person could play to support your physical activity:",
            "French": " Qui pourrais-tu inviter à faire partie de ton plan d’activité physique? Écris leurs initiales et tes idées du rôle que chaque personne pourrait jouer pour t’appuyer.",
        },
        "SubTitle11": {
            "English": "Homework",
            "French": "Devoir",
        },
        "Paragraph7": {
            "English": "Contact the people you listed above to invite them to be a part of your physical activity or running plan",
            "French": " Contacte les personnes que tu as identifiées ci-dessus et invite-les à faire partie de ton plan.",
        },
        "Paragraph8": {
            "English": "Revisit your goals from Module #2. If you need to make changes to your goals so that they are achievable, write the modified version of your goals in the space below. This will help you to stay motivated!",
            "French": " Revisite tes objectifs Module 2. Si tu dois modifier tes objectifs pour les rendre atteignables, écris la version modifiée dans l’espace ci-dessous.  Ceci t’aidera à maintenir ta motivation!",
        },
    },
    "Module 4": {
        "Title": {
            "English": "Module #4",
            "French": "Module 4",
        },
        "SubTitle1": {
            "English": "Psychological Skills",
            "French": "Habiletés psychologiques",
        },
        "SubTitle2": {
            "English": "How Do You Run At Your Best?",
            "French": "Comment courir à ton meilleur?",
        },
        "Paragraph1": {
            "English": "Psychological skills are tools that you can use to help you move into your optimal state or ‘optimal zone’ (for physical activity and for your overall well-being).",
            "French": " Les habiletés psychologiques sont des outils que tu peux utiliser pour accéder à ta ‘zone optimale’ (pour l’activité physique et pour ton bien-être général).",
        },
        "Paragraph2": {
            "English": "Think about what helps you perform well… Are you the type of person who needs to be calm and relaxed to get the most out of your activity, really ramped up and energized, or somewhere in the middle?",
            "French": " Pense à ce qui t’aide à bien performer. Pour retirer le plus possible de ton activité, es-tu le type de personne qui a besoin d’être calme et relaxe, intense et énergisé ou quelque part entre les deux? ",
        },
        "Table1R1C1": {
            "English": "If you run or perform best when you are...",
            "French": "Si tu cours ou performe mieux quand tu es calme…",
        },
        "Table1R1C2": {
            "English": "Calm & relaxed",
            "French": "Calme et relaxe",
        },
        "Table1R1C3": {
            "English": "Moderately energized",
            "French": "Énergie modérée",
        },
        "Table1R1C4": {
            "English": "Highly energized",
            "French": "Énergie élevée",
        },
        "Table1R2C1": {
            "English": "Then focus on...",
            "French": "alors concentre-toi sur…",
        },
        "Table1R2C2": {
            "English": "Relaxation, Breathing, Mindfulness",
            "French": "Relaxation, Respiration, Pleine conscience",
        },
        "Table1R2C3": {
            "English": "Self-talk, Imagery / Visualization, Mindfulness",
            "French": "Dialogue interne, Imagerie mentale / visualisation, Pleine conscience",
        },
        "Table1R2C4": {
            "English": "Music, Self-Talk, Imagery / Visualization",
            "French": "Musique, Dialogue interne, Imagerie mentale / visualisation",
        },
        "SubTitleTalk": {
            "English": "Self-Talk",
            "French": "Dialogue intérieur",
        },
        "Paragraph3": {
            "English": "We all have inner speech of some sort (called an internal monologue) that we engage in throughout the day. Within this inner commentary are self-statements (i.e., statements about yourself, your actions).",
            "French": " Nous avons tous un dialogue intérieur (aussi appelé monologue intérieur) qui se passe au courant de la journée. À l’intérieur de soi, il y a des déclarations (p. ex., déclarations sur toi-même, sur tes actions).",
        },
        "Paragraph4": {
            "English": "The challenge: For most of us, our self-statements tend to be self-criticisms; they are typically focused on the things we wish were different or that we believe we haven’t done (or won’t do) well",
            "French": " Le défi: Pour la plupart d’entre nous, les déclarations ont tendances à être des critiques; typiquement elles sont concentrées sur les choses qu’on souhaiterait changer ou qu’on croit que nous n’avons pas bien fait (ou que nous ne ferons pas bien).",
        },
        "Paragraph5": {
            "English": "The good news: Research shows that intentionally saying positive things to ourselves about our actions (or intended actions) can help to build self-esteem and encourage us as we make healthy changes.",
            "French": " La bonne nouvelle: Les recherches démontrent qu’en se parlant positivement au sujet de nos actions, on peut bâtir son estime de soi et s’encourager à faire des changements sains. ",
        },
        "Paragraph6": {
            "English": "Use self-talk to enhance motivation, focus your attention, build self-confidence, and modify your activation",
            "French": " Utilise ta voix intérieure pour améliorer ta motivation, te concentrer, bâtir ta confiance et modifier ton activation.",
        },
        "SubTitle3": {
            "English": "Practicing Positive Self-Talk",
            "French": "Pratiquer les declarations positives",
        },
        "List1Title": {
            "English": "Here are four ways that you can make your self-talk more helpful:",
            "French": " Voici quatre façons que ta voix interne peut mieux te servir:",
        },
/**** The 4 ways to make self-talk helpful have titles which are missing! Interrupting your thoughts: As soon as…
Changing negatives to positives: Involves…
Countering: Instead…
Reframing: Changing…*/
        "List1Item1": {
            "English": "Interrupting your thoughts as soon as a negative thought emerges, use a cue to stop the thought before it continues. Example cues: think or say the word “stop”, draw a dot or stop on your hand, touch an elastic on your wrist, etc.",
            "French": "Interrompre tes pensées: Aussitôt qu’une pensée négative survient, utilise un indice pour mettre fin à cette pensée. Par exemple, tu peux penser ou dire le mot ‘arrête’, dessiner un point ou écrire ‘arrête’ sur ta main, toucher un élastique sur ton poignet, etc.",
        },
        "List1Item2": {
            "English": "Changing negatives to positives Involves stopping a negative thought and changing it right away into a positive statement. Example: \"This will never work\" is replaced with \"I’ll give it a try\”.",
            "French": "Passer du négatif au positif: Nécessite mettre fin à la pensée négative et la reformuler en déclaration positive. Par exemple, ‘Ça ne fonctionnera jamais’ est remplacé par ‘Je vais l’essayer’.",
        },
        "List1Item3": {
            "English": "Countering Instead of changing the wording of the thought, another more positive statement follows the initial thought. Example: “I can’t run fast … but I can run longer!” The key here is that the positive statement has to be something that is believable / achievable.",
            "French": "Réfuter/Répliquer: Au lieu de changer les mots de la pensée négative, une déclaration positive suit la pensée initiale. Par exemple, ‘Je ne cours pas vite… mais je peux courir plus longtemps!’ L’essentiel est que la déclaration positive doit être atteignable et réalisable.",
        },
        "List1Item4": {
            "English": "Reframing Changing the entire perspective in a way that focuses on opportunities (to learn, be challenged, practice, achieve).",
            "French": "Recrader: Changer complètement la perspective de façon à se concentrer sur une opportunité (d’apprendre, de contester, de pratiquer, d’atteindre).",
        },
        "Paragraph7": {
            "English": "Take a few minutes to write some positive self-statements that you could say to yourself",
            "French": " Prends quelques minutes pour écrire des déclarations positives que tu pourrais te dire.",
        },
        "Paragraph8": {
            "English": "(a) to help motivate you before a run or physical activity session",
            "French": "(a) pour te motive avant une course ou une session d’activité physique",
        },
        "Paragraph9": {
            "English": "(b) to help you feel good after a run or physical activity session",
            "French": "(b) pour t’aider à te sentir bien après une course ou une session d’activité physique",
        },
        "SubTitle4": {
            "English": "Imagery",
            "French": "Imagerie mentale",
        },
        "Paragraph10": {
            "English": "Imagery involves using all the senses to create or re-create experiences in the mind.",
            "French": " L’imagerie implique l’utilisation de tous tes sens pour créer ou recréer des expériences dans sa tête.",
        },
        "Paragraph11": {
            "English": "Use imagery to enhance motivation, manage stress, and improve performance",
            "French": " Utilise l’imagerie pour augmenter ta motivation, gérer ton stress et améliorer ta performance.",
        },
        "SubTitle5": {
            "English": "Practicing Imagery",
            "French": "Pratiquer l’imagerie",
        },
        "List2Item1": {
            "English": "Throughout the imagery process, take time to reflect on your thoughts and your feelings.",
	        "French": " Pendant le processus de l’imagerie, prends le temps de réfléchir sur tes pensées et tes sentiments.",
        },
        "List2Item2": {
            "English": "Enjoy your surroundings and let yourself be far from what stresses you for as long as you like.",
            "French": "Apprécie ton entourage et reste éloigné de ce qui te stresse aussi longtemps que tu veux.",
        },
        "List2Item3": {
            "English": "When you’re ready to return to reality, count back from 10 or 20, and tell yourself that when you get to 1, you’ll feel serene and alert, and enjoy the rest of your day.",
            "French": " Lorsque tu es prêt/e à revenir à la réalité, compte de reculons à partir de 10 ou 20 et dis-toi que quand tu arrives à 1, tu te sentiras serein/e et alerte et tu apprécieras le reste de ta journée.",
        },
        "List2Item4": {
            "English": "To help enhance the clarity of the image in your mind, take the time to intentionally focus on all the sensations of the moment you want to create.",
            "French": "Pour aider à améliorer la clarté de l’image dans ta tête, prends le temps de te concentrer sur toutes les sensations du moment que tu veux créer.",
        },
        "SubTitle6": {
            "English": "Visualization",
            "French": "Visualisation",
        },
        "Paragraph12": {
            "English": "Visualization is a technique that involves imagining desired outcomes.",
            "French": " La visualisation est une technique qui te permet d’imaginer un résultat désiré.",
        },
        "Paragraph13": {
            "English": "Use visualization to enhance performance, spark creative ideas and solutions that help you reach your goals, and enhance your internal (intrinsic) motivation",
            "French": " Utilise la visualisation pour rehausser ta performance, pour déclencher des idées créatives et des solutions qui t’aideront à atteindre tes objectifs et pour optimiser ta motivation interne (intrinsèque).",
        },
        "SubTitle7": {
            "English": "Practicing Visualization",
            "French": "Pratiquer la visualisation",
        },
        "List3Item1": {
            "English": "Practicing visualization is a lot like practicing imagery!",
            "French": " Mettre en pratique la visualisation ressemble beaucoup à l’imagerie!",
        },
        "List3Item2": {
            "English": "Sit in a comfortable position, close your eyes and imagine — as vividly and in as much detail as you can — what you would be looking at if the dream or goals you have were already realized. Imagine being inside of yourself, looking out through your eyes at the ideal result.",
            "French": " Assieds-toi confortablement, ferme tes yeux et imagine, aussi vivement et détaillé que possible, ce que tu verrais si ton rêve ou ton objectif était déjà réalisé. Imagine que tu es à l’intérieur de toi-même, que tu vois avec tes yeux le résultat idéal.",
        },
        "SubTitle8": {
            "English": "Mindfulness",
            "French": "Pleine conscience",
        },
        "Paragraph14": {
            "English": "Mindfulness is paying attention to all of your senses in the present moment, without judgement. It is having a moment-by-moment awareness of your thoughts, emotions, bodily sensations, and surrounding environment with openness and curiosity.",
            "French": " La pleine conscience signifie être attentif/ve à l’expérience en cours, être dans le présent, sans porter jugement. Il s’agit de prendre conscience, moment par moment, de ses pensées, ses émotions, ses sensations corporelles et de son environnement avec une ouverture d’esprit et avec curiosité.",
        },
        "Paragraph15": {
            "English": "Use mindfulness to reduce stress, manage (regulate) your emotions, become more focused, and improve your working memory",
            "French": " Utilise la pleine conscience pour réduire le stress, gérer tes émotions, améliorer ta concentration et ta mémoire de travail.",
        },
        "Paragraph16": {
            "English": "If you’re someone who performs best when you’re calm, mindfulness can play a big role in getting you to your optimal ‘zone’",
            "French": " Si tu es quelqu’un qui performe mieux lorsque tu es calme, la pleine conscience peut jouer un rôle important pour atteindre ta zone optimale.",
        },
        "SubTitle9": {
            "English": "Practicing Mindfulness",
            "French": "Pratiquer la pleine conscience",
        },
        "List4Item1": {
            "English": "To engage in mindfulness, you take a moment to yourself, take in a breath of air, and plug into yourself – what is happening in the present moment?",
            "French": "Pour y parvenir, arrête-toi un instant, prends une grande respiration et branche-toi sur toi-même – que se passe-t-il en ce moment?",
        },
        "List4Item2": {
            "English": "Every time that you find your thoughts drifting to the past or future, bring yourself back to the present moment.",			"French": "Chaque fois que tu constates que tes idées dérivent vers le passé ou le futur, ramène-les au moment présent.",
        },
        "List4Item3": {
            "English": "Much of the chatter in your mind is simply background chatter that does not require your attention.",
            "French": " Le flot continu de bavardage qui se passe dans ta tête ne requiert pas ton attention.",
        },
        "List4Item4": {
            "English": "As you practice mindfulness more and more often, you develop an awareness of your thoughts. When you become aware that you’re getting stuck with a thought, you can simply label it as “worry thoughts” or “thoughts” and come back to your present moment.",
            "French": "En pratiquant souvent la pleine conscience, tu développes un éveil face à tes pensées. Lorsque tu constates qu’une pensée ne te lâche pas, tu peux lui donner l’étiquette ‘inquiétude’ ensuite revenir au moment présent.",
        },
        "SubTitle10": {
            "English": "Adapted from Nemours Foundation 'Mindfulness Exercises’",
            "French": "Adapté de Nemours Foundation 'Mindfulness Exercises’",
        },
        "SubTitle11": {
            "English": "Mindful Eating:",
            "French": "Pleine conscience alimentaire:",
        },
        "Paragraph17": {
            "English": "You can practice mindful eating with any food, but it’s best to practice with a food that you enjoy. Let's say you decide to use a strawberry. Your job is to eat the strawberry slowly, without rushing. The key is that you are paying close attention to what you're eating.",
            "French": " Tu peux pratiquer la pleine conscience alimentaire avec n’importe quel aliment, mais c’est mieux de pratiquer avec un aliment que tu aimes. Disons que tu décides d’utiliser une fraise. Ton job est de manger la fraise lentement, sans presse. Ce qui importe est de porter une attention particulière à ce que tu manges.",
        },
        "List5Item1": {
            "English": "Start by holding your strawberry. Notice how it feels in your fingers, how heavy it is, its texture.",
            "French": " Commence par tenir la fraise. Remarque le sentiment entre tes doigts, son poids, sa texture.",
        },
        "List5Item2": {
            "English": "Hold it near your nose. What does it smell like?",
            "French": " Approche-la près de ton nez. Quelle est son odeur?",
        },
        "List5Item3": {
            "English": "If you have your eyes open, notice how it looks. Pay attention to whether the skin is smooth or bumpy. If you hold it firmly, is it squishy?",
            "French": "Si tes yeux sont ouverts, observe son apparence. Regarde pour voir si la peau est lisse ou bosselée. Si tu appuies un peu dessus, va-t-elle s’écrasée?",
        },
        "List5Item4": {
            "English": "Is your mouth watering? Go ahead and taste your strawberry. Notice how it feels on your tongue and against your teeth. Notice the flavor, the texture, and the juiciness as you chew slowly. Take your time as you chew, taste, smell, and feel each bite.",
            "French": "As-tu l’eau à la bouche? Allez, goûte à ta fraise. Remarque la sensation sur ta langue et sur tes dents. En mastiquant lentement, remarque la saveur, la texture et la chair juteuse. Prends le temps de mastiquer, goûter, sentir et ressentir chaque bouchée.",
        },
        "SubTitle12": {
            "English": "Mindful Breathing:",
            "French": "Pleine conscience respiratoire:",
        },
        "Paragraph18": {
            "English": "With this exercise, you focus your attention on your breath. You don’t need to force or change your breath, just notice it.",
            "French": " Avec cet exercice, tu concentres sur ta respiration. Tu n’as pas besoin de forcer ni de changer ton souffle, simplement en prendre conscience.",
        },
        "List6Item1": {
            "English": "Sit up in a comfortable way. Close your eyes.",
            "French": "Assieds-toi confortablement. Ferme les yeux.",
        },
        "List6Item2": {
            "English": "Notice your breathing as you inhale and exhale normally. Just pay attention to your breath as it goes in and out. Can you feel the place where the air tickles your nostrils?",
            "French": "Dirige ton attention vers ta respiration en inspirant et en expirant normalement. Porte attention à ton souffle alors qu’il entre et sort de ton corps. Ressens-tu l’endroit où l’air chatouille tes narines?",
        },
        "List6Item3": {
            "English": "Pay attention to how the breath gently moves your body. Can you notice your belly or your chest moving as you breathe?",
            "French": "Observe comment le souffle fait légèrement bouger ton corps. Remarques-tu qu’en respirant ton ventre ou ta poitrine bouge?",
        },
        "List6Item4": {
            "English": "Sit for a few minutes, just paying attention to your gentle breathing. See how relaxed you can feel just sitting, breathing in and out.",
            "French": "Pendant quelques minutes encore, porte simplement attention à ta respiration. Constate comment tu peux te sentir relaxe simplement en étant assis et en respirant.",
        },
        "List6Item5": {
            "English": "When your mind starts to wander and think about something else, gently guide your attention back to your breath.",
            "French": "Lorsque tes pensées commencent à vaciller, ramène doucement ton attention sur ton souffle.",
        },
        "SubTitle13": {
            "English": "Mindful Walking:",
            "French": "Pleine conscience en marchant:",
        },
        "ParagraphExtra1": {
            "English": "This exercise is about paying attention to how your body moves as you walk.",
            "French": " Cet exercice te permet de prendre conscience des mouvements de ton corps pendant que tu marches.",
        },
        "List6aItem1": {
            "English": "To start, pick up one foot and take a step forward, in slow motion. Pay attention to how you naturally keep your balance.",
            "French": "Pour commencer, soulève un pied et prends un pas vers l’avant, au ralenti. Remarque comment tu gardes naturellement ton équilibre.",
        },
        "List6aItem2": {
            "English": "Now walk in slow motion, step by step. Notice how your arms, legs, and feet move. Pay attention to how your knees bend and straighten, as you lift one foot and then the other, nice and slow.",
            "French": "Maintenant, marche au ralenti, pas après pas. Remarque comment tes bras, tes jambes et tes 	pieds bougent. Porte attention à tes genoux qui plient et se redressent lorsque tu soulèves un 	pied ensuite l’autre, doucement et lentement.",
        },
        "List6aItem3": {
            "English": "Breathe in and out, in time with your steps. See if you can keep your attention focused on walking slowly, step by step.",
            "French": "Respire au rythme de tes pas. Tente de voir si tu peux garder ton attention sur la marche lente, pas après pas. ",
        },
        "List6aItem4": {
            "English": "Whenever your mind wanders, gently guide it back to noticing your steps and your movement.",
            "French": "Lorsque tes pensées commencent à vaciller, ramène doucement ton attention vers tes pas et ton mouvement.",
        },
        "SubTitle13a": {
            "English": "Mindful Word:",
            "French": "Pleine conscience d’un mot:",
        },
        "Paragraph18a": {
            "English": "Think of a word that seems calm or soothing to you. This could be a word like \"love\" or \"peace\”.",
            "French": " Pense à un mot qui te calme ou qui te soulage. Ça pourrait être un mot comme ‘amour’ ou ‘paix’.",
        },
        "List6bItem1": {
            "English": "Think the word to yourself. Say it silently and slowly in your mind. Say your word to yourself with each breath you take, in and out. Keep your attention gently focused on your word.",
            "French": " Pense au mot. Dis-le dans ta tête lentement. Dis ton mot chaque fois que tu inspires et expires. Garde ton attention sur ton mot.",
        },
        "List6bItem2": {
            "English": "When your mind wanders, guide your attention back to your word. Keep saying it gently and slowly while you relax and breathe.",
            "French": "Lorsque tes pensées vacillent, guide ton attention vers ton mot. Continue à le dire doucement et lentement pendant que tu relaxes et respires.",
        },
        "List6bItem3": {
            "English": "Can you do this for a whole minute? Your mind will wander during this time, but can you bring it back to your word and your breath?",
            "French": "Peux-tu faire cet exercice pendant une minute? Si tes pensées vacillent, peux-tu ramener ton attention à ton mot et ta respiration?",
        },
        "SubTitle14": {
            "English": "Emotional Regulation",
            "French": "Régulation émotionnelle",
        },
        "Paragraph19": {
            "English": "As you practice tuning in to the present moment, you’ll also get better at noticing what emotions you’re experiencing at any given time.",
            "French": " En te pratiquant à demeurer dans le moment présent, tu deviendras plus habile à remarquer les émotions que tu ressens à tout moment.",
        },
        "Paragraph20": {
            "English": "Noticing what you’re feeling (e.g., proud, happy, tense, frustrated) is the first step towards improving your ability to manage how you react.",
            "French": " Prêter son attention à ses sentiments (p. ex., fier, joyeux, tendu, frustré) est la première étape pour améliorer son habileté à gérer nos réactions.",
        },
        "SubTitle15": {
            "English": "Emotion Thermometer",
            "French": "Thermomètre d’émotions",
        },
        "Paragraph21": {
            "English": "Keep track of how you are feeling before and after your runs or physical activity sessions. Keep a journal or simply use thermometers to track your feelings.",
            "French": " Tiens compte de comment tu te sens avant et après tes courses ou tes sessions d’activité physique. Garde un journal ou utilise les thermomètres pour tenir compte de tes sentiments.",
        },
        "Paragraph22": {
            "English": "Identify the positive and negative emotions that are most noticeable for you (for example: happy, anxious, sad, relieved, joyful, etc.) and rate how intensely you’re feeling each emotion (from 1 to 10).",
            "French": " Dans les espaces vides, identifie les émotions positives et négatives que tu remarques le plus chez toi (par exemple, joie, anxiété, tristesse, soulagement, excité, serein, etc.) et cote le degré d’intensité pour chacune des émotions (de 1 à 10).",
        },
        "Paragraph23": {
            "English": "There are many apps that are designed to help people keep track of how they are feeling and their various lifestyle behaviours (e.g., how much they’re moving). Some of these apps also offer free guided mindfulness and relaxation recordings. Every app is different, so you might need to try out a few to find the one you like most.",
            "French": "Il y a plusieurs applications qui existent pour aider aux gens à tenir compte de leurs sentiments et leurs comportements (p. ex., compter les pas).  Certaines applis offrent aussi des enregistrements qui permettent de pratiquer la pleine conscience et la relaxation. Chaque appli est différente, donc il faudra peut-être en essayer quelques-unes pour trouver celle que tu préfères.",
        },
        "SubTitle16": {
            "English": "Homework",
            "French": "Devoir",
        },
        "Paragraph24": {
            "English": "Practice using the skills introduced in this module (positive self-talk, imagery, visualization, mindfulness). Prioritize the skills you think you could benefit from the most given how you perform best (in a calm state vs. energized)",
            "French": "Pratique les habiletés présentées dans ce module (dialogue intérieur, imagerie, visualisation, pleine conscience).    Priorise les habiletés qui te seraient plus bénéfiques selon ta performance (dans un état calme vs énergisé)",
        },
        "Paragraph25": {
            "English": "Continue to track your runs in your Runner’s Log",
            "French": " Continue à monitorer tes courses dans ton Journal du coureur.",
        },
        "Paragraph26": {
            "English": "On a scale of 1 (not good at this skill at all) to 10 (excellent at this skill), how would you rate yourself on each of these skill areas?",
            "French": "Utilisant une echelle de 1 (pas du tout habile) à 10 (j’excelle), quelle cote te donnerais-tu pour chacune de ces habiletés?",
        },
        "List7Item1": {
            "English": "Stress management",
            "French": "Gestion du stress",
        },
        "List7Item2": {
            "English": "Imagery / Visualization",
            "French": "Imagerie / visualisation",
        },
        "List7Item3": {
            "English": "Motivation",
            "French": "Motivation",
        },
        "List7Item4": {
            "English": "Self-talk",
            "French": "Dialogue intérieur",
        },
        "List7Item5": {
            "English": "Confidence",
            "French": "Confiance",
        },
        "List7Item6": {
            "English": "Concentration",
            "French": "Concentration",
        },
        "Paragraph27": {
            "English": "You may recognize these questions from the beginning of the program. Have any of your responses changed since then?",
            "French": "Il est possible que ces questions soient familières car ells étaient au début du programme.",
        },/*TODO:
*** Missing – English: Have any of your responses changed since then?
French: Tes réponses ont-elles changé?*/
        "Paragraph28": {
            "English": "Which skills do you want to continue to focus on going forward?",
            "French": "Quelles habiletés aimerais-tu développer advantage en avançant?",
        },
        "SubTitle17": {
            "English": "References",
            "French": "Références",
        },
        "Paragraph29": {
            "English": "Biddle, S. J., Ciaccioni, S., Thomas, G., & Vergeer, I. (2019). Physical activity and mental health in children and adolescents: An updated review of reviews and an analysis of causality. Psychology of Sport and Exercise, 42, 146-155.",
            "French": " Biddle, S. J., Ciaccioni, S., Thomas, G., & Vergeer, I. (2019). Physical activity and mental health in children and adolescents: An updated review of reviews and an analysis of causality. Psychology of Sport and Exercise, 42, 146-155",
        },
        "Paragraph30": {
            "English": "Parks-Stamm, E. J., & Gollwitzer, P. M. (2009). Goal implementation: The benefits and costs of if-then planning. In G. B. Moskowitz & H. Grant (Eds.), The psychology of goals (pp. 362-391). Guilford Press",
            "French": "Parks-Stamm, E. J., & Gollwitzer, P. M. (2009). Goal implementation: The benefits and costs of if-then planning. In G. B. Moskowitz & H. Grant (Eds.), The psychology of goals (pp. 362-391). Guilford Press",
        },
        "Paragraph31": {
            "English": "Hardcastle, S. J., Hancox, J., Hattar, A., Maxwell-Smith, C., Thøgersen-Ntoumani, C., & Hagger, M. S. (2015). Motivating the unmotivated: How can health behavior be changed in those unwilling to change? Frontiers in Psychology, 6, 835.",
            "French": "Hardcastle, S. J., Hancox, J., Hattar, A., Maxwell-Smith, C., Thøgersen-Ntoumani, C., & Hagger, M. S. (2015). Motivating the unmotivated: How can health behavior be changed in those unwilling to change? Frontiers in Psychology, 6, 835.",
        },
        "Paragraph32": {
            "English": "Hynynen, S-T., van Stralen, M. M., Sniehotta, F. F., Araújo- Soares, V., Hardeman, W., Chinapaw, M. J. M., Vasankari, T., & Hankonen, N. (2016). A systematic review of school-based interventions targeting physical activity and sedentary behaviour among older adolescents. International Review of Sport and Exercise Psychology, 9(1), 22-44.",
            "French": "Hynynen, S-T., van Stralen, M. M., Sniehotta, F. F., Araújo- Soares, V., Hardeman, W., Chinapaw, M. J. M., Vasankari, T., & Hankonen, N. (2016). A systematic review of school-based interventions targeting physical activity and sedentary behaviour among older adolescents. International Review of Sport and Exercise Psychology, 9(1), 22-44.",
        },
        "Paragraph33": {
            "English": "Michie, S., Ashford, S., Sniehotta, F. F., Dombrowski, S. U., Bishop, A., & French, D. P. (2011). A refined taxonomy of behaviour change techniques to help people change their physical activity and healthy eating behaviours: The CALO-RE taxonomy. Psychology & Health, 26(11), 1479-1498.",
            "French": "Michie, S., Ashford, S., Sniehotta, F. F., Dombrowski, S. U., Bishop, A., & French, D. P. (2011). A refined taxonomy of behaviour change techniques to help people change their physical activity and healthy eating behaviours: The CALO-RE taxonomy. Psychology & Health, 26(11), 1479-1498.",
        },
        "Paragraph34": {
            "English": "Morris, T., Spittle, M., & Watt, A. P. (2005). Imagery in sport. Human Kinetics.",
            "French": "Morris, T., Spittle, M., & Watt, A. P. (2005). Imagery in sport. Human Kinetics.",
        },
        "Paragraph35": {
            "English": "Van Raalte, J. L., Vincent, A., & Brewer, B. W. (2016). Self-talk: Review and sport-specific model. Psychology of Sport and Exercise, 22, 139-148.",
            "French": " Van Raalte, J. L., Vincent, A., & Brewer, B. W. (2016). Self-talk: Review and sport-specific model. Psychology of Sport and Exercise, 22, 139-148.",
        },
    },
    "Module 5": {
        "Title": {
            "English": "Module #5",
            "French": "Module 5",
        },
        "SubTitle1": {
            "English": "Social Benefits",
            "French": "Bienfaits sociaux",
        },
        "Paragraph1": {
            "English": "When you choose to run with people it is not only safer but those people can offer support. If you are having a tough mental or physical day, your running group can help you get motivated or energized. Joining running groups is a great way to network, meet new people with similar interests and build a running community!",
            "French": " Lorsque tu choisis de courir avec un groupe, non seulement c’est plus sécuritaire mais ces gens peuvent offrir un appui. Si tu éprouves une journée difficile sur le plan physique ou mental, le groupe peut aider à te motiver ou t’offrir de l’énergie. Rejoindre un groupe de course est bon pour le réseautage, pour rencontrer des gens qui ont des intérêts semblables et bâtir une communauté de coureurs!",
        },
        "Input1Header": {
            "English": "Get into small groups and research 2-3 different local running groups. Once you have found your three, present one of your findings to the rest of the class!",
            "French": " En petit groupe, rechercher 2-3 différents groupes de coureurs locaux. Une fois que vous avez trouvé vos 3, en choisir un pour présenter à la classe.",
        },
        "SubTitle2": {
            "English": "Benefits of Running in Groups",
            "French": "Bienfaits de courir en groupe",
        },
        "List1Title": {
            "English": "Mental:",
            "French": "Mental",
        },
        "List1Item1": {
            "English": "Increase or maintain motivation: people in the group have similar interests and can understand each other’s lived experience. Support is there when you need it!",
            "French": "Augmenter ou maintenir la motivation: les gens dans le groupe partagent des intérêts semblables et peuvent comprendre les expériences vécues. L’appui est présent lorsque tu en as besoin!",
        },
        "List1Item2": {
            "English": "Increases accountability: you are less likely to skip a session as others are expecting you to show up.",
            "French": "Augmente l’imputabilité: il est moins probable que tu manques une session car les membres du groupe s’attendent que tu participes.",
        },
        "List1Item3": {
            "English": "It creates a sense of community: even when you are running alone, and you run by other runners you feel connected to them. Maybe you wave or smile every time you pass a fellow runner.",
          "French": " Crée un sentiment de communauté: même lorsque tu cours seul ou seule et tu croises d’autres coureurs, tu ressens une connexion. Peut-être que tu envoies la main ou tu offres un sourire lorsque tu croises un ami coureur.",
        },
        "List2Title": {
            "English": "Physical:",
            "French": "Physique",
        },
        "List2Item1": {
            "English": "Improves or increases running performance: running with a group is a great way to learn tips and techniques from others. Especially from the experienced runners in the group.",
            "French": " Améliore et augmente la performance: courir en groupe est une bonne façon d’apprendre des techniques et d’obtenir des conseils, surtout des coureurs qui ont plus d’expérience.",
        },
        "List2Item2": {
            "English": "Helps increase physical safety: if someone gets injured or feels sick there is always someone there to help.",
            "French": " Plus sécuritaire: si quelqu’un se blesse ou se sent malade, il y a toujours quelqu’un qui peut aider.",
        },
        "List2Item3": {
            "English": "Increase competitive drive: when you run with others it can encourage you to run a little harder and a little longer than you thought you could.",
          "French": " Augmente l’esprit compétitif: lorsque tu cours avec d’autres, cela peut te motiver à courir un peu plus fort et un peu plus loin que tu croyais possible.",
        },
        "SubTitle3": {
            "English": "Running Group And Etiquette Tips",
            "French": "Étiquette et conseils pour la course en groupe",
        },
        "List3Item1": {
            "English": "Leave your headphones at home! Engage with your fellow runners and spark up conversations!",
            "French": " Laisse tes écouteurs à la maison! Apprends à connaître les membres du groupe en jasant.",
        },
        "List3Item2": {
            "English": "Abilities between group members will vary. Welcome everyone with a smile!",
            "French": " Les habiletés des coureurs peuvent varier. Souhaite la bienvenue à tout le monde en souriant!",
        },
        "List3Item3": {
            "English": "Always follow traffic rules. When you don't, it might make other members anxious or nervous.",
            "French": " Respecte les règles de circulation, sinon cela peut rendre des membres du groupe anxieux ou nerveux.",
        },
        "List3Item4": {
            "English": "LGBTQ+ individuals may struggle to feel included in the wider community, make sure you and your group promote diversity and ensure a positive, safe, and accepting environment.",
            "French": " La communauté LGBTQ+ se sent parfois exclus, donc assure-toi que le groupe de course soit inclusif, positif et soit un milieu sûr et acceptant.",
        },
        "List3Item5": {
            "English": "If you need to cough or clear your throat and expel saliva, move to the back of the pack so that others are not affected by your less-than desirable bodily functions.",
            "French": " Si tu dois tousser ou racler ta gorge et cracher, retire-toi vers la queue du peloton pour ne pas déranger les membres du groupe.",
        },
        "List3Item6": {
            "English": "Mind your distance between you and your fellow runners. Try not to run more than 2 abreast.",
            "French": " Respecte une distance entre toi et les membres du groupe. Essaie de ne pas courir plus que 2 côte à côte.",
        },
        "List3Item7": {
            "English": "Always run WITH your group. There might be times you want to separate from the pack. Be sure that you keep with the group pace to enhance the experience.",
            "French": " Cours toujours AVEC le groupe. Il se peut que tu veuilles te séparer du groupe. Assure-toi de garder le pas du groupe pour rehausser ton expérience.",
        },
        "List4Title": {
            "English": "While group running benefits are substantial, there are certain considerations that you should be aware of:",
            "French": " Bien qu’il y ait plusieurs avantages de courir en groupe, il y a des inconvénients à garder en tête:",
        },
        "List4Item1": {
            "English": "Your group is not likely to have a lot of flexibility in terms of scheduling. Although, scheduling can increase accountability, some weeks the times and locations might not work for you. If your personal schedule changes regularly and you have to skip workouts often, then that particular group may not be for you.",
            "French": " La flexibilité au niveau de l’horaire peut être un défi. Même courir en groupe augmente l’imputabilité, parfois l’horaire ou l’endroit ne te conviendra pas. Si ton horaire change régulièrement et tu dois manquer des sessions souvent, peut-être que ce groupe n’est pas le meilleur pour toi.",
        },
        "List4Item2": {
            "English": "Some people run better alone because they like to set their own pace and tempo. If you are one of these people that is okay but know that when you run with others try to maintain a pack mentality and keep the same pace and tempo as others.",
            "French": " Certaines personnes courent mieux seul ou seule car ils aiment régler leur propre rythme et cadence. Si tu es une de ces personnes c’est correct, mais lorsque tu cours avec d’autres, essaie de rester avec le groupe et maintenir leur rythme.",
        },
        "SubTitle4": {
            "English": "How To Find A Running Group",
            "French": "Où trouver un groupe de course?",
        },
        "List5Item1": {
            "English": "A simple online search of “running group” plus the name of your city or town",
            "French": "Une simple recherche en ligne ‘groupe de course’ avec le nom de ta ville devrait suffire.",
        },
        "List5Item2": {
            "English": "If you belong to a health club, inquire with staff to see if there is a group to train with.",
            "French": "Si tu fréquentes une gym, demande au personnel s’ils connaissent un groupe.",
        },
        "List5Item3": {
            "English": "Does your school have a run group? Could you make one?",
            "French": "Ton école a-t-elle un groupe de course? Pourrais-tu créer un groupe de course?",
        },
        "List5Item4": {
            "English": "You can ask your local community center or religious center",
            "French": "Tu peux demander à ton centre Communautaire ou ton centre religieux.",
        },
        "List5Item5": {
            "English": "In local parks on the bulletin boards there is often information posted for local running groups",
            "French": "Tu peux verifier les affiches sur les babillards dans les parcs.",
        },
        "List5Item6": {
            "English": "Could you start a running group at your place of work? Or do they already have one?",
            "French": "Tu pourrais peut-être commencer un groupe de course à ton lieu de travail. Ou en existe-t-il déjà un?",
        },
        "Paragraph2": {
            "English": "I found a run team and a run crew. What's the difference?",
            "French": "J’ai trouvé une équipe de course et un club de course. Quelle est la difference?",
        },
        "Paragraph3": {
            "English": "A run crew is a more diverse, less competitive, and more open to beginner runners. A run team is typically compromised of more experienced runners with a more competitive atmosphere.",
            "French": " Un club est plus diversifié, moins compétitif et plus ouvert aux débutants. Une équipe de course est typiquement composée de coureurs expérimentés avec un esprit compétitif.",
        },
        "SubTitle5": {
            "English": "Support comes in many forms!",
            "French": "L’appui arrive sous diverses formes!",
        },
        "Paragraph4": {
            "English": "If you cannot run with others whether it be due to a mental or physical health issue, or if you just prefer to run alone, this does not mean you are disconnected! You can still receive social benefits from online forums, group chats through apps, weekly posts, blogs and podcasts! Social support and the social benefits attached to it come in many different forms. You do not need a run group to feel connected and supported. For example, someone that does not run or that is not in a run group with you could still be in your motivational, information, emotional or skill acquisition support system.",
            "French": " Si tu ne peux pas courir avec d’autres, soit pour des raisons de santé physique ou mentale, ou que tu préfères simplement courir seul ou seule, ça ne veut pas pour autant dire que tu es déconnecté ou déconnectée! Tu peux tout de même profiter des bienfaits sociaux en participant à des forums en ligne, des chats de groupe, des blogs ou des baladodiffusions! Les appuis sociaux et leurs bienfaits arrivent sous diverses formes. Par exemple, quelqu’un qui ne court pas ou qui ne fait pas partie de ton groupe de course peut quand même t’appuyer (te motiver, t’informer, te soutenir sur le plan émotif ou dans l’acquisition d’habiletés). ",
        },
        "Paragraph5": {
            "English": "If you are in a running group keep in mind that the same person does not need to provide you with all forms of support. Esteem and motivation can come from one person while, emotional support can come from another.",
            "French": " Si tu fais partie d’un groupe de course, souviens-toi qu’il ne revient pas à une seule personne de te fournir toutes les formes de soutien dont tu as besoin. La motivation et l’estime peut venir d’une personne alors que le soutien émotif peut venir d’une autre personne.",
        },
        "SubTitle6": {
            "English": "Homework",
            "French": "Devoir",
        },
        "Paragraph6": {
            "English": "Pick two out of the three following social benefit module questions to complete.",
            "French": "Choisis deux des trois questions à répondre.",
        },
        "Paragraph7": {
            "English": "Based on this module, create a 5 -10 minute \"TedTalk\" about the benefits of running in a group. Present it to the class.",
            "French": " Basé sur ce module, crée une vidéo ‘Ted Talk’ de 5-10 minutes pour présenter les bienfaits de la course en groupe. Présente-le à la classe.",
        },
        "Paragraph8": {
            "English": "Keeping the social benefit module in mind, if Team Unbreakable asked you to design a new ad, what would it look like?",
            "French": " En gardant en tête ce module, si L’équipe imbattable te demandait de créer une nouvelle publicité, à quoi ressemblerait-elle?",
        },
        "Paragraph9": {
            "English": "If you had to make a new running group today, list people you think would join and create a name for your running group, list where and when they meet and create a group statement and goal that might catch the eye of other runners. Beside each member write the type of support they might give you (e.g., skill, emotional, information, motivation, safety)",
            "French": " Si tu devais créer un nouveau groupe de course aujourd’hui, dresse une liste des gens que tu penses en feraient partie et crée un nom pour le groupe. Identifie où et quand le groupe se rencontre. Rédige un énoncé de groupe et un objectif pour attirer l’attention de d’autres coureurs. À côté de chaque nom, identifie la forme de soutien qu’il ou elle fournira (p. ex., habileté, émotif, information, motivation, sécurité).",
        },
        "Input2Header": {
            "English": "Possible Members",
            "French": "Membres possibles",
        },
        "Input3Header": {
            "English": "Group Name",
            "French": "Nom du groupe",
        },
        "Input4Header": {
            "English": "When and where to meet",
            "French": "Quand et où on se rencontre",
        },
        "Input5Header": {
            "English": "Group statement and goal",
            "French": "Énoncé de groupe et objectif",
        },
        "Paragraph10": {
            "English": "Remember: Do not just sit around waiting for someone to ask! You ask them!",
            "French": "Rappel: ne fais pas juste t’asseoir en attendant que quelqu’un t’approche. Demande-leur!",
        },
    },
    "Module 6": {
        "Title": {
            "English": "Module #6",
            "French": "Module 6",
        },
        "SubTitle1": {
            "English": "Body Image",
            "French": "Image corporelle",
        },
        "SubTitle2": {
            "English": "What Is Body Image",
            "French": "Qu’est-ce que l’image corporelle?",
        },
        "Paragraph1": {
            "English": "When you run, do you find yourself thinking about how your body moves or looks to yourself or from the viewpoint of others? If you do, know that it is normal. Body image refers to how a person thinks and feels about their body in its appearance and function. Everyone experiences positive and negative body image.",
            "French": " Lorsque tu cours, penses-tu à comment ton corps bouge ou à ce qu’il a l’air? Plusieurs personnes le font. L’image corporelle vient des pensées et des sentiments que nous avons à l’égard de notre corps au niveau de l’apparence et la performance (fonction). On estime que plus de 95% des ados et des jeunes adultes ont quelque chose qu’ils n’aiment pas et des choses qu’ils apprécient à l’égard de leur corps.",
        },
        "SubTitle3": {
            "English": "What Does Body Image Look Like?",
            "French": "What Does Body Image Look Like?",	/*TODO: Get French */
        },
        "Paragraph2": {
            "English": "Body image is multidimensional construct. It is more than just how we see ourselves but as well as how we think, feel, and behave. After reading a summary of each of the four aspects of body image, give an example of how each aspect can be represented using your own experience of body image:",
            "French": " L’image corporelle est un concept multidimensionnel car il englobe les perceptions présentes et 	passées, les jugements affectifs, les connaissances et les comportements.  Peux-tu penser à un exemple pour chacun des aspects ci-dessous?",
        },
        "Paragraph3": {
            "English": "The mental representation that we have of our body in how it looks and its capabilities.",
            "French": " La représentation mentale que nous avons de notre corps au niveau de l’apparence et la performance.",
        },
        "Input1Header": {
            "English": "Perceptual",
            "French": "Perception",
        },
        "Paragraph4": {
            "English": "The thoughts and beliefs that we have of our own bodies appearance and functions.",
            "French": " Les pensées et les croyances que nous avons à l’égard de notre corps, son apparence et sa fonction.",
        },
        "Input2Header": {
            "English": "Cognitive",
            "French": "Cognitif",
        },
        "Paragraph5": {
            "English": "The emotions that we experience in relation to our body’s function and appearance.",
            "French": " Les émotions ressenties à l’égard de mon corps, son apparence et ce qu’il peut faire.",
        },
        "Input3Header": {
            "English": "Affective",
            "French": "Affectif",
        },
        "Paragraph6": {
            "English": "The actions that we display or do not based on the perception, thoughts, and emotions we have on our body image.",
            "French": " Les actions qu’on démontre basées sur les perceptions, les pensées et les émotions que nous avons à l’égard de notre image corporelle.",
        },
        "Input4Header": {
            "English": "Behavioural",
            "French": "Comportemental",
        },
        "SubTitle4": {
            "English": "Body Image And Sports",
            "French": "L’image corporelle et les sports",
        },
        "Paragraph7": {
            "English": "Body image and sport/exercise have a bi-directional relationship. This means that body image can influence and be influenced by sports, physical activity, and/or exercise. This can be either positive or negative.",
            "French": " L’image corporelle et les sports/l’exercice ont une relation bidirectionnelle. Ceci veut dire que l’image corporelle peut influencer et peut être influencée par le sport, l’activité physique et/ou l’exercice de façon positive ou négative.",
        },
        "Paragraph8": {
            "English": "For some individuals, physical activities can create perceived changes to one’s physical self that can boost self-esteem and confidence. When we perceive that our body has improved for the better, so does our perception of our body as well. For others, it is possible that one’s body image can deter them from participating in sports and/or physical activities. It has been shown that focusing and promoting functional aspects of our body can enhance positive body image. The goal of physical activity should be that it is enjoyable and supports mental and physical health. The goal should not be to focus on body shape and size",
            "French": " Pour certaines personnes, l’activité physique peut créer des changements perçus ce qui peut augmenter l’estime de soi et la confiance. Pour d’autres personnes, l’image corporelle peut les décourager de pratiquer un sport ou une activité physique. Il a été démontré que la promotion des aspects fonctionnels de notre corps peut améliorer l’image corporelle. Cela signifie qu’on doit se concentrer sur ce que notre corps peut faire et comment il fonctionne. De plus, on doit éviter d’établir des objectifs qui sont liés à l’apparence et le poids.",
        },
        "Input5Header": {
            "English": "How could an individual experiencing body image concerns be able to use running as a way to combat negative body image?",
            "French": "Quelles sont les façons dont tu pourrais te concentrer sur la façon dont ton corps fonctionne plutôt que son apparence? Quelles stratégies peux-tu utiliser pendant que tu cours?",
        },
        "SubTitle4a": {
            "English": "Is There An Ideal Body Type?",
            "French": "Y a-t-il un type de corps idéal?",
        },
        "Paragraph9": {
            "English": "Ask a group of people of their ideal body type and you will get a variety of answers. This is because there is no one ideal perfect body type. It can be influenced by a variety of factors including media (i.e.., advertising, movies/TV show, pop culture, family, friends/peers, and our own self. Body image ideals are ever changing in time and space. Therefore, it is important to remember that there is no one ideal body type. All individuals are unique in their body type and function. It is important that we work on accepting each other and ourselves",
            "French": " Demande à un groupe de personnes quel est leur type de corps idéal et tu obtiendras une variété de réponses. C’est parce qu’il n’y a pas un seul type de corps idéal parfait. Il peut être influencé par une variété de facteurs, y compris les médias (p. ex., les publicités, les films ou émissions de télé, la culture pop, la famille, les amis ou les pairs et notre propre culture et nos expériences. Il est important de se rappeler qu’il n’y a pas un seul type de corps idéal. On doit donc apprendre à accepter les autres tels qu’ils sont et s’accepter tel que nous sommes.",
        },
        "SubTitle5": {
            "English": "Body Image And Social Media",
            "French": "L’image corporelle et les medias sociaux",
        },
        "Paragraph10": {
            "English": "Different than traditional media, social media features the user and their content themselves, often edited to present an idealized image of themselves and their lives. Social media allows us to connect more than ever, yet we often find ourselves in a bubble comparing ourselves to our peers. This is called social comparison and is often a negative aspect of social media platforms. For example, Instagram has been shown to be linked to body dissatisfaction and body surveillance for women. Similarly social media has played a role in the development of body dissatisfaction for men with an increased emphasis on masculinity and leanness in body image. Comparing ourselves to others can lead to the development of mental health issues and eating disorders.",
            "French": " Les messages sur les médias sociaux sont souvent édités pour présenter une image idéalisée. Les médias sociaux nous permettent de se connecter plus que jamais, toutefois les gens se comparent souvent à leurs pairs et à ceux qu’ils suivent. Cette comparaison sociale suscite rarement des perceptions positives, ni sur le plan cognitif ni émotif. Par exemple, on a trouvé un lien entre Instagram et une insatisfaction corporelle chez les femmes. De même, les médias sociaux ont joué un rôle dans le développement d’une insatisfaction corporelle pour les hommes en mettant l’accent sur la masculinité et la maigreur dans l’image corporelle. Y a-t-il des choses que tu peux faire pour être moins influencé/influencée par les messages sur les médias sociaux? Oui, par exemple, tu pourrais choisir de suivre des gens qui te font sentir bien. Tu pourrais supprimer des flux que tu trouves nuisibles.",
        },
        "Input6Header": {
            "English": "Take a moment and view your own social media feed and list and explain the differences and similarities of how body image is idealized between men and women.",
            "French": "Prends un moment et consulte ton flux de médias sociaux et la liste de ce que tu aimes / ce qui te fait sentir bien et que tu n’aimes pas / ce qui te fait sentir mal. Si tu n’as pas de médias sociaux, parcours les profils publics en ligne.",
        },
        "SubTitle6": {
            "English": "Homework",
            "French": "Devoir",
        },
        "Paragraph11": {
            "English": "Write a letter to yourself using self-compassion techniques about comforting a body image insecurity that you are experiencing or have experienced. What will you do to try to combat this insecurity?",
            "French": " Écris-toi une lettre en utilisant des techniques d’auto-compassion pour réconforter une insécurité d’image corporelle que tu éprouves ou que tu as éprouvé. Qu’est-ce que tu vas faire pour lutter contre cette insécurité?",
        },
    },
    "Module 7": {
        "Title": {
            "English": "Module #7",
            "French": "Module 7",
        },
        "SubTitle1": {
            "English": "Self Compassion",
            "French": "Auto-compassion",
        },
        "Paragraph1": {
            "English": "\"You yourself, as much as anybody in the entire universe deserve your love and affection.\" - Buddha",
            "French": "‘Vous-même, autant que n’importe qui dans tout l’univers, méritez votre amour et votre affection.’ – Bouddha",
        },
        "SubTitle2": {
            "English": "What Is Self-Compassion?",
            "French": "Qu’est-ce que l’auto-compassion?",
        },
        "Paragraph2": {
            "English": "Self-compassion is a love for yourself that comes with an expression of understanding, warmth, and kindness. Treat yourself the way you would treat a friend (for example, not judging them too harshly when they fail or make a mistake).",
            "French": "L’auto-compassion est l’amour de soi accompagné de la compréhension, de la tendresse et de la gentillesse. On doit se traiter de la même façon qu’on traiterait un ami ou une amie (p. ex., ne pas juger trop sévèrement quand ils échouent ou font une erreur).",
        },
        "SubTitle3": {
            "English": "Self-Compassion",
            "French": "Auto-compassion",
        },
        "Paragraph3Title": {
            "English": "Self-kindness: ",
            "French": "L’auto-gentillesse:",
        },
        "Paragraph3": {
            "English": "Being kind to yourself, especially when you make a mistake",
            "French": "être gentil(le) envers soi-même, surtout lorsqu’on commet une erreur.",
        },
        "Paragraph4Title": {
            "English": "Common Humanity: ",
            "French": "Humanité commune:",
        },
        "Paragraph4": {
            "English": "Understanding that everyone has troubles and needs a helping hand sometimes",
            "French": "comprendre que nous avons tous des problèmes et nous avons tous besoin d’un coup de main occasionnellement.",
        },
        "Paragraph5Title": {
            "English": "Gratitude: ",
            "French": "Gratitude:",
        },
        "Paragraph5": {
            "English": "An expression or feeling of being grateful",
            "French": " expression ou sentiment de reconnaissance.",
        },
        "Paragraph6Title": {
            "English": "Mindfulness: ",
            "French": "Pleine conscience",
        },
        "Paragraph6": {
            "English": "A mental state achieved by focussing one's awareness on the present moment",
            "French": " l’état mental qu’on atteint en se concentrant sur le moment présent.",
        },
        "SubTitle4": {
            "English": "Why is self-compassion important?",
            "French": "Pourquoi l’auto-compassion est importante?",
        },
        "List1Title": {
            "English": "Here are just some of the benefits of practicing self-compassion:",
            "French": "Voici quelques bienfaits de pratiquer l’auto-compassion:",
        },
        "List1Item1": {
            "English": "Increased self-confidence",
            "French": "Confiance en soi accrue",
        },
        "List1Item2": {
            "English": "Improved mood",
            "French": "Amélioration de l’humeur",
        },
        "List1Item3": {
            "English": "Enjoyment of activities such as running",
            "French": "S’amuser en pratiquant des activités telle que la course à pied",
        },
        "List1Item4": {
            "English": "Enhanced motivation",
            "French": "Motivation accrue",
        },
        "List1Item5": {
            "English": "Increased self-value",
            "French": "Augmentation de la valeur de soi",
        },
        "List1Item6": {
            "English": "Better body image",
            "French": "Meilleure image corporelle",
        },
        "List1Item7": {
            "English": "Better health",
            "French": "Meilleure santé",
        },
        "SubTitle5": {
            "English": "Self-Kindness",
            "French": "Auto-bienveillance",
        },
        "Paragraph7": {
            "English": "Self-kindness is treating yourself without judgement, criticism, or insult. Practicing self- kindness means being warm and understanding to yourself, and this perspective is particularly important during times of pain or personal shortcomings. To be self-kind, we also need to practice:",
            "French": "L’auto-bienveillance, c’est être gentil envers soi, sans jugement, sans critique ou sans insulte. Pratiquer l’auto-bienveillance signifie être chaleureux et compréhensif envers soi-même, et cette perspective est particulièrement importante lorsque nous éprouvons des épreuves personnelles. Pour être auto-bienveillant, nous devons également pratiquer:",
        },
        "List2Item1": {
            "English": "Self-love: defined as the act of showing yourself love and prioritizing yourself and your health. Self-love can be practiced using positive self-talk, affirmations, refraining from judging yourself, and setting healthy boundaries with yourself and the people around you.",
            "French": " Amour de soi: est la capacité de démontrer de l’amour pour soi-même et de se prioriser ainsi que sa santé. L’amour de soi pour inclure l’utilisation d’un dialogue intérieur positif, des affirmations, s’abstenir de se juger et établir des limites saines pour soi-même et pour les gens autour de nous.",
        },
        "List2Item2": {
            "English": "Self-care: described as actions or behaviours that you can take to help you feel positive emotions. For example, some people may relax by unwinding with a cup of tea and a hot bubble bath, but other people may go outside to meet with friends or go for a walk by themselves to feel happy. Self-care strategies are often good for your physical health as well, but they do not have to be. Sometimes, self-care can be a nap if you need it. Self-care strategies are personal and unique to you- what works for others, might not work for you.",
            "French": "Auto-soins: sont des actions ou des comportements que l’on adopte pour prendre soin de soi-même et qui nous permettent de se sentir bien. Par exemple, certaines personnes peuvent relaxer en prenant une tasse de thé et un bain mousseux alors que d’autres préfèrent rencontrer des amis ou amies ou encore faire une marche seule. Les stratégies sont souvent bonnes pour notre santé physique, mais ce n’est pas obligatoire. Parfois une sieste est ce dont on a besoin pour s’occuper de soi. Les stratégies sont personnelles et uniques, ce qui fonctionne pour d’autres personnes ne fonctionnera pas nécessairement pour vous. ",
        },
        "Paragraph8": {
            "English": "Fun fact: Exercise is a form of self-care for a lot of people. This is because exercise not only improves your physical health but is proven to impact your mental health positively as well. Exercise helps you feel motivated, happy, and energetic when you enjoy it!",
            "French": "Fait amusant: L’exercice est une stratégie d’auto-soins pour plusieurs personnes. Non seulement l’exercice améliore notre santé physique mais il est prouvé qu’elle peut aussi avoir un impact positif sur notre santé mentale. L’exercice nous aide à se sentir motivé(e), heureux(se) et énergique lorsque tu aimes l’activité.",
        },
        "Paragraph9": {
            "English": "It is important to note that self-kindness (and the acts of self- love and self-care) do not come easily to everyone and may take some practice. Prioritizing the practice of self-kindness is important, and remember that self-kindness, love, and care are not selfish!",
            "French": "Il est important de noter que l’auto-bienveillance (ainsi que l’amour de soi et l’auto-soins) ne viennent pas facilement à tout le monde et nécessite une certaine pratique. Il est important de prioriser la pratique de l’auto-bienveillance et se souvenir que ce n’est pas égoïste de le faire.",
        },
        "SubTitle6": {
            "English": "Common Humanity",
            "French": "Humanité commune",
        },
        "Paragraph10": {
            "English": "Common humanity is understanding that pain and unpleasant feelings are a part of the human experience and are universal. In this way, through an understanding that pain is shared among all individuals, we feel connected and bonded. Not only do you feel more connected to the people are around you, but you also get the feeling that you are not alone in your unpleasant feelings.",
            "French": "L’humanité commune c’est comprendre que la douleur et les sentiments négatifs font partie de l’expérience humaine et sont universels, c’est-à-dire que ça arrive à tout le monde. En comprenant que tout le monde vit des épreuves, on se sent connecté. Non seulement on se sent connecté, mais on a aussi l’impression de ne pas être seul(e) avec nos sentiments désagréables.",
        },
        "Paragraph11": {
            "English": "A good understanding of common humanity is helpful to experience gratitude and resilience and getting through difficult times a little easier.",
            "French": "Une bonne compréhension de l’humanité commune est utile pour éprouver de la gratitude, faire preuve de résilience et traverser les moments difficiles un peu plus facilement.",
        },
        "SubTitle7": {
            "English": "Gratitude",
            "French": "Gratitude",
        },
        "Paragraph12": {
            "English": "Gratitude is a powerful psychological tool. Research shows that when people adopt an “attitude of gratitude”, it can improve both their physical and mental health. But that’s not all…",
            "French": "La gratitude est un outil psychologique puissant. Les recherches démontrent que lorsque les gens adoptent une ‘attitude de gratitude’, leur santé physique et leur santé mentale s’améliorent. Mais ce n’est pas tout…",
        },
        "Paragraph13": {
            "English": "Gratitude can also help us become better runners. An article published in Runners World explored the concept of the “gratitude mile”. Runners weighed in on what keeps them going when they get tired. Focusing on things that they were grateful for when they “hit the wall” helped them push through and finish a run feeling strong.",
            "French": "La gratitude peut aussi nous aider à devenir de meilleurs coureurs. Un article publié dans la revue Runners World a exploré le concept du ‘mile gratitude’. Les coureurs ont partagé ce qui leur 	permet de continuer lorsqu’ils sont fatigués. Lorsqu’ils ‘frappent le mur’, se concentrer sur des choses pour lesquels ils sont reconnaissants les aident à traverser l’obstacle et terminer la course en pleine forme.",
        },
        "Paragraph14": {
            "English": "Create your own internal mantra, a word or phrase you commonly repeat to help you stay motivated, for when you run, and things get tough. Something as simple as “I can do this”, “I am strong,” or better yet, “I am unbreakable” can help boost your mood and confidence.",
            "French": "Crée ton propre mantra interne, un mot ou une phrase que tu pourras te répéter en courant et qui t’aidera à rester motivé(e) lorsque les choses deviennent difficiles. Une phrase simple comme ‘Je peux le faire’, ‘Je suis fort(e)’, ou encore mieux ‘Je suis imbattable’ peut aider à stimuler ton humeur et ta confiance.",
        },
        "SubTitle8": {
            "English": "Mindfulness",
            "French": "Pleine conscience",
        },
        "Paragraph15": {
            "English": "Mindfulness is paying attention to all of your senses in the present moment, without judgement. It is having a moment-by-moment awareness of your thoughts, emotions, bodily sensations, and surrounding environment with openness and curiosity.",
            "French": "La pleine conscience signifie prêter attention à tous nos sens dans le moment présent, sans jugement. C’est prendre conscience de ses pensées, ses émotions, ses sensations corporelles et son environnement avec ouverture et curiosité.",
        },
        "Paragraph16": {
            "English": "As you practice mindfulness more and more often, you develop an awareness of your thoughts. When you become aware that you’re getting stuck with a thought, you can simply label it as “worry thoughts” or “thoughts” and come back to your present moment.",
            "French": " En pratiquant la pleine conscience de plus en plus souvent, tu développeras une conscience de tes pensées. Lorsque tu constates qu’une pensée ne te lâche pas, tu peux l’étiqueter comme étant une ‘inquiétude’ ou simplement une ‘pensée’ ensuite revenir au moment présent.",
        },
        "SubTitle9": {
            "English": "How to practice self-compassion in the Team Unbreakable program",
            "French": "Comment pratiquer l’auto-compassion dans le programme ‘Team Unbreakable’",
        },
        "List3Item1": {
            "English": "Don’t judge your run critically, identify what you are grateful for once the run is over.",
            "French": " Ne juge pas ta course de façon critique, identifie ce pour lequel tu es reconnaissant(e) une fois la course terminée.",
        },
        "List3Item2": {
            "English": "Everyone starts at different places mentally and physically, and every day is different. So, comparing yourself to others is futile.",
            "French": " Tout le monde commence à différents endroits mentalement et physique et chaque jour est différent. Donc, se comparer aux autres est futile.",
        },
        "List3Item3": {
            "English": "It is ok to walk when you need to but remember to do what you can.",
            "French": " Il est permis de marcher lorsque tu en as besoin mais n’oublie pas de faire ce que tu peux faire.",
        },
        "List3Item4": {
            "English": "Avoid negative self-talk, turn negative statements like “I should have done more” into “I will try to do better next time”.",
            "French": " Évite le dialogue interne négatif, change une déclaration négative comme ‘J’aurais dû en faire plus’ au positif ‘Je vais essayer de faire mieux la prochaine fois’.",
        },
        "SubTitle10": {
            "English": "Strategies For Self-Care",
            "French": "Stratégies d’auto-soins",
        },
        "Paragraph17": {
            "English": "Think about your personal experience and write down some of your favourite hobbies and self-care strategies in the left column. Next to each strategy, write down what emotion that activity elicits. You can include anything you find fun and positive, don’t limit yourself!",
            "French": "En te basant sur ton expérience personnelle, écris quelques-uns de tes passe-temps préférés ainsi que des stratégies d’auto-soins dans la colonne de gauche. À côté de chaque stratégie, écris quelle émotion est suscitée par cette activité. Tu peux inclure tout ce que tu trouves amusant et positif, il n’y a pas de limites.",
        },
        "SubTitle11": {
            "English": "Meditation Activity",
            "French": "Activité de méditation",
        },
        "Paragraph18": {
            "English": "Watch Goodful’s 5-minute meditation and write a few sentences about how you felt before and after the exercise – describe if you think it will be helpful for you in reaching the goals that you have set and with improving your self-compassion:",
            "French": "Regarde la vidéo de 5 minutes sur la méditation de Goodful ensuite écris quelques phrases décrivant ce que ressens avant et après l’exercice. Décris si tu penses que la méditation te sera utile pour l’atteinte de tes objectifs et pour l’amélioration de ton auto-compassion:",
        },
        "Input1Header": {
            "English": "What Are Your Thoughts",
            "French": "Qu’en penses-tu?",
        },
        "SubTitle12": {
            "English": "Social Media Activity",
            "French": "Activité sur les medias sociaux",
        },
        "Paragraph19": {
            "English": "Often you see on the internet and social media that self-care is all about wearing face masks, buying yourself nice things and eating your favourite food, but there’s a lot more to it than that.",
            "French": " À l’Internet et dans les médias sociaux, on présente souvent l’auto-soin comme étant le fait de porter des masques, de s’acheter de belles choses et manger sa nourriture préférée, mais il y a beaucoup plus que cela.",
        },
        "Paragraph20": {
            "English": "Social media, depending on how you use it and how you react to it, can be used as a strategy for self-care, or can be detrimental to your self-image. To use social media as a means of self-care, you should use it for things that you enjoy – for example, if you enjoy making art, following art pages, and using them as inspiration or even starting your own art page could be considered a form of self-care! It is important not to get caught up in the number of likes you get or start comparing yourself to others because this can start making you feel negative; use social media to connect to your friends, discover new hobbies and safely share your experiences. Remember, moderation is key with something like social media, so if you use it, make sure you make time to log off and enjoy your hobbies in person.",
            "French": " Les médias sociaux peuvent être utilisés comme une stratégie d’auto-soin ou peuvent nuire à ton image de soi, dépendant de la façon dont tu l’utilises et la façon dont tu y réagis. Pour être un moyen d’auto-soin, tu dois l’utiliser pour des choses que tu aimes. Par exemple, si tu aimes faire de l’art, suivre des pages d’art, les utiliser comme source d’inspiration ou même commencer ta propre page d’art pourrait être considéré comme une forme d’auto-soin.  C’est important de ne pas se laisser prendre par le nombre de ‘j’aime’ qu’on ne reçoit ni commencer à se comparer à d’autres car cela peut entraîner des sentiments négatifs. Utilise plutôt les médias sociaux pour te connecter à tes amis(es), pour découvrir de nouveaux passe-temps et pour partager certaines expériences. Rappelle-toi que lorsqu’il s’agit des médias sociaux, la modération est la clé. Si tu choisis de t’en servir, assure-toi de te déconnecter et jouir de tes passe-temps en personne.",
        },
        "Paragraph21": {
            "English": "For this activity, try and think of some ways you could use your personal social media as self-care. If you don’t have social media, try and think of some ways social media could be detrimental to your self-image and self-care ability.",
            "French": " Pour cette activité, essaie de penser à des façons que tu pourrais utiliser tes médias sociaux comme technique d’auto-soin. Si tu n’as pas de médias sociaux, essaie de penser à des façons que les médias sociaux pourraient nuire à ton image de soi.",
        },
    },
    "Module 8": {
        "Title": {
            "English": "Module #8",
            "French": "Module 8",
        },
        "SubTitle1": {
            "English": "Creating A Balanced Life",
            "French": "Créer une vie équilibrée",
        },/* TODO:
*** missing – English: Life can get a little busy…  So how do you choose what is most important to you and how do you create balance in your life?
French – La vie peut devenir un peu occupée… Alors comment choisir ce qui est le plus important pour toi afin de créer un équilibre dans ta vie? */
        "SubTitle2": {
            "English": "Balance As A Wheel",
            "French": "La roue de l’équilibre",
        },
        "Paragraph1": {
            "English": "There are many ways of thinking about balance. One way is to think of balance as a wheel. For a wheel to roll smoothly, it needs to have balanced sections. Each section represents the different things in your life, and what you need and want to direct your time and energy to wards. Many different things like family, schoolwork, or sleep can make up the sections of the wheel and everyone’s wheel will look different!",
            "French": " Il existe plusieurs façons de penser à l’équilibre. Une des façons est d’imaginer une roue; pour qu’elle roule doucement, elle doit avoir des sections équilibrées. Chaque section représente les différents secteurs dans ta vie donc ce vers lequel tu diriges ton énergie et tu consacres ton temps. Ta roue d’équilibre de vie pourrait inclure des composantes comme la famille, les travaux scolaires ou même le sommeil. La roue de vie de chaque personne sera différente!",
        },
        "SubTitle3": {
            "English": "Activity 1: Creating Balance",
            "French": "Activité 1: Créer une roue de l’équilibre",
        },
        "Paragraph2": {
            "English": "Everyone has different things in their lives that are most important to them and that deserve to be prioritized. What are the important things in your life, and what does balance look like for you? Check out the example below and list a few more things that are important to you.",
            "French": " Chacun a des choses différentes dans sa vie qui lui sont importantes et qui méritent d’être priorisées. Quelles sont les choses importantes dans ta vie et à quoi ressemble l’équilibre pour toi? Consulte l’exemple ci-dessous et énumère quelques éléments supplémentaires qui sont importants pour toi.",
        },
        "SubTitle4": {
            "English": "Things to keep in mind when we think about Balance...",
            "French": " Maintenant que tu as identifié tous les secteurs importants dans ta vie, essayons de construire une roue de l’équilibre. Voici 5 éléments à considérer lors de la création d’une roue.",
        },
        "List1Item1": {
            "English": "Are there sections you’d like to make larger, more important, and fill more time? Put a star near them",
            "French": " a-t-il des sections que tu aimerais agrandir, qui sont plus importantes et qui prennent plus de temps? Place une étoile près d’elles",
        },
        "List1Item2": {
            "English": "Do some brainstorming:",
            "French": "Fais un remue-méninge:",
        },
        "List1Item2a": {
            "English": "Are there activities you used to enjoy when you were younger?",
           "French": "Quelles activités te plaisaient quand tu étais plus jeune?",
        },
        "List1Item2b": {
            "English": "Have you included some exercise other than Team Unbreakable, like yoga?",
	        "French": "As-tu inclus des exercices autres que ceux du programme ‘Team Unbreakable’, comme le yoga?",
        },
        "List1Item2c": {
            "English": "What are some activities that you could do that include other people?",
		 "French": "Quelles sont certaines activités que tu pourrais faire qui inclus d’autres personnes?",
        },
        "List1Item2d": {
            "English": "Have there been activities that people have been recommending and you’ve been refusing to try?",
		"French": " Est-ce qu’on t’a recommandé des activités que tu hésites d’essayer?",
        },
        "List1Item2e": {
            "English": "Have you ever had a teacher tell you that you are good at something, but you didn’t believe them? Now is the time to try something new!",
        "French": " Un(e) enseignant(e) t’a-t-il(elle) déjà dit que tu étais bon(ne) dans quelque chose, mais tu ne l’as pas cru? Il est maintenant temps d’essayer quelque chose de nouveau!",
        },
        "List1Item2f": {
            "English": "Have you finished your volunteer hours for school?",
            "French": "As-tu terminé tes heures de bénévolats pour l’école?",
        },
        "List1Item3": {
            "English": "Share these ideas and thoughts with others",
            "French": "Partage ces idées et pensées avec d’autres (tes amis(es) ou ta famille",
        },
        "List1Item3a": {
            "English": "Friends, parents, etc. The more people know, the more they might have ideas that can help.",
         "French": "Le plus tu en parles aux gens, meilleures sont les chances que quelqu’un puisse t’offrir des idées pour aider.",
        },
        "List1Item4": {
            "English": "Take action",
            "French": "Passe à l’action",
        },
        "List1Item4a": {
            "English": "Make new AIM Goals that have to do with trying a new activity.",
            "French": "Fixe un nouvel objectif lié à une nouvelle activité.",
        },
        "List1Item4b": {
            "English": "Joining something, and/or talking to an adult who can help you figure out when/ where to add these activities.",
            "French": "Fais partie d’un club ou d’un groupe. ",
        },
        "List1Item5": {
            "English": "Remember, finding balance is one thing. Maintaining balance is a whole other challenge. You need to work at it. As your life changes, so will your wheel. You’ll need to take breaks and re-evaluate this. You’ll always need to make sure that your most important sections:",
            "French": "Rappelle-toi que trouver l’équilibre est une chose, mais maintenir l’équilibre est tout un autre défi. Tu dois y travailler. Au fur et à mesure que ta vie change, ta roue changera aussi. Tu devras prendre des pauses et réévaluer. ",
        },
        "List1Item5a": {
            "English": "The ones that make you feel better, more in control, happier are taken care of before you try to fit in other things.",
            "French": "Tu devras toujours t’assurer que les sections les plus importantes pour toi (celles qui te plaisent et te rendent heureux/se) font partie de ta roue avant d’ajouter autres choses.",
        },
        "SubTitle5": {
            "English": "Balance As A Jar",
            "French": "L’équilibre dans un bocal",
        },
        "Paragraph2": {
            "English": "Think of balance as a jar and think of your life as a jar full of rocks, gravel, and sand. When we add the big rocks first, we can still fill in the gaps with gravel and sand. But if we don’t put the big rocks in first, then the gravel and sand will take up all the valuable space in the jar, leaving no room for the big rocks. The big rocks represent the most important things, while the gravel and sand are the less important things. If you fill your life with the less important things, like TV and social media, you will never have enough time or energy for the more important things in your life like family, school, self-care, and friends.",
            "French": "Une autre façon d’illustrer l’équilibre est de penser à sa vie comme étant un bocal rempli de roches, de gravier et de sable. Lorsqu’on ajoute les grosses roches en premier, on peut remplir les espaces avec le gravier et le sable. Toutefois si on ne dépose pas les roches en premier, le gravier et le sable prendront l’espace précieux, laissant moins d’espace pour les roches. Les roches représentent les choses les plus importantes alors que le gravier et le sable sont les choses moins importantes. Si on donne priorité aux peccadilles (comme la télé, les médias sociaux…) on n’aura plus suffisamment de temps précieux à consacrer aux éléments importants (comme la famille, l’école, les amis(es) et l’auto-bienveillance).",
        },
        "Paragraph3": {
            "English": "Use the space in the jar below to write out the most important things that bring your life balance.",
           "French": "Utilise l’espace dans le bocal ci-dessous pour écrire les tâches/activités les plus importantes qui t’apportent un équilibre.", 
        },
        "Paragraph3a": {
            "English": "Look at the things you've placed in your jar. If you had to place them in a smaller jar, what would come first? Do you feel like you might need more space in the jar, or more hours in the day to fit it all in? Or do you feel like there might be hours that are left over?",
            "French": "Regarde la liste. Si tu devais les placer dans un bocal plus petit, que choisirais-tu en premier? Crois-tu avoir besoin un plus grand bocal ou plus d’heures dans la journée pour tout inclure? Ou crois-tu qu’il te restera des heures en surplus?", 
        },
        "SubTitle6": {
            "English": "Next Step for a Balanced Life - Time management",
            "French": "La prochaine étape pour une vie équilibrée – Gestion du temps",
        },
        "Paragraph4": {
            "English": "In order to achieve a balanced life, time management is an essential skill for you. Here are ten time management tips for you to assist you with your balanced life! (See attachment for detailed descriptions for each tip).",
            "French": "Afin d’atteindre une vie équilibrée, la gestion du temps est une compétence essentielle. Voici dix conseils de gestion du temps pour t’aider à atteindre une vie équilibrée (voir la pièce jointe pour une description détaillée pour chaque conseil).",
        },
        "List2Item1": {
            "English": "Make a “to-do” list for each day and every week",
            "French": "Dresser une liste de choses à faire pour chaque jour et chaque semaine.",
        },
        "List2Item2": {
            "English": "Review your notes every day",
            "French": "Revoir tes notes à tous les jours.",
        },
        "List2Item3": {
            "English": "Do the stuff you don't want to do first",
            "French": "Faire les choses moins intéressantes/agréables en premier",
        },
        "List2Item4": {
            "English": "For your long tasks, break them up into smaller chunks and give yourself breaks",
            "French": "Couper les tâches plus longues en petits morceaux et se donner des pauses.",
        },
        "List2Item5": {
            "English": "Use spare time wisely",
            "French": "Utiliser ton temps libre sagement.",
        },
        "List2Item6": {
            "English": "It’s OK to say “no”",
            "French": "C’est correct de dire ‘non’.",
        },
        "List2Item7": {
            "English": "Figure out when and how you work best",
            "French": "Déterminer quand et comment tu travailles le mieux.",
        },
        "List2Item8": {
            "English": "Get enough sleep",
            "French": "S’assurer d’avoir assez de sommeil.",
        },
        "List2Item9": {
            "English": "Let others know when you are busy and be willing to turn off your phone",
            "French": "Dire aux autres lorsque tu es occupé(e) et accepter d’éteindre ton cellulaire.",
        },
        "List2Item10": {
            "English": "Control your time, do not waste time agonizing",
            "French": "Gérer ton temps, ne perd pas de temps à t’angoisser.",
        },
        "List2Item11": {
            "English": "Do not waste time agonizing",
            "French": "Ne perd pas de temps à t’angoisser.",
        },
        "SubTitle7": {
            "English": "Helpful tools",
            "French": "Outils utiles",
        },
        "Paragraph5": {
            "English": "It won’t hurt to have a little help when trying to improve your time management skill, here are three tools that can assist you with your time management.",
            "French": "Ne te gêne pas pour demander un peu d’aide lorsque tu essaies d’améliorer tes compétences en gestion du temps. Voici cinq outils qui pourraient t’aider:",
        },
        "List3Item1": {
            "English": "Flora-Focus",
            "French": "Flora-Focus",
        },
        "List3Item1a": {
            "English": "A new way for you to focus on a task, plant a seed before you start a task, if you work hard, the seed will grow into a healthy tree. Focus on your work and give birth to a healthy tree.",
            "French": "Cette appli offre une nouvelle façon de se concentrer sur une tâche en plantant une graine avant de commencer et en travaillant fort pour la faire pousser. En te concentrant sur ton travail, tu feras naître un arbre sain.",
        },
        "List3Item2": {
            "English": "Productive-Habit Tracker",
            "French": "‘Productive-Habit Tracker’ (Le traqueur d’habitudes)",
        },
        "List3Item2a": {
            "English": "An easy-to-use tool that helps you build a routine of positive life changing habits.",
            "French": "Une appli facile à utiliser qui t’aide à développer une routine d’habitudes positives qui changent la vie.",
        },
        "List3Item3": {
            "English": "Carrot Rewards",
            "French": "‘Carrot Rewards’ (La carotte récompense)",
        },
        "List3Item3a": {
            "English": "Free and fun app that lets you track your habits, you can gain points by finishing your task and competing with your friends.",
            "French": "Cette appli gratuite et amusante te permet de suivre tes habitudes. Tu gagnes des points en terminant ta tâche et tu peux jouer avec tes amis(es) dans un esprit compétitif.",
/*TODO:*** have the next 2 been eliminated?
English
Brian Tracy: Goal Tracker & Daily Planner
A great coaching program that helps you discover, plan and achieve your most important personal and professional goal. 
Sleep cycle
You can use this program to track your daily sleep. A good night's sleep gives your body and brain the time to rest and recover. 
French
‘Brian Tracy : Goal Tracker & Daily Planner’ (traqueur d’objectifs et planificateur)
Un excellent programme de coaching qui t’aide à découvrir, planifier et atteindre ton objectif personnel et professionnel le plus important.
‘Sleep cycle’ (Cycle de sommeil)
Tu peux utiliser ce programme pour suivre ton sommeil quotidien. Une bonne nuit de sommeil permet à ton corps et à ton cerveau de se reposer et de récupérer.*/

        },
        "SubTitle8": {
            "English": "9 Tips For Time Management",
            "French": "9 conseils pour gérer son temps",
        },
        "List4Item1": {
            "English": "Review your notes every day. If you review your notes daily, you will need less time to",
            "French": "Revoir tes notes à tous les jours. Si tu revois tes notes à tous les jours, tu auras besoin moins de temps.",
        },
        "List4Item2": {
            "English": "Do the stuff you don’t want to do first Break long tasks into smaller chunks and give yourself breaks",
            "French": "Fais les choses moins agréables/intéressantes en premier. Divise les grosses tâches en petites bouchées et prend des pauses.",
        },
        "List4Item3": {
            "English": "Use spare time wisely Study or read on the be bus ride to school",
            "French": "Utilise ton temps libre sagement. Étudie ou lit dans l’autobus en route vers l’école.",
        },
        "List4Item4": {
            "English": "It’s OK to say “no” Know your limits. You can't please everyone",
            "French": "C’est correct de dire ‘non’. Connais tes limites. C’est impossible de plaire à tout le monde.",
        },
        "List4Item5": {
            "English": "Figure out when and how you work best Some people are “morning people” and other people’s brains work better later in the evening. What is your style?",
            "French": "Quel est ton style? Certaines personnes travaillent mieux le matin alors que d’autres fonctionnent mieux en soirée.",
        },
        "List4Item6": {
            "English": "Get Enough Sleep. Teens need at least 9 to 11 hours of sleep every night. Giving your body and brain the time to rest and recover through sleep helps you be alert and ready to complete the tasks on your to-do list. Never sacrifice sleep to get other things on your list done. Without enough sleep your tasks will be harder and take longer. Also, remember to spend some time quietly before trying to get to sleep --turn off the technology and read, write, draw, stretch – do something that will help slow your body and mind down.",
            "French": "Assure-toi d’avoir assez de sommeil. Les ados ont besoin au moins 9 à 11 heures de sommeil à tous les soirs. Dormir permet à ton corps de se reposer et aussi à récupérer ce qui te permettra d’être alerte et prêt(e) à compléter les tâches sur ta liste de choses à faire. Il ne faut jamais sacrifier son sommeil pour compléter autres choses sur sa liste. Sans suffisamment de sommeil, les tâches sembleront plus difficiles et prendront plus de temps. De plus, souviens-toi de relaxer avant d’essayer de dormir, éteint tes appareils technologiques et écris, lis, dessine, fais quelque chose pour ralentir ton corps et ton cerveau.",
        },
        "List4Item7": {
            "English": "Let others know when you are busy and be willing to turn off your phone. An uninterrupted hour will give you more time than three hours that are interrupted by checking your phone",
            "French": "N’hésite pas à dire que tu es occupé(e) et sens-toi libre d’éteindre ton cellulaire. Une heure sans interruptions t’offrira plus de temps que trois heures dérangées par la vérification de ton téléphone.",
        },
        "List4Item8": {
            "English": "Control your time and figure out a way to spend some time each day doing something you truly enjoy",
            "French": "Contrôle ton temps. Trouve une façon de passer un peu de temps chaque jour à faire quelque chose que tu aimes vraiment.",
        },
        "List4Item9": {
            "English": "Do not waste time agonizing. Have you ever wasted an entire evening by worrying about something that you were supposed to be doing? Was it worth it? There will be slip-ups... yesterday is done. Breathe and re-focus. Readjust your lists if need be. Ask for help if you need to. Forgive yourself. Move on.",
            "French": "Ne perds pas de temps à t’angoisser. As-tu déjà perdu une soirée entière à t’inquiéter de quelque chose que tu étais censé(e)faire? Est-ce que ça en valait la peine? Il y aura des faux-pas… hier est terminé. Respire et reprends-toi, ajuste ta liste si nécessaire. Demande de l’aide si tu en as besoin. Pardonne-toi. Continue!",
        },
    },
}