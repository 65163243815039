import React, { useState } from "react";
import { Container, Row, Col, Button, InputGroup, Input, InputGroupText } from "reactstrap";
import { teamUnbreakableDB } from "../../utils/firestore";
import { updateDocumentProperty, deleteNestedDocumentProperty, DeleteFromStorage, UploadFileToStorage } from "../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ModalWindow from "../common/modalWindow";
import ImageUploadAndCrop from "../common/imageUploadAndCrop";
import { Colors } from "../../utils/constants";

export const AdminCommunityArticle = (props) => {
    
    const [urlDescription, setUrlDescription] = useState(props.urlDescription);
    const [url, setUrl] = useState(props.url);
    const [title, setTitle] = useState(props.title);
    const [order, setOrder] = useState(props.order);
    const [imageUrl, setImageUrl] = useState(props.imageUrl);
    const [date, setDate] = useState(props.date);

    const [inHouseArticle, setInHouseArticle] = useState(props.inHouseArticle);
    const [contentSections, setContentSections] = useState(props.contentSections);
    const [incrementor, setIncrementor] = useState(0);


    const [crop] = useState({unit: '%',
    width: 30,
    aspect: 356/323});
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn, setModalConfirmOn] = useState(true);
    const [modalConfimFunction, setModalConfirmFunction] = useState();
    const [modalCancelText, sestModalCancelText] = useState("Cancel");

    const toggle = () => setModal(!modal);

    const storageLocation = `images/media/`;

    const updateElement = (newImageUrl) => {
        var data = {
            urlDescription: urlDescription,
            url: url,
            title: title,
            order: order,
            id: props.id,
            date: date,
            imageUrl: newImageUrl ? newImageUrl : "N/A",
            timeStamp: props.timestamp,
            inHouseArticle: inHouseArticle,
            contentSections: contentSections,
        }

        if(props.articleType === "article"){
            updateDocumentProperty(teamUnbreakableDB, "content", "media", "articles." + props.id, data, UpdateSuccess, UpdateFailed);
        }
        else if(props.articleType === "event"){
            updateDocumentProperty(teamUnbreakableDB, "content", "media", "events." + props.id, data, UpdateSuccess, UpdateFailed);
        }
        else if(props.articleType === "backgrounder"){
            updateDocumentProperty(teamUnbreakableDB, "content", "media", "backgrounders." + props.id, data, UpdateSuccess, UpdateFailed);
        }
        else{
            updateDocumentProperty(teamUnbreakableDB, "content", "media", "pressreleases." + props.id, data, UpdateSuccess, UpdateFailed);
        }
    }

    const UpdateSuccess = () => {
        setModalTitle("Successfully Updated");
        setModalMessage("Your article has been updated successfully");
        sestModalCancelText("Okay");
        setModalConfirmOn(false);
        setModal(true);
    }

    const UpdateFailed = () => {
        setModalTitle("Update Failed");
        setModalMessage("Something went wrong when updating");
        setModalConfirmOn(false);
        toggle();
    }

    const doSubmit = (imageFile) => {
        const childName = props.id;
        UploadFileToStorage(imageFile, storageLocation, childName, SuccessUpload, FailedUpload)
    };

    const SuccessUpload = (url) => {
        setImageUrl(url);
        updateElement(url);
    }

    const FailedUpload = (error) => {
        console.log(error);
    }

    const RetrieveImage = (image, url) => {
        setImageUrl(url);
        doSubmit(image);
    }

    const DeletePrompt = () => {
        setModalTitle("Delete Element?");
        setModalMessage("Are you sure you want to delete this element?");
        sestModalCancelText("Cancel");
        setModalConfirmOn(true);
        setModalConfirmFunction(() => deleteElement);
        toggle();
    }

    const deleteElement = () => {
        DeleteFromStorage(storageLocation, props.id, SetNullImageRef);
        if(props.articleType === "article"){
            deleteNestedDocumentProperty(teamUnbreakableDB, "content", "media", "articles", props.id);
        }
        else if(props.articleType === "event"){
            deleteNestedDocumentProperty(teamUnbreakableDB, "content", "media", "events", props.id);
        }
        else if(props.articleType === "backgrounder"){
            deleteNestedDocumentProperty(teamUnbreakableDB, "content", "media", "backgrounders", props.id);
        }
        else{
            deleteNestedDocumentProperty(teamUnbreakableDB, "content", "media", "pressreleases", props.id);
        }
        toggle();
    }

    const RemoveImagePrompt = () => {
        setModalTitle("Delete Picture");
        setModalMessage("Are you sure you want to delete your picture?");
        sestModalCancelText("Cancel");
        setModalConfirmOn(true);
        setModalConfirmFunction(() => RemoveImage);
        toggle();
      }
  
      const RemoveImage = () => {
        setImageUrl("N/A");
        DeleteFromStorage(storageLocation, props.id, SetNullImageRef);
      }

      const SetNullImageRef = () => {
        updateElement("N/A");
        toggle();
      }

      //TODO this doesn't work well
    const createContentSection = (value) => {
        if(value){
            if(contentSections === undefined){
                console.log("Is undefined");
                setContentSections([{title: "", content: ""}]);
            }
        }
        setInHouseArticle(value);
    }

    const addSection = () => {
        var newContentArray = contentSections;
        console.log(newContentArray);
        if(newContentArray === undefined){
            console.log("Is undefined");
            setContentSections([{title: "", content: ""}]);
        }
        else{
            newContentArray.push({title: "", content: ""});
            setContentSections(newContentArray);
        }
        
        setIncrementor(incrementor + 1);
    }

    const udpateContentSectionTitles = (index, value) => {
        var newContent = contentSections;
        newContent[index].title = value;
        setContentSections(newContent);
        setIncrementor(incrementor + 1);
    }

    const udpateContentSectionContent = (index, value) => {
        var newContent = contentSections;
        newContent[index].content = value;
        setContentSections(newContent);
        setIncrementor(incrementor + 1);
    }

    return(
        <React.Fragment>
            <ModalWindow cancelText={modalCancelText} isOpen={modal} title={modalTitle} message={modalMessage} cancelFunction={toggle} confirmFunction={modalConfimFunction} showConfirmButton={modalConfirmOn} />
            <Row>
            {(!imageUrl || imageUrl ==="N/A") && 
                <Col xs="5"><ImageUploadAndCrop maxWidth="500px" submitFunction={RetrieveImage} crop={crop} descriptionText="Upload Program Picture" /></Col>
            }
            {(imageUrl && imageUrl !== "N/A") && 
                <Col xs="5"><br />
                    <StyledArticleImage src={imageUrl} alt="" />
                    <StyledButton onClick={() => RemoveImagePrompt()} color="danger">
                        <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                    </StyledButton>
                </Col>
            }
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <InputGroup>
                                <div addonType="prepend">
                                    <InputGroupText>Title</InputGroupText>
                                </div>
                                    <Input type="text" value={title} onChange={e => setTitle(e.target.value)}/>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <div addonType="prepend">
                                        <InputGroupText>Order</InputGroupText>
                                    </div>
                                    <Input type="text" value={order} onChange={e => setOrder(e.target.value)}/>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <div addonType="prepend">
                                        <InputGroupText>Short Description</InputGroupText>
                                    </div>
                                    <Input type="textarea" value={urlDescription} onChange={e => setUrlDescription(e.target.value)}/>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <div addonType="prepend">
                                        <InputGroupText>URL</InputGroupText>
                                    </div>
                                    <Input type="text" value={url} onChange={e => setUrl(e.target.value)}/>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <div addonType="prepend">
                                        <InputGroupText>Date</InputGroupText>
                                    </div>
                                    <Input type="text" value={date} onChange={e => setDate(e.target.value)}/>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <div addonType="prepend">
                                        In house article?
                                    </div>
                                    <Input type="checkbox" defaultChecked={inHouseArticle} onChange={e => createContentSection(!inHouseArticle)}></Input>
                                </InputGroup>
                                {inHouseArticle && <div>
                                        {contentSections.length > 0 && contentSections.map((section, i) => {
                                            return <div key={i}>
                                                <h3>Paragraph: {i + 1}</h3>
                                            <InputGroup>
                                            <div addonType="prepend" style={{width:"130px"}}>
                                                Title
                                            </div>
                                            <Input type="text" value={section.title} onChange={e => udpateContentSectionTitles(i, e.target.value)}></Input>
                                            </InputGroup>
                                            <InputGroup>
                                                <div addonType="prepend" style={{width:"130px"}}>
                                                    Content
                                                </div>
                                                <Input type="textarea" value={section.content} onChange={e => udpateContentSectionContent(i, e.target.value)}></Input>
                                            </InputGroup>
                                            <br />
                                        </div>
                                    })}
                                    <Button onClick={() => {addSection()}} color="primary">
                                        Add New Paragraph
                                    </Button>
                                </div>}
                            </Col>
                        </Row>
                        <Row>
                            <FlexCol>
                                <StyledButton onClick={()=> {updateElement(imageUrl)}} color="primary">
                                    <FAIconButton icon={["fas", "edit"]}></FAIconButton>
                                </StyledButton>
                                <StyledButton onClick={()=> {DeletePrompt()}} color="danger">
                                    <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                                </StyledButton>
                            </FlexCol>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </React.Fragment>
    ) 
}

export default AdminCommunityArticle;

const StyledButton = styled(Button)`
    margin-left: 5px;
`;

const FlexCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledArticleImage = styled.img`
    padding: 5px;
    box-shadow: 0 0.5rem 1rem ${Colors.primaryblue};
    max-width: 300px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: -20px;
`