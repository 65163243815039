import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Copy } from "./module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { 
    ColoredH2, 
    StyledHR, 
    StyledP, 
    CenterTitleFlexCol, 
    WhiteH2, 
    StyledBoldP, 
    ColoredUL, 
    CenterFlexCol, 
    StyledButton, 
    CompletedP, 
    CompletedDiv, 
    LargeInput
} from "../../styles/styledComponents";
import { Event } from "../../utils/analytics";
import HomeworkImage from "../../images/homework.png";
import SelfImageImage from "../../images/self-image.png";
import styled from "styled-components";
import StarsImage from '../../images/stars.png';

export const Module6 = ( props ) => {

    const [user, setUser] = useState();

    const [input1, setInput1] = useState((props.user.moduleData !== undefined && props.user.moduleData.module6 !== undefined && props.user.moduleData.module6.input1 != undefined) ? props.user.moduleData.module6.input1.input1 : "");
    const [input2, setInput2] = useState((props.user.moduleData !== undefined && props.user.moduleData.module6 !== undefined && props.user.moduleData.module6.input2 != undefined) ? props.user.moduleData.module6.input2.input2 : "");
    const [input3, setInput3] = useState((props.user.moduleData !== undefined && props.user.moduleData.module6 !== undefined && props.user.moduleData.module6.input3 != undefined) ? props.user.moduleData.module6.input3.input3 : "");
    const [input4, setInput4] = useState((props.user.moduleData !== undefined && props.user.moduleData.module6 !== undefined && props.user.moduleData.module6.input4 != undefined) ? props.user.moduleData.module6.input4.input4 : "");
    const [input5, setInput5] = useState((props.user.moduleData !== undefined && props.user.moduleData.module6 !== undefined && props.user.moduleData.module6.input5 != undefined) ? props.user.moduleData.module6.input5.input5 : "");

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const UpdateInput = (inputKey, inputValue) => {
        var data = user;
        data.moduleData.module6[inputKey] = inputValue;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    const completeModule = () => {
        Event("Module Completed", "Mental Module", "Module 6");

        var data = user;
        data.modules.module6 = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module 6"].SubTitle1[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 6"].SubTitle2[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 6"].Paragraph1[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 6"].SubTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 6"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </Container>
                <br />
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <StyledBoldP>{Copy["Module 6"].Input1Header[props.user.language]}</StyledBoldP>
                                    {" - "}
                                    <StyledP>{Copy["Module 6"].Paragraph3[props.user.language]}</StyledP>
                                    <LargeInput value={input1} onChange={e => setInput1(e.target.value)} onBlur={() => {UpdateInput("input1", {input1})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledBoldP>{Copy["Module 6"].Input2Header[props.user.language]}</StyledBoldP>
                                    {" - "}
                                    <StyledP>{Copy["Module 6"].Paragraph4[props.user.language]}</StyledP>
                                    <LargeInput value={input2} onChange={e => setInput2(e.target.value)} onBlur={() => {UpdateInput("input2", {input2})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledBoldP>{Copy["Module 6"].Input3Header[props.user.language]}</StyledBoldP>
                                    {" - "}
                                    <StyledP>{Copy["Module 6"].Paragraph5[props.user.language]}</StyledP>
                                    <LargeInput value={input3} onChange={e => setInput3(e.target.value)} onBlur={() => {UpdateInput("input3", {input3})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledBoldP>{Copy["Module 6"].Input4Header[props.user.language]}</StyledBoldP>
                                    {" - "}
                                    <StyledP>{Copy["Module 6"].Paragraph6[props.user.language]}</StyledP>
                                    <LargeInput value={input4} onChange={e => setInput4(e.target.value)} onBlur={() => {UpdateInput("input4", {input4})}}></LargeInput>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <CenterFlexVerticalCol xs="12" md="3">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 6"].SubTitle4[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 6"].Paragraph7[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 6"].Paragraph8[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 6"].Input5Header[props.user.language]}</StyledP>
                        <LargeInput value={input5} onChange={e => setInput5(e.target.value)} onBlur={() => {UpdateInput("input5", {input5})}}></LargeInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                        <EmotionsIMG src={SelfImageImage}></EmotionsIMG>
                        <StyledHR></StyledHR>
                    </Col>
                    </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 6"].SubTitle4a[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 6"].Paragraph9[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 6"].SubTitle5[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 6"].Paragraph10[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 6"].Input6Header[props.user.language]}</StyledP>
                        <LargeInput value={input1} onChange={e => setInput1(e.target.value)} onBlur={() => {UpdateInput("input1", {input1})}}></LargeInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 6"].SubTitle6[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 6"].Paragraph11[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                    <Col xs="12" md="7">
                        <HomeworkIMG src={HomeworkImage}></HomeworkIMG>
                    </Col>
                </Row>
            </Container>
            {user !== undefined && <Container>
                <Row>
                    <CenterFlexCol>
                        {user.modules.module6 ? <CompletedDiv><CompletedP>Module Completed</CompletedP></CompletedDiv> : <StyledButton onClick={() => completeModule()} color="primary">Complete Module</StyledButton>}
                    </CenterFlexCol>
                </Row>
            </Container>}
        </React.Fragment>
    );
}

export default Module6;

const EmotionsIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StarsIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;

const HomeworkIMG = styled.img`
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
`;