import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from "reactstrap";
import DarkHomePageBackground from "../../images/DarkHomePageBackground.png";
import PictureBackground from "../common/picture-background";
import { Colors } from "../../utils/constants";

const RegisterComplete = () => {
    return (  
    <React.Fragment>
        <HeightDiv>
            <PictureBackground id="picture background" url={DarkHomePageBackground} />
            <OffsetDiv />
            <InteractingContainer>
            <Row>
                <Col>
                    <RegisterTitle>Registration Complete</RegisterTitle>
                </Col>
            </Row>
            <MarginRow>
                <Col>
                    <StyledP>Thank you for registering your account, an email has been sent to your email address to verify your email. If you don't see an email, please check your junk mail.</StyledP>
                </Col>
            </MarginRow>
        </InteractingContainer>
    </HeightDiv>
    </React.Fragment> );
}
 
export default RegisterComplete;

const HeightDiv = styled.div`
min-height: ${window.innerHeight - 149}px;
`;

const OffsetDiv = styled.div`
    height: 170px;
`;

const InteractingContainer = styled(Container)`
    margin-top: 0px;
    background-color: ${Colors.primaryblue};
    max-width: 400px;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
`;

const StyledP = styled.p`
    margin: 0px;
    color: white;
    font-size: 18px;
`;

const MarginRow = styled(Row)`
    margin-top: 5px;
`;

const RegisterTitle = styled.h3`
    color: white;
    text-align: center;
`;