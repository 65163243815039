import React, { useState, useEffect } from "react";
import Person from './people-person';
import { teamUnbreakableDB } from '../../../utils/firestore';
import { listenToDocumentProperty } from "../../../utils/firestore-functions";
import styled from 'styled-components';
import { Container } from 'reactstrap';
import SubHeader from "../../common/sub-header";
import AboutTestimonial from "./about-testimonial";
import AboutProgram from "./about-program";
import ContactUs from "../contact-us";
import LandAcknowledgement from "./about-land-acknowledgement";
import { useParams } from "react-router-dom";
import { Colors } from "../../../utils/constants";

export const About = () => {
        
    const [people, setPeople] = useState({});
    const [testimonials, setTestimonials] = useState({});
    const [program, setProgram] = useState({});
    const [subHeaderOptions, setSubheaderOptions] = useState([{_id: "Testimonials" }, {_id: "Team" }, {_id: "Contact Us"}, {_id: "Land Acknowledgement"}])

    let { subheader } = useParams();
    if (subheader === undefined) {
        subheader = "The Program";
        window.history.replaceState(null, "", "/About/The Program");
    }
    const [content, setContent] = useState(subheader);

    useEffect(() => {
        window.history.replaceState(null, "", "/About/" + content);
    }, [content])
    
    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "about", "program", MapProgramInformation);
        return unsubscribe;
    }, [])

    const MapProgramInformation = (data) => {
        var objectArray = {};
        var subHeaderAdditions = [];
        var firstTitleSelected = false;
        var firstTitle = "";
        Object.entries(data).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
            objectArray[t[1].title] = t[1];
            subHeaderAdditions.push({_id: t[1].title})
            if(!firstTitleSelected){
                if (subheader) {
                    firstTitle = subheader;
                } else {
                    firstTitle = t[1].title;
                }
                firstTitleSelected = true;
            }
        })

        setContent(firstTitle);
        setProgram(objectArray);
        
        subHeaderAdditions.push({_id: "Testimonials"}, {_id: "Team"}, {_id: "Contact Us"}, {_id: "Land Acknowledgement"})
        setSubheaderOptions(subHeaderAdditions);
    }
 
    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "testimonials", "testimonials", setTestimonials);
        return unsubscribe;
    }, [])

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "people", "people", setPeople);
        return unsubscribe;
    }, [])


    let flip = false;
    let ctr = -1;

    return(
        <React.Fragment>
            <MainDiv>
                <SubHeader setContent={setContent} selected={content} options={subHeaderOptions}></SubHeader>
                <GrayBackground />
                <OffsetDiv></OffsetDiv>
                
                {content==="Team" && <Container>
                    <br />
                    <StyledHeaderTitle>Our Team</StyledHeaderTitle>
                    <ContentContainer>
                 {(Object.entries(people).length > 0) && Object.entries(people).sort((a,b)=>(a[1].order-b[1].order)).map(person => {
                    flip = !flip;
                    ctr += 0.5;
                    return <Person key={person[0]} imageurl={person[1].imageUrl} flip={flip} delay={ctr} name={person[1].name} title={person[1].title} description={person[1].description}></Person>
                })}</ContentContainer>
                </Container>}

                {content==="Testimonials" && <AboutTestimonial testimonials={testimonials}></AboutTestimonial>}

                {content === "Contact Us" && <ContactUs />}

                {content === "Land Acknowledgement" && <LandAcknowledgement />}

                {program[content] !== undefined && content === program[content].title && 
                <Container><br />
                    <StyledHeaderTitle>{program[content].title}</StyledHeaderTitle><br />
                    <AboutProgram 
                        delay={"-0.5s"} 
                        key={program[content].title} 
                        title={program[content].title} 
                        description={program[content].description} 
                        url={program[content].imgURL}>
                    </AboutProgram>
                </Container>}
            </MainDiv>
        </React.Fragment>
    );
}

export default About;

const MainDiv = styled.div`
    background-color: ${Colors.primarybackground};
    padding-bottom: 30px;
`

const StyledHeaderTitle = styled.h1`
    color: ${Colors.primaryblue};
    font-family: Roboto;
`

const GrayBackground = styled.div`
    width: 100%;
    height: 100%;
    z-index: -1000;
    background-color: ${Colors.primarybackground};
    background-size: cover;
`

const OffsetDiv = styled.div`
    height: 180px;
`;

const ContentContainer = styled(Container)`
    max-width: 1200px;
    margin-bottom: 2%;
`;