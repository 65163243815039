import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Copy } from "./module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { 
    ColoredH2, 
    StyledHR, 
    StyledP, 
    CenterTitleFlexCol, 
    WhiteH2, 
    StyledBoldP, 
    ColoredUL, 
    ColoredOL,
    CenterFlexCol, 
    StyledButton, 
    CompletedP, 
    CompletedDiv, 
    LargeInput,
    BoldP
} from "../../styles/styledComponents";
import { Colors } from "../../utils/constants";
import styled from "styled-components";
import { Event } from "../../utils/analytics";
import HomeworkImage from "../../images/homework.png";
import StarsImage from '../../images/stars.png';
import BenefitsImage from '../../images/benefits.png';

export const Module5 = ( props ) => {

    const [user, setUser] = useState();

    const [input1, setInput1] = useState((props.user.moduleData !== undefined && props.user.moduleData.module5 !== undefined && props.user.moduleData.module5.input1 != undefined) ? props.user.moduleData.module5.input1.input1 : "");
    const [input2, setInput2] = useState((props.user.moduleData !== undefined && props.user.moduleData.module5 !== undefined && props.user.moduleData.module5.input2 != undefined) ? props.user.moduleData.module5.input2.input2 : "");
    const [input3, setInput3] = useState((props.user.moduleData !== undefined && props.user.moduleData.module5 !== undefined && props.user.moduleData.module5.input3 != undefined) ? props.user.moduleData.module5.input3.input3 : "");
    const [input4, setInput4] = useState((props.user.moduleData !== undefined && props.user.moduleData.module5 !== undefined && props.user.moduleData.module5.input4 != undefined) ? props.user.moduleData.module5.input4.input4 : "");

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const UpdateInput = (inputKey, inputValue) => {
        var data = user;
        data.moduleData.module5[inputKey] = inputValue;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    const completeModule = () => {
        Event("Module Completed", "Mental Module", "Module 5");
        var data = user;
        data.modules.module5 = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module 5"].SubTitle1[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 5"].Paragraph1[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 5"].Input1Header[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </Container>
            
            <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 5"].SubTitle2[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledBoldP>{Copy["Module 5"].List1Title[props.user.language]}</StyledBoldP>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 5"].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List1Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List1Item3[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledBoldP>{Copy["Module 5"].List2Title[props.user.language]}</StyledBoldP>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 5"].List2Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List2Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List2Item3[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                        <EmotionsIMG src={BenefitsImage}></EmotionsIMG>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 5"].SubTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 5"].List3Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List3Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List3Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List3Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List3Item5[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List3Item6[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List3Item7[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                    <CenterFlexVerticalCol xs="12" md="3">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledBoldP>{Copy["Module 5"].List4Title[props.user.language]}</StyledBoldP>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 5"].List4Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List4Item2[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 5"].SubTitle4[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module 5"].List5Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List5Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List5Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List5Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List5Item5[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module 5"].List5Item6[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
            </Container>
            <WhiteContainer>
                <Row>
                    <Col>
                        <BoldP>{Copy["Module 5"].Paragraph2[props.user.language]}</BoldP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 5"].Paragraph3[props.user.language]}</StyledP>
                    </Col>
                </Row>
            </WhiteContainer>
            <br />
            <Container>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module 5"].SubTitle5[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 5"].Paragraph4[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module 5"].Paragraph5[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <ColoredH2>{Copy["Module 5"].SubTitle6[props.user.language]}</ColoredH2>
                                    <StyledHR></StyledHR>
                                    <ColoredUL>
                                        <li><StyledP>{Copy["Module 5"].Paragraph6[props.user.language]}</StyledP></li>
                                        <ColoredOL>
                                            <li><StyledP>{Copy["Module 5"].Paragraph7[props.user.language]}</StyledP></li>
                                            <li><StyledP>{Copy["Module 5"].Paragraph8[props.user.language]}</StyledP></li>
                                            <li><StyledP>{Copy["Module 5"].Paragraph9[props.user.language]}</StyledP></li>
                                        </ColoredOL>
                                    </ColoredUL>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledBoldP>{Copy["Module 5"].Input2Header[props.user.language]}</StyledBoldP>
                                    <LargeInput value={input1} onChange={e => setInput1(e.target.value)} onBlur={() => {UpdateInput("input1", {input1})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledBoldP>{Copy["Module 5"].Input3Header[props.user.language]}</StyledBoldP>
                                    <LargeInput value={input2} onChange={e => setInput2(e.target.value)} onBlur={() => {UpdateInput("input2", {input2})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledBoldP>{Copy["Module 5"].Input4Header[props.user.language]}</StyledBoldP>
                                    <LargeInput value={input3} onChange={e => setInput3(e.target.value)} onBlur={() => {UpdateInput("input3", {input3})}}></LargeInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyledBoldP>{Copy["Module 5"].Input5Header[props.user.language]}</StyledBoldP>
                                    <LargeInput value={input4} onChange={e => setInput4(e.target.value)} onBlur={() => {UpdateInput("input4", {input4})}}></LargeInput>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs="12" md="5">
                        <HomeworkIMG src={HomeworkImage}></HomeworkIMG>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <StyledBoldP>{Copy["Module 5"].Paragraph10[props.user.language]}</StyledBoldP>
                    </Col>
                </Row>
            </Container>
            {user !== undefined && <Container>
                <Row>
                    <CenterFlexCol>
                        {user.modules.module5 ? <CompletedDiv><CompletedP>Module Completed</CompletedP></CompletedDiv> : <StyledButton onClick={() => completeModule()} color="primary">Complete Module</StyledButton>}
                    </CenterFlexCol>
                </Row>
            </Container>}
        </React.Fragment>
    );
}

export default Module5;

const WhiteContainer = styled(Container)`
    border-radius: 10px;
    background-color: white;
    border-color: ${Colors.primaryblue};
    border-width: 1px;
    border-style: solid;
`;

const EmotionsIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StarsIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;

const HomeworkIMG = styled.img`
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
`;