import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Container, Row, Col } from 'reactstrap';

import InstagramFeed from './instagram-feed';

export const InstagramFeedContainer = (props) => {
    const [feeds, setFeedsData] = useState([])
    //use useRef to store the latest value of the prop without firing the effect
    const tokenProp = useRef(props.token);
    tokenProp.current = props.token;

    useEffect(() => {
        console.log(props);
        async function fetchInstagramPost () {
          try{
            axios
                .get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${props.limit}&access_token=${props.token}`)
                .then((resp) => {
                    setFeedsData(resp.data.data)
                })
          } catch (err) {
              console.log('error', err)
          }
        }
        
        if (props.token) {
            // this is to avoid memory leaks (EB: Just copied it from the example online)
            const abortController = new AbortController();

            fetchInstagramPost();
            
            return () => {
                // cancel pending fetch request on component unmount
                abortController.abort(); 
            };
        }
  
    }, [props.limit, props.token])

    return (
        <React.Fragment>
            {feeds.map((feed) => {
                return (
                    <InstagramFeed key={feed.id} feed={feed} />
                )
            })}
        </React.Fragment>
    );
}

export default InstagramFeedContainer;