import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { auth } from "../../utils/firestore";
import { PageView } from "../../utils/analytics.js";

const VerifiedRoute = ({ title, path, children, user, ...rest }) => {

  useEffect(() => {
    document.title = title;
    PageView(title, path);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user !== "Guest"){
          return auth.currentUser.emailVerified ? children : <Redirect to={{ pathname: "/Verify Account" }}/>; //TODO current workaround for email verified
        }
        else{
          return (<Redirect to={{ pathname: "/login" }} />);
        }      
      }}
    />
  );
};

export default VerifiedRoute;