import React, { useState } from 'react';
import styled from 'styled-components';
import CommunityArticle from './community-article';
import { Container, Row, Col, Button } from 'reactstrap';
import InHouseArticle from './in-house-article'
import { Colors } from "../../../utils/constants";

const CommunitySection = ({ contentName, articles, openArticleFunction }) => {
    const [showingAllArticles, setShowingAllArticles] = useState(false);

    let flip = false;
    let ctr = 0;

    
    var eventCounter = 0;

    const showAllArticles = () => {
        setShowingAllArticles(true);
    }

   return (<React.Fragment>
                {showingAllArticles && <div>
                <Container> 
                    <Row>
                        <Col>
                            <StyledHeaderTitle>{contentName}</StyledHeaderTitle> 
                        </Col>
                    </Row>
                    {contentName === "Press Releases" && 
                        (Object.entries(articles).length > 0) && Object.entries(articles).sort((a,b)=>(a[1].order-b[1].order)).map(article => {
                            
                            return <InHouseArticle
                                info={article[1]}
                            />
                        })}
                    {contentName !== "Press Releases" &&
                    <ContentContainer>
                        <Row>
                        {(Object.entries(articles).length > 0) && Object.entries(articles).sort((a,b)=>(a[1].order-b[1].order)).map(article => {
                            flip = !flip;
                            ctr += 0.5;
                            return <CommunityArticle 
                            key={article[0]} 
                            flip={flip} 
                            delay={ctr} 
                            url={article[1].url} 
                            title={article[1].title} 
                            imageUrl={article[1].imageUrl} 
                            urlDescription={article[1].urlDescription}
                            date={article[1].date}
                            inHouseArticle = {article[1].inHouseArticle}
                            contentSections = {article[1].contentSections}
                            showArticleFunction = {openArticleFunction}
                            fullInfo = {article[1]}
                            />
                        })}
                        {Object.entries(articles).length < 1 && 
                            <StyledParagraph>
                                No articles to show at this time.
                            </StyledParagraph>
                        }
                    </Row>
                    
                    </ContentContainer>}
                </Container>
                </div>}

                {!showingAllArticles && <Container>
                    <Row>
                        <StyledHeaderTitle>{contentName}</StyledHeaderTitle>
                    </Row>
                    <ContentContainer>
                    {contentName === "Press Releases" && 
                        (Object.entries(articles).length > 0) && Object.entries(articles).sort((a,b)=>(a[1].order-b[1].order)).map(article => {
                            return <InHouseArticle
                                info={article[1]}
                            />
                        })}
                    <Row>
                        {contentName !== "Press Releases" && 
                        (Object.entries(articles).length > 0) && Object.entries(articles).sort((a,b)=>(a[1].order-b[1].order)).map(article => {
                            eventCounter += 1;
                            if(eventCounter > 3) {return;}
                            flip = !flip;
                            ctr += 0.5;
                            return <CommunityArticle 
                            key={article[0]} 
                            flip={flip} 
                            delay={ctr} 
                            url={article[1].url} 
                            title={article[1].title} 
                            imageUrl={article[1].imageUrl} 
                            urlDescription={article[1].urlDescription}
                            date={article[1].date}
                            inHouseArticle = {article[1].inHouseArticle}
                            contentSections = {article[1].contentSections}
                            showArticleFunction = {openArticleFunction}
                            fullInfo = {article[1]}
                            />
                        })}
                        {Object.entries(articles).length < 1 && 
                            <StyledParagraph>
                                No articles to show at this time.
                            </StyledParagraph>
                        }
                    
                    </Row>
                    <Row>
                        {(Object.entries(articles).length > 3) && <Col>
                            <StyledButton onClick={showAllArticles}>All {contentName}</StyledButton>
                        </Col>}
                    </Row>
                    </ContentContainer>
                </Container>}

   </React.Fragment>);
}
export default CommunitySection;

const StyledButton = styled(Button)`
    text-align: center;
    font-family: Roboto;
    width: 175px;
    background: ${Colors.primaryblue};
    border-radius: 5px;
    color: white;
    border: none;
    padding: 15px;
    &:hover {
        color: white;
        background-color: ${Colors.secondarygray};
        cursor: pointer;
    }
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
`

const ContentContainer = styled(Container)`
    max-width: 1200px;
`

const StyledHeaderTitle = styled.h1`
    color: ${Colors.primaryblue};
    font-family: Roboto;
`

const StyledParagraph = styled.p`
    color: ${Colors.secondarytext};
    font-size: medium;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
`