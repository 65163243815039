import React, { useState, useEffect } from "react";
import { Container, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from 'classnames';
import styled from "styled-components";
import PhysicalModuleIntroduction from "./physical-module-introduction";
import { teamUnbreakableDB } from "../../utils/firestore";
import { listenToDocument } from "../../utils/firestore-functions";
import PhysicalModuleSafety from "./physical-module-safety";
import PhysicalModuleRunningMechanics from "./physical-module-running-mechanics";
import PhysicalModuleRunningDrills from "./physical-module-running-drills";
import PhysicalModuleNutrition from "./physical-module-nutrition";
import PhysicalModuleSetbacks from "./physical-module-setbacks";
import PhysicalModuleHills from "./physical-module-hills";
import ReactGA from 'react-ga';
import { Colors } from "../../utils/constants";
import PhysicalModuleAlternativeCardio from "./physical-module-alternative-cardio";
import PhysicalModuleEquipment from "./physical-module-equipment";


export const PhysicalModules = (props) => {
    ReactGA.pageview('/physical-modules');
    const [activeTab, setActiveTab] = useState('1');
    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

    return(
        <React.Fragment>
            {user !== undefined && <Container>
                <Nav tabs>
                    <NavItem>
                        <StyledNavLink active={activeTab === '1'} className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                            Introduction
                        </StyledNavLink>
                    </NavItem>
                    <NavItem>
                        <StyledNavLink active={activeTab === '2'} className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                            Safety
                        </StyledNavLink>
                    </NavItem>
                    <NavItem>
                        <StyledNavLink active={activeTab === '3'} className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                            Running Mechanics
                        </StyledNavLink>
                    </NavItem>
                    <NavItem>
                        <StyledNavLink active={activeTab === '4'} className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                            Running Drills
                        </StyledNavLink>
                    </NavItem>
                    <NavItem>
                        <StyledNavLink active={activeTab === '5'} className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }}>
                            Nutrition + Hydration
                        </StyledNavLink>
                    </NavItem>
                    <NavItem>
                        <StyledNavLink active={activeTab === '6'} className={classnames({ active: activeTab === '6' })} onClick={() => { toggle('6'); }}>
                            Managing Physical Setbacks
                        </StyledNavLink>
                    </NavItem>
                    <NavItem>
                        <StyledNavLink active={activeTab === '7'} className={classnames({ active: activeTab === '7' })} onClick={() => { toggle('7'); }}>
                            Hills
                        </StyledNavLink>
                    </NavItem>
                    {user.admin && <NavItem>
                        <StyledNavLink active={activeTab === '8'} className={classnames({ active: activeTab === '8' })} onClick={() => { toggle('8'); }}>
                            Alternative Cardio
                        </StyledNavLink>
                    </NavItem>}
                    {user.admin && <NavItem>
                        <StyledNavLink active={activeTab === '9'} className={classnames({ active: activeTab === '9' })} onClick={() => { toggle('9'); }}>
                            Equipment
                        </StyledNavLink>
                    </NavItem>}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <PhysicalModuleIntroduction user={props.user}></PhysicalModuleIntroduction>
                    </TabPane>
                    <TabPane tabId="2">
                        <PhysicalModuleSafety user={props.user}></PhysicalModuleSafety>
                    </TabPane>
                    <TabPane tabId="3">
                        <PhysicalModuleRunningMechanics user={props.user}></PhysicalModuleRunningMechanics>
                    </TabPane>
                    <TabPane tabId="4">
                        <PhysicalModuleRunningDrills user={props.user}></PhysicalModuleRunningDrills>
                    </TabPane>
                    <TabPane tabId="5">
                        <PhysicalModuleNutrition user={props.user}></PhysicalModuleNutrition>
                    </TabPane>
                    <TabPane tabId="6">
                        <PhysicalModuleSetbacks user={props.user}></PhysicalModuleSetbacks>
                    </TabPane>
                    <TabPane tabId="7">
                        <PhysicalModuleHills user={props.user}></PhysicalModuleHills>
                    </TabPane>
                    <TabPane tabId="8">
                        <PhysicalModuleAlternativeCardio user={props.user}></PhysicalModuleAlternativeCardio>
                    </TabPane>
                    <TabPane tabId="9">
                        <PhysicalModuleEquipment user={props.user}></PhysicalModuleEquipment>
                    </TabPane>
                </TabContent>
            </Container>}
        </React.Fragment>
    );
}

export default PhysicalModules;

const StyledNavLink = styled(NavLink)`

    color: ${props => props.active ? Colors.primaryAccent : "black"} !important;
    border-color: black;
    text-decoration: none;
    cursor: pointer;    

    &:hover {   
        color: ${Colors.primaryblue};
        border-color: black;
        text-decoration: none;
        cursor: pointer;
    }
`