import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Copy } from "./physical-module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { ColoredH2, StyledHR, ColoredOL, ColoredUL, StyledP, CenterTitleFlexCol, WhiteH2 } from "../../styles/styledComponents";
import HomeworkImage from "../../images/homework.png";
import HillsImage from "../../images/hills.png";
import styled from "styled-components";

export const PhysicalModuleHills = ( props ) => {

    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const completeModule = () => {
        var data = user;
        data.modules.introduction = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module Hills"].Title[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Hills"].SubTitle1[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Hills"].Paragraph1[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <StyledHR />
                <Row>
                    <CenterFlexVerticalCol>
                        <PhysicalActivityIMG src={HillsImage}></PhysicalActivityIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <StyledHR />
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Hills"].SubTitle2[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy["Module Hills"].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Hills"].List1Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Hills"].List1Item3[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Hills"].SubTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Hills"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Hills"].SubTitle4[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Hills"].Paragraph3[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                    <Col xs="12" md="6">
                        <HomeworkIMG src={HomeworkImage}></HomeworkIMG>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default PhysicalModuleHills;

const PhysicalActivityIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const HomeworkIMG = styled.img`
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
`;