import React from "react";
import { Col, Button } from "reactstrap";
import styled from 'styled-components';
import { Colors } from "../../../utils/constants";

export const CommunityArticle = (props) => {
    const showArticle = () => {
        props.showArticleFunction(props.fullInfo)
    }

    return(        
        <Col lg="4" md="6" xs="12">
            <br/>
            <MarginDiv>
                {props.imageUrl !== "N/A" &&  <StyledAnchor href={props.url}><ArticleImg src={props.imageUrl} alt="Article" />
                <StyledTitle>{props.title}</StyledTitle></StyledAnchor>}
                <StyledDate>{props.date}</StyledDate> {/*TODO Placeholder */}
                <StyledDescription>{props.urlDescription}</StyledDescription><br />
                {/*props.inHouseArticle && <StyledButton onClick={showArticle}>View Article</StyledButton>*/}
            </MarginDiv>
        </Col>
    );
}

export default CommunityArticle;

const StyledButton = styled(Button)`
display: block;
margin-left: auto;
margin-right: auto;
width: 200px;

font-size: large;
font-family: Roboto;
text-align: center;
color: black;
border-radius: 10px;
background: ${Colors.primaryblue};
color: white;
border-width: 1px;
border-color: ${Colors.primaryblue}; 
border-style: solid;
&:hover {
    background: white;
    cursor: pointer;
    border-radius: 10px;
    border-color: ${Colors.primaryblue};
    border-width: 1px;
    border-style: solid;
    color: black;
}
`

const StyledAnchor = styled.a`
    text-decoration: none;
`

const ArticleImg = styled.img`
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    border-radius: 5px;
`

const StyledTitle = styled.h3`
    color: ${Colors.primaryblue};
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: large;
    margin-top: 10px;
`

const StyledDate = styled.p`
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
`

const MarginDiv = styled.div`
    background-color: ${Colors.primarybackground};
    margin-bottom: 30px;
`

const StyledDescription = styled.p`
    margin-bottom: 0px;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
`
