import React, { useState } from "react";
import { Container, Row, Col, InputGroup, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import styled from 'styled-components';
import { setDocument } from "../../utils/firestore-functions";
import { teamUnbreakableDB, auth } from "../../utils/firestore";
import { NewUser } from "../../utils/constants";
import { validateEmail, validPassword } from "../../utils/helper";
import emailjs, { init } from 'emailjs-com';
import firebase from "firebase/app";
import { Event } from "../../utils/analytics";
import { Colors } from "../../utils/constants";


export const RegisterInstructor = () => {

    init("user_8Qt3ZJv9dlnGhiJefkhBh");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [organization, setOrganization] = useState("");
    const [position, setPosition] = useState("Position");
    const [contactMe, setContactMe] = useState(true);
    const [positionDropdownOpen, setPositionDropdownOpen] = useState(false);

    const togglePositionDropdown = () => setPositionDropdownOpen((prevState) => !prevState);

    const register = () => {
        var error = false;
        if (password !== confirmPassword){
            setError("Passwords do not match");
            setMessage("");
            error = true;
        }
        if (!validPassword(password)){
            setError("Password needs to be at least 6 characters long and contain a number, character and special character");
            setMessage("");
            error = true;
        }
        if (firstName.length <= 1){
            setError("First Name needs to be at least 2 characters in length");
            setMessage("");
            error = true;
        }
        if (lastName.length <= 1){
            setError("Last Name needs to be at least 2 characters in length");
            setMessage("");
            error = true;
        }
        if (email === "" || email === undefined) {
            setError("Email cannot be empty");
            setMessage("");
            error = true;
            
        } else if (!validateEmail(email)){
            setError("Invalid Email");
            setMessage("");
            error = true;
        }
        if(!acceptedTerms){
            setError("You must accept the terms and conditions to register");
            setMessage("");
            error = true;
        }
        if (organization.length <= 1){
            setError("Organization cannot be empty.");
            setMessage("");
            error = true;
        }
        if (position.length === "Position"){
            setError("You need to select a position.");
            setMessage("");
            error = true;
        }
        if (!error){
            setError("");
            createUser();
            //trigger a "sign up" event here
            Event("New Instructor Signed Up - test"); 
        }
        else{
            console.log("One or more errors were found during registration");
        }
    }

    const createUser = () => {
        auth.createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
            let user = userCredential.user;
            emailjs.send("service_xfajc29", "template_r3plua8", {
                firstName: firstName,
                lastName: lastName,
                email: email,
                contactMe: contactMe
            });
            user.updateProfile({
                displayName: firstName,
            }).then(function(){
                var timeStamp = firebase.firestore.Timestamp.now();
                let person = NewUser;
                person.email = email;
                person.firstName = firstName;
                person.lastName = lastName;
                person.admin = false;
                person.verified = false;
                person.id = user.uid;
                person.instructor = true;
                person.awaitingInstructorConfirmation =  true;
                person.timeStamp = timeStamp;
                person.organization = organization;
                person.contactMe = contactMe;
                person.position = position;
            setDocument(teamUnbreakableDB, "users", user.uid, person, 
                function(){
                    user.sendEmailVerification().then(() => {
                        window.location = "/RegisterComplete";
                    });
            },
                function(){
                    console.log("Error Callback.")
                })
            }).catch((error) => {
                console.log("Error message: " + error.message);
                setError(error.message);
            });
        }).catch((error) => {
            setError(error.message);
        })
    }

return (
    <HeightDiv>
        <InteractingContainer>
            <Row>
                <Col>
                    <RegisterTitle>Program Leader Registration</RegisterTitle>
                </Col>
            </Row>
            <MarginRow>
                <Col>
                    <StyledP>*First Name:</StyledP>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <InputGroup>
                        <Input maxLength="30" value={firstName} onChange={e => setFirstName(e.target.value)} />
                    </InputGroup>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <StyledP>*Last Name:</StyledP>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <InputGroup>
                        <Input maxLength="30" value={lastName} onChange={e => setLastName(e.target.value)} />
                    </InputGroup>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <StyledP>*Email Address:</StyledP>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <InputGroup>
                        <Input maxLength="50" value={email} onChange={e => setEmail(e.target.value)} />
                    </InputGroup>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <StyledP>*Password:</StyledP>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <InputGroup>
                        <Input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    </InputGroup>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <StyledP>*Confirm Password:</StyledP>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <InputGroup>
                        <Input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                    </InputGroup>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <StyledP>*School, School Board or Organization:</StyledP>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <InputGroup>
                        <Input maxLength="75" value={organization} onChange={e => setOrganization(e.target.value)} />
                    </InputGroup>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col>
                    <StyledP>*Position:</StyledP>
                </Col>
            </MarginRow>
            <MarginRow>
                <Col style={{ textAlign:"center"}}>
                    <Dropdown  isOpen={positionDropdownOpen} toggle={togglePositionDropdown} >
                        <DropdownToggle color="light" outline caret>{position}</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {setPosition("Teacher")}}>Teacher</DropdownItem>
                            <DropdownItem onClick={() => {setPosition("Mental Health Professional")}}>Mental Health Professional</DropdownItem>
                            <DropdownItem onClick={() => {setPosition("Administrator")}}>Administrator</DropdownItem>
                            <DropdownItem onClick={() => {setPosition("Other")}}>Other</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </MarginRow>
            <br />
            <MarginRow>
                <FlexCol md="auto">
                    <Checkbox value={contactMe} onClick={() => setContactMe(!contactMe)} type="checkbox" />
                </FlexCol>
                <FlexCol style={{paddingLeft: "0px"}}>
                    <DisclaimerP>Would you like to be contacted about starting a Team Unbreakable Program?
                    </DisclaimerP>
                </FlexCol>
            </MarginRow>
            <br />
            <MarginRow>
                <FlexCol md="auto">
                    <Checkbox value={acceptedTerms} onClick={() => setAcceptedTerms(!acceptedTerms)} type="checkbox" />
                </FlexCol>
                <FlexCol style={{paddingLeft: "0px"}}>
                    <DisclaimerP>The Team Unbreakable Program is for use of approved Schools/Community centers only. Our materials are
                        not to be used or shared without the permission of Team Unbreakable. Click here if you accept.
                    </DisclaimerP>
                </FlexCol>
            </MarginRow>
            {error.length > 0 && <MarginRow>
                <CenterFlexCol>
                    <ErrorP>{error}</ErrorP>
                </CenterFlexCol>
            </MarginRow>}
            {message.length > 0 && <MarginRow>
                <CenterFlexCol>
                    <MessageP>{message}</MessageP>
                </CenterFlexCol>
            </MarginRow>}
            <br />
            <MarginRow>
                <CenterFlexCol>
                    <RegisterButton onClick={() => {register()}} color="primary">Register</RegisterButton>
                </CenterFlexCol>
            </MarginRow>
        </InteractingContainer>
    </HeightDiv>
    );
}

export default RegisterInstructor;

const HeightDiv = styled.div`
min-height: ${window.innerHeight - 149}px;
`

const RegisterButton = styled(Button)`
    background-color: white;
    color: ${Colors.primaryblue};
    padding-left: 35px;
    padding-right: 35px;
    border: solid;
    border-width: 1px;
        &:hover {
            background-color: ${Colors.primaryblue};
            border-color: white;     
        }
`

const StyledP = styled.p`
    margin: 0px;
    color: white;
    font-size: 18px;
`;

const ErrorP = styled.p`
    color: red;
    margin: 0;
`

const MessageP = styled.p`
    color: green;
    margin: 0;
`

const MarginRow = styled(Row)`
    margin-top: 5px;
    
`;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const RegisterTitle = styled.h3`
    color: white;
    text-align: center;
`;

const InteractingContainer = styled(Container)`
    margin-top: 0px;
    background-color: ${Colors.primaryblue};
    max-width: 400px;
    padding-top: 20px;
    padding-bottom: 120px;
    padding-left: 50px;
    padding-right: 50px;
    
`;

const Checkbox = styled(Input)`
`;

const DisclaimerP = styled.p`
    color: white;
    margin-bottom: 0px;
`;

const FlexCol = styled(Col)`
    display: flex;
`;