import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import styled from 'styled-components';
import { Colors } from "../../../utils/constants";
import { listenToDocumentProperty } from "../../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../../utils/firestore";
import FAQItem from './faq-item';

const FAQ = () => {

    const [items, setItems] = useState({});

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "faq", "items", setItems);
        return unsubscribe;
    }, []);

   return (   
    <HeightDiv>
        <Container>
            <Row>
                <Col>
                    <StyledHeaderTitle>Frequently Asked Questions</StyledHeaderTitle>
                </Col>
            </Row>
        </Container>
        <StyledContainer>
            {items !== undefined && Object.entries(items).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                return <Row key={t[0]}>
                    <Col>
                        <FAQItem question={t[1].question} answer={t[1].answer}></FAQItem>
                    </Col>
                </Row>})
            }
        </StyledContainer>
    </HeightDiv>);
}
export default FAQ;

const StyledContainer = styled(Container)`
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid ${Colors.primaryblue};
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0.5rem 1rem ${Colors.primaryblue};
    cursor: pointer;
`;

const HeightDiv = styled.div`
    min-height: ${window.innerHeight - 149}px;
`;

const StyledHeaderTitle = styled.h1`
    color: ${Colors.primaryblue};
    font-family: Roboto;
`
