import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import styled, { keyframes } from 'styled-components';
import { Colors } from "../../../utils/constants";

export const AboutTestimonial = (props) => {
    const colors = [ "white" , Colors.primaryblue, "white", Colors.secondarygray, ];
    const textColors = [Colors.secondarygray, "white", Colors.secondarygray, "white", ];
    const sourceColors = ["#999999", "#90dbe6", "#999999", "#999999"];
    const quoteColors = ["#ebebeb", "white", "#ebebeb", "#484848", ];

    const [testimonials, setTestimonials] = useState([])
    const [masterTestimonials, setMasterTestimonials] = useState([])
    const [sectionsFadingOut, setSectionsFadingOut] = useState([false, false, false, false]);
    const [sectionsFadingIn, setSectionsFadingIn] = useState([false, false, false, false]);

    useEffect(() => {
        OrganizeTestimonials();
      }, []);

    const OrganizeTestimonials = () => { 
        var testimonialList = [];

        Object.entries(props.testimonials).sort((a,b)=>(a[1].order-b[1].order)).map((t, i) => {
            testimonialList.push(t[1]);
        })
        setMasterTestimonials(testimonialList);
        RandomlyChooseTestimonials(testimonialList);  
    }

    const RandomlyChooseTestimonials = (masterList) => {
        var viewableList = [];
        var numbersChosen = [];

        while(numbersChosen.length < 4){
            var r = Math.floor(Math.random() * masterList.length);
            if(numbersChosen.indexOf(r) === -1) numbersChosen.push(r);
        }

        for(let i = 0; i < numbersChosen.length; i++){
            viewableList.push(masterList[numbersChosen[i]]);
        }
        setTestimonials(viewableList);
    }
    
    return(
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <Col>
                        <StyledHeaderTitle>Testimonials</StyledHeaderTitle>
                    </Col>
                </Row>
                <ContentContainer>
                <Row>
                    <Col lg="6">
                        <NoMarginContainer>
                            <Row>
                                <Col lg="6">
                                    {testimonials.length > 0 && <StyledColumnContainer fadingin={sectionsFadingIn[0]} fadingout={sectionsFadingOut[0]} backcolor={colors[0]}>
                                        <LargeQuote quotecolor={quoteColors[0]}>"</LargeQuote>
                                        <NoMarginP textcolor={textColors[0]}>{testimonials[0].testimonial}</NoMarginP>
                                        <SourceName sourcecolor={sourceColors[0]}>-{testimonials[0].source}</SourceName>
                                    </StyledColumnContainer>}
                                </Col>

                                <Col lg="6">
                                    {testimonials.length > 1 && <StyledColumnContainer fadingin={sectionsFadingIn[1]} fadingout={sectionsFadingOut[1]} backcolor={colors[1]}>
                                        <LargeQuote quotecolor={quoteColors[1]}>"</LargeQuote>
                                        <NoMarginP textcolor={textColors[1]}>{testimonials[1].testimonial}</NoMarginP>
                                        <SourceName sourcecolor={sourceColors[1]}>-{testimonials[1].source}</SourceName>
                                    </StyledColumnContainer>}
                                </Col>
                            </Row>
                        </NoMarginContainer>
                    </Col>
                    <Col lg="6">
                        <NoMarginContainer>
                            <Row>
                                <Col>
                                    {testimonials.length > 2 && <StyledRowContainer fadingin={sectionsFadingIn[2]} fadingout={sectionsFadingOut[2]} backcolor={colors[2]}>
                                        <LargeQuote quotecolor={quoteColors[2]}>"</LargeQuote>
                                        <NoMarginP textcolor={textColors[2]}>{testimonials[2].testimonial}</NoMarginP>
                                        <SourceName sourcecolor={sourceColors[2]}>-{testimonials[2].source}</SourceName>
                                    </StyledRowContainer>}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    {testimonials.length > 3 && <StyledRowContainer fadingin={sectionsFadingIn[3]} fadingout={sectionsFadingOut[3]} backcolor={colors[3]}>
                                        <LargeQuote quotecolor={quoteColors[3]}>"</LargeQuote>
                                        <NoMarginP textcolor={textColors[3]}>{testimonials[3].testimonial}</NoMarginP>
                                        <SourceName sourcecolor={sourceColors[3]}>-{testimonials[3].source}</SourceName>
                                    </StyledRowContainer>}
                                </Col>
                            </Row>
                        </NoMarginContainer>
                    </Col>
                </Row>
                </ContentContainer>
            </Container>
        </React.Fragment>
    );
}

export default AboutTestimonial;

const StyledHeaderTitle = styled.h1`
    color: ${Colors.primaryblue};
    font-family: Roboto;
    margin-bottom: 30px;
`

const NoMarginContainer = styled(Container)`
    padding-left: 0px;
    
`

const ContentContainer = styled(Container)`
    max-width: 1200px;
    margin-bottom: 2%;
`;

const SourceName = styled.h5`
    margin-bottom: 20px;
    color: ${props => props.sourcecolor}
`

const LargeQuote = styled.h1`
    font-size: 100px;
    font-family: Roboto;
    height: 60px;
    color: ${props => props.quotecolor}
`

const fadeInAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
       
        opacity: 1;
    }
`;

const fadeOutAnimation = keyframes`
    from {
        opacity: 1;
    }
    to {
       
        opacity: 0;
    }
`;


const StyledColumnContainer = styled(Container)`
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.secondarybackground};
    background-color: ${props => props.backcolor};
    margin-left: 0px;
    height: 100%;
    animation: ${props => props.fadingin && fadeInAnimation} 2s 0s both;
    animation: ${props => props.fadingout && fadeOutAnimation} 2s 0s both;
`;

const StyledRowContainer = styled(Container)`
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.secondarybackground};
    background-color: ${props => props.backcolor};
    margin-left: 0px;
    width: 100%;
    height: 100%;
    animation: ${props => props.fadingin && fadeInAnimation} 2s 0s both;
    animation: ${props => props.fadingout && fadeOutAnimation} 2s 0s both;
`;

const NoMarginP = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-size: large;
    color: ${props => props.textcolor};

`
