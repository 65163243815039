import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import SubHeader from "../../common/sub-header";
import { getUserInformation } from "../../../utils/firestore-functions";
import { auth, teamUnbreakableDB } from "../../../utils/firestore";
import RegisterStudent from "../../registration/register-student";
import RegisterInstructor from "../../registration/register-instructor";
import YoutubeVideo from "../../common/youtube-video";
import StudentResources from "./student-resources";
import InstructorResources from "./Instructor-resources";
import ResearchHome from "../research/research-home";
import ResourceCard from "./resource-card";
import { listenToDocumentProperty } from "../../../utils/firestore-functions";
import { useParams } from "react-router-dom";
import NotVerified from "../../common/not-verified";
import { Colors } from "../../../utils/constants";
import SlideDecks from "./slide-deck";
import FAQ from "./FAQ";
import SlideDecksElementary from "./slide-deck-elementary";

export const Resources = (props) => {
    const [pictureURL, setPictureURL] = useState("https://team-unbreakable-space.nyc3.cdn.digitaloceanspaces.com/pictures/background-student-registration.jpg");
    const [subHeaderOptions, setSubHeaderOptions] = useState([
        {_id: "Home" },
        {_id: "Student Registration" },
        {_id: "Program Leader Registration" },
        {_id: "Slide Decks" },
        {_id: "Slide Decks (Elementary)" },
        {_id: "Research" },
        {_id: "Articles" },
        {_id: "FAQ" },
    ]);
    const [articleResearch, setArticleResearch] = useState({});

    let { subheader } = useParams();
    if (subheader === undefined) {
        subheader = "Home";
        window.history.replaceState(null, "", "/Resources/Home");
    }
    const [content, setContent] = useState(subheader);

    useEffect(() => {
        window.history.replaceState(null, "", "/Resources/" + content);
    }, [content])

    useEffect(() => {
        if(auth.currentUser){
            getUserInformation(teamUnbreakableDB, auth.currentUser.uid, UpdateSubHeader)
        }
      }, []);

      const UpdateSubHeader = (user) => {
        var newSubHeader = [
            {_id: "Home"},            
            {_id: "Student Booklet"},
        ];  
        if(user.instructor){
            newSubHeader.push({_id: "Program Leader Booklet"});
        }
        newSubHeader.push({_id: "Slide Decks"});
        newSubHeader.push({_id: "Slide Decks (Elementary)"});
        newSubHeader.push({_id: "Research"});
        newSubHeader.push({_id: "Articles"});
        newSubHeader.push({_id: "FAQ"});
        setSubHeaderOptions(newSubHeader);
      };

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "research", "articles", setArticleResearch);
        return unsubscribe;
    }, []);

    return(
        <React.Fragment>
            <MainDiv>
                <SubHeader setBackground={setPictureURL} setContent={setContent} selected={content} options={subHeaderOptions}></SubHeader>
                <OffsetDiv></OffsetDiv>
                    <Row>
                        {content === "Home" && <div>
                            <Container>
                                <br />
                                <Row>
                                    <Col>
                                        <StyledHeaderTitle>Resources Home</StyledHeaderTitle><br />
                                    </Col>
                                </Row>
                                <ContentContainer>
                                    <StyledSubHeader>Team Unbreakable: Physical Health For Mental Health</StyledSubHeader>
                                    <YoutubeVideo embedId="xcFWdwTq9NU" />
                                    <br />
                                    <StyledSubHeader>Goal Setting</StyledSubHeader>
                                    <YoutubeVideo embedId="2uvOk6eBAXA" />
                                    <br />
                                    <StyledSubHeader>Motivation</StyledSubHeader>
                                    <YoutubeVideo embedId="b9rLsjcZ2e0" />
                                    <br />
                                    <StyledSubHeader>Psychological Skills: Mindfulness</StyledSubHeader>
                                    <YoutubeVideo embedId="rflfYtOOep8" />
                                    <br />
                                    <StyledSubHeader>Compassion: Self Care Self Love</StyledSubHeader>
                                    <YoutubeVideo embedId="wQHlHF8ICqU" />
                                    <br />
                                    <StyledSubHeader>Gratitude</StyledSubHeader>
                                    <YoutubeVideo embedId="IXD9HG-CawU" />
                                </ContentContainer>
                            </Container>
                        </div>}

                        {(auth.currentUser === null  && content ==="Student Registration") && <RegisterStudent />}
                        {(auth.currentUser === null  && content ==="Program Leader Registration") && <RegisterInstructor />}
                        {content ==="Slide Decks" && <SlideDecks user={props.user} />}
                        {content ==="Slide Decks (Elementary)" && <SlideDecksElementary user={props.user} />}
                        {content ==="Research" && <ResearchHome />}
                        {content ==="Articles" && <div>
                            <Container>
                                <br/>
                                <StyledHeaderTitle>Articles</StyledHeaderTitle> 
                            {(Object.entries(articleResearch).length > 0) && Object.entries(articleResearch).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                                return (
                                    <ResourceCard
                                        key={t[0]}
                                        id={t[0]} 
                                        date={t[1].timeStamp}
                                        category={t[1].category} 
                                        downloadlink={t[1].url} 
                                        order={t[1].order} 
                                        description={t[1].description}  
                                        title={t[1].title}
                                        youtubeId={t[1].youtubeId}
                                        imgUrl={t[1].imgUrl}
                                    />);
                                })
                            }
                            {(Object.entries(articleResearch).length < 1) && <ContentContainer>
                                <StyledParagraph>
                                    No articles at this time.
                                </StyledParagraph>
                                </ContentContainer>}
                            </Container>
                            </div>
                        }
                        {content ==="FAQ" && <FAQ />}
                        {/* Need to add the student resources and instructor resources here */}
                        {(auth.currentUser !== null && auth.currentUser.emailVerified && content === "Student Booklet") && <StudentResources />}
                        {(auth.currentUser !== null && auth.currentUser.emailVerified && content === "Program Leader Booklet") && <InstructorResources />}
                        {(auth.currentUser !== null && !auth.currentUser.emailVerified && content === "Student Booklet") && <NotVerified />}
                        {(auth.currentUser !== null && !auth.currentUser.emailVerified && content === "Program Leader Booklet") && <NotVerified />}
                    </Row>
            </MainDiv>
        </React.Fragment>
    );
}

export default Resources;

const MainDiv = styled.div`
    min-height: ${window.innerHeight - 149}px;
    background-color: ${Colors.primarybackground};
`

const ContentContainer = styled(Container)`
    max-width: 1200px;
    margin-bottom: 2%;

`;

const OffsetDiv = styled.div`
    height: 180px;
`;

const StyledHeaderTitle = styled.h1`
    color: ${Colors.primaryblue};
    font-family: Roboto;
`

const StyledSubHeader = styled.h3`
    font-family: Roboto;
`

const StyledParagraph = styled.p`
    color: ${Colors.secondarytext};
    font-size: medium;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
`