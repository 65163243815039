import React, { useState } from "react";
import { Container, Row, Col, InputGroup, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import styled from 'styled-components';
import { setDocument } from '../../utils/firestore-functions';
import { teamUnbreakableDB, auth} from "../../utils/firestore";
import { NewUser } from "../../utils/constants";
import { validateEmail, validPassword } from "../../utils/helper";
import firebase from "firebase/app";
import { Event } from "../../utils/analytics";
import { Colors } from "../../utils/constants";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const RegisterStudent = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [location, setLocation] = useState("");
    const [teacher, setTeacher] = useState("");
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [gender, setGender] = useState("Gender");
    const [birthday, setBirthday] = useState("");
    const [genderDropdownOpen, setGenderDropdownOpen] = useState(false);

    const toggleGenderDropdown = () => setGenderDropdownOpen((prevState) => !prevState);

    const register = () => {
        var error = false;
        if (password !== confirmPassword){
            setError("Passwords do not match");
            setMessage("");
            error = true;
        }
        if (!validPassword(password)){
            setError("Password needs to be at least 6 characters long and contain a number, character and special character");
            setMessage("");
            error = true;
        }
        if (firstName.length <= 1){
            setError("First Name needs to be at least 2 characters in length");
            setMessage("");
            error = true;
        }

        if (!validateEmail(email)){
            setError("Invalid Email");
            setMessage("");
            error = true;
        }
        if(!acceptedTerms){
            setError("You must accept the terms and conditions to register");
            setMessage("");
            error = true;
        }

        if (!error){
            setError("");
            createUser();
            // trigger a "sign up" event here
            Event("New User Signed Up - test"); 
        }
        else{
            console.log("One or more errors were found during registration");
        }
    }

    const createUser = () => {
        auth.createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
            let user = userCredential.user;
            user.updateProfile({
                displayName: firstName,
            }).then(function(){
                var timeStamp = firebase.firestore.Timestamp.now(); 
                let person = NewUser;
                person.email = email;
                person.firstName = firstName;
                person.teacher = teacher;
                person.location = location;
                person.admin = false;
                person.verified = false;
                person.id = user.uid;
                person.timeStamp = timeStamp;
                person.birthday = birthday.ts;
                person.gender = gender;
            setDocument(teamUnbreakableDB, "users", user.uid, person, 
                function(){
                    user.sendEmailVerification().then(() => {
                        window.location = "/RegisterComplete";
                    });
            },
                function(){
                    console.log("Failed to create user in DB");
                })
            });
        }).catch((error) => {
            setError(error.message);
        })
    }

return (
    <InteractingContainer>
        <Row>
            <Col>
                <RegisterTitle>Student Registration</RegisterTitle>
            </Col>
        </Row>
        <MarginRow>
            <Col>
                <StyledP>*First Name:</StyledP>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <InputGroup>
                    <StyledInput maxLength="30" value={firstName} onChange={e => setFirstName(e.target.value)} />
                </InputGroup>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <StyledP>*Email Address:</StyledP>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <InputGroup>
                    <StyledInput maxLength="50" value={email} onChange={e => setEmail(e.target.value)} />
                </InputGroup>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <StyledP>*Password:</StyledP>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <InputGroup>
                    <StyledInput type="password" value={password} onChange={e => setPassword(e.target.value)} />
                </InputGroup>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <StyledP>*Confirm Password:</StyledP>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <InputGroup>
                    <StyledInput type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                </InputGroup>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <StyledP>School/Institution:</StyledP>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <InputGroup>
                    <StyledInput maxLength="50" value={location} onChange={e => setLocation(e.target.value)} />
                </InputGroup>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <StyledP>Program Leader:</StyledP>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col >
                <InputGroup>
                    <StyledInput maxLength="50" value={teacher} onChange={e => setTeacher(e.target.value)} />
                </InputGroup>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <StyledP>Gender:</StyledP>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col style={{ textAlign:"center"}}>
                <Dropdown  isOpen={genderDropdownOpen} toggle={toggleGenderDropdown} >
                    <DropdownToggle color="light" outline caret>{gender}</DropdownToggle>
                    <DropdownMenu>
                    <DropdownItem onClick={() => {setGender("Boy")}}>Boy</DropdownItem>
                    <DropdownItem onClick={() => {setGender("Girl")}}>Girl</DropdownItem>
                    <DropdownItem onClick={() => {setGender("Non-binary")}}>Non-Binary</DropdownItem>
                    <DropdownItem onClick={() => {setGender("Prefer not to say")}}>Prefer not to say</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <StyledP>Birthday:</StyledP>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker onChange={(newValue) => {setBirthday(newValue)}}/>
                </LocalizationProvider>
            </Col>
        </MarginRow>
        <br />
        <MarginRow>
            <FlexCol md="auto">
                <Checkbox value={acceptedTerms} onClick={() => setAcceptedTerms(!acceptedTerms)} type="checkbox" />
            </FlexCol>
            <FlexCol style={{paddingLeft: "0px"}}>
                <DisclaimerP>The Team Unbreakable Program is for use of approved Schools/Community centers only. Our materials are
                    not to be used or shared without the permission of Team Unbreakable. Click here if you accept.
                </DisclaimerP>
            </FlexCol>
        </MarginRow>
        {error.length > 0 && <MarginRow>
            <CenterFlexCol>
                <ErrorP>{error}</ErrorP>
            </CenterFlexCol>
        </MarginRow>}
        {message.length > 0 && <MarginRow>
            <CenterFlexCol>
                <MessageP>{message}</MessageP>
            </CenterFlexCol>
        </MarginRow>}
        <br />
        <MarginRow>
            <CenterFlexCol>
                <RegisterButton onClick={() => {register()}} color="primary">Register</RegisterButton>
            </CenterFlexCol>
        </MarginRow>
    </InteractingContainer>
    );
}

export default RegisterStudent;

const RegisterButton = styled(Button)`
    background-color: white;
    color: ${Colors.primaryblue};
    padding-left: 35px;
    padding-right: 35px;
    border: solid;
    border-width: 1px;
        &:hover {
            background-color: ${Colors.primaryblue};
            border-color: white;
        }
`

const InteractingContainer = styled(Container)`
    margin-top: 0px;
    background-color: ${Colors.primaryblue};
    max-width: 400px;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
`;

const StyledInput = styled(Input)`
    width: 100px; 
    &:focus {
        box-shadow: 0 0 10px white;
}
`

const StyledP = styled.p`
    margin: 0px;
    color: white;
    font-size: 18px;
`;

const Checkbox = styled(Input)`
`;

const DisclaimerP = styled.p`
    color: white;
    margin-bottom: 0px;
`;

const ErrorP = styled.p`
    color: red;
    margin: 0;
`

const MessageP = styled.p`
    color: green;
    margin: 0;
`

const MarginRow = styled(Row)`
    margin-top: 5px;
`;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const FlexCol = styled(Col)`
    display: flex;
`;

const RegisterTitle = styled.h3`
    color: white;
    text-align: center;
`;