import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Copy } from "./physical-module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { ColoredH2, StyledHR, StyledP, CenterTitleFlexCol, WhiteH2 } from "../../styles/styledComponents";
import RunningImage from "../../images/running.png";
import StarsImage from "../../images/stars.png";
import styled from "styled-components";
import { Colors } from "../../utils/constants";

export const PhysicalModuleRunningMechanics = ( props ) => {

    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const completeModule = () => {
        var data = user;
        data.modules.introduction = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module Running Mechanics"].Title[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph1[props.user.language] + " "}</StyledP>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph3[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <StyledHR />
                <Row>
                    <CenterFlexVerticalCol>
                        <PhysicalActivityIMG src={RunningImage}></PhysicalActivityIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <StyledHR />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Running Mechanics"].SubTitle1[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph4[props.user.language]}</StyledP>
                    </Col>
                    <CenterFlexVerticalCol lg="4">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Running Mechanics"].SubTitle2[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph5[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Running Mechanics"].SubTitle3[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph6[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <CenterFlexVerticalCol lg="4">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                    <Col>
                        <ColoredH2>{Copy["Module Running Mechanics"].SubTitle4[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph7[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Running Mechanics"].SubTitle5[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph8[props.user.language] + " "}</StyledP>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph9[props.user.language] + " "}</StyledP>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph10[props.user.language] + " "}</StyledP>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph11[props.user.language]}</StyledP>
                    </Col>
                    <CenterFlexVerticalCol lg="4">
                        <StarsIMG src={StarsImage}></StarsIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <br />
                <StyledWhiteRow>
                    <Col>
                        <StyledP>{Copy["Module Running Mechanics"].Paragraph12[props.user.language]}</StyledP>
                    </Col>
                </StyledWhiteRow>
            </Container>
        </React.Fragment>
    );
}

export default PhysicalModuleRunningMechanics;

const StyledWhiteRow = styled(Row)`
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
    background-color: white;
    padding: 5px;
`;

const StarsIMG = styled.img`
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
`;

const PhysicalActivityIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;