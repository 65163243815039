import React, { useState } from "react";
import { Row, Col,  Button, InputGroup, Input, InputGroupText, Container } from "reactstrap";
import { teamUnbreakableDB } from "../../utils/firestore";
import { updateDocumentProperty, deleteNestedDocumentProperty, UploadFileToStorage, DeleteFromStorage } from "../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ModalWindow from "../common/modalWindow";
import ImageUploadAndCrop from "../common/imageUploadAndCrop";
import { Colors } from "../../utils/constants";

export const AdminResearchRow = (props) => {
    const [timeStamp] = useState(props.timeStamp);
    const [description, setDescription] = useState(props.description);
    const [url, setUrl] = useState(props.url);
    const [imgUrl, setImgUrl] = useState(props.imgUrl);
    const [title, setTitle] = useState(props.title);
    const [category, setCategory] = useState(props.category);
    const [youtubeId, setYoutubeId] = useState(props.youtubeId);
    const [order, setOrder] = useState(props.order);
    const [fileName, setFileName] = useState(props.fileName);
    const [resourceFile, setResourceFile] = useState(); //Will need default value?
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn, setModalConfirmOn] = useState(true);
    const [modalConfimFunction, setModalConfirmFunction] = useState();
    const [modalCancelText, setModalCancelText] = useState("Cancel");
    const [crop] = useState({unit: '%',
    width: 30});

    const toggle = () => setModal(!modal);

    const storageLocation = `files/research/`;

    const updateElement = (newUrl, newFileName, imgUrl) => {
        var data = {
            description: description,
            url: newUrl,
            fileName: newFileName ? newFileName : "No File Uploaded",
            title: title,
            category: category,
            order: order,
            id: props.id,
            timeStamp: timeStamp,
            youtubeId: youtubeId,
            imgUrl: imgUrl
        }
        
        updateDocumentProperty(teamUnbreakableDB, "content", "research", props.type + "." + props.id, data, UpdateSuccess, UpdateFailed);
    }

    const UpdateSuccess = () => {
        setModalTitle("Successfully Updated");
        setModalMessage("Your resource has been updated successfully");
        setModalCancelText("Back")
        setModalConfirmOn(false);
        setModal(true);
    }

    const UpdateFailed = () => {
        setModalTitle("Update Failed");
        setModalMessage("Something went wrong when updating");
        setModalCancelText("Back")
        setModalConfirmOn(false);
        toggle();
    }

    const DeletePrompt = () => {
        setModalTitle("Delete Element?");
        setModalMessage("Are you sure you want to delete this element?");
        setModalCancelText("Cancel")
        setModalConfirmOn(true);
        setModalConfirmFunction(() => deleteElement);
        toggle();
    }

    const deleteElement = () => {
        DeleteFromStorage(storageLocation, fileName)
        deleteNestedDocumentProperty(teamUnbreakableDB, "content", "research", props.type, props.id)
    }

    const DeleteFilePrompt = () => {
        setModalTitle("Delete Element?");
        setModalMessage("Are you sure you want to delete this element?");
        setModalCancelText("Cancel")
        setModalConfirmOn(true);
        setModalConfirmFunction(() => DeleteFile);
        toggle();
    }

    const DeleteFile = () =>{
        DeleteFromStorage(storageLocation, fileName, DeletionConfirmation)
    }

    const DeletionConfirmation = () => {
        setUrl("");
        setFileName("No File Uploaded");
        setResourceFile("");
        updateElement("", "No File Uploaded", imgUrl);
    }

    const ValidateFile = (data) => {
        if(!data){return;}
        
        const fileRegex = '^.*.(jpg|JPG|doc|DOC|pdf|PDF|docx|mp4|mov|png)$';
        var fileName = data[0].name;
        var validFile = fileName.match(fileRegex);
        
        if(validFile !== null) {
            UploadResourceFile(data[0]);
        }
        else{
            setModalTitle("Invalid File");
            setModalMessage("Invalid file entered, please submit a valid file");
            setModalCancelText("Back")
            setModalConfirmOn(false);
            toggle();
        }     
    }

    const UploadResourceFile = (data) => {
        UploadFileToStorage(data, storageLocation, data.name, SuccessfulUpload, FailedUpload, data.name);
    }

    const doSubmit =(imageFile) => {
        const childName = props.id;
        UploadFileToStorage(imageFile, storageLocation, childName, SuccessUpload, FailedUpload)
    };

    const SuccessUpload = (imgUrl) => {
        setImgUrl(imgUrl);
        updateElement(url, fileName, imgUrl);
    }

    const RetrieveImage = (image, imgUrl) => {
        setImgUrl(imgUrl);
        doSubmit(image);
    }

    const SuccessfulUpload = (urlLocation, fileName) => {
        setUrl(urlLocation);
        setFileName(fileName);
        updateElement(urlLocation, fileName, imgUrl);
    }

    const FailedUpload = () => {
        setModalTitle("Failed to Upload");
        setModalMessage("Something went wrong when trying to upload");
        setModalConfirmOn(false);
        setModalCancelText("Back")
        setModal(true);
    }

    const RemoveImagePrompt = () => {
        setModalTitle("Delete Picture");
        setModalMessage("Are you sure you want to delete your picture?");
        setModalConfirmOn(true);
        setModalConfirmFunction(() => RemoveImage);
        toggle();
      }
  
      const RemoveImage = () => {
        setUrl("N/A");
        DeleteFromStorage(storageLocation, props.id, SetNullImageRef);
      }

      const SetNullImageRef = () => {
        updateElement(url, fileName, "N/A");
        toggle();
      }

    return(
        <React.Fragment>
            <ModalWindow 
            isOpen={modal} 
            title={modalTitle} 
            cancelText={modalCancelText} 
            message={modalMessage} 
            cancelFunction={toggle} 
            confirmFunction={modalConfimFunction} 
            showConfirmButton={modalConfirmOn} 
            />
            <Row>
                <Container>
                    <Row>
                        <Col xs="4">
                            <Container fluid={true}>
                                <Row>
                                    {(!imgUrl || imgUrl ==="N/A") &&
                                        <Col><ImageUploadAndCrop maxWidth="" submitFunction={RetrieveImage} crop={crop} descriptionText="Upload Post Picture" /></Col>
                                    }
                                    {(imgUrl && imgUrl !== "N/A") &&
                                        <Col><br />
                                            <StyledProfileImage src={imgUrl} alt="" />
                                            <StyledButton onClick={() => RemoveImagePrompt()} color="danger">
                                                <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                                            </StyledButton>
                                        </Col>
                                    }
                                </Row>
                            </Container>
                        </Col>
                        <Col>
                            <Container>
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <div addonType="prepend">
                                                <InputGroupText>Title</InputGroupText>
                                            </div>
                                            <Input type="text" value={title} onChange={e => setTitle(e.target.value)}/>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <div addonType="prepend">
                                                <InputGroupText>Order</InputGroupText>
                                            </div>
                                            <Input type="text" value={order} onChange={e => setOrder(e.target.value)}/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <div addonType="prepend">
                                                <InputGroupText>Category</InputGroupText>
                                            </div>
                                            <Input type="text" value={category} onChange={e => setCategory(e.target.value)}/>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <div addonType="prepend">
                                                <InputGroupText>Video: Youtube ID</InputGroupText>
                                            </div>
                                            <Input type="text" value={youtubeId} onChange={e => setYoutubeId(e.target.value)}/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <div addonType="prepend">
                                                <InputGroupText>File Name</InputGroupText>
                                            </div>
                                            {fileName}
                                        </InputGroup>
                                    </Col>
                                    <FlexCol xs="2">
                                        <StyledButton onClick={()=> {updateElement(url, fileName, imgUrl)}} color="primary">
                                            <FAIconButton icon={["fas", "edit"]}></FAIconButton>
                                        </StyledButton>
                                        <StyledButton onClick={()=> {DeletePrompt()}} color="danger">
                                            <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                                        </StyledButton>
                                    </FlexCol>
                                </Row>
                                <br />
                                <Row>
                                    <InputGroup>
                                    {(!fileName || fileName === "No File Uploaded") &&
                                        <div addonType="prepend">
                                            <InputGroupText>Add New File</InputGroupText>
                                            <Input type="file" value={resourceFile} onChange={e => ValidateFile(e.target.files)}/>
                                        </div>}
                                        {fileName && fileName !== "No File Uploaded" && <StyledButton onClick={()=> {DeleteFilePrompt()}} color="danger">
                                            Delete File
                                        </StyledButton>} 
                                    </InputGroup>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <div addonType="prepend">
                                                <InputGroupText>Research Description</InputGroupText>
                                            </div>
                                            <Input type="text" value={description} onChange={e => setDescription(e.target.value)}/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </React.Fragment>
    ) 
}

export default AdminResearchRow;

const StyledButton = styled(Button)`
    margin-left: 5px;
`;

const FlexCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;


const StyledProfileImage = styled.img`
    padding: 5px;
    box-shadow: 0 0.5rem 1rem ${Colors.primaryblue};
    max-width: 300px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: -20px;
`