import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCWhX31MPS4Oy3F27BYJv6cmlP5ENJ1P0o",
    authDomain: "team-unbreakable.firebaseapp.com",
    projectId: "team-unbreakable",
    storageBucket: "team-unbreakable.appspot.com",
    messagingSenderId: "219659186360",
    appId: "1:219659186360:web:3cc4014128cfebaf27844d",
    measurementId: "G-SREWFCS1G3"
});

const teamUnbreakableDB = firebaseApp.firestore();
const storage = firebase.storage();

export const generateGUID = uuidv4;
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export { teamUnbreakableDB };
export { storage };