import React, { useState } from "react";
import { InputGroup, Input, Container, Row, Col, Button } from "reactstrap";
import styled from 'styled-components';
import { auth } from "../../utils/firestore";
import { Colors } from "../../utils/constants";

export const LostPassword = () => {
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [email, setEmail] = useState("");
    
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const attemptEmail = () => {
        var error = false;
        if (email.length <= 3){
            error = true;
            setError("Email length is too short.");
            setMessage("");
        }

        if (!error){
            auth.sendPasswordResetEmail(email);
            setEmailSubmitted(true);
        }
        else{
            console.log("Invalid email entered: " + error);
        }
    }

    return(
        <React.Fragment>
            <MinHeightDiv>
                <GrayBackground />
                <OffsetDiv></OffsetDiv>
                <InteractingContainer>
                    <Row>
                        <Col>
                            <Title>Reset Password</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StyledHR></StyledHR>
                        </Col>
                    </Row>
                    {!emailSubmitted &&
                    <div><MarginRow>
                        <Col>
                            <StyledP>
                                Email Address
                            </StyledP>
                        </Col>
                    </MarginRow>
                    <MarginRow>
                        <Col>
                            <InputGroup>
                                <Input value={email} onChange={e => setEmail(e.target.value)} />
                            </InputGroup>
                        </Col>
                    </MarginRow></div>}
                    <hr />
                    {emailSubmitted &&
                    <div>
                    <MarginRow>
                        <Col>
                            <StyledP>A password reset email has been sent to your account.</StyledP>
                        </Col>
                    </MarginRow></div>}
                    {error.length > 0 && <MarginRow>
                        <CenterFlexCol>
                            <ErrorP>{error}</ErrorP>
                        </CenterFlexCol>
                    </MarginRow>}
                    {message.length > 0 && <MarginRow>
                        <CenterFlexCol>
                            <MessageP>{message}</MessageP>
                        </CenterFlexCol>
                    </MarginRow>}
                    {!emailSubmitted && <MarginRow>
                        <CenterFlexCol>
                            <StyledButton onClick={() => {attemptEmail()}} color="primary">Send Email</StyledButton>
                        </CenterFlexCol>
                    </MarginRow>}
                </InteractingContainer>
                <br></br>
            </MinHeightDiv>
        </React.Fragment>
    );
}

export default LostPassword;

const StyledButton = styled(Button)`
    background-color: white;
    padding-left: 40px;
    padding-right: 40px;
    color: ${Colors.primaryblue};
    border: none;
    &:hover {
        color: white;
        background-color: ${Colors.secondarygray};
    }
`

const MinHeightDiv = styled.div`
    min-height: ${window.innerHeight - 149}px;
`;

const StyledP = styled.p`
    margin: 0px;
    color: white;
    font-size: 18px;
`;

const StyledHR = styled.hr`
    margin-top: 0px;
    background-color: white;
`;

const OffsetDiv = styled.div`
    height: 180px;
`;

const ErrorP = styled.p`
    color: red;
    margin: 0;
`

const MessageP = styled.p`
    color: green;
    margin: 0;
`

const Title = styled.h3`
    color: white;
    text-align: center;
`;

const MarginRow = styled(Row)`
    margin-top: 5px;
`;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const InteractingContainer = styled(Container)`
    display: block;
    padding: 30px;
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: ${Colors.primaryblue};
    max-width: fit-content;
    min-width: 400px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    color: white;
`;

const GrayBackground = styled.div`
    width: 100%;
    height: 100%;
    z-index: -1000;
    background-color: ${Colors.primarybackground};
    background-size: cover;
`