import React, {useEffect, useState} from 'react';
import { Container, Row, InputGroup, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { teamUnbreakableDB } from '../../../utils/firestore';
import { listenToNestedDocumentProperty, updateDocumentProperty } from '../../../utils/firestore-functions';
import ContactTicket from './admin-contact-ticket';
import TicketModal from './ticket-modal';

const TicketList = () => {
    const [ticketList, setTicketList] = useState({});
    const [viewingResolved, setViewingResolved] = useState(false);
    const [elementDropdownOpen, setElementDropdownOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn, setModalConfirmOn] = useState(true);
    const [modalConfimFunction, setModalConfirmFunction] = useState();
    const [modalEmail, setModalEmail] = useState();
    const [modalPhone, setModalPhone] = useState();

    const [selectedTicket, setSelectedTicket] = useState();

    const toggle = () => setModal(!modal);

    useEffect(() => {
        var unsubscribe = listenToNestedDocumentProperty(teamUnbreakableDB, "admin", "tickets", "contactus", setTicketList);
        return unsubscribe;
    }, []);

    const ViewTicketInformation = (ticket) => {
        setSelectedTicket(ticket);
        setModalTitle(ticket[1].name);
        setModalMessage(ticket[1].message);
        setModalEmail(ticket[1].email);
        setModalPhone(ticket[1].phone);
        if(ticket[1].resolved){
            setModalConfirmOn(false);
        }
        else{
            setModalConfirmOn(true);
        }
        setModalConfirmFunction(() => ResolveTicket);
        setModal(true);
    }

    const ResolveTicket =  () => {
        var updatedTicket = selectedTicket[1];
        updatedTicket.resolved = true;
        updateDocumentProperty(teamUnbreakableDB, "admin", "tickets", "contactus." + selectedTicket[0], updatedTicket, SuccessUpdate, FailedUpdate);
    }

    const SuccessUpdate = () => {
        setModalTitle("Resolved Ticket");
        setModalMessage("The ticket has been resolved");
        setModalConfirmOn(false);
    }

    const FailedUpdate = () => {
        setModalTitle("Something went wrong");
        setModalMessage("Failed to resolve ticket");
        setModalConfirmOn(false);
    }

    return (<React.Fragment>
        <TicketModal
            isOpen={modal} 
            title={modalTitle} 
            message={modalMessage} 
            cancelFunction={toggle} 
            confirmFunction={modalConfimFunction} 
            showConfirmButton={modalConfirmOn} 
            confirmButtonText = "Resolve"
            cancelText = "Close"
            phone={modalPhone}
            email={modalEmail}
        />
        <Container>
            <Row>
                <Col>
                    <h1>Tickets</h1>
                </Col>
                <Col>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"200px"}}>
                            {viewingResolved ? "Resolved" : "Unresolved"}
                        </div>
                        <Dropdown isOpen={elementDropdownOpen} toggle={() => {setElementDropdownOpen(!elementDropdownOpen)}}>
                            <DropdownToggle caret></DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => setViewingResolved(true)}>Resolved</DropdownItem>
                                <DropdownItem onClick={() => setViewingResolved(false)}>Unresolved</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </InputGroup>
                </Col>
            </Row>
            
            {(Object.entries(ticketList).length > 0) && Object.entries(ticketList).sort((a,b)=>(b[1].timeStamp-a[1].timeStamp)).map(ticket => {
                if(viewingResolved === ticket[1].resolved){ return <Row key={ticket[0]}>
                    <ContactTicket 
                        name={ticket[1].name} 
                        email={ticket[1].email} 
                        phone={ticket[1].phone} 
                        message={ticket[1].message} 
                        timeStamp={ticket[1].timeStamp.toDate().toLocaleString('en-US')}
                        ticket={ticket}
                        confirmFunction={ViewTicketInformation}
                    />
                </Row>}
            })}
        </Container>
    </React.Fragment>);
}
export default TicketList;