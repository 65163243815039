import React, { useState } from 'react';
import { Container, Row, Col, Collapse } from "reactstrap";
import styled from 'styled-components';
import { Colors } from "../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FAQItem = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

   return (   
    <Container>
        <Row onClick={() => {toggle()}}>
            <FlexCol>
                {!isOpen && <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                {isOpen && <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton>}
                <StyledQuestion>{props.question}</StyledQuestion>
            </FlexCol>
        </Row>
        <Collapse isOpen={isOpen}>
            <Row>
                <Col>
                    <StyledAnswer>{props.answer}</StyledAnswer>
                </Col>
            </Row>
        </Collapse>
    </Container>
    );
}
export default FAQItem;

const StyledContainer = styled(Container)`
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid ${Colors.primaryblue};
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0.5rem 1rem ${Colors.primaryblue};
    cursor: pointer;
`;

const FlexCol = styled(Col)`
    display: flex;
    justify-content: left;
    align-items: center;
`;

const StyledQuestion = styled.h1`
    color: black;
    font-family: Roboto;
    font-size: 30px;
`;

const StyledAnswer = styled.h1`
    color: ${Colors.primaryblue};
    font-family: Roboto;
    font-size: 26px;
    font-weight: 200;
    margin-left: 60px;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 6px;
`;