import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col } from "reactstrap";

const TicketModal = ({phone, email, isOpen, title, message, cancelFunction, cancelText = "Cancel", confirmFunction, showConfirmButton = true, confirmButtonText = "Confirm"}) => {
    return (
    <Modal isOpen={isOpen}>
        <ModalHeader>
                {title}
        </ModalHeader>
        <ModalBody>
            <Container>
                <Row>
                    <Col>
                        <h4>{phone}</h4>
                    </Col>
                    <Col>
                        <h4>{email}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            {message}
                        </p>
                    </Col>
                </Row>
            </Container>
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={cancelFunction}>{cancelText}</Button>
            {showConfirmButton && <Button color="primary" onClick={confirmFunction}>{confirmButtonText}</Button>}
        </ModalFooter>
    </Modal> );
}
 
export default TicketModal;