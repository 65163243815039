import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import { listenToNestedDocumentProperty } from '../../utils/firestore-functions';
import { teamUnbreakableDB } from "../../utils/firestore";
import { getAllChallenges } from "../../utils/challenges";
import StudentChallenge from "./student-challenge";
import styled from "styled-components";
import { Colors } from "../../utils/constants";

export const StudentChallenges = ( props ) => {

    const [currentChallenge, setCurrentChallenge] = useState();
    const [challenges, setChallenges] = useState();

    //TODO currently not properly listening to challenges
    useEffect(() => {
        let unsubscribe = listenToNestedDocumentProperty(teamUnbreakableDB, "users", props.user.id, "data.currentChallenge", setCurrentChallenge);
        return unsubscribe;
    }, []);

    useEffect(() => {
        let unsubscribe = listenToNestedDocumentProperty(teamUnbreakableDB, "users", props.user.id, "data.challenges", setChallenges);
        return unsubscribe;
    }, []);

    let alternating = false;

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <ColoredH2>Challenges</ColoredH2>
                </Col>
            </Row>
            <Row>
                {challenges !== undefined && currentChallenge !== undefined && Object.entries(getAllChallenges(props.user)).length > 0 && Object.entries(getAllChallenges(props.user)).map((_challenge) => {
                    {alternating = !alternating}
                    return (
                        <Col xl="4" md="6" xs="12">
                            <StudentChallenge selected={currentChallenge.challengeName === _challenge[1].id} alternating={alternating} user={props.user} challenge={_challenge} />
                        </Col>
                    )
                })}
            </Row>
        </React.Fragment>
    );
}

export default StudentChallenges;

const ColoredH2 = styled.h2`
    color: ${Colors.primaryblue};
    margin-bottom: 20px;
`;

const SolidChallengeContainer = styled(Container)`
    background-color: ${Colors.primaryblue};
`;
