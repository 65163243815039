import React, { useState, useEffect } from 'react';
import { teamUnbreakableDB, generateGUID } from "../../../utils/firestore";
import { listenToDocumentProperty, updateDocumentProperty } from "../../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Button, InputGroup, Input } from "reactstrap";
import styled from "styled-components";
import { Person } from '../../../utils/constants';
import AdminPerson from './admin-person';

const AdminPeople = () => {
    const [people, setPeople] = useState({});
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [order, setOrder] = useState(0);

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "people", "people", setPeople);
        return unsubscribe;
    }, [])

    const addPerson = () => {
        var guid = generateGUID();
        var person = Person;
        person.title = title;
        person.description = description;
        person.name= name;
        person.order= order;
        person.id= guid;
        updateDocumentProperty(teamUnbreakableDB, "content", "people", "people." + guid, person);
        clearInput();
    }

    const clearInput = () => {
        setTitle("");
        setName("");
        setDescription("");
        setOrder(0);
    }

    return ( 
        <Container>
            <Row>
                <CenterFlexCol>
                    <h1>Team Members</h1>
                </CenterFlexCol>
            </Row>
            {(Object.entries(people).length > 0) && Object.entries(people).sort((a,b)=>(parseInt(a[1].order))-(parseInt(b[1].order))).map(person => {
                return <ColoredDiv key={person[0]}>
                    <AdminPerson name={person[1].name} id={person[0]} imageurl={person[1].imageUrl} title={person[1].title} description={person[1].description} order={person[1].order}></AdminPerson>
                </ColoredDiv>
            })}
            <Row>
                <Col>
                    <h2>Add New Person</h2>
                </Col>
            </Row>
            <Row>
                <FlexCol xs="1">
                    <Button onClick={() => {addPerson()}} color="primary">
                        <FAIconButton icon={["fas", "user-plus"]}></FAIconButton>
                    </Button>
                </FlexCol>
                <FlexCol>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"100px"}}>
                            Name
                        </div>
                        <Input value={name} onChange={e => setName(e.target.value)} />
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend">
                            Title
                        </div>
                        <Input value={title} onChange={e => setTitle(e.target.value)}/>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend">
                            Order
                        </div>
                        <Input value={order} onChange={e => setOrder(e.target.value)} />
                    </InputGroup>
                </FlexCol>
            </Row>
            <Row>
                <FlexCol xs="1">
                </FlexCol>
                <FlexCol>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"100px"}}>
                            Description
                        </div>
                    <Input type="textarea" value={description} onChange={e => setDescription(e.target.value)}/>
                    </InputGroup>
                </FlexCol>
            </Row>
        </Container>
     );
}
 
export default AdminPeople;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const ColoredDiv = styled.div`
    margin-bottom: 5px;
    padding: 5px;
    padding-top: 15px;
    background-color: rgba(0,0,0,0.05);
`;

const FlexCol = styled(Col)`
    display: flex;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;