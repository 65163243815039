import React, { useState } from "react";
import {  Row, Col,  Button, InputGroup, Input, InputGroupText } from "reactstrap";
import { teamUnbreakableDB } from "../../../utils/firestore";
import { updateDocumentProperty, deleteNestedDocumentProperty, DeleteFromStorage, UploadFileToStorage } from "../../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ModalWindow from "../../common/modalWindow";
import ImageUploadAndCrop from "../../common/imageUploadAndCrop";
import { Colors } from "../../../utils/constants";

export const AdminAboutProgram = (props) => {
    
    const [description, setDescription] = useState(props.description);
    const [url, setUrl] = useState(props.url);
    const [title, setTitle] = useState(props.title);
    const [order, setOrder] = useState(props.order);
    const [crop] = useState({unit: '%',
    width: 30});
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn, setModalConfirmOn] = useState(true);
    const [modalConfimFunction, setModalConfirmFunction] = useState();
    const [modalCancelText, setModalCancelText] = useState("Cancel");

    const toggle = () => setModal(!modal);

    const storageLocation = `images/aboutsection/`;

    const updateElement = (newUrl) => {
        
        var data = {
            description: description,
            imgURL: newUrl,
            title: title,
            order: order,
            id: props.id,
        }
        updateDocumentProperty(teamUnbreakableDB, "content", "about", "program." + props.id, data, UpdateSuccess, UpdateFailed);
    }

    const UpdateSuccess = () => {
        setModalTitle("Successfully Updated");
        setModalMessage("Your article has been updated successfully");
        setModalCancelText("Okay");
        setModalConfirmOn(false);
        setModal(true);
    }

    const UpdateFailed = () => {
        setModalTitle("Update Failed");
        setModalMessage("Something went wrong when updating");
        setModalCancelText("Okay");
        setModalConfirmOn(false);
        toggle();
    }

    const doSubmit = (imageFile) => {
        const childName = props.id;
        UploadFileToStorage(imageFile, storageLocation, childName, SuccessUpload, FailedUpload)
    };

    const SuccessUpload = (url) => {
        setUrl(url);
        updateElement(url);
    }

    const FailedUpload = (error) => {
        console.log(error);
    }

    const RetrieveImage = (image, url) => {
        setUrl(url);
        doSubmit(image);
    }

    const DeletePrompt = () => {
        setModalTitle("Delete Element?");
        setModalMessage("Are you sure you want to delete this element?");
        setModalCancelText("Cancel");
        setModalConfirmOn(true);
        setModalConfirmFunction(() => deleteElement);
        toggle();
    }

    const deleteElement = () => {
        DeleteFromStorage(storageLocation, props.id, SetNullImageRef);
        deleteNestedDocumentProperty(teamUnbreakableDB, "content", "about", "program", props.id);
        toggle();
    }

    const RemoveImagePrompt = () => {
        setModalTitle("Delete Picture");
        setModalMessage("Are you sure you want to delete your picture?");
        setModalCancelText("Cancel");
        setModalConfirmOn(true);
        setModalConfirmFunction(() => RemoveImage);
        toggle();
      }
  
      const RemoveImage = () => {
        setUrl("N/A");
        DeleteFromStorage(storageLocation, props.id, SetNullImageRef);
      }

      const SetNullImageRef = () => {
        updateElement("N/A");
        toggle();
      }

    return(
        <React.Fragment>
            <ModalWindow 
                cancelText={modalCancelText} 
                isOpen={modal} 
                title={modalTitle} 
                message={modalMessage} 
                cancelFunction={toggle} 
                confirmFunction={modalConfimFunction} 
                showConfirmButton={modalConfirmOn} 
            />
            <Row>
            {(!url || url ==="N/A") && 
                <Col xs="5"><ImageUploadAndCrop maxWidth="500px" submitFunction={RetrieveImage} crop={crop} descriptionText="Upload Program Picture" /></Col>
            }
            {(url && url !== "N/A") && 
                <Col xs="5"><br />
                    <StyledProfileImage src={url} alt="" />
                    <StyledButton onClick={() => RemoveImagePrompt()} color="danger">
                        <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                    </StyledButton>
                </Col>
            }
                <Col>
                    <InputGroup>
                        <div addonType="prepend">
                            <InputGroupText>Order</InputGroupText>
                        </div>
                        <Input type="text" value={order} onChange={e => setOrder(e.target.value)}/>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend">
                            <InputGroupText>Title</InputGroupText>
                        </div>
                        <Input type="text" value={title} onChange={e => setTitle(e.target.value)}/>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend">
                            <InputGroupText>Description</InputGroupText>
                        </div>
                        <Input type="textarea" value={description} onChange={e => setDescription(e.target.value)}/>
                    </InputGroup>
                </Col>
                <FlexCol xs="2">
                    <StyledButton onClick={()=> {updateElement(url)}} color="primary">
                        <FAIconButton icon={["fas", "edit"]}></FAIconButton>
                    </StyledButton>
                    <StyledButton onClick={()=> {DeletePrompt()}} color="danger">
                        <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                    </StyledButton>
                </FlexCol>
            </Row>
        </React.Fragment>
    ) 
}

export default AdminAboutProgram;

const StyledButton = styled(Button)`
    margin-left: 5px;
`;

const FlexCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledProfileImage = styled.img`
    padding: 5px;
    box-shadow: 0 0.5rem 1rem ${Colors.primaryblue};
    max-width: 300px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: -20px;
`