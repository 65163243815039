import React, { useState, useEffect } from "react";
import { Container, Table } from 'reactstrap';
import styled from "styled-components";
import { listenToNestedDocumentProperty } from '../../utils/firestore-functions';
import { teamUnbreakableDB } from "../../utils/firestore";
import { Colors } from "../../utils/constants";

export const StudentHistory = ( props ) => {

    const [history, setHistory] = useState("");

    useEffect(() => {
        let unsubscribe = listenToNestedDocumentProperty(teamUnbreakableDB, "users", props.user.id, "data.activities", setHistory);
        return unsubscribe;
    }, []);

    let alternating = false;

    return (
        <React.Fragment>
            <Container>
                <Table>
                    <tr>
                        <th>Date</th>
                        <th>Distance</th>
                        <th>Time</th>
                        {/*
                            <th>Sleep</th>
                            <th>Food</th>
                            <th>Hydration</th>
                            <th>Route Conditions</th>
                            <th>Comments</th>
                            <th>Post Run Emotions</th>
                            <th>Pre Run Emotions</th>
                        */}
                        <th>Post Run Mood</th>
                        <th>Pre Run Mood</th>
                        <th>Run With Others</th>
                    </tr>
                {history !== undefined && (Object.entries(history).length > 0) && (Object.entries(history).sort((b,a)=>(Date.parse(a[1].date)-Date.parse(b[1].date))).map((activity) => {
                    {alternating = !alternating}
                    return (
                        alternating ? <WhiteTR>
                            <td>{activity[1].date.toString()}</td>
                            <td>{activity[1].distance.toString()} km</td>
                            <td>{activity[1].time.toString()}</td>
                            {/*
                                <td>{activity[1].sleep.toString()}</td>
                                <td>{activity[1].food.toString()}</td>
                                <td>{activity[1].water.toString()}</td>
                                <td>{activity[1].route.toString()}</td>
                                <td>{activity[1].comments.toString()}</td>
                                <td>{activity[1].preRunEmotions.toString()}</td>
                                <td>{activity[1].postRunEmotions.toString()}</td>
                            */}
                            <td>{activity[1].preRunMood.toString()}</td>
                            <td>{activity[1].postRunMood.toString()}</td>
                            <td>{activity[1].ranWithOthers ? activity[1].ranWithOthers.toString() : "N/A"}</td>
                        </WhiteTR> : <BlueTR>
                            <td>{activity[1].date.toString()}</td>
                            <td>{activity[1].distance.toString()} km</td>
                            <td>{activity[1].time.toString()}</td>
                            {/*
                                <td>{activity[1].sleep.toString()}</td>
                                <td>{activity[1].food.toString()}</td>
                                <td>{activity[1].water.toString()}</td>
                                <td>{activity[1].route.toString()}</td>
                                <td>{activity[1].comments.toString()}</td>
                                <td>{activity[1].preRunEmotions.toString()}</td>
                                <td>{activity[1].postRunEmotions.toString()}</td>
                            */}
                            <td>{activity[1].preRunMood.toString()}</td>
                            <td>{activity[1].postRunMood.toString()}</td>
                            <td>{activity[1].ranWithOthers ? activity[1].ranWithOthers.toString() : "N/A"}</td>
                        </BlueTR>
                    );
                }))}
                </Table>
            </Container>
        </React.Fragment>
    );
}

export default StudentHistory;


const WhiteTR = styled.tr`
    background-color: white;
    border-radius: 10px;
`;

const BlueTR = styled.tr`
    background-color: azure;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
`;
