import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import DarkHomePageBackground from "../../images/DarkHomePageBackground.png";
import { InputGroup, Input, Container, Row, Col, Button } from "reactstrap";
import { loginUser } from '../../utils/firestore-functions';
import { auth } from '../../utils/firestore';
import PictureBackground from '../common/picture-background';
import { Colors } from "../../utils/constants";

const LoginWindow = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const attemptLogin = () => {
        var error = false;
        if (email.length <= 3){
            error = true;
            setError("Email length is too short.");
            setMessage("");
        }

        if (password.length <= 3){
            error = true;
            setError("Password length is too short.");
            setMessage("");
        }

        if (!error){
            loginUser(email, password, auth, success, failure);
        }
        else{
            console.log("Did not login, had error: " + error);
        }
    }

    const success = () => {
        window.location=("/Playbook")
    }

    const failure = () => {
        setError("Invalid Login Credentials");
    }

    function handleKeyDown(event){
        if (event.keyCode === 13){
            attemptLogin();
        }
    }

   return (
   <React.Fragment>
    <HeightDiv>
        <PictureBackground id="picture background" url={DarkHomePageBackground} />
    </HeightDiv>
    <OffsetDiv />
    <InteractingContainer delay={"0s"}>
        <Row>
            <Col>
                <Title>Team Unbreakable Login</Title>
            </Col>
        </Row>
        <MarginRow>
            <Col>
                <StyledP>
                    Email Address:
                </StyledP>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <InputGroup>
                    <StyledInput maxLength="50" value={email} onChange={e => setEmail(e.target.value)} />
                </InputGroup>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <StyledP>
                    Password:
                </StyledP>
            </Col>
        </MarginRow>
        <MarginRow>
            <Col>
                <InputGroup>
                    <StyledInput onKeyDown={handleKeyDown} type="password" value={password} onChange={e => setPassword(e.target.value)} />
                </InputGroup>
            </Col>
        </MarginRow>
        {error.length > 0 && <MarginRow>
            <CenterFlexCol>
                <ErrorP>{error}</ErrorP>
            </CenterFlexCol>
        </MarginRow>}
        {message.length > 0 && <MarginRow>
            <CenterFlexCol>
                <MessageP>{message}</MessageP>
            </CenterFlexCol>
        </MarginRow>}
        <MarginRow>
            <CenterFlexCol>
                Not a member yet?
            </CenterFlexCol>
        </MarginRow>
        <MarginRow>
            <CenterFlexCol>
                <AnchorTag href="/register">Register by clicking here</AnchorTag>
            </CenterFlexCol>
        </MarginRow>
        <br />
        <MarginRow>
            <CenterFlexCol>
                <LoginButton onClick={() => {attemptLogin()}} color="primary">Login</LoginButton>
            </CenterFlexCol>
        </MarginRow>
        <br />
        <MarginRow>
            <CenterFlexCol>
                <AnchorTag href="/LostPassword">Forgot password?</AnchorTag>
            </CenterFlexCol>
        </MarginRow>
      </InteractingContainer>
    </React.Fragment>);
}
export default LoginWindow;


const OffsetDiv = styled.div`
    height: 150px;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const FadeAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const InteractingContainer = styled(Container)`
    display: block;
    padding: 30px;
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: ${Colors.primaryblue};
    max-width: fit-content;
    min-width: 400px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    color: white;
    animation: ${FadeAnimation} .5s ${props => props.delay} both;
`;

const StyledInput = styled(Input)`
    width: 100px;
    &:focus {
        box-shadow: 0 0 10px white;
    }
`

const LoginButton = styled(Button)`
    background-color: white;
    padding-left: 40px;
    padding-right: 40px;
    color: ${Colors.primaryblue};
    border: none;
    &:hover {
        color: white;
        background-color: ${Colors.secondarygray};
    }
`

const AnchorTag = styled.a`
    color: white;
`

const StyledP = styled.p`
    margin: 0px;
    color: white;
    font-size: 18px;
`;

const ErrorP = styled.p`
    color: red;
    margin: 0;
`

const MessageP = styled.p`
    color: green;
    margin: 0;
`

const Title = styled.h3`
    color: white;
    text-align: center;
`;

const MarginRow = styled(Row)`
    margin-top: 5px;
`;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;