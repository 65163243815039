import React, { useState } from "react";
import { Container, Row, Col, Button, InputGroup, Input, InputGroupText } from "reactstrap";
import { teamUnbreakableDB } from "../../../utils/firestore";
import { updateDocumentProperty, deleteNestedDocumentProperty, DeleteFromStorage, UploadFileToStorage } from "../../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ImageUploadAndCrop from "../../common/imageUploadAndCrop";
import ModalWindow from "../../common/modalWindow";
import { Colors } from "../../../utils/constants";

export const AdminPerson = (props) => {
    
    const [description, setDescription] = useState(props.description);
    const [name, setName] = useState(props.name);
    const [title, setTitle] = useState(props.title);
    const [id] = useState(props.id);
    const [profileImageUrl, setProfileImageUrl] = useState(props.imageurl);//TODO grab the URL to the files location
    const [order, setOrder] = useState(props.order);
    const [crop] = useState({unit: '%',
    width: 30,
    aspect: 1/1,});
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn, setModalConfirmOn] = useState(true);
    const [modalConfimFunction, setModalConfirmFunction] = useState();
    const [modalCancelText, setModalCancelText] = useState("Cancel");

    const toggle = () => setModal(!modal);

    const storageLocation = `images/teammembers/`;

    const updatePerson = (newUrl) => {
        var person = {
            description: description,
            name: name,
            title: title,
            order: order,
            imageUrl: newUrl,
        }
        updateDocumentProperty(teamUnbreakableDB, "content", "people", "people." + props.id, person, UpdateSuccess, UpdateFailed);
    }

    const UpdateSuccess = () => {
        setModalTitle("Successfully Updated");
        setModalMessage("This person has been updated successfully");
        setModalCancelText("Okay");
        setModalConfirmOn(false);
        toggle();
    }

    const UpdateFailed = () => {
        setModalTitle("Update Failed");
        setModalMessage("Something went wrong when updating");
        setModalCancelText("Okay");
        setModalConfirmOn(false);
        toggle();
    }

    const RetrieveImage = (image, url) => {
        setProfileImageUrl(url); //This is a temporary URL
        doSubmit(image);
    }

    const doSubmit = (imageFile) => {
        //TODO I need to validate this as an image before uploading
        UploadFileToStorage(imageFile, storageLocation, id, SuccessUpload, FailedUpload)
    };
  
    const SuccessUpload = (url) => {
        setProfileImageUrl(url);
        updatePerson(url);
    }

    const FailedUpload = (error) => {
        console.log(error);
    }

    const DeletePrompt = () => {
        setModalTitle("Delete Element?");
        setModalMessage("Are you sure you want to delete this element?");
        setModalCancelText("Cancel");
        setModalConfirmOn(true);
        setModalConfirmFunction(() => deleteElement);
        toggle();
    }

    const deleteElement = () => {
        deleteNestedDocumentProperty(teamUnbreakableDB, "content", "people", "people", props.id, SuccessDelete);
        toggle();
    }

    const SuccessDelete = () =>{
        DeleteFromStorage(storageLocation, props.id, SetNullImageRef);
    }

    const RemoveImagePrompt = () => {
        setModalTitle("Delete Picture");
        setModalMessage("Are you sure you want to delete this picture?");
        setModalCancelText("Cancel");
        setModalConfirmOn(true);
        setModalConfirmFunction(() => RemoveImage);
        toggle();
      }
  
      const RemoveImage = () => {
        setProfileImageUrl("N/A");
        DeleteFromStorage(storageLocation, id, SetNullImageRef);
      }
  
      const SetNullImageRef = () => {
        updatePerson("N/A");
        toggle();
      }

    return(
        <React.Fragment>
            <ModalWindow 
                cancelText={modalCancelText} 
                isOpen={modal} 
                title={modalTitle} 
                message={modalMessage} 
                cancelFunction={toggle} 
                confirmFunction={modalConfimFunction} 
                showConfirmButton={modalConfirmOn} 
            />
            <Row>
                {(!profileImageUrl || profileImageUrl ==="N/A") &&<Col xs="4"><ImageUploadAndCrop maxWidth="400px" submitFunction={RetrieveImage} crop={crop} descriptionText="Upload Profile Image" /></Col>}
                {(profileImageUrl && profileImageUrl !== "N/A") && <Col xs="4"><br />
                    <StyledProfileImage src={profileImageUrl} alt="" />
                    <StyledButton onClick={() => RemoveImagePrompt()} color="danger">
                        <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                    </StyledButton>
                </Col>}
                <Col>
                    <Container fluid={true}>
                    <Row>
                            <Col>
                                <InputGroup>
                                    <div addonType="prepend">
                                        <InputGroupText>Order</InputGroupText>
                                    </div>
                                    <Input type="text" value={order} onChange={e => setOrder(e.target.value)}/>
                                </InputGroup>
                            </Col>
                            <FlexCol xs="2">
                                <StyledButton onClick={()=> {updatePerson(profileImageUrl)}} color="primary">
                                    <FAIconButton  icon={["fas", "edit"]}></FAIconButton>
                                </StyledButton>
                                <StyledButton onClick={()=> {DeletePrompt()}} color="danger">
                                    <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                                </StyledButton>
                            </FlexCol>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <InputGroup>
                                    <div addonType="prepend">
                                        <InputGroupText>Name</InputGroupText>
                                    </div>
                                    <Input type="text" value={name} onChange={e => setName(e.target.value)}/>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <div addonType="prepend">
                                        <InputGroupText>Title</InputGroupText>
                                    </div>
                                    <Input type="text" value={title} onChange={e => setTitle(e.target.value)}/>
                                </InputGroup>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <InputGroup>
                                    <Input type="textarea" value={description} onChange={e => setDescription(e.target.value)}/>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </React.Fragment>
    ) 
}

export default AdminPerson;

const StyledButton = styled(Button)`
    margin-left: 5px;
`;

const FlexCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledProfileImage = styled.img`
    padding: 5px;
    box-shadow: 0 0.5rem 1rem ${Colors.primaryblue};
    max-width: 300px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: -20px;
`