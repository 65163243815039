import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Copy } from "./physical-module-copy";
import { listenToDocument,  updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { ColoredH2, StyledHR, StyledP, CenterTitleFlexCol, WhiteH2, StyledBoldP, ColoredUL } from "../../styles/styledComponents";
import HomeworkImage from "../../images/homework.png";
import WeatherImage from "../../images/weather.png";
import styled from "styled-components";

export const PhysicalModuleSafety = ( props ) => {

    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const completeModule = () => {
        var data = user;
        data.modules.introduction = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy["Module Safety"].Title[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Safety"].SubTitle1[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Safety"].Paragraph1[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Safety"].SubTitle2[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledBoldP>{Copy["Module Safety"].List1Title[props.user.language]}</StyledBoldP>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Safety"].List1Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Safety"].List1Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Safety"].List1Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Safety"].List1Item4[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledBoldP>{Copy["Module Safety"].List2Title[props.user.language]}</StyledBoldP>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Safety"].List2Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Safety"].List2Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Safety"].List2Item3[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <StyledHR />
                <Row>
                    <CenterFlexVerticalCol>
                        <PhysicalActivityIMG src={WeatherImage}></PhysicalActivityIMG>
                    </CenterFlexVerticalCol>
                </Row>
                <StyledHR />
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Safety"].SubTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Safety"].List3Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Safety"].List3Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Safety"].List3Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Safety"].List3Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Safety"].List3Item5[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Safety"].SubTitle4[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy["Module Safety"].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ColoredH2>{Copy["Module Safety"].SubTitle5[props.user.language]}</ColoredH2>
                        <StyledHR></StyledHR>
                        <ColoredUL>
                            <li><StyledP>{Copy["Module Safety"].Paragraph3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy["Module Safety"].Paragraph4[props.user.language]}</StyledP></li>
                        </ColoredUL>
                    </Col>
                    <Col xs="12" md="6">
                        <HomeworkIMG src={HomeworkImage}></HomeworkIMG>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default PhysicalModuleSafety;

const PhysicalActivityIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const HomeworkIMG = styled.img`
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
`;