import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../utils/constants';

const CarouselWeek = (props) => {

   return (<CardStyle>
      <h3><u>Week {props.weekNumber}</u></h3>
      <h5>Workout Structure:</h5><p>{props.workoutStructure}</p>
      <h5>Session 1:</h5><p>{props.session1}</p>
      <h5>Session 2:</h5><p> {props.session2}</p>
      <h5>Session 3:</h5><p>{props.session3}</p>
   </CardStyle>);
}
export default CarouselWeek;

const CardStyle = styled.div`
   background-color: white;
   border-style: solid;
   border-width: 1px;
   border-color: ${Colors.primaryblue};
   border-radius: 10px;
   padding: 15px;
   text-align: center;
`