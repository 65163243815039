export const getInsight = (user) => {
    let insights = [
        totalSleep,
        totalMood,
        totalDistance,
    ];
    let rand = insights[Math.floor(Math.random()*insights.length)]
    return rand(user);
}

const totalSleep = (user) => {
    let insight = {
        prefix: "You have slept:",
        suffix: "Hours",
        icon: "bed",
    };
    let sleep = 0;
    let ctr = 0;
    Object.entries(user.data.activities).map((activity) => {
        sleep += parseFloat(activity[1].sleep);
        ctr++;
    })
    insight["number"] = sleep;
    insight["quip"] = "That's an average of " + Math.round(sleep/ctr * 10) / 10 + " hours per night";
    return insight;
}

const totalMood = (user) => {
    let insight = {
        prefix: "Your average mood post-run is:",
        suffix: "",
        icon: "grin",
    };
    let preRunMood = 0;
    let postRunMood = 0;
    let ctr = 0;
    Object.entries(user.data.activities).map((activity) => {
        preRunMood += parseFloat(activity[1].preRunMood);
        postRunMood += parseFloat(activity[1].postRunMood);
        ctr++;
    })
    insight["number"] = postRunMood/ctr;
    insight["quip"] = "Your mood improves by an average of " + (Math.round(((postRunMood/ctr) - (preRunMood/ctr)) * 10) / 10).toString() + " after a run.";
    return insight;
}

const totalDistance = (user) => {
    let insight = {
        prefix: "You have run a total of:",
        suffix: "KM",
        icon: "running",
    };
    let distance = 0;
    let ctr = 0;
    Object.entries(user.data.activities).map((activity) => {
        distance += parseFloat(activity[1].distance);
        ctr++;
    })
    insight["number"] = distance;
    insight["quip"] = "That's approximately " + Math.round(distance/0.4*10) / 10 + " laps around a track!";
    return insight;
}

const totalTime = (user) => {
    let time = 0;
    let ctr = 0;
    Object.entries(user.data.activities).map((activity) => {
        time += parseFloat(activity[1].time);
        ctr++;
    })
    return ("You have run for a total of " + time + " minutes!");
}

const totalWater = (user) => {
    let water = 0;
    let ctr = 0;
    Object.entries(user.data.activities).map((activity) => {
        water += parseFloat(activity[1].water);
        ctr++;
    })
    return ("You have had " + water + " Litres of water! That's approximately " + water/4.7195 + " small aquariums!");
}
