import React, { useState } from "react";
import styled from 'styled-components';
import SubHeader from '../common/sub-header';
import RegisterStudent from "./register-student";
import RegisterInstructor from "./register-instructor";
import PictureBackground from "../common/picture-background";
import DarkHomePageBackground from "../../images/DarkHomePageBackground.png";

export const Register = () => {
    
    const [content, setContent] = useState("Student");

    return(
        <React.Fragment>
            <HeightDiv>
                <PictureBackground id="picture background" url={DarkHomePageBackground} />
            </HeightDiv>
            <OffsetDiv />
            <SubHeader setContent={setContent} selected={content} options={[{_id: "Student"}, {_id: "Program Leader"}]}></SubHeader>
            <br></br>
            {content==="Student" && <RegisterStudent></RegisterStudent>}
            {content==="Program Leader" && <RegisterInstructor></RegisterInstructor>}
            <br></br>
        </React.Fragment>
    );
}

export default Register;

const OffsetDiv = styled.div`
    height: 150px;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;