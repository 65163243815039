import React, { useState } from "react";
import { Container, Row, Col, NavLink } from "reactstrap";
import styled from 'styled-components';
import DarkHomePageBackground from "../../images/DarkHomePageBackground.png";
import PictureBackground from "./picture-background";
import ReactGA from 'react-ga';
import { teamUnbreakableDB, auth } from "../../utils/firestore";

export const NotVerified = (props) => {
    ReactGA.pageview('/not-verified');
    const [resent, setResent] = useState(false);

    const resendEmailLink = () => {
        setResent(true);
        console.log(auth);
        auth.currentUser.sendEmailVerification();
    }
    
    return(
        <React.Fragment>
            {resent === false &&
            <MinHeightDiv>
                <PictureBackground url={DarkHomePageBackground} id="picture-background">
                </PictureBackground>
                <Shader></Shader>
                {window.location.pathname === "/Verify%20Account" &&
                <OffsetDiv id="offset-div"></OffsetDiv>}
                {window.location.pathname !== "/Verify%20Account" &&
                <OffsetDiv style={{height: "100px"}} id="offset-div"></OffsetDiv>}
                <ContentContainer>
                    <Row>
                        <CenterCol>
                            <StyledP>Your account is unverified. <br/><br/>
                                Please verify your account using the link found in your email.<br/><br/>
                                <StyledNavLink onClick={resendEmailLink}>Click here to resend your verification email.</StyledNavLink></StyledP>
                        </CenterCol>
                    </Row>
                </ContentContainer>
            </MinHeightDiv>}
            {resent === true &&  <MinHeightDiv>
                <PictureBackground url={DarkHomePageBackground}>
                </PictureBackground>
                <Shader></Shader>
                <OffsetDiv></OffsetDiv>
                <ContentContainer>
                    <Row>
                        <CenterCol>
                            <StyledP>Your verification email has been resent. <br/> <br/> 
                            Please click on the email link to verify your account. </StyledP>
                        </CenterCol>
                    </Row>
                </ContentContainer>
            </MinHeightDiv>}
        </React.Fragment>
    );
}

export default NotVerified;

const StyledNavLink = styled(NavLink)`
    font-weight: bold;
    font-style: italic;
    color: white;
    display: inline;
    float: left;
    text-align: left;
    margin-left: 0px;
    padding: 0px;
    &hover: {

    }

`

const MinHeightDiv = styled.div`
    min-height: ${window.innerHeight - 149}px;
`;

const CenterCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-content: center;
`;

const StyledP = styled.p`
    margin: 0px;
    margin-bottom: 5px;
    font-size: 20px;
    color: white;
`;

const ContentContainer = styled(Container)`
    margin-top: 0px;
    background-color: #21B7CC;
    max-width: 400px;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
`;

const OffsetDiv = styled.div`
    height: 180px;
`;

const Shader = styled.div`
    height: 100vh;
    position: fixed;
    z-index: -1;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
`;