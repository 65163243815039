import React from 'react';
import styled from 'styled-components';

export const MinHeightWrapper = (props) => {

    return (
        <MinHeightDiv id="wrapper">
            {props.children}
        </MinHeightDiv>
    );
}

export default MinHeightWrapper;

const MinHeightDiv = styled.div`
    min-height: ${window.innerHeight - 149}px;
`;