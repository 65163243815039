import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import { Colors } from "../../../utils/constants";

const InHouseArticle = ({ info }) => {

   return (<React.Fragment>
       <Container>
           <br/>
           <StyledImage src={info.imageUrl}  alt={info.title}></StyledImage> 
           <StyledTitle>{info.title}</StyledTitle> 
           <StyledDate>{info.date}</StyledDate>
           <StyledParagraph> <br/>
                {info.urlDescription}
            </StyledParagraph>
            <br/>
            {/*
           <Row>
               {info.imageUrl != "N/A" && <Col lg="4">
                    <StyledImage src={info.imageUrl}  alt={info.title}/>
               </Col>}
               <Col>
                    <Container>
                        <Row>
                            <Col>
                                <StyledTitle>{info.title}</StyledTitle>
                            </Col>
                        </Row>
                        <Row>
                            <StyledDate>{info.date}</StyledDate>
                        </Row>
                        <Row>
                        {/*(Object.entries(info.contentSections).length > 0) && Object.entries(info.contentSections).map((section, i) => {
                     return <div key={i}>
                         <ParagraphTitle>{section[1].title}</ParagraphTitle>
                         <StyledParagraph>{section[1].content}</StyledParagraph>
                         </div>
                    })*//*}
                        <StyledParagraph>
                            {info.urlDescription}
                        </StyledParagraph>
                        </Row>
                        <br/>
                    </Container>                 
               </Col>
                </Row>*/}
       </Container>
   </React.Fragment>);
}
export default InHouseArticle;

const StyledImage = styled.img`
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;   
    border: 2px solid ${Colors.secondarybackground};
    border-radius: 5px;
`

const StyledTitle = styled.h1`
    color: ${Colors.primaryblue};
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
`

const ParagraphTitle = styled.h3`
    color: ${Colors.primaryblue};
    font-style: normal;
    margin-top: 10px;
    font-family: Roboto;
`

const StyledParagraph = styled.p`
    color: black;
    font-size: medium;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
`
const StyledDate = styled.p`
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
`