import React, { useEffect, useState } from 'react';
import { Container, Row, Col,  Button, InputGroup, Input } from "reactstrap";
import { listenToDocumentProperty, updateDocumentProperty } from '../../utils/firestore-functions';
import { teamUnbreakableDB } from '../../utils/firestore';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalWindow from '../common/modalWindow';

const AdminSurvey = () => {
    const [surveyUrl, setSurveyUrl] = useState("");
    const [surveyTitle, setSurveyTitle] = useState("");
    const [surveyDescription, setSurveyDescription] = useState("");
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn] = useState(false);
    const [modalCancelText] = useState("Okay");

    const toggle = () => setModal(!modal);

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "survey", "info", setSurveyDetails);
        return unsubscribe;
    }, [])

    const setSurveyDetails = (survey) => {
        setSurveyUrl(survey.surveyUrl);
        setSurveyTitle(survey.surveyTitle);
        setSurveyDescription(survey.surveyDescription);
    }

    const updateSurvey = () => {
        let data = {
            surveyUrl: surveyUrl,
            surveyTitle: surveyTitle,
            surveyDescription: surveyDescription,
        }
        updateDocumentProperty(teamUnbreakableDB, "content", "survey", "info", data, SuccessUpdate);  
    }

    const SuccessUpdate = () => {
        setModalTitle("Updated Survey");
        setModalMessage("Survey has been updated successfully");

        toggle();
    }
    
    return (
        <Container>
            <ModalWindow cancelText={modalCancelText} isOpen={modal} title={modalTitle} message={modalMessage} cancelFunction={toggle} showConfirmButton={modalConfirmOn} />
            <Row>
                <CenterFlexCol>
                    <h1>Survey Details</h1>
                </CenterFlexCol>
            </Row>
            <Row>
                <Col>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"130px"}}>
                            Title
                        </div>
                        <Input type="text" value={surveyTitle} onChange={e => setSurveyTitle(e.target.value)}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"130px"}}>
                            Description
                        </div>
                        <Input type="textarea" value={surveyDescription} onChange={e => setSurveyDescription(e.target.value)}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div addonType="prepend" style={{width:"130px"}}>
                            Survey URL
                        </div>
                        <Input type="text" value={surveyUrl} onChange={e => setSurveyUrl(e.target.value)}></Input>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <FlexCol xs="1">
                    <Button onClick={() => {updateSurvey()}} color="primary">
                        <FAIconButton icon={["fas", "edit"]}></FAIconButton>
                    </Button>
                </FlexCol>
            </Row>
        </Container>
        );
}
 
export default AdminSurvey;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const FlexCol = styled(Col)`
    display: flex;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;