import React, { useState } from "react";
import { InputGroup, Input, Container, Row, Col, Button } from "reactstrap";
import styled from 'styled-components';
import { auth } from "../../utils/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Colors } from "../../utils/constants";

export const ResetPassword = (props) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const resetPassword = () => {
        var error = false;
        if (password.length <= 3){
            error = true;
            setError("Password length is too short.");
            setMessage("");
        }
        if (!error){
            var actionCode = props.location.state.actionCode;
            if(!actionCode){
                console.log("You got here not logged in and not from the redirect")
                return;
            }
            auth.verifyPasswordResetCode(actionCode).then(() => {
                auth.confirmPasswordReset(actionCode, password).then((resp) => {
                    setPasswordReset(true);
                }).catch((error) => {
                    console.log(error);
                })
            }).catch((error) => {
                console.log(error);
            }) 
        }
    }

    const toggleViewPassword = () => {
        setShowPassword(!showPassword);
    }

    return(
        <React.Fragment>
            <MinHeightDiv>
                <GrayBackground />
                <OffsetDiv></OffsetDiv>
                <InteractingContainer>
                    <Row>
                        <Col>
                            <Title>Set New Password</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StyledHR />
                        </Col>
                    </Row>
                    {!passwordReset &&
                    <div><MarginRow>
                        <Col>
                            <StyledP>
                                Set New Password
                            </StyledP>
                        </Col>
                    </MarginRow>
                    <MarginRow>
                        <Col>
                            <InputGroup>
                                <Input 
                                    placeholder="New Password..." 
                                    type={showPassword ? "" : "password"} 
                                    value={password} 
                                    onChange={e => setPassword(e.target.value)} 
                                /><MediaIcon onClick={() => toggleViewPassword()}>
                                {showPassword && <Icon icon={["fas", "eye"]}></Icon>}
                                {!showPassword && <Icon icon={["fas", "eye-slash"]}></Icon>}
                            </MediaIcon>
                            </InputGroup>
                        </Col>
                    </MarginRow>
                    <MarginRow>
                        <Col>
                            <InputGroup>
                                <Input 
                                    placeholder="Please re-enter New Password..." 
                                    type={showPassword ? "" : "password"} 
                                    value={confirmPassword} 
                                    onChange={e => setConfirmPassword(e.target.value)} 
                                />
                            </InputGroup>
                        </Col>
                    </MarginRow></div>}
                    <hr />
                    {passwordReset &&
                    <div><MarginRow>
                        <Col>
                            <StyledP>Your password has been reset.</StyledP>
                        </Col>
                    </MarginRow></div>}
                    {error.length > 0 && <MarginRow>
                        <CenterFlexCol>
                            <ErrorP>{error}</ErrorP>
                        </CenterFlexCol>
                    </MarginRow>}
                    {message.length > 0 && <MarginRow>
                        <CenterFlexCol>
                            <MessageP>{message}</MessageP>
                        </CenterFlexCol>
                    </MarginRow>}
                    {!passwordReset && <MarginRow>
                        <CenterFlexCol>
                            <StyledButton onClick={() => {resetPassword()}} color="primary">Update Password</StyledButton>
                        </CenterFlexCol>
                    </MarginRow>}
                </InteractingContainer>
                <br></br>
            </MinHeightDiv>
        </React.Fragment>
    );
}

export default ResetPassword;

const StyledButton = styled(Button)`
    background-color: white;
    padding-left: 40px;
    padding-right: 40px;
    color: ${Colors.primaryblue};
    border: none;
    &:hover {
        color: white;
        background-color: ${Colors.secondarygray};
    }
`

const MinHeightDiv = styled.div`
    min-height: ${window.innerHeight - 149}px;
`;

const StyledP = styled.p`
    margin: 0px;
    color: white;
    font-size: 18px;
`;

const StyledHR = styled.hr`
    margin-top: 0px;
    background-color: white;
`;

const OffsetDiv = styled.div`
    height: 180px;
`;

const ErrorP = styled.p`
    color: red;
    margin: 0;
`

const MessageP = styled.p`
    color: green;
    margin: 0;
`

const Title = styled.h3`
    color: white;
    text-align: center;
`;

const MarginRow = styled(Row)`
    margin-top: 5px;
`;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const InteractingContainer = styled(Container)`
    display: block;
    padding: 30px;
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: ${Colors.primaryblue};
    max-width: fit-content;
    min-width: 400px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    color: white;
`;

const GrayBackground = styled.div`
    width: 100%;
    height: 100%;
    z-index: -1000;
    background-color: ${Colors.primarybackground};
    background-size: cover;
`

const Icon = styled(FontAwesomeIcon)`
width: 15px;
height: 15px;
`

const MediaIcon = styled.div`
    padding-left: 10px;
    padding-top: 5px;
    text-align: center;
    display: inline-block;
    transform: perspective(1px) translateZ(0);
    position: relative;
    transition: color 0.3s;
    color: black;
    &:hover {
        color: white;
        cursor: pointer;
    }
    &:before {
        border-radius: 6px;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: ${Colors.primaryblue};
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: transform 0.3s ease-out;
    }
    &:hover:before{
        transform: scaleX(1);
    }
`