import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Copy } from "./physical-module-copy";
import { listenToDocument, updateUser } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import { ColoredH2, StyledHR, ColoredOL, ColoredUL, StyledP, CenterTitleFlexCol, WhiteH2, StyledBoldP } from "../../styles/styledComponents";
import styled from "styled-components";

export const PhysicalModuleEquipment = ( props ) => {

    const [user, setUser] = useState();
    const module = "Finding the Right Equipment";

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const completeModule = () => {
        var data = user;
        data.modules.introduction = true;
        updateUser(teamUnbreakableDB, user.id, data);
    };

    return(
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <CenterTitleFlexCol>
                        <WhiteH2>{Copy[module].Title[props.user.language]}</WhiteH2>
                    </CenterTitleFlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        <StyledP>{Copy[module].Paragraph1[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy[module].SubTitle1[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li>
                                <StyledBoldP>{Copy[module].List1Item1Title[props.user.language]}</StyledBoldP>
                                <StyledP> {Copy[module].List1Item1Content[props.user.language]}</StyledP>
                            </li>
                            <li>
                                <StyledBoldP>{Copy[module].List2Item1Title[props.user.language]}</StyledBoldP>
                                <StyledP> {Copy[module].List2Item1Content[props.user.language]}</StyledP>
                            </li>
                            <li>
                                <StyledBoldP>{Copy[module].List3Item1Title[props.user.language]}</StyledBoldP>
                                <StyledP> {Copy[module].List3Item1Content[props.user.language]}</StyledP>
                            </li>
                            <li>
                                <StyledBoldP>{Copy[module].List4Item1Title[props.user.language]}</StyledBoldP>
                                <StyledP> {Copy[module].List4Item1Content[props.user.language]}</StyledP>
                            </li>
                            <li>
                                <StyledBoldP>{Copy[module].List5Item1Title[props.user.language]}</StyledBoldP>
                                <StyledP> {Copy[module].List5Item1Content[props.user.language]}</StyledP>
                            </li>
                            <li>
                                <StyledBoldP>{Copy[module].List6Item1Title[props.user.language]}</StyledBoldP>
                                <StyledP> {Copy[module].List6Item1Content[props.user.language]}</StyledP>
                            </li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy[module].SubTitle2[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li><StyledP>{Copy[module].List7Item1[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List7Item2[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List7Item3[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List7Item4[props.user.language]}</StyledP></li>
                            <li><StyledP>{Copy[module].List7Item5[props.user.language]}</StyledP></li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy[module].SubTitle3[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].Paragraph2[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredOL>
                            <li>
                                <StyledBoldP>{Copy[module].List8Item1Title[props.user.language]}</StyledBoldP>
                                <StyledP> {Copy[module].List8Item1Content[props.user.language]}</StyledP>
                                <StyledP> {Copy[module].List8Item1Content2[props.user.language]}</StyledP>
                            </li>
                            <li>
                                <StyledBoldP>{Copy[module].List9Item1Title[props.user.language]}</StyledBoldP>
                                <StyledP> {Copy[module].List9Item1Content[props.user.language]}</StyledP>
                                <StyledP> {Copy[module].List9Item1Content2[props.user.language]}</StyledP>
                            </li>
                            <li>
                                <StyledBoldP>{Copy[module].List10Item1Title[props.user.language]}</StyledBoldP>
                                <StyledP> {Copy[module].List10Item1Content[props.user.language]}</StyledP>
                                <StyledP> {Copy[module].List10Item1Content2[props.user.language]}</StyledP>
                            </li>
                        </ColoredOL>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy[module].SubTitle4[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].Paragraph3[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColoredH2>{Copy[module].SubTitle5[props.user.language]}</ColoredH2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledHR></StyledHR>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledP>{Copy[module].Paragraph4[props.user.language]}</StyledP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledURL href={Copy[module].URL1[props.user.language]}>Shoe Quiz</StyledURL>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default PhysicalModuleEquipment;

const PhysicalActivityIMG = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const HomeworkIMG = styled.img`
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
`;

const StyledURL = styled.a`
`;