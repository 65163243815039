import React, { useState } from 'react';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import styled from 'styled-components';
import { generateGUID, teamUnbreakableDB } from '../../../utils/firestore';
import { updateDocumentProperty } from '../../../utils/firestore-functions';
import ModalWindow from '../../common/modalWindow';
import firebase from "firebase/app";
import emailjs, { init } from 'emailjs-com';
import { Colors } from "../../../utils/constants";

const LandAcknowledgement = () => {
    
    init("user_8Qt3ZJv9dlnGhiJefkhBh");
    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn, setModalConfirmOn] = useState(true);
    const [modalConfimFunction, setModalConfirmFunction] = useState();

    const toggle = () => setModal(!modal);
    const [copyAlertTim, setCopyAlertTim] = useState(false);
    const [copyAlertNicole, setCopyAlertNicole] = useState(false);

    const copyEmail = (person) => {
        if (person === "Tim") {
            navigator.clipboard.writeText("tmcfadden@teamunbreakable.ca");
            setCopyAlertTim(true);

            let timer = setTimeout(() => { 
                setCopyAlertTim(false);
            }, 3000);
            return () => {clearTimeout(timer)};
        } else if (person === "Nicole") {
            navigator.clipboard.writeText("nicolek@teamunbreakable.ca");
            setCopyAlertNicole(true);

            let timer = setTimeout(() => { 
                setCopyAlertNicole(false);
            }, 3000);
            return () => {clearTimeout(timer)};
        }
    }

    const SendMessage = () => {
        let id = generateGUID();
        let data = {
            name: name,
            email: email,
            phone: phone,
            message: message,
            resolved: false,
            timeStamp: firebase.firestore.Timestamp.fromDate(new Date()),
        }
        updateDocumentProperty(teamUnbreakableDB, "admin", "tickets", "contactus." + id, data, SuccessSend, FailedSend);
    }

    const SuccessSend = () => {
        emailjs.send("service_xfajc29", "template_aerd9av", {
            name: name,
            email: email,
            message: message,
        });
        setModalTitle("Message Sent");
        setModalMessage("Your message has been sent");
        setModalConfirmOn(false);
        setModal(true);
        ClearInputs();
    }

    const ClearInputs = () => {
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
    }

    const FailedSend = (error) => {
        setModalTitle("Message was not sent");
        setModalMessage("Something went wrong and your message failed to send");
        setModalConfirmOn(false);
        setModal(true);
    }

    return (<React.Fragment>
        <ModalWindow cancelText="OK" isOpen={modal} title={modalTitle} message={modalMessage} cancelFunction={toggle} confirmFunction={modalConfimFunction} showConfirmButton={modalConfirmOn} />
        <MainDiv>
            <Container>
                <br />
                <Row>
                    <Col>
                        <StyledHeaderTitle>Land Acknowledgement</StyledHeaderTitle><br />
                    </Col>
                </Row>
                <ContentContainer>
                    <Row>
                        <Col>
                            In acknowledging the legacy of the Truth and Reconciliation Commission of Canada (TRC) and its 94 Calls to Action, Team Unbreakable commits to continuing to advance reconciliation through sport. Focusing on health and inclusivity, we align with specific Calls to Action, promoting the physical and mental health benefits of sports and play activities (Call to Action 19), reducing barriers to sports participation (Call to Action 89), and establishing culturally relevant programs and initiatives (Call to Action 90).
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            In recognizing the unique perspective of Indigenous wellness, Team Unbreakable understands the importance of balance and harmony, connecting to family, community, and nature. We acknowledge that our work spans the 46 Treaties and agreements covering the land now called Ontario, expressing gratitude to the First Nations, Métis, and Inuit Peoples for their ongoing stewardship.
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            Our primary office in Mississauga is situated within the Treaty and Traditional Territory of the Mississaugas of the Credit First Nation, The Haudenosaunee Confederacy, the Huron-Wendat, and Wyandot Nations. We honour these peoples and their ancestors as the original inhabitants of these lands. Recognizing running as a traditional Indigenous sport, Team Unbreakable embraces its significance. As running takes centre stage in our physical activities, we invite reflection on the lands we inhabit, the sports we engage in, and encourage learning about their cultural histories. We are honoured to bring physical activities to communities across these territories, fostering unity and understanding through the rich roots and history of Indigenous sports.
                        </Col>
                    </Row>
                </ContentContainer>
            </Container>
        </MainDiv>
    </React.Fragment> );
}
 
export default LandAcknowledgement;

const NoPaddingCol = styled(Col)`
    padding-left: 5px;
    padding-right: 5px;
`
const StyledAlert = styled(Container)`
    color: ${Colors.secondarybackground};
    font-size:16px;
    font-family: Roboto;
    font-weight: normal;
    padding: 0px;
`


const EmailButton = styled.div`
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    font-family: Roboto;
    padding-left: 0px;
    padding-right: 0px;
    color: ${Colors.primaryblue};
    border: none;
    &:hover {
        color: black;
        padding-left: 0px;
        background: none;
        cursor: pointer;
    }
    &:focus {
        color: black;
        border: none;
        border-weight: 0px;
        padding-left: 0px;
        background: none;
        cursor: pointer;
    }
`

const ReachOutParagraph = styled.p`
    font-weight: bold;
    font-size: 20px;
`
const ContactParagraph = styled.p`
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0px;
`

const OffsetDiv = styled.div`
`;

const StyledInput = styled(Input)`
    width: 100%;
    margin-bottom: 25px;
    height: 50px;
    &:focus {
        box-shadow: 0 0 10px white;
}
`

const ContentContainer = styled(Container)`
    max-width: 1200px;
    margin-bottom: 2%;
    background-color: white;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
    padding: 20px;
`;


const StyledButton = styled(Button)`
    text-align: center;
    font-family: Roboto;
    width: 150px;
    background: ${Colors.primaryblue};
    border-radius: 5px;
    color: white;
    border: none;
    padding: 15px;
    &:hover {
        color: white;
        background-color: ${Colors.secondarygray};
        cursor: pointer;
    }
    margin-bottom: 50px;
`

const MainDiv = styled.div`
    background-color: ${Colors.primarybackground};
`

const StyledHeaderTitle = styled.h1`
    color: ${Colors.primaryblue};
    font-family: Roboto;
`