import React from 'react';
import YoutubeVideo from './youtube-video';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import { Colors } from "../../utils/constants";

const VideoModal = ({isOpen, title, videoId, cancelFunction, cancelText = "Close"}) => {
    return (<StyledModal isOpen={isOpen}>
        <ModalHeader>{title}</ModalHeader>
        <StyledModalBody>
            <YoutubeVideo embedId={videoId} />
        </StyledModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={cancelFunction}>{cancelText}</Button>
        </ModalFooter>
    </StyledModal> );
}
 
export default VideoModal;

const StyledModal = styled(Modal)`
    top: 5%;
    width: fit-content;
    max-width: 100%;
    border-style: solid;
    border-width: 3px;
    border-color: ${Colors.primaryblue};
`

const StyledModalBody = styled(ModalBody)`
    width: 1000px;
    max-width: 100%;
`