import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Navbar, Collapse } from 'reactstrap';
import classnames from 'classnames';
import MinHeightWrapper from "../common/min-height-wrapper";
import { getInsight } from "../../utils/insights";
import { getChallenge } from "../../utils/challenges";
import { Colors } from "../../utils/constants";
import { StudentActivity } from './student-activity';
import { StudentHistory } from './student-history';
import StudentProfile from "./student-profile";
import StudentChallenges from "./student-challenges";
import { listenToDocumentProperty, listenToDocument } from "../../utils/firestore-functions";
import { teamUnbreakableDB } from "../../utils/firestore";
import Success from "../../images/success.png";
import Modules from "../../components/modules/modules";
import PhysicalModules from '../physical-modules/physical-modules';
import { BoldP } from "../../styles/styledComponents";

export const Playbook = ( props ) => {
    let BodyStyle = createGlobalStyle`
    body {
      background-color: "F8F8F8";
    }
  `

    const [insight, setInsight] = useState("");
    const [currentChallenge, setCurrentChallenge] = useState();
    
    const [activeTab, setActiveTab] = useState('1');
    const [user, setUser] = useState();

    const [survey, setSurvey] = useState("");

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "content", "survey", setSurvey);
        return unsubscribe;
    }, []);

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    useEffect(() => {
        let unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "users", props.user.id, "data.currentChallenge", setCurrentChallenge);
        return unsubscribe;
    }, []);

    useEffect(() => {
        setInsight(getInsight(props.user));
    }, [props.user])

    return(
        <MinHeightWrapper>
            <BodyStyle></BodyStyle>
            <GrayBackground></GrayBackground>
            <OffsetDiv></OffsetDiv>
            <NarrowContainer>
                <Row>
                    <CenterFlexCol>
                        <ColoredH1>Welcome {props.user.firstName}</ColoredH1>
                    </CenterFlexCol>
                </Row>
                <Row>
                    <CenterFlexCol>
                        <StyledIMG src={Success}></StyledIMG>
                    </CenterFlexCol>
                </Row>
                {user !== undefined &&<Row>
                    <ColoredCol>
                        <WhiteP>You have earned:</WhiteP>
                        <FlexDiv>
                            <FAIconButton size="2x" icon={["fas", "star"]}></FAIconButton><WhiteH3>{user.data.points}</WhiteH3>
                        </FlexDiv>
                        <StyledBoldP>Unbreakable Points</StyledBoldP>
                        <StyledP>Complete Challenges to earn more points</StyledP>
                    </ColoredCol>
                    <NarrowCol></NarrowCol>
                    {insight !== undefined && <ColoredCol>
                        <WhiteP>{insight.prefix}</WhiteP>
                        <FlexDiv>
                            <FAIconButton size="2x" icon={["fas", insight.icon]}></FAIconButton><WhiteH3>{Math.round(insight.number*10)/10}</WhiteH3>
                        </FlexDiv>
                        <StyledBoldP>{insight.suffix}</StyledBoldP>
                        <StyledP>{insight.quip}</StyledP>
                    </ColoredCol>}
                </Row>}
            </NarrowContainer>
            <NarrowContainer>
                {user !== undefined && <BorderRow>
                    <CenterFlexVerticalCol>
                        <ColoredBoldP>Current Challenge:</ColoredBoldP>
                        <FlexDiv>
                            <CircularChart viewBox="0 0 36 36">
                                <CircleBackground
                                    stroke-dasharray="100, 100"
                                    d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                                <Circle
                                    fillPercentage={getChallenge(user.data.currentChallenge.challengeName).getCurrent(user) / getChallenge(user.data.currentChallenge.challengeName).goals[getChallenge(user.data.currentChallenge.challengeName).getLevel(user)] * 100}
                                    d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                            </CircularChart>
                            <BlackH3>{getChallenge(user.data.currentChallenge.challengeName).getCurrent(user) + " / " + getChallenge(user.data.currentChallenge.challengeName).goals[getChallenge(user.data.currentChallenge.challengeName).getLevel(user)]}</BlackH3>
                        </FlexDiv>
                        <StyledBoldP>{getChallenge(user.data.currentChallenge.challengeName).toString()}</StyledBoldP>           
                    </CenterFlexVerticalCol>
                    <NarrowCol>
                        <ThinVerticalDiv></ThinVerticalDiv>
                    </NarrowCol>
                    <CenterFlexVerticalCol>
                        <p>{getChallenge(user.data.currentChallenge.challengeName).description()}</p>
                    </CenterFlexVerticalCol>
                </BorderRow>}
                <Row>
                    <Col>
                        <div></div>
                    </Col>
                </Row>
            </NarrowContainer>
            <br />
            {false && survey !== "" && <NarrowContainer>
                <StyledWhiteRow>
                    <CenterFlexVerticalCol>
                        <BoldP>{survey.info.surveyTitle}</BoldP>
                        <NoMarginP>{survey.info.surveyDescription}</NoMarginP>
                        <StyledLink href={survey.info.surveyUrl}>{survey.info.surveyUrl}</StyledLink>
                    </CenterFlexVerticalCol>
                </StyledWhiteRow>
            </NarrowContainer>}
            <NavContainer fluid={true}>
                <Row>
                    <RightFlexCol>
                        <Navbar expand="md">
                            <Collapse isOpen={true} navbar>
                                <StyledNav className="mr-auto" navbar>
                                    <StyledNavItem>
                                        <StyledNavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                            Track Activity
                                        </StyledNavLink>
                                    </StyledNavItem>
                                    <StyledNavItem>
                                        <StyledNavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                            Challenges
                                        </StyledNavLink>
                                    </StyledNavItem>
                                    <StyledNavItem>
                                        <StyledNavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                                            History
                                        </StyledNavLink>
                                    </StyledNavItem>
                                    <StyledNavItem>
                                        <StyledNavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                                            Profile EN/FR
                                        </StyledNavLink>
                                    </StyledNavItem>
                                </StyledNav>
                            </Collapse>
                        </Navbar>
                    </RightFlexCol>
                    <LeftFlexCol>
                        <Navbar expand="md">
                            <Collapse isOpen={true} navbar>
                                <StyledNav className="mr-auto" navbar>
                                    <StyledNavItem>
                                            <StyledNavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }}>
                                                Mental Modules
                                            </StyledNavLink>
                                        </StyledNavItem>
                                        <StyledNavItem>
                                            <StyledNavLink className={classnames({ active: activeTab === '6' })} onClick={() => { toggle('6'); }}>
                                                Physical Modules
                                            </StyledNavLink>
                                        </StyledNavItem>
                                        {/*}
                                        <StyledNavItem>
                                            <StyledNavLink className={classnames({ active: activeTab === '7' })} onClick={() => { toggle('7'); }}>
                                                Worksheets
                                            </StyledNavLink>
                                        </StyledNavItem>
                                        */}
                                </StyledNav>
                            </Collapse>
                        </Navbar>
                    </LeftFlexCol>
                </Row>
            </NavContainer>    
            <ContentContainer>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <StudentActivity user={props.user}></StudentActivity>
                    </TabPane>
                    <TabPane tabId="2">
                        <StudentChallenges user={props.user}></StudentChallenges>
                    </TabPane>
                    <TabPane tabId="3">
                        <StudentHistory user={props.user}></StudentHistory>
                    </TabPane>
                    <TabPane tabId="4">
                        <StudentProfile user={props.user}></StudentProfile>
                    </TabPane>
                    <TabPane tabId="5">
                        <Modules user={props.user}></Modules>
                    </TabPane>
                    <TabPane tabId="6">
                        <PhysicalModules user={props.user}></PhysicalModules>
                    </TabPane>
                </TabContent>
            </ContentContainer>
        </MinHeightWrapper>
    );
}

export default Playbook;

const StyledWhiteRow = styled(Row)`
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.primaryblue};
    background-color: white;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const StyledNav = styled(Nav)`
    border-style: none;
`;

const StyledNavLink = styled(NavLink)`
&& {
    color: black;
    border-style: none;
    &&.active {
        color: ${Colors.primaryblue};
        font-weight: bold;
        border-style: none;
    }
    &:hover {
        border-style: none;
        color: ${Colors.primaryblue};
        cursor: pointer;
    }
}
`;

const StyledNavItem = styled(NavItem)`
    color: black;
`;

const NavContainer = styled(Container)`
    background-color: white;
    color: black;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
`;

const progress = (fillPercentage) => keyframes`
    0% {
        stroke-dasharray: 0, 100;
    }
    100%{
        stroke-dasharray: ${fillPercentage}, 100;
    }
`;

const CircularChart = styled.svg`
    display: block;
    margin: 0px;;
    max-width: 80%;
    max-height: 50px;
`;

const Circle = styled.path`
    stroke: #4CC790;
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
    animation: ${props => progress(props.fillPercentage)} ease-out forwards;
    animation-duration: 4s;
`;

const CircleBackground = styled.path`
    stroke: ${Colors.secondarygray};
    fill: none;
    stroke-width: 3.6;
    stroke-linecap: round;
    z-index: -1;
`;

const NarrowCol = styled(Col)`
    max-width: 20px;
`;

const NarrowContainer = styled(Container)`
    max-width: 700px;
`;

const BlackH3 = styled.h3`
    color: black;
    margin-bottom: 0px;
    margin-left: 10px;
`;

const ColoredBoldP = styled.p`
    color: ${Colors.primaryblue};
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 18px;
`;

const ColoredP = styled.p`
    color: ${Colors.primaryblue};
    margin-bottom: 0px;
`;

const ThinVerticalDiv = styled.div`
    background-color: black;
    width: 1px;
    height: 80%;
    margin-top: 10px;
`;

const BorderRow = styled(Row)`
    margin-top: 25px;
    border-style: solid;
    border-color: black;
    border-width: 1px;
`;

const StyledIMG = styled.img`
    margin-bottom: 20px;
    width: 100%;
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

const StyledP = styled.p`
    margin-bottom: 15px;
    text-align: center;
`;

const NoMarginP = styled.p`
    margin: 0px;
    text-align: center;
`;

const StyledBoldP = styled.p`
    margin-bottom: 0px;
    font-weight: bold;
`;

const WhiteP = styled.p`
    color: white;
    margin-bottom: 0px;
    margin-top: 15px;
`;

const WhiteH3 = styled.h3`
    color: white;
    margin-bottom: 0px;
`;

const ColoredCol = styled(Col)`
    background-color: ${Colors.primaryblue};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ColoredH1 = styled.h1`
    color: ${Colors.primaryblue};
`;

const GrayBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${Colors.primarybackground};
    background-size: cover;
    position: fixed;
    top: 0;
    z-index: -1;
`

const RightFlexCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
    padding: 0px;
`;

const LeftFlexCol = styled(Col)`
    display: flex;
    justify-content: flex-start;
    padding: 0px;
`;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: Center;
`;

const CenterFlexVerticalCol = styled(Col)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 15px 12px;
`;

const ContentContainer = styled(Container)`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const OffsetDiv = styled.div`
    height: 180px;
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
    color: white;
`;

const StyledLink = styled.a`
    color: ${Colors.primaryblue};
`