import React from 'react';
import styled from 'styled-components';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { Colors } from "../../../utils/constants";

const HomeCarousel = (props) => {
    const responsive = {
        superLargeDesktop: {

          breakpoint: { max: 4000, min: 1400 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 1400, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    const CustomDot = ({
      index,
      onClick,
      active
    }) => {
      return (<div>
        <BlueButton
          onClick={e => {
            onClick();
            e.preventDefault();
          }}
          active={active}
        >
        </BlueButton>
        </div>
      );
    };

   return (<React.Fragment>
        <Carousel 
            responsive={responsive} 
            infinite={true}
            swipeable={true}
            draggable={true}
            showDots={true}
            autoPlaySpeed={2000}
            autoPlay={true}
            arrows={false}
            customDot={<CustomDot/>}
            
        >
            {props.items.map((donor, i) => (
                <CarouselItem key={i}>
                    {donor.imageUrl && <a href={donor.url} target="_blank">
                        <DonorImage src={donor.imageUrl} />
                    </a>}
                </CarouselItem>
            ))} 
        </Carousel>
        <SpacerDiv/>
   </React.Fragment>)
}
export default HomeCarousel;

const SpacerDiv = styled.div`
  height: 20px;
`

const CarouselItem = styled.div`
padding-left: 10px;
padding-right: 10px;    
&:hover {
        cursor: pointer;
}
`
const DonorImage = styled.img`
    width: 100%;
`

const BlueButton = styled.button`
  margin-right: 2px;
  margin-left: 2px; 
  background: ${props => props.active ? Colors.primaryAccent: "white"};
  border-color: ${Colors.primaryblue};
  border-style: solid;
  border-width: 2px;
  height: 16px;
  width: 15px;
  border-radius: 50%;
`
