import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { Colors } from "../../../utils/constants";

const SlideDecks = (props) => {
    const colors = [ "white" , Colors.primaryblue, "white", Colors.secondarygray, ];
    const textColors = [Colors.secondarygray, "white", Colors.secondarygray, "white", ];
    const titleColors = [Colors.primaryblue, "white", Colors.primaryblue, "white"];
    const [isLoggedIn, setIsLoggedIn] = useState(props.user.instructor);

    const iframeStyle = {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0, 
        border: "none",
        padding: 0,
        margin: 0
    }

   return (
   <React.Fragment>
       <div>
       <Container>
            <br />
            <Row>
                <Col>
                    <StyledHeaderTitle>Slide Decks</StyledHeaderTitle><br />
                </Col>
            </Row>
       <ContentContainer>
       <BackgroundContainer>
           <Row>
                <Col>
                    <SecondaryTitle>Week 1</SecondaryTitle>
                    <CanvaDiv>
                        <iframe title="test" loading="lazy" style={iframeStyle}
                            src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFYfRaLj10&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
                        </iframe>
                    </CanvaDiv>
                    <a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFYfRaLj10&#x2F;view?utm_content=DAFYfRaLj10&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener noreferrer">
                        Week 1: Physical 4 Mental Health &amp; Safety</a> by Nicole Kuzmich
                </Col>
            </Row>
       </BackgroundContainer>
       <BackgroundContainer>
           <Row>
                <Col style={{position:'relative'}}>
                    {!isLoggedIn && (
                        <Overlay >
                            <Message>
                                Register for free to access this content.
                            </Message>
                        </Overlay>
                    )}
                    <SecondaryTitle>Week 2</SecondaryTitle>
                    <CanvaDiv>
                        {isLoggedIn && <iframe title="test" loading="lazy" style={iframeStyle}
                            src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFYg9aGOZo&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
                        </iframe>}
                    </CanvaDiv>
                    {isLoggedIn && <div>
                        <a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFYg9aGOZo&#x2F;view?utm_content=DAFYg9aGOZo&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener noreferrer">
                        Week 2: Goal Setting &amp; Running Mechanics</a> by Nicole Kuzmich
                    </div>}
                </Col>
            </Row>
       </BackgroundContainer>
       <BackgroundContainer>
           <Row>
                <Col style={{position:'relative'}}>
                    {!isLoggedIn && (
                        <Overlay >
                            <Message>
                                Register for free to access this content.
                            </Message>
                        </Overlay>
                    )}
                    <SecondaryTitle>Week 3</SecondaryTitle>
                    <CanvaDiv>
                        {isLoggedIn && <iframe title="test" loading="lazy" style={iframeStyle}
                            src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFYlpD3rvI&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
                        </iframe>}
                    </CanvaDiv>
                    {isLoggedIn && <div>
                    <a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFYlpD3rvI&#x2F;view?utm_content=DAFYlpD3rvI&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener noreferrer">
                        Week 3: Motivation &amp; Running Drills</a> by Nicole Kuzmich
                    </div>}
                </Col>
            </Row>
       </BackgroundContainer>
       <BackgroundContainer>
           <Row>
                <Col style={{position:'relative'}}>
                    {!isLoggedIn && (
                        <Overlay >
                            <Message>
                                Register for free to access this content.
                            </Message>
                        </Overlay>
                    )}
                    <SecondaryTitle>Week 4</SecondaryTitle>
                    <CanvaDiv>
                        {isLoggedIn && <iframe title="test" loading="lazy" style={iframeStyle}
                            src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFYraws6Os&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
                        </iframe>}
                    </CanvaDiv>
                    {isLoggedIn && <div>
                    <a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFYraws6Os&#x2F;view?utm_content=DAFYraws6Os&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener noreferrer">
                        Week 4: Psychological Skills &amp; Nutrition and Hydration</a> by Nicole Kuzmich
                    </div>}
                </Col>
            </Row>
       </BackgroundContainer>
       <BackgroundContainer>
           <Row>
                <Col style={{position:'relative'}}>
                    {!isLoggedIn && (
                        <Overlay >
                            <Message>
                                Register for free to access this content.
                            </Message>
                        </Overlay>
                    )}
                    <SecondaryTitle>Week 5</SecondaryTitle>
                    <CanvaDiv>
                        {isLoggedIn && <iframe title="test" loading="lazy" style={iframeStyle}
                            src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFZReLOMQs&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
                        </iframe>}
                    </CanvaDiv>
                    {isLoggedIn && <div>
                    <a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFZReLOMQs&#x2F;view?utm_content=DAFZReLOMQs&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener noreferrer">
                        Week 5: Social Benefits &amp; Managing Setbacks</a> by Nicole Kuzmich
                    </div>}
                </Col>
            </Row>
       </BackgroundContainer>
       <BackgroundContainer>
           <Row>
                <Col style={{position:'relative'}}>
                    {!isLoggedIn && (
                        <Overlay >
                            <Message>
                                Register for free to access this content.
                            </Message>
                        </Overlay>
                    )}
                    <SecondaryTitle>Week 6</SecondaryTitle>
                    <CanvaDiv>
                        {isLoggedIn && <iframe title="test" loading="lazy" style={iframeStyle}
                            src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFZW8AXP3U&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
                        </iframe>}
                    </CanvaDiv>
                    {isLoggedIn && <div>
                    <a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFZW8AXP3U&#x2F;view?utm_content=DAFZW8AXP3U&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener noreferrer">
                        Week 6: Body Image &amp; Hills</a> by Nicole Kuzmich
                    </div>}
                </Col>
            </Row>
       </BackgroundContainer>
       <BackgroundContainer>
           <Row>
                <Col style={{position:'relative'}}>
                    {!isLoggedIn && (
                        <Overlay >
                            <Message>
                                Register for free to access this content.
                            </Message>
                        </Overlay>
                    )}
                    <SecondaryTitle>Week 7</SecondaryTitle>
                    <CanvaDiv>
                        {isLoggedIn && <iframe title="test" loading="lazy" style={iframeStyle}
                            src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFZX9OjSNo&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
                        </iframe>}
                    </CanvaDiv>
                    {isLoggedIn && <div>
                    <a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFZX9OjSNo&#x2F;view?utm_content=DAFZX9OjSNo&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener noreferrer">
                        Week 7: Self-Compassion & Alternative Cardiovascular Workouts</a> by Nicole Kuzmich
                    </div>}
                </Col>
            </Row>
       </BackgroundContainer>
       <BackgroundContainer>
           <Row>
                <Col style={{position:'relative'}}>
                    {!isLoggedIn && (
                        <Overlay >
                            <Message>
                                Register for free to access this content.
                            </Message>
                        </Overlay>
                    )}
                    <SecondaryTitle>Week 8</SecondaryTitle>
                    <CanvaDiv>
                        {isLoggedIn && <iframe title="test" loading="lazy" style={iframeStyle}
                            src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFZh2a43Xo&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
                        </iframe>}
                    </CanvaDiv>
                    {isLoggedIn && <div>
                    <a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFZh2a43Xo&#x2F;view?utm_content=DAFZh2a43Xo&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener noreferrer">
                        Week 8: Creating a Balanced Life & Finding the Right Equipment</a> by Nicole Kuzmich
                    </div>}
                </Col>
            </Row>
       </BackgroundContainer>
       </ContentContainer>
       </Container>
       </div>
   </React.Fragment>);
}
export default SlideDecks;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
`;

const Message = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

const CanvaDiv = styled.div`
    position: relative; 
    width: 100%; 
    height: 0; 
    padding-top: 56.2500%;
    padding-bottom: 0; 
    box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); 
    margin-top: 0.9em; 
    margin-bottom: 0.9em; 
    overflow: hidden;
    border-radius: 8px; 
    will-change: transform;
`;

const ContentContainer = styled(Container)`
    max-width: 1200px;
    margin-bottom: 2%;

`;

const StyledHeaderTitle = styled.h1`
    color: ${Colors.primaryblue};
    font-family: Roboto;
`

const BackgroundContainer = styled(Container)`
    background-color: white;
    padding-bottom: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
`;

const ListItem = styled.li`
    color: black;
    font-size: medium;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
`

const ColorBox = styled.div`
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.secondarybackground};
    background-color: ${props => props.backcolor};
    margin-left: 0px;
    padding: 20px;
    min-height: ${props => props.height ? props.height : ""}
`

const SecondaryTitle = styled.h3`
    font-weight: normal;
    color: ${Colors.primaryblue};
    font-family: Roboto;
    font-style: normal;
    justify-content: left;
    margin-top: 0.4em;
`

const StyledLink = styled.a`
    color: ${Colors.primaryblue};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

const StyledBoxLink = styled.a`
    color: white;
    font-size: 16px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    display: block;
`

const StyledParagraph = styled.p`
    color: black;
    font-size: medium;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
`

const NoMarginContainer = styled(Container)`
    padding: 0px;
    
`

const IngredientTitle = styled.h5`
    font-size: xx-large;
    margin-bottom: 20px;
    color: ${props => props.titleColor}
`

const StyledColumnContainer = styled(Container)`
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.secondarybackground};
    background-color: ${props => props.backcolor};
    margin-left: 0px;
    padding: 20px;
`;

const StyledRowContainer = styled(Container)`
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.secondarybackground};
    background-color: ${props => props.backcolor};
    margin-left: 0px;
    width: 100%;
    height: 100%;
    padding: 20px;
`;

const NoMarginP = styled.p`
    margin-top: -20px;
    font-family: Roboto;
    font-style: normal;
    font-size: x-large;
    color: ${props => props.textcolor};
`


const BoxParagraph = styled.p`
    font-family: Inter;
    font-style: normal;
    font-size: x-large;
    color: ${props => props.textcolor};
    margin-bottom: 10px;
`
