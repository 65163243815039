import React, { useState, useEffect } from 'react';
import { Row, Col, Navbar, NavbarToggler, NavbarBrand, NavLink, Collapse } from 'reactstrap';
import styled from 'styled-components'
import { useLocation } from "react-router-dom";
import UnbreakableIcon from "../../images/DiamondLogo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Login from '../accountNavigation/login';
import { auth } from '../../utils/firestore';
import { Colors } from "../../utils/constants";

const Header = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loginBoxOpen, setLoginBoxOpen] = useState(true);
    const [showLoginButton, setShowLoginButton] = useState(false);
    const [navbarCollapsed, setNavbarCollapsed] = useState(false);
    const location = useLocation();
    const toggle = () => setIsOpen(!isOpen);

    //TODO use this to determine sub header size
    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
          width: undefined,
          height: undefined,
        });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
          // Set window width/height to state
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
      }, []); // Empty array ensures that effect is only run on mount
      return windowSize;
    }



    useEffect(() => {
            setLoginBoxOpen(false);
            var x = document.getElementById('myNavbar');
            var canSee = window.getComputedStyle(x).display;
            if(canSee === "none"){
                setShowLoginButton(true);
                setNavbarCollapsed(false);
            }
            else{
                setShowLoginButton(false);
                setNavbarCollapsed(true);
            }
    }, [useWindowSize()]);

    const getMenuTitleElement = (elName, rightAligned = false) => {
        if (!location.pathname.localeCompare("/" + elName)){
            return <SelectedNavLink href={("/" + elName)} rightaligned={rightAligned}>
                {elName}
            </SelectedNavLink>
        }else{
            return <StyledNavLink href={("/" + elName)} rightaligned={rightAligned}>
                {elName}
            </StyledNavLink>
        }
    }

    const getDonateTitleElement = (elName) => {
        if (!location.pathname.localeCompare("/" + elName)){
            return <SelectedDonateLink href={("/" + elName)}>
                {elName}
            </SelectedDonateLink>
        }else{
            return <StyledDonateLink href={("/" + elName)}>
                {elName}
            </StyledDonateLink>
        }
    }

    const ToggleLoginBox =() => {
        setLoginBoxOpen(!loginBoxOpen);
        if(isOpen){
            setIsOpen(false);
        }
    }

    const LogOutUser = () => {
        auth.signOut().then(window.location = "/");
    }

    /* MAYBE MOVE THIS SOMEWHERE?
    const SendVerificationEmail = () => {
        auth.currentUser.sendEmailVerification().then(() => {
            window.location = "/RegisterComplete";
        })
    }
    */

    return (
        <React.Fragment>
        <StyledNavbar light expand="lg">
            <StyledNavBrand href="/">
                <HeaderIcon src={UnbreakableIcon} alt="HIMALAYAS Trial" height="75px" />
            </StyledNavBrand>
            <StyledNavToggler onClick={toggle} className="mr-2" id="myNavbar" />
            <Collapse isOpen={isOpen} navbar>
                <MenuDiv>
                    {getMenuTitleElement("About")}
                </MenuDiv>
                <MenuDiv>
                    {getMenuTitleElement("Resources")}
                </MenuDiv>
                <MenuDiv>
                    {getMenuTitleElement("Community")} 
                </MenuDiv>
                <MenuDiv>
                    {getDonateTitleElement("Donate")}
                </MenuDiv>
                <RightItems>
                    {props.user.admin && <Col>{getMenuTitleElement("Admin", true)}</Col>}
                    {(props.user !== "Guest" && auth.currentUser.emailVerified) && <Col>{getMenuTitleElement("Playbook", true)}</Col>}
                    {(props.user !== "Guest" && !auth.currentUser.emailVerified) && <Col>{getMenuTitleElement("Verify Account", true)}</Col>}
                </RightItems>
                {/*{(props.user !=="Guest" && !auth.currentUser.emailVerified) && <LogInOutButton onClick={SendVerificationEmail}>Email Unverified, Click to send</LogInOutButton>}*/}
                {(props.user === "Guest" && !showLoginButton) && <LogInOutButton navcollaped={navbarCollapsed} onClick={ToggleLoginBox}>Login</LogInOutButton> }
                {(props.user !== "Guest" && !showLoginButton) && <LogInOutButton navcollaped={navbarCollapsed} onClick={LogOutUser}>Logout</LogInOutButton>}
                
                </Collapse>
                {((navbarCollapsed && isOpen) || !navbarCollapsed) && <MediaIcon onClick={() => {window.open("https://www.facebook.com/teamunbreakableofficial/")}}>
                    <FontAwesomeIcon size="2x" icon={["fab", "facebook"]}></FontAwesomeIcon>
                </MediaIcon>}
                {((navbarCollapsed && isOpen) || !navbarCollapsed) && <MediaIcon onClick={() => {window.open("https://twitter.com/teamunbreakable")}}>
                    <FontAwesomeIcon size="2x" icon={["fab", "twitter"]}></FontAwesomeIcon>
                </MediaIcon>}
                {((navbarCollapsed && isOpen) || !navbarCollapsed) && <MediaIcon onClick={() => {window.open("https://www.instagram.com/officialteamunbreakable/")}}>
                    <FontAwesomeIcon size="2x" icon={["fab", "instagram"]}></FontAwesomeIcon>
                </MediaIcon>}
                {((navbarCollapsed && isOpen) || !navbarCollapsed) && <MediaIcon onClick={() => {window.open("https://www.linkedin.com/company/teamunbreakable/")}}>
                    <FontAwesomeIcon size="2x" icon={["fab", "linkedin"]}></FontAwesomeIcon>
                </MediaIcon>}
                {((navbarCollapsed && isOpen) || !navbarCollapsed) && <MediaIcon onClick={() => {window.open("https://www.youtube.com/channel/UCQbSXa6w1zsfBFNpZOHlcLA")}}>
                    <FontAwesomeIcon size="2x" icon={["fab", "youtube"]}></FontAwesomeIcon>
                </MediaIcon>}
                {(props.user === "Guest" && !isOpen && !navbarCollapsed) && <LogInOutButton  onClick={ToggleLoginBox}>Login</LogInOutButton> }
                {(props.user !== "Guest" && !isOpen && !navbarCollapsed) && <LogInOutButton onClick={LogOutUser}>Logout</LogInOutButton>}
        </StyledNavbar>
        {loginBoxOpen && <Login />}
        </React.Fragment>
    )
}

const MenuDiv = styled.div`
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
`

const RightItems = styled(Row)`
    margin-left: auto;
`

const MediaIcon = styled.div`
    width: 50px;
    height: 40px;
    margin-left: 5px;
    margin-right: 5px;
    align-items: center; 
    text-align: center;
    display: flex;
    justify-content: center;
    transform: perspective(1px) translateZ(0);
    position: relative;
    transition: color 0.3s;
    color: black;
    &:hover {
        color: white;
        cursor: pointer;
    }
    &:before {
        border-radius: 6px;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: ${Colors.primaryblue};
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: transform 0.3s ease-out;
    }
    &:hover:before{
        transform: scaleX(1);
    }
`

const LogInOutButton = styled(NavLink)`
    font-size: large;
    font-family: Roboto;
    text-align: center;
    color: black;
    border-radius: 10px;
    background: ${Colors.primaryblue};
    color: white;
    border-width: 1px;
    border-style: solid;
    padding-left: ${props => props.navcollaped ? "" : "10px;"}; 
    padding-right: ${props => props.navcollaped ? "" : "10px;"}; 
    padding-top: ${props => props.navcollaped ? "" : "5px;"};
    padding-bottom: ${props => props.navcollaped ? "" : "5px;"};
    &:hover {
        background: white;
        cursor: pointer;
        border-radius: 10px;
        border-color: ${Colors.primaryblue};
        border-width: 1px;
        border-style: solid;
        color: black;
    }
`

const StyledNavLink = styled(NavLink)`
    white-space: nowrap;
    font-size: large;
    font-family: Roboto;
    text-align: center;
    color: black;
    border-radius: 10px;
    &:hover {
        color: ${Colors.primaryblue};
    }
    margin-top: ${props => props.rightaligned ? "5px" : ""}
`

const SelectedNavLink = styled(NavLink)`
    white-space: nowrap;
    font-size: large;
    font-family: Roboto;
    text-align: center;
    color: ${Colors.primaryblue};
    border-radius: 10px;
    &:hover {
        color: ${Colors.primaryblue};
    }
    margin-top: ${props => props.rightaligned ? "5px" : ""}
`

const StyledDonateLink = styled(NavLink)`
    font-size: large;
    font-family: Roboto;
    text-align: center;
    color: black;
    border-radius: 10px;
    background: ${Colors.primaryblue};
    color: white;
    border-width: 1px;
    border-style: solid;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    &:hover {
        background: white;
        border-radius: 10px;
        border-color: ${Colors.primaryblue};
        border-width: 1px;
        border-style: solid;
        color: black;
    }
`

const SelectedDonateLink = styled(NavLink)`
    font-size: large;
    font-family: Roboto;
    text-align: center;
    color: ${Colors.primaryblue};
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    &:hover {
        background: white;
        border-radius: 10px;
        border-color: ${Colors.primaryblue};
        border-width: 1px;
        border-style: solid;
        color: black;
    }
`

const StyledNavToggler = styled(NavbarToggler)`
    margin-right: 3%
`

const StyledNavBrand = styled(NavbarBrand)`
    padding-left: 3%;
    padding-top: 0px;
    padding-bottom: 0px;
`

const HeaderIcon = styled.img`
    width: 80px;
    height: auto;
`

const StyledNavbar = styled(Navbar)`
    position: fixed;
    maring-bottom: 1000px;
    z-index: 1000;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
`;

export default Header