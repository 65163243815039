import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from 'reactstrap';
import { listenToDocumentProperty, updateUser } from '../../utils/firestore-functions';
import { teamUnbreakableDB } from "../../utils/firestore";
import { Colors } from '../../utils/constants';
import styled from "styled-components";

export const StudentProfile = ( props ) => {

    const [language, setLanguage] = useState(props.user.language);
    
    useEffect(() => {
        let unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "users", props.user.id, "language", setLanguage);
        return unsubscribe;
    }, []);

    const updateLanguage = (newLanguage) => {
        var data = props.user;
        data.language = newLanguage;
        updateUser(teamUnbreakableDB, props.user.id, data, () => { window.location.reload(false)});
    }

    return (
        <React.Fragment>
            {console.log(props)}
            <ColoredContainer>
                <Row>
                    <FlexCol>
                        <StyledP>Language:</StyledP>
                        {language === "English" ? <StyledButton>English</StyledButton> : <StyledButton onClick={()=> {updateLanguage("English")}} outline >English</StyledButton>}
                        {language === "French" ? <StyledButton>French</StyledButton> : <StyledButton onClick={()=> {updateLanguage("French")}} outline>French</StyledButton>}
                    </FlexCol>
                </Row>
                <Row>
                    <FlexCol>
                        <StyledAnchor download="Team-Unbreakable-Playbook-English.pdf" href="/Team-Unbreakable-Playbook-English.pdf">
                            Download English Playbook
                        </StyledAnchor>
                    </FlexCol>
                </Row>
                <Row>
                    <FlexCol>
                        <StyledAnchor download="Team-Unbreakable-Playbook-French.pdf" href="/Team-Unbreakable-Playbook-French.pdf">
                            Download French Playbook
                        </StyledAnchor>
                    </FlexCol>
                </Row>
            </ColoredContainer>
        </React.Fragment>
    );
}

export default StudentProfile;

const StyledAnchor = styled.a`
    text-decoration: none;
    color: white;
`;

const FlexCol = styled(Col)`
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
`;

const StyledButton = styled(Button)`
    margin-left: 10px;
    color: white;
    border-color: white;
`;

const StyledP = styled.p`
    margin: 0px;
    color: white;
`;

const ColoredContainer = styled(Container)`
    background-color: ${Colors.primaryblue};
    border-radius: 12px;
    color: white;
    width: 400px;
`;