import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { PageView } from "../../utils/analytics.js";

const AdminRoute = ({ title, path, user, children, ...rest }) => {

  useEffect(() => {
    document.title = title;
    PageView(title, path);
  }, []);
  
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user !== undefined){
          if(user.admin){
            return user.admin ? children : <Redirect to={{ pathname: "/login" }} />;
          }
          else{
            return (<Redirect to={{ pathname: "/not-found" }} />);
          }
        }
        else{
          return (<Redirect to={{ pathname: "/not-found" }} />);
        }      
      }}
    />
  );
};

export default AdminRoute;