import React from "react";
import { Container, Row, Col } from "reactstrap";
import styled from 'styled-components';
import YoutubeVideo from "../../common/youtube-video";
import { Colors } from "../../../utils/constants";

export const HomeElement = (props) => {   
    return(
        <React.Fragment>
            {props.type==="PictureAndCopy" &&
                <StyledContainer>
                     <Row>
                        <Col md="6" xs="12">
                            <HeightContainer>
                                <HeightRow>
                                    <HeightCol>
                                        <FillIMG src={props.imgURL} />
                                    </HeightCol>
                                </HeightRow>
                            </HeightContainer>
                        </Col>
                        <Col>
                            <Container>
                                <Row>
                                    <CenterFlexCol>
                                        <StyledTitle>
                                            {props.title}
                                        </StyledTitle>
                                    </CenterFlexCol>
                                </Row>
                                <Row>
                                    <Col>
                                        <StyledParagraph>
                                            {props.description}
                                        </StyledParagraph>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </StyledContainer>}
            {props.type==="Video" && <div>
                <Container>
                    <Row>
                        <Col>
                            <YoutubeVideo embedId={props.embedCode}></YoutubeVideo>
                        </Col>
                    </Row>
                </Container>
            </div>}
        </React.Fragment>
    );
}

export default HomeElement;

const StyledParagraph = styled.p`
    color: black;
    font-size: medium;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
`

const FillIMG = styled.img`
    width: 100%;
`;

const HeightContainer = styled(Container)`
    height: 100%;
    width: 100%;
    padding: 0px;
`;

const HeightRow = styled(Row)`
    height: 100%;
    width: 100%;
    padding: 0px;
    margin: 0px;
`;

const HeightCol = styled(Col)`
    height: 100%;
    width: 100%;
    padding: 0px;
`;

const CenterFlexCol = styled(Col)`
`;

const StyledTitle = styled.h4`
    padding-top: 15px;
    padding-bottom: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: xx-large;
    margin-bottom: 0px;
    color: ${Colors.primaryblue};
`;

const StyledContainer = styled(Container)`
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: ${Colors.primarybackground};
    padding: 0px;
`;