import React from "react";
import styled from "styled-components";

export const YoutubeVideo = ( props ) => (
  <SizeDiv>
    <StyledIframe
      src={`https://www.youtube.com/embed/${props.embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </SizeDiv>
);

export default YoutubeVideo;

const SizeDiv = styled.div`
    height: 600px;
    width: 100%;
`;

const StyledIframe = styled.iframe`
    width: 100%;
    height: 100%;
`;