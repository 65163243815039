import React, { useState } from "react";
import styled from "styled-components";
import AdminTestimonials from "./admin-about/admin-testimonials";
import AdminHome from "./admin-home/admin-home";
import AdminAbout from "./admin-about/admin-about";
import AdminMedia from "./admin-community";
import AdminResearch from "./admin-research";
import MinHeightWrapper from "../common/min-height-wrapper";
import AdminUsers from "./admin-users";
import AdminPeople from "./admin-about/admin-people";
import AdminSurvey from "./admin-survey";
import SubHeader from "../common/sub-header";
import TicketList from "./admin-tickets/admin-ticket-list";
import AdminDonor from "./admin-donors";
import AdminLinks from "./admin-links";
import ReactGA from 'react-ga';
import AdminFAQ from "./adminFaq";
import AdminData from "./admin-data";

export const Admin = () => {
    ReactGA.pageview('/admin');
    const [content, setContent] = useState("Users");

    return(
        <MinHeightWrapper>
            <SubHeader setContent={setContent} selected={content} options={[
                    {_id: "Users" },
                    {_id: "Team" },
                    {_id: "Testimonials" },
                    {_id: "Home Page" },
                    {_id: "Program" },
                    {_id: "Community" },
                    {_id: "Research" },
                    {_id: "Survey" },
                    {_id: "Tickets"},
                    {_id: "Donors"},
                    {_id: "Links"},
                    {_id: "FAQ"},
                    {_id: "Data"},
                ]}></SubHeader>
            <OffsetDiv></OffsetDiv>
            {content === "Users" && <AdminUsers />}
            {content === "Team" && <AdminPeople />}
            {content === "Testimonials" && <AdminTestimonials />}
            {content === "Home Page" && <AdminHome />}
            {content === "Program" && <AdminAbout />}
            {content === "Community" && <AdminMedia />}
            {content === "Research" && <AdminResearch />}
            {content === "Survey" && <AdminSurvey />}
            {content === "Tickets" && <TicketList />}
            {content === "Donors" && <AdminDonor />}
            {content === "Links" && <AdminLinks />}
            {content === "FAQ" && <AdminFAQ />}
            {content === "Data" && <AdminData />}
        </MinHeightWrapper> 
    ) 
}

export default Admin;

const OffsetDiv = styled.div`
    height: 180px;
`;