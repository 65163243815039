import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Button } from 'reactstrap';
import { listenToNestedDocumentProperty, listenToDocument, updateUser } from '../../utils/firestore-functions';
import { teamUnbreakableDB } from '../../utils/firestore';
import { getChallenge, points } from '../../utils/challenges';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fireworks } from '../common/fireworks'
import { Colors } from '../../utils/constants';
import ReactGA from 'react-ga';

export const StudentChallenge = ( props ) => {

    const [fireworks, setFireworks] = useState(false);
    const [challenge, setChallenge] = useState();
    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToNestedDocumentProperty(teamUnbreakableDB, "users", props.user.id, "data.challenges." + props.challenge[0], setChallenge);
        return unsubscribe;
    }, []);

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const trackChallenge = (challenge) => {
        let u = user;
        let newChallenge = getChallenge(challenge);
        u.data.currentChallenge.challengeName = newChallenge.id;
        u.data.currentChallenge.challengeLevel = newChallenge.getLevel(user)

        updateUser(teamUnbreakableDB, props.user.id, u);
        
    };

    const completeChallenge = (challenge) => {
        let c = getChallenge(challenge);
        c.levelUp(user);
        setFireworks(true);
        setTimeout(function(){setFireworks(false);}, 5000);
        ReactGA.event({
            category: 'User',
            action: 'Completed a challenge',
            label: challenge,
          });
    };

    return(
        <React.Fragment>
            {fireworks && <Fireworks />}
            {challenge !== undefined && user != undefined && <StyledContainer alternating={props.alternating}>
                <FlexRow>
                    <CenterFlexCol>
                        <StyledImg src={props.challenge[1].image} />
                    </CenterFlexCol>
                </FlexRow>
                <FlexRow>
                    <CenterFlexCol>
                        <WhiteH2>{props.challenge[1].toString()}</WhiteH2>
                    </CenterFlexCol>
                </FlexRow>
                {/*}
                <FlexRow>
                    <Col>
                        <StyledP>Level {challenge.level} - {props.challenge[1].getCurrent(user)} / {props.challenge[1].goals[challenge.level]} </StyledP>
                    </Col>
                </FlexRow>
                */}
                <FlexRow>
                    <CenterFlexCol>
                        {props.challenge[1].description()}
                    </CenterFlexCol>
                </FlexRow>
                {/*
                <FlexRow>
                    <Col>
                        Points - {points[challenge.level]}
                    </Col>
                </FlexRow>
                */}
                <BottomFlexRow>
                    <CenterFlexCol>
                        {props.challenge[1].getCurrent(user) >= props.challenge[1].goals[challenge.level] ? 
                        <CompleteButton onClick={() => {completeChallenge(props.challenge[0])}} color="primary">
                            <FAIconButton size="2x" icon={["fas", "check"]}></FAIconButton>
                        </CompleteButton> : props.selected ? <ButtonMargin><FAColoredIconButton icon={["fas", "star"]}></FAColoredIconButton>Tracked</ButtonMargin> : <ButtonMargin onClick={() => {trackChallenge(props.challenge[0])}} ><FAColoredIconButton icon={["fas", "star"]}></FAColoredIconButton>Track</ButtonMargin>}
                    </CenterFlexCol>
                </BottomFlexRow>
                <br></br>
            </StyledContainer>}
        </React.Fragment>
    )
}

export default StudentChallenge;

const BottomFlexRow = styled(Row)`
    display: flex;
    align-items: center;
    min-height: 60px;
    margin-top: 10px;
`;

const StyledImg = styled.img`
    height: 160px;
`;

const WhiteH2 = styled.h2`
    color: white;
`;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const ButtonMargin = styled(Button)`
    margin-right: 10px;
    background-color: white;
    border-style: none;
    width: 50%;
    color: ${Colors.primaryblue};
    font-weight: bold;
    height: 60px;
`;

const StyledContainer = styled(Container)`
    background-color: ${Colors.primaryblue};
    color: white;
    border-radius: 5px;
    margin-bottom: 25px;
    padding-top: 3px;
    padding-bottom: 3px;
    min-height: 250px;
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
    color:white;
`;

const FAColoredIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
    color: ${Colors.primaryblue};
`;

const CompleteButton = styled.div`
    border-radius: 50%;
    background-color: rgb(253,85,69);
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FlexRow = styled(Row)`
    display: flex;
    align-items: center;
    min-height: 50px;
`;