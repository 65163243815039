import React from "react";
import styled, { createGlobalStyle } from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import { Colors } from "../../utils/constants";

export const PrivacyPolicy = () => {
    
    let BodyStyle = createGlobalStyle`
    body {
      background-color: ${Colors.secondarygray}";
    }
  `

    return(
        <React.Fragment>
            <BodyStyle></BodyStyle> 
            <MinHeightDiv>
                <OffsetDiv></OffsetDiv>
                <ContentContainer>
                    <Row>
                        <Col>
                            <StyledH3>Our Commitment</StyledH3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Team Unbreakable is committed to protecting the privacy of the personal information of its employees, members, donors and other stakeholders. 
                                Team Unbreakable values the trust of those it deals with, and of the public, and recognizes that maintaining this trust requires transparency and accountability in how it treats the information that people choose to share. 
                                During the course of various projects and activities, Team Unbreakable frequently gathers and uses personal information. 
                                Anyone from whom Team Unbreakable collects such information should expect that it will be carefully protected and that any use of or other dealing with this information is subject to consent. 
                                Privacy practices of the charity are designed to achieve this</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StyledH3>Defining Personal Information</StyledH3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Personal information is any information that can be used to distinguish, identify or contact a specific individual. 
                                This information can include an individual’s opinions or beliefs, as well as facts about, or related to, the individual. 
                                Exceptions: business contact information and certain publicly available information, such as names, addresses and telephone numbers as published in telephone directories, are not considered personal information. 
                                Where an individual uses his or her home contact information as business contact information as well, Team Unbreakable considers that the contact information provided is business contact information, and is not therefore subject to protection as personal information.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StyledH3>Privacy Practices</StyledH3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul>
                                <li>Personal information gathered by Team Unbreakable is kept in confidence.</li>
                                <li>Team Unbreakable personnel are authorized to access personal information based only on their need to deal with the information for the reason(s) for which it was obtained.</li>
                                <li>Safeguards are in place to ensure that the information is not disclosed or shared more widely than is necessary to achieve the purpose for which it was gathered.</li>
                                <li>Team Unbreakable will take measures to ensure the integrity of this information is maintained and to prevent it being lost or destroyed.</li>
                                <li>Team Unbreakable will collect, use and disclose personal information only for purposes that a reasonable person would consider appropriate in light of the circumstances.</li>
                                <li>Team Unbreakable will routinely offer individuals it deals with the opportunity to opt not to have their information shared for purposes beyond those for which it was explicitly collected.</li>
                                <li>Team Unbreakable shall designate a Board member who shall be the Privacy Officer. The Privacy Officer is responsible for the development, monitoring and implementation of practices that ensure compliance with this policy and any relevant legislation.</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StyledH3>Website and Electronic Commerce</StyledH3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Team Unbreakable will use password protocols and encryption software to protect personal and other information we receive when a product or service is requested and/or paid for online.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StyledH3>Updating of Privacy Policy</StyledH3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Team Unbreakable will regularly review its privacy practices for its various activities, and update this policy accordingly.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StyledH3>Contact Information</StyledH3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Questions, concerns or complaints relating to Team Unbreakable’s privacy policy on the treatment of personal information can be e-mailed to: <a href="mailto:info@teamunbreakable.ca">info@teamunbreakable.ca</a></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StyledH3>Information on Privacy</StyledH3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Further information on privacy and your rights in regard to your personal information may be found on the website of the Privacy Commissioner of Canada at: <a href="www.privcom.gc.ca">www.privcom.gc.ca</a> </p>
                        </Col>
                    </Row>
                </ContentContainer>
            </MinHeightDiv>
        </React.Fragment>
    );
}

export default PrivacyPolicy;

const StyledH3 = styled.h3`
    color: ${Colors.primaryblue};
`;

const MinHeightDiv = styled.div`
    min-height: ${window.innerHeight - 149}px;
`;

const ContentContainer = styled(Container)`
    background-color: white;
    border-radius: 15px;
    border-style: solid;
    border-width: 2px;
    border-color: ${Colors.primaryblue};
    margin-top: 20px;
    margin-bottom: 20px;
`;

const OffsetDiv = styled.div`
    height: 180px;
`;
