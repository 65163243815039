import React from "react";
import { Container, Row, Col } from "reactstrap";
import styled from 'styled-components';
import { Colors } from "../../../utils/constants";

export const AboutProgram = (props) => {   
    return(
        <React.Fragment>
            <StyledContainer>
                <Row>
                    <StyledVideo controls>
                        <source src={"https://team-unbreakable-space.nyc3.cdn.digitaloceanspaces.com/videos/promo-video.mp4"} type="video/mp4" />
                        Your browser does not support the video tag.
                    </StyledVideo>
                    {props.description !== "" && <ImageCol lg="5">
                        <StyledImg src={props.url} />
                    </ImageCol>}
                    {props.description === "" && <ImageCol xs ="12">
                        <StyledImg src={props.url} />
                    </ImageCol>}
                    {props.description !== "" && <Col><br />
                        <NoMarginP>{props.description}</NoMarginP>
                    </Col>}
                </Row>
            </StyledContainer>
        </React.Fragment>
    );
}

export default AboutProgram;

const StyledVideo = styled.video`
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
`

const ImageCol = styled(Col)`
    padding-left: 0px;
`

const StyledImg = styled.img`
    width: 100%;
    height: auto;
`

const StyledContainer = styled(Container)`
    background-color: white;
    max-width: 1200px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.secondarybackground};
    margin-bottom: 30px;
    border-radius: 5px;
`;

const NoMarginP = styled.p`
    margin-bottom: 0px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
`
