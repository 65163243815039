import React, { useState, useEffect } from "react";
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from 'classnames';
import styled from "styled-components";
import { Colors } from "../../utils/constants";
import ModuleIntroduction from "./module-introduction";
import Module1 from "./module1";
import Module2 from "./module2";
import Module3 from "./module3";
import Module4 from "./module4";
import Module5 from "./module5";
import Module6 from "./module6";
import Module7 from "./module7";
import Module8 from "./module8";
import { teamUnbreakableDB } from "../../utils/firestore";
import { listenToDocument } from "../../utils/firestore-functions";

export const Modules = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const [user, setUser] = useState();

    useEffect(() => {
        let unsubscribe = listenToDocument(teamUnbreakableDB, "users", props.user.id, setUser);
        return unsubscribe;
    }, []);

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

    return(
        <React.Fragment>
            {user !== undefined && <Container>
                <Nav tabs>
                    <NavItem>
                        <StyledNavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                            Introduction
                        </StyledNavLink>
                    </NavItem>
                    {user.modules.introduction && <NavItem>
                        <StyledNavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                            Physical Health for Mental Health
                        </StyledNavLink>
                    </NavItem>}
                    {user.modules.module1 && <NavItem>
                        <StyledNavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                            Goals
                        </StyledNavLink>
                    </NavItem>}
                    {user.modules.module2 && <NavItem>
                        <StyledNavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                            Motivation
                        </StyledNavLink>
                    </NavItem>}
                    {user.modules.module3 && <NavItem>
                        <StyledNavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }}>
                            Psychology
                        </StyledNavLink>
                    </NavItem>}
                    {user.modules.module4 && <NavItem>
                        <StyledNavLink className={classnames({ active: activeTab === '6' })} onClick={() => { toggle('6'); }}>
                            Social Benefits
                        </StyledNavLink>
                    </NavItem>}
                    {user.modules.module5 && <NavItem>
                        <StyledNavLink className={classnames({ active: activeTab === '7' })} onClick={() => { toggle('7'); }}>
                            Body Image
                        </StyledNavLink>
                    </NavItem>}
                    {user.modules.module6 && <NavItem>
                        <StyledNavLink className={classnames({ active: activeTab === '8' })} onClick={() => { toggle('8'); }}>
                            Self-Compassion
                        </StyledNavLink>
                    </NavItem>}
                    {user.modules.module7 && <NavItem>
                        <StyledNavLink className={classnames({ active: activeTab === '9' })} onClick={() => { toggle('9'); }}>
                            Balance
                        </StyledNavLink>
                    </NavItem>}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <ModuleIntroduction user={props.user}></ModuleIntroduction>
                    </TabPane>
                    <TabPane tabId="2">
                        <Module1 user={props.user}></Module1>
                    </TabPane>
                    <TabPane tabId="3">
                        <Module2 user={props.user}></Module2>
                    </TabPane>
                    <TabPane tabId="4">
                        <Module3 user={props.user}></Module3>
                    </TabPane>
                    <TabPane tabId="5">
                        <Module4 user={props.user}></Module4>
                    </TabPane>
                    <TabPane tabId="6">
                        <Module5 user={props.user}></Module5>
                    </TabPane>
                    <TabPane tabId="7">
                        <Module6 user={props.user}></Module6>
                    </TabPane>
                    <TabPane tabId="8">
                        <Module7 user={props.user}></Module7>
                    </TabPane>
                    <TabPane tabId="9">
                        <Module8 user={props.user}></Module8>
                    </TabPane>
                </TabContent>
            </Container>}
        </React.Fragment>
    );
}

export default Modules;

const StyledNavLink = styled(NavLink)`
&:hover {   
    color: ${Colors.primaryblue};
    border-color: black;
    text-decoration: none;
    cursor: pointer;
}
`