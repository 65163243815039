import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { Container, Row, Col, Button, InputGroup, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { teamUnbreakableDB, generateGUID } from "../../../utils/firestore";
import { listenToDocumentProperty, updateDocumentProperty } from "../../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import AdminHomeElement from "./admin-home-element";

export const AdminHome = () => {
 
    const [image, setImage] = useState(null);
    const [topParagraph, setTopParagraph] = useState("");
    const [footerListItems, setFooterListItems] = useState("");

    const [elements, setElements] = useState({});
    const [order, setOrder] = useState(0);

    const [elementDropdownOpen, setElementDropdownOpen] = useState(false);
    const [elementDropdownValue, setElementDropdownValue] = useState("");
    const [elementTitle, setElementTitle] = useState("");
    const [elementDescription, setElementDescription] = useState("");

    const [title, setTitle] = useState({});
    const [elementURL, setElementURL] = useState("");

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "home", "elements", setElements);
        return unsubscribe;
    }, [])

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "footer", "listItems", setFooterListItems);
        return unsubscribe;
    }, [])

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "home", "title", setTitle);
        return unsubscribe;
    }, [])

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "home", "topParagraph", setTopParagraph);
        return unsubscribe;
    }, [])

    const addElement = () => {
        let id = generateGUID();
        let e = {
            order: order,
            id: id,
            type: elementDropdownValue,
            imgURL: "",
            title: elementTitle,
            description: elementDescription,
            embedCode: elementURL,
        }
        updateDocumentProperty(teamUnbreakableDB, "content", "home", "elements." + id, e); 
        clearInput();
    }

    const UpdateTitle = () => {
        updateDocumentProperty(teamUnbreakableDB, "content", "home", "title", title); 
        UpdateTopParagraph();
        UpdateFooter(); 
    }

    const UpdateTopParagraph = () => {
        updateDocumentProperty(teamUnbreakableDB, "content", "home", "topParagraph", topParagraph);
    }

    const UpdateFooter = () => {
        updateDocumentProperty(teamUnbreakableDB, "content", "footer", "listItems", footerListItems);
    }

    const UpdateFooterItem = (index, key, value) => {
        let data = [...footerListItems];
        data[index][key] = value;
        setFooterListItems(data);
    }

    const clearInput = () => {
        setOrder(0);
        setElementDropdownValue("");
        setElementTitle("");
        setElementDescription("");
    }

    const pictureAndCopy = () => {
        setElementDropdownValue("PictureAndCopy");
    }

    const video = () => {
        setElementDropdownValue("Video");
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`images/homesection/background`);
        imageRef.put(file).then(() => {
          imageRef.getDownloadURL().then((url) => {
            let data = {...title};
            data.background = url;
            updateDocumentProperty(teamUnbreakableDB, "content", "home", "title", data); 
          });
        });
      };

      const handleMobileImageUpload = (event) => {
        const file = event.target.files[0];
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`images/homesection/mobile-background`);
        imageRef.put(file).then(() => {
          imageRef.getDownloadURL().then((url) => {
            let data = {...title};
            data.mobilebackground = url;
            updateDocumentProperty(teamUnbreakableDB, "content", "home", "title", data); 
          });
        });
      };

    return(
        <Container>
            <Row>
                <CenterFlexCol>
                    <h1>Home Page Content</h1>
                </CenterFlexCol>
            </Row>
            {(Object.entries(elements).length > 0) && Object.entries(elements).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
                return <ColoredDiv key={t[0]}>
                    <AdminHomeElement id={t[0]} url={t[1].imgURL} order={t[1].order} description={t[1].description} type={t[1].type} title={t[1].title} embed={t[1].embedCode}></AdminHomeElement>
                </ColoredDiv>
            })}
            <Row>
                <Col>
                    <h2>Add Home Page Article</h2>
                </Col>
            </Row>
                <Row>
                <FlexCol xs="1">
                    <Button onClick={() => {addElement()}} color="primary">
                        <FAIconButton icon={["fas", "plus-circle"]}></FAIconButton>
                    </Button>
                </FlexCol>
                <FlexCol>
                    <InputGroup>
                        <div style={{width:"200px"}}>
                            Element Type
                        </div>
                        <Dropdown isOpen={elementDropdownOpen} toggle={() => {setElementDropdownOpen(!elementDropdownOpen)}}>
                            <DropdownToggle caret></DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => pictureAndCopy()}>Picture and Copy</DropdownItem>
                                <DropdownItem onClick={() => video()}>Video</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </InputGroup>
                </FlexCol>
            </Row>
            <Row>
                <Col>
                    {elementDropdownValue === "PictureAndCopy" && <div>
                        <StyledP>Picture and Copy</StyledP>
                        <InputGroup>
                            Order
                            <Input value={order} onChange={e => setOrder(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            Title
                            <Input value={elementTitle} onChange={e => setElementTitle(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            Description
                            <Input value={elementDescription} onChange={e => setElementDescription(e.target.value)} />
                        </InputGroup>
                    </div>}
                    {elementDropdownValue === "Video" && <div>
                        <StyledP>Video</StyledP>
                        <InputGroup>
                            Order
                            <Input value={order} onChange={e => setOrder(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            Youtube Embed Code
                            <Input value={elementURL} onChange={e => setElementURL(e.target.value)} />
                        </InputGroup>
                    </div>}
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Head Content</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                <InputGroup>
                        <div style={{width:"130px"}}>
                            Top Paragraph
                        </div>
                        <Input type="text" value={topParagraph} onChange={e => setTopParagraph(e.target.value)}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            First Word
                        </div>
                        <Input type="text" value={title.first} onChange={e => setTitle({...title, first:e.target.value})}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Second Word
                        </div>
                        <Input type="text" value={title.second} onChange={e => setTitle({...title, second:e.target.value})}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Third Word
                        </div>
                        <Input type="text" value={title.third} onChange={e => setTitle({...title, third:e.target.value})}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Final Sentence
                        </div>
                        <Input type="text" value={title.sentence} onChange={e => setTitle({...title, sentence:e.target.value})}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Paragraph 1
                        </div>
                        <Input type="text" value={title.paragraph1} onChange={e => setTitle({...title, paragraph1:e.target.value})}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Paragraph 2
                        </div>
                        <Input type="text" value={title.paragraph2} onChange={e => {setTitle({...title, paragraph2:e.target.value}); console.log(title)}}></Input>
                    </InputGroup>
                    <hr></hr>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Button 1 Text
                        </div>
                        <Input type="text" value={title.button1text} onChange={e => setTitle({...title, button1text:e.target.value})}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Button 1 URL
                        </div>
                        <Input type="text" value={title.button1url} onChange={e => setTitle({...title, button1url:e.target.value})}></Input>
                    </InputGroup>
                    <hr></hr>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Button 2 Text
                        </div>
                        <Input type="text" value={title.button2text} onChange={e => setTitle({...title, button2text:e.target.value})}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Button 2 URL
                        </div>
                        <Input type="text" value={title.button2url} onChange={e => setTitle({...title, button2url:e.target.value})}></Input>
                    </InputGroup>
                    <hr></hr>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Button 3 Text
                        </div>
                        <Input type="text" value={title.button3text} onChange={e => setTitle({...title, button3text:e.target.value})}></Input>
                    </InputGroup>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Button 3 URL
                        </div>
                        <Input type="text" value={title.button3url} onChange={e => setTitle({...title, button3url:e.target.value})}></Input>
                    </InputGroup>
                    <hr></hr>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Background
                        </div>
                        <button onClick={() => document.getElementById('imageUpload').click()}>
                            Upload Background Image
                        </button>
                        <input
                            id="imageUpload"
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            style={{ display: 'none' }}
                        />
                    </InputGroup>
                    <InputGroup>
                        <div style={{width:"130px"}}>
                            Mobile Background
                        </div>
                        <button onClick={() => document.getElementById('imageUpload').click()}>
                            Upload Mobile Background Image
                        </button>
                        <input
                            id="imageUpload"
                            type="file"
                            accept="image/*"
                            onChange={handleMobileImageUpload}
                            style={{ display: 'none' }}
                        />
                    </InputGroup>
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Footer Content</h2>
                </Col>
            </Row>
            {footerListItems !== undefined && footerListItems.length > 0 && footerListItems.map((element, index) => {
                return (
                    <InputGroup key={index}>
                        <div style={{width:"130px"}}>
                            List Item Text
                        </div>
                        <Input type="text" value={element.text} onChange={e => UpdateFooterItem(index, "text", e.target.value)}></Input>
                        <div style={{width:"130px"}}>
                            List Item URL
                        </div>
                        <Input type="text" value={element.url} onChange={e => UpdateFooterItem(index, "url", e.target.value)}></Input>
                    </InputGroup>
                )})
            }
            <hr></hr>
            <Row>
                <Col>
                <Button onClick={(e) => {UpdateTitle();}}>
                    Update
                </Button>
                </Col>
            </Row>
        </Container>
    ) 
}

export default AdminHome;

const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const StyledP = styled.p`
    margin: 0px;
`;

const ColoredDiv = styled.div`
    margin-bottom: 5px;
    padding: 5px;
    padding-top: 15px;
    background-color: rgba(0,0,0,0.05);
`;

const FlexCol = styled(Col)`
    display: flex;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;