import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, InputGroup, Input } from "reactstrap";
import { teamUnbreakableDB, generateGUID } from "../../utils/firestore";
import { listenToDocumentProperty, updateDocumentProperty } from "../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import AdminFAQItem from "./adminFAQItem";

const AdminFAQ = () => {
    const [items, setItems] = useState({});

    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [order, setOrder] = useState(0);

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(teamUnbreakableDB, "content", "faq", "items", setItems);
        return unsubscribe;
    }, []);

    const addLink = () => {
        let id = generateGUID();
        let e = {
            order: order,
            id: id,
            question: question,
            answer: answer,
        }
        updateDocumentProperty(teamUnbreakableDB, "content", "faq", "items." + id, e); 
        clearInput();
    }

    const clearInput = () => {
        setOrder(0);
        setQuestion("");
        setAnswer("");
    }

   return (<Container>
    <Row>
        <CenterFlexCol>
            <h1>FAQ</h1>
        </CenterFlexCol>
    </Row>
    {(Object.entries(items).length > 0) && Object.entries(items).sort((a,b)=>(a[1].order-b[1].order)).map(t => {
        return <ColoredDiv key={t[0]}>
            <AdminFAQItem id={t[0]} order={t[1].order} question={t[1].question} answer={t[1].answer}></AdminFAQItem>
        </ColoredDiv>
    })}
    <Row>
        <Col>
            <h2>Add Question</h2>
        </Col>
    </Row>
        <Row>
        <FlexCol xs="1">
            <Button onClick={() => {addLink()}} color="primary">
                <FAIconButton icon={["fas", "plus-circle"]}></FAIconButton>
            </Button>
        </FlexCol>
        <FlexCol>
        </FlexCol>
    </Row>
    <Row>
        <Col>
            <InputGroup>
                <div addonType="prepend">
                    Order
                </div>
                <Input value={order} onChange={e => setOrder(e.target.value)} />
            </InputGroup>
            <InputGroup>
                <div addonType="prepend">
                    Question
                </div>
                <Input value={question} onChange={e => setQuestion(e.target.value)} />
            </InputGroup>
            <InputGroup>
                <div addonType="prepend">
                    Answer
                </div>
                <Input value={answer} onChange={e => setAnswer(e.target.value)} />
            </InputGroup>
        </Col>
    </Row>
</Container>);
}
export default AdminFAQ;


const CenterFlexCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const ColoredDiv = styled.div`
    margin-bottom: 5px;
    padding: 5px;
    padding-top: 15px;
    background-color: rgba(0,0,0,0.05);
`;

const FlexCol = styled(Col)`
    display: flex;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;