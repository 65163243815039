import React, { useState } from "react";
import { Container, Row, Col, Button, InputGroup, Input, InputGroupText } from "reactstrap";
import { teamUnbreakableDB } from "../../utils/firestore";
import { updateDocumentProperty, deleteNestedDocumentProperty } from "../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ModalWindow from "../common/modalWindow";
import { Colors } from "../../utils/constants";

export const AdminFAQItem = (props) => {
    
    const [order, setOrder] = useState(props.order);
    const [question, setQuestion] = useState(props.question);
    const [answer, setAnswer] = useState(props.answer);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalConfirmOn, setModalConfirmOn] = useState(true);
    const [modalConfimFunction, setModalConfirmFunction] = useState();
    const [modalCancelText, setModalCancelText] = useState("Cancel");

    const toggle = () => setModal(!modal);

    const updateElement = () => {
        var e = {
            question: question,
            answer: answer,
            order: order,
            id: props.id
        }
        updateDocumentProperty(teamUnbreakableDB, "content", "faq", "items." + props.id, e, UpdateSuccess, UpdateFailed);
    }

    const UpdateSuccess = () => {
        setModalTitle("Successfully Updated");
        setModalMessage("Your faq item has been updated successfully");
        setModalCancelText("Okay");
        setModalConfirmOn(false);
        setModal(true);
    }

    const UpdateFailed = () => {
        setModalTitle("Update Failed");
        setModalMessage("Something went wrong when updating");
        setModalCancelText("Back");
        setModalConfirmOn(false);
        toggle();
    }

    const DeletePrompt = () => {
        setModalTitle("Delete Element?");
        setModalMessage("Are you sure you want to delete this element?");
        setModalCancelText("Cancel");
        setModalConfirmOn(true);
        setModalConfirmFunction(() => deleteElement);
        toggle();
    }

    const deleteElement = () => {
        deleteNestedDocumentProperty(teamUnbreakableDB, "content", "faq", "items", props.id);
        toggle();
    }

    return(
        <React.Fragment>
            <ModalWindow 
                cancelText={modalCancelText} 
                isOpen={modal} 
                title={modalTitle} 
                message={modalMessage} 
                cancelFunction={toggle} 
                confirmFunction={modalConfimFunction}
                showConfirmButton={modalConfirmOn} 
            />
            <Row>
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <div addonType="prepend">
                                        <InputGroupText>Order</InputGroupText>
                                    </div>
                                    <Input type="text" value={order} onChange={e => setOrder(e.target.value)}/>
                                </InputGroup>
                                <InputGroup>
                                    <div addonType="prepend">
                                        <InputGroupText>Question</InputGroupText>
                                    </div>
                                    <Input type="text" value={question} onChange={e => setQuestion(e.target.value)}/>
                                </InputGroup>
                            </Col>
                            <FlexCol xs="2">
                                <StyledButton onClick={()=> {updateElement()}} color="primary">
                                    <FAIconButton icon={["fas", "edit"]}></FAIconButton>
                                </StyledButton>
                                <StyledButton onClick={()=> {DeletePrompt()}} color="danger">
                                    <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                                </StyledButton>
                            </FlexCol>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <div addonType="prepend">
                                        <InputGroupText>Answer</InputGroupText>
                                    </div>
                                    <Input type="textarea" value={answer} onChange={e => setAnswer(e.target.value)}/>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </React.Fragment>
    ) 
}

export default AdminFAQItem;

const StyledButton = styled(Button)`
    margin-left: 5px;
`;

const FlexCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledProfileImage = styled.img`
    padding: 5px;
    box-shadow: 0 0.5rem 1rem ${Colors.primaryblue};
    max-width: 300px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: -20px;
`